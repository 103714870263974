import { ShieldCheckIcon } from "@heroicons/react/24/solid";
import {
  Add,
  CheckCircle,
  EditOutlined,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  Button,
  Chip,
  Divider,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { countries } from "../../components/Countries";
import showErrorToast from "../../components/Error";
import FlagById from "../../components/FlagById";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { PolicyBullet } from "../../components/PasswordPolicy/PolicyBullet";
import { SuccessModal } from "../../components/SuccessModal";
import KycSnackBar from "../../components/kyc/KycSnackBar";
import { BusinessSessionHelper } from "../../helpers/business-session.helper";
import { BusinessService } from "../../services/business.service";
import { animateSelectStyle } from "../../styles/select";
import { IBusinessResponse } from "../../types/business";

export default function ProfileBusinesses({
  businesses,
  onRefreshBusinesses,
}: {
  businesses?: IBusinessResponse[];
  onRefreshBusinesses?: () => void;
}) {
  const navigate = useNavigate();

  const [selectedBusiness, setSelectedBusiness] =
    useState<IBusinessResponse | null>(businesses?.[0] ?? null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { t } = useTranslation();

  const removeBusiness = () => {
    setShowDeleteConfirmation(true);
  };

  const handleSelectBusiness = (business: IBusinessResponse) => {
    setSelectedBusiness(business);
    BusinessSessionHelper.SetActiveBusiness(business).catch((err) =>
      toast(showErrorToast(err), { duration: 4000 })
    );
  };

  const onConfirmRemoveBusiness = () => {
    if (!selectedBusiness) return;
    setShowDeleteConfirmation(false);
    setLoading(true);
    BusinessService.deleteBusiness(selectedBusiness?.id)
      .then(() => {
        setSelectedBusiness(null);
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          onRefreshBusinesses?.();
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast(showErrorToast(err), { duration: 4000 });
      });
  };

  useEffect(() => {
    if ((businesses?.length ?? 0) > 0) {
      const activeBusiness = BusinessSessionHelper.Business();
      if (activeBusiness) {
        const foundBusiness = businesses?.find(
          (business) => business.id === activeBusiness?.id
        );
        if (foundBusiness) {
          setSelectedBusiness(foundBusiness);
          BusinessSessionHelper.SetActiveBusiness(foundBusiness).catch((err) =>
            toast(showErrorToast(err), { duration: 4000 })
          );
        }
      } else {
        setSelectedBusiness(businesses![0]);
        BusinessSessionHelper.SetActiveBusiness(businesses![0]).catch((err) =>
          toast(showErrorToast(err), { duration: 4000 })
        );
      }
    }
  }, [businesses]);

  const BusinessDetailItem = ({
    title,
    description,
  }: {
    title: string;
    description: string;
    isEmail?: boolean;
    onEditClick?: () => void;
  }) => {
    return (
      <Stack
        alignItems="start"
        direction="column"
        justifyContent="stretch"
        sx={{ position: "relative" }}
        minWidth={"200px"}
        width={{
          xs: "auto",
          md: "420px",
        }}
      >
        <Typography overflow={"auto"} fontSize={"sm"} fontWeight="sm">
          {title}
        </Typography>
        <Typography textColor={"neutral.900"} fontSize={"md"} fontWeight={"lg"}>
          {description}
        </Typography>
      </Stack>
    );
  };

  const BusinessDetail = ({ business }: { business?: IBusinessResponse }) => {
    const bankInfo =
      (business?.bankInfo?.length ?? 0) > 0 ? business?.bankInfo[0] : null;
    return (
      <Stack
        alignItems="start"
        direction="column"
        justifyContent="stretch"
        sx={{ position: "relative", width: { xs: "90vw", lg: "100%" } }}
      >
        <Stack gap={2} direction="row" alignItems="start">
          <Stack
            gap={2}
            direction="column"
            alignItems="start"
            justifyContent={"start"}
          >
            <BusinessDetailItem
              title={t("landing.signup.phoneNumber")}
              description={`+(${business?.companyNumberCountryCode}) ${business?.companyNumber}`}
            />
            <BusinessDetailItem
              title={t("profile.business.companyWebsite")}
              description={business?.companyWebsite ?? "-"}
            />
          </Stack>
          {/* <Stack direction="column" alignItems="start" justifyContent={"start"}>
            <BusinessDetailItem
              title={"Email"}
              description={business?.companyEmail ?? "-"}
            />
          </Stack> */}
        </Stack>
        <br />
        <Divider />
        <br />
        <Stack gap={2} direction="row" alignItems="start">
          <Stack
            gap={2}
            direction="column"
            alignItems="start"
            justifyContent={"start"}
          >
            <BusinessDetailItem
              title={t("profile.business.companyAddress")}
              description={business?.companyAddress ?? "-"}
            />
            <BusinessDetailItem
              title={t("profile.business.province")}
              description={business?.companyProvince ?? "-"}
            />
          </Stack>
          <Stack direction="column" alignItems="start" justifyContent={"start"}>
            <BusinessDetailItem
              title={t("profile.business.country")}
              description={
                countries.find((c) => c.code === business?.companyCountry)
                  ?.label ?? "-"
              }
            />
          </Stack>
        </Stack>
        <br />
        <Divider />
        <br />
        <Stack gap={2} direction="row" alignItems="start">
          <Stack
            gap={2}
            direction="column"
            alignItems="start"
            justifyContent={"start"}
          >
            <BusinessDetailItem
              title={t("profile.business.companyCurrency")}
              description={bankInfo?.currency ?? "-"}
            />
            <BusinessDetailItem
              title={t("profile.business.companyBankName")}
              description={bankInfo?.bankName ?? "-"}
            />
          </Stack>
          <Stack
            gap={2}
            direction="column"
            alignItems="start"
            justifyContent={"start"}
          >
            <BusinessDetailItem
              title={t("profile.business.companyBankAccountNo")}
              description={bankInfo?.accountNo ?? "-"}
            />
            <BusinessDetailItem
              title={t("profile.business.companyBankAccountHolderName")}
              description={bankInfo?.accountHolderName ?? "-"}
            />
          </Stack>
        </Stack>
        <ul style={{ paddingLeft: "1rem" }}>
          {business?.companyPaymentTerms?.map((term) => (
            <li key={term}>
              <Typography startDecorator={<PolicyBullet />}>{term}</Typography>
            </li>
          )) ?? "-"}
        </ul>
        <br />
      </Stack>
    );
  };

  return (
    <Stack
      alignItems="stretch"
      direction="column"
      justifyContent="space-between"
      spacing={2}
      width={"100%"}
      sx={{ position: "relative" }}
    >
      <br />
      <Stack
        gap={2}
        direction={{
          xs: "column",
          md: "row",
        }}
        alignItems={{
          xs: "start",
          md: "center",
        }}
        justifyContent={"space-between"}
      >
        <Typography
          flex={1}
          fontSize={"md"}
          textColor={"neutral.900"}
          fontWeight="lg"
        >
          {t("profile.business.currentLoggedInCompanyTitle")}
        </Typography>
        <Stack gap={1} direction={"row"}>
          {BusinessSessionHelper.IsBusinessAdmin() && (
            <Button
              startDecorator={<EditOutlined />}
              onClick={() =>
                navigate(`business/${selectedBusiness?.id}`, {
                  state: selectedBusiness,
                })
              }
              size="sm"
              variant="outlined"
              color="primary"
            >
              {t("profile.business.editCompany")}
            </Button>
          )}
          {BusinessSessionHelper.IsOwner() && (
            <Button
              onClick={() => removeBusiness()}
              size="sm"
              variant="outlined"
              color="danger"
            >
              {t("profile.business.removeCompany")}
            </Button>
          )}
        </Stack>
      </Stack>
      <Select
        key={selectedBusiness?.id}
        value={selectedBusiness}
        onChange={(_, value) => {
          if (!value) return;
          handleSelectBusiness(value as IBusinessResponse);
        }}
        renderValue={(option) => (
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            alignItems={{
              xs: "start",
              md: "center",
            }}
            justifyContent={"start"}
          >
            <Typography
              fontSize={"md"}
              startDecorator={
                <FlagById id={option?.value?.companyCountry ?? ""} />
              }
            >
              {option?.label}
            </Typography>
            {selectedBusiness?.id &&
              (selectedBusiness as any).kycPaymentGatewayStatus ===
                "VALIDATED" && (
                <Chip
                  color="success"
                  startDecorator={<ShieldCheckIcon width={20} />}
                  sx={{
                    padding: "0.5rem",
                    borderRadius: "0.5rem",
                    marginLeft: {
                      xs: "1.5rem",
                      md: "0.5rem",
                    },
                  }}
                  size="sm"
                >
                  <Typography fontSize={"xs"} fontWeight={"sm"}>
                    Validated
                  </Typography>
                </Chip>
              )}
          </Stack>
        )}
        variant="outlined"
        indicator={<KeyboardArrowDown />}
        sx={{
          ...animateSelectStyle,
          backgroundColor: "white",
          borderColor: "neutral.500",
        }}
      >
        {businesses
          ?.sort(
            (a, b) =>
              (a.id === selectedBusiness?.id ? 0 : 1) -
              (b.id === selectedBusiness?.id ? 0 : 1)
          )
          .map((business) => (
            <Option value={business} label={business.companyName}>
              <Stack
                sx={{ width: "100%" }}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={5}
              >
                <div>
                  <Typography
                    sx={{ flex: 1 }}
                    startDecorator={<FlagById id={business.companyCountry} />}
                  >
                    {business.companyName}
                  </Typography>
                  {business.id === selectedBusiness?.id && (
                    <Typography fontSize={12} textColor="neutral.500">
                      {t("profile.business.currentLoggedInCompanyLabel")}
                    </Typography>
                  )}
                </div>
                {business.id === selectedBusiness?.id && (
                  <CheckCircle color="primary" />
                )}
              </Stack>
            </Option>
          ))}
      </Select>
      {selectedBusiness?.kycPaymentGatewayStatus !== "VALIDATED" &&
        !BusinessSessionHelper.IsValidatedKYCPaymentGateway() && (
          <KycSnackBar />
        )}
      {selectedBusiness && (
        <div style={{ marginTop: "1rem" }}>
          <BusinessDetail business={selectedBusiness!} />
        </div>
      )}
      {BusinessSessionHelper.IsOwner() && (
        <>
          <Divider />
          <Stack direction="row" justifyContent="start">
            <Button
              sx={{
                width: {
                  xs: "100%",
                  md: "auto",
                },
              }}
              onClick={() => navigate("business")}
              startDecorator={<Add />}
              variant="solid"
            >
              {t("profile.business.addCompany")}
            </Button>
          </Stack>
        </>
      )}
      <ConfirmationModal
        open={showDeleteConfirmation}
        title={`${t("profile.business.removeCompany")} ${
          selectedBusiness?.companyName
        }?`}
        content={`${t("commons.confirmationDesc")}`}
        onConfirm={() => {
          onConfirmRemoveBusiness();
        }}
        onCancel={() => setShowDeleteConfirmation(false)}
      />
      <SuccessModal
        open={success}
        title={`${t("commons.successModalTitle")}`}
        content={`${t("profile.business.removeBusinessSuccess")}`}
      />
      <LoadingModal isLoading={loading} />
    </Stack>
  );
}
