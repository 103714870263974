import {
  Autocomplete,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import { useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { DISCOUNT_TYPE, TaxAmountTypes } from "../../utils/constants";
import { formatPrice } from "../../utils/formatPrice";
import { calcDiscountAmountByType } from "../../utils/order";
import {
  PaymentFlowTypeEnum,
  TaxAmountTypeEnum,
  TaxTypeEnum,
} from "../../types/order";

const ThreeColumnGridRow = ({ label, inputElement, outputValue }: any) => {
  return (
    <Stack direction={"row"} sx={{ width: "100%" }}>
      <Grid
        container
        columns={{ xs: 12 }}
        flexGrow={1}
        alignItems={"start"}
        rowGap={2}
      >
        <Grid xs={12} lg={4}>
          <FormLabel sx={{ mt: { xs: 0, lg: 2 } }}>{label}</FormLabel>
        </Grid>
        <Grid>{inputElement}</Grid>
      </Grid>

      <Stack
        width={{ xs: "50%", lg: "33.33%" }}
        textAlign={"right"}
        sx={{ mt: { xs: 0, lg: 2 }, ml: "auto" }}
      >
        {outputValue}
      </Stack>
    </Stack>
  );
};

const GrandTotal = ({
  sellerCountry,
  nationalTaxes,
  isReadOnly,
  isAdjustmentOrder,
}: any) => {
  const { t } = useTranslation();
  const {
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useFormContext();
  const watchedProducts = useWatch({ name: "orderItems" });

  // const watchedProducts = watch(`orderItems`);
  // const [watchedAdditionalDiscount, watchedAdditionalDiscountType] = useWatch({ name: ["additionalDiscount", "additionalDiscountType"] });
  // const [watchedDeliveryFee, watchedAdditionalTax] = watch([
  //   "deliveryFee",
  //   "additionalTax",
  // ]);

  const calcTotalAmountWithoutAdditionalDiscount = (oItems: any) => {
    return oItems?.reduce((acc: any, product: any) => {
      // let itemPrice = (product.price || 0) * (product.quantity || 0);
      // const discount = calcDiscountAmountByType(
      //   product.discount,
      //   product.discountType,
      //   itemPrice
      // );

      return (
        acc +
        (Number.isNaN(product.itemTotalAmount) ? 0 : product.itemTotalAmount)
      );
    }, 0);
  };

  const calcAdditionalDiscount = () => {
    let amount =
      calcTotalAmountWithoutAdditionalDiscount(getValues("orderItems")) +
      getValues("deliveryFee");

    return calcDiscountAmountByType(
      getValues("additionalDiscount"),
      getValues("additionalDiscountType"),
      amount
    );
  };

  const calculateSubTotal = (oItems: any) => {
    let totalAmountWithoutAdditionalDiscount =
      calcTotalAmountWithoutAdditionalDiscount(oItems);
    let totalAmountWithDeliveryFee =
      totalAmountWithoutAdditionalDiscount + (getValues("deliveryFee") || 0);

    return (
      totalAmountWithDeliveryFee -
      calcDiscountAmountByType(
        getValues("additionalDiscount"),
        getValues("additionalDiscountType"),
        totalAmountWithDeliveryFee
      )
    );
  };

  const calculateOrderItemsTaxTotal = (products: any) => {
    console.log("calculateOrderItemsTaxTotal >>>", products);
    return products?.reduce((acc: any, product: any) => {
      // let itemPrice = (product.price || 0) * (product.quantity || 0);
      // const discount = calcDiscountAmountByType(
      //   product.discount,
      //   product.discountType,
      //   itemPrice
      // );

      // let itemPriceWithDiscount = itemPrice - discount;

      if (product.taxItem?.taxAmount !== 0) {
        if (product.taxItem?.taxType === TaxTypeEnum.PPH_GROSS_UP) {
          return (
            acc +
            product.itemTotalAmount * ((product.taxItem?.taxAmount || 0) / 100)
          );
        } else if (product.taxItem?.taxType === TaxTypeEnum.PPN_INCLUSIVE) {
          return (
            acc +
            (product.itemPriceWithDiscount *
              ((product.taxItem?.taxAmount || 0) / 100)) /
              (1 + (product.taxItem?.taxAmount || 0) / 100)
          );
        } else if (
          product.taxItem?.taxType === TaxTypeEnum.PPH_NPWP ||
          product.taxItem?.taxType === TaxTypeEnum.PPN_EXCLUSIVE ||
          product.taxItem?.taxType === TaxTypeEnum.GST
        ) {
          return (
            acc +
            product.itemPriceWithDiscount *
              ((product.taxItem?.taxAmount || 0) / 100)
          );
        } else if (product.taxItem?.taxType === TaxTypeEnum.MANUAL) {
          return product.taxItem?.taxAmountType === TaxAmountTypeEnum.FIXED
            ? acc + (product.taxItem?.taxAmount || 0)
            : acc +
                product.itemPriceWithDiscount *
                  ((product.taxItem?.taxAmount || 0) / 100);
        }
      }
      return acc;
    }, 0);
  };

  const calculateTotalDiscount = (oItems: any) => {
    return (
      oItems?.reduce((acc: any, product: any) => {
        let itemPrice = (product.price || 0) * (product.quantity || 0);

        return (
          acc +
          calcDiscountAmountByType(
            product.discount,
            product.discountType,
            itemPrice
          )
        );
      }, 0) + calcAdditionalDiscount()
    );
  };

  const calculateGrandTotalAmount = (
    subTotal: number,
    additionalTax: number,
    additionalTaxAmountType: string = TaxAmountTypeEnum.PERCENTAGE
  ): number => {
    const calGTA =
      additionalTaxAmountType === TaxAmountTypeEnum.FIXED
        ? subTotal + (additionalTax || 0)
        : subTotal + subTotal * ((additionalTax || 0) / 100);
    return Number(calGTA.toFixed(2));
  };

  const additionalTaxDisplayAmount =
    getValues("additionalTaxItem.taxAmountType") === TaxAmountTypeEnum.FIXED
      ? getValues("additionalTaxItem.taxAmount")
      : (calculateSubTotal(getValues("orderItems")) *
          getValues("additionalTaxItem.taxAmount")) /
        100;

  function handleGrandTotalAllCalculations() {
    calcTotalAmountWithoutAdditionalDiscount(getValues("orderItems"));
    calcAdditionalDiscount();
    calculateTotalDiscount(getValues("orderItems"));
    calculateOrderItemsTaxTotal(getValues("orderItems"));
    console.log(
      "Tax Amount Before calculate Grand Total >>>",
      getValues("additionalTaxItem.taxAmount"),
      getValues("additionalTaxItem.taxAmountType")
    );
    const calculatedGTA = calculateGrandTotalAmount(
      calculateSubTotal(getValues("orderItems")),
      getValues("additionalTaxItem.taxAmount"),
      getValues("additionalTaxItem.taxAmountType")
    );
    setValue(
      "grandTotalAmount",
      getValues("currency") === "IDR" ? Math.ceil(calculatedGTA) : calculatedGTA
    );
  }

  useEffect(() => {
    console.log("Watched Products from Grand Total :::::", watchedProducts);
    handleGrandTotalAllCalculations();
  }, [watchedProducts]);

  return (
    <Stack
      spacing={2}
      p={3}
      sx={{
        backgroundColor: "var(--joy-palette-primary-50)",
        borderRadius: "16px",
        boxShadow: "var(--joy-shadow-md)",
      }}
    >
      <ThreeColumnGridRow
        label={t("order.createOrder.grandTotalForm.deliveryFee")}
        inputElement={
          <Controller
            name={"deliveryFee"}
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <NumericFormat
                name={name}
                value={value}
                customInput={Input}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={getValues("currency") === "IDR" ? "." : ","}
                decimalSeparator={getValues("currency") === "IDR" ? "," : "."}
                sx={{ width: 260 }}
                placeholder={""}
                startDecorator={<>{getValues("currency")}</>}
                onValueChange={(v) => {
                  onChange(Number(v.value) || 0);
                  handleGrandTotalAllCalculations();
                }}
                disabled={isReadOnly || isAdjustmentOrder}
              />
            )}
          />
        }
        outputValue={
          (getValues("currency") || "") +
          " " +
          formatPrice(getValues("deliveryFee") || 0, getValues("currency"))
        }
      />
      <ThreeColumnGridRow
        label={t("order.createOrder.grandTotalForm.additionalDiscount")}
        inputElement={
          <FormControl>
            <Controller
              name={"additionalDiscount"}
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <NumericFormat
                  name={name}
                  value={value}
                  customInput={Input}
                  // valueIsNumericString={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={
                    getValues("currency") === "IDR" ? "." : ","
                  }
                  decimalSeparator={getValues("currency") === "IDR" ? "," : "."}
                  {...(getValues(`additionalDiscountType`) ===
                    DISCOUNT_TYPE.percentage.value && {
                    isAllowed: (values) => {
                      const { floatValue } = values;
                      return floatValue === undefined || floatValue <= 100; // Limit input to 100%
                    },
                  })}
                  sx={{ width: 260 }}
                  placeholder={t(
                    "order.createOrder.grandTotalForm.additionalDiscountPlaceholder"
                  )}
                  endDecorator={
                    <>
                      <Divider orientation="vertical" />
                      <FormControl>
                        <Controller
                          name={"additionalDiscountType"}
                          defaultValue={DISCOUNT_TYPE.percentage.value}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              variant="plain"
                              value={value}
                              onChange={(_, value) => {
                                onChange(value);
                                setValue(`additionalDiscount`, 0);
                              }}
                              slotProps={{
                                listbox: {
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "&:hover": { bgcolor: "transparent" },
                                // ml: -1.5,
                                pt: 0,
                                pb: 0,
                                minHeight: "auto",
                              }}
                            >
                              <Option value={DISCOUNT_TYPE.percentage.value}>
                                {DISCOUNT_TYPE.percentage.label}
                              </Option>
                              <Option value={DISCOUNT_TYPE.amount.value}>
                                {DISCOUNT_TYPE.amount.label}
                              </Option>
                            </Select>
                          )}
                        />
                      </FormControl>
                    </>
                  }
                  onValueChange={(v) => {
                    onChange(Number(v.value) || 0);
                    handleGrandTotalAllCalculations();
                  }}
                  disabled={isReadOnly || isAdjustmentOrder}
                />
              )}
            />
          </FormControl>
        }
        outputValue={
          (getValues("currency") || "") +
          " " +
          formatPrice(calcAdditionalDiscount(), getValues("currency"))
        }
      />

      <FormControl orientation="horizontal">
        <FormLabel sx={{ flex: 1 }}>
          {t("order.createOrder.grandTotalForm.subtotal")}
        </FormLabel>
        {getValues("currency") || ""}{" "}
        {formatPrice(
          calculateSubTotal(getValues("orderItems") || 0),
          getValues("currency")
        )}
      </FormControl>

      {getValues("orderItems").every(
        (item: any) =>
          item.taxItem?.taxType === null && item.taxItem?.taxAmount === 0
      ) && (
        <ThreeColumnGridRow
          label={t("order.createOrder.grandTotalForm.additionalTax")}
          inputElement={
            <>
              <Controller
                name={`additionalTaxItem`}
                defaultValue={{
                  taxLabel: "NONE",
                  taxAmount: 0,
                  taxType: null,
                }}
                control={control}
                render={({
                  field: { onChange, onBlur, name, value, ref },
                  fieldState: { error },
                }) => (
                  <FormControl sx={{ flex: "1" }} error={!!error} ref={ref}>
                    <Autocomplete
                      name={name}
                      sx={{ width: 260 }}
                      placeholder={t(
                        "order.createOrder.grandTotalForm.additionalTaxPlaceholder"
                      )}
                      options={nationalTaxes || []}
                      getOptionLabel={(option) => option?.taxLabel || "NONE"}
                      onChange={(_event, newValue: any) => {
                        onChange(newValue);

                        if (newValue) {
                          // setValue(`additionalTaxLabel`, newValue.taxLabel);
                          // setValue(`additionalTax`, newValue.taxAmount);
                          // setValue(`additionalTaxType`, newValue.taxType);

                          /** Reset each orderItem's tax to NONE */
                          setValue(
                            "orderItems",
                            getValues("orderItems").map((item: any) => ({
                              ...item,
                              taxItem: {
                                taxLabel: "NONE",
                                taxAmount: 0,
                                taxType: null,
                              },
                            }))
                          );
                        } else {
                          /** If clicked the cross 'x' button */
                          setValue(`additionalTaxItem`, {
                            taxLabel: "NONE",
                            taxAmount: 0,
                            taxType: null,
                          });
                          // setValue(`additionalTaxLabel`, "NONE");
                          // setValue(`additionalTax`, 0);
                          // setValue(`additionalTaxType`, null);
                        }
                        console.log(
                          "Additional Tax Item changed? ::",
                          newValue
                        );
                        handleGrandTotalAllCalculations();
                      }}
                      onBlur={onBlur}
                      value={value}
                      disabled={isReadOnly || isAdjustmentOrder}
                    />
                    {/* <FormHelperText>{error?.message}</FormHelperText> */}
                  </FormControl>
                )}
              />

              {/* Manual Tax Amount */}
              {getValues(`additionalTaxItem.taxType`) ===
                TaxTypeEnum.MANUAL && (
                <FormControl>
                  <Controller
                    name={`additionalTaxItem.taxAmount`}
                    defaultValue={0}
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <NumericFormat
                        name={name}
                        value={value}
                        customInput={Input}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={
                          getValues("currency") === "IDR" ? "." : ","
                        }
                        decimalSeparator={
                          getValues("currency") === "IDR" ? "," : "."
                        }
                        {...(getValues(`additionalTaxItem.taxAmountType`) ===
                          TaxAmountTypeEnum.PERCENTAGE && {
                          isAllowed: (values) => {
                            const { floatValue } = values;
                            return (
                              floatValue === undefined || floatValue <= 100
                            ); // Limit input no more than 100%
                          },
                        })}
                        sx={{ width: 260, mt: 2 }}
                        placeholder={t(
                          "order.createOrder.productForm.otherTaxAmount"
                        )}
                        onValueChange={(v) => {
                          console.log("Additional Tax Amount changed ???", v);
                          onChange(Number(v.value) || 0);
                          handleGrandTotalAllCalculations();
                        }}
                        disabled={isReadOnly || isAdjustmentOrder}
                        endDecorator={
                          <>
                            <Divider orientation="vertical" />
                            <Controller
                              name={`additionalTaxItem.taxAmountType`}
                              defaultValue={TaxAmountTypeEnum.PERCENTAGE}
                              control={control}
                              render={({
                                field: { name, onChange, value, ref },
                              }) => (
                                <Select
                                  variant="plain"
                                  name={name}
                                  value={value}
                                  ref={ref}
                                  onChange={(_, value) => {
                                    onChange(value);
                                    console.log(
                                      "Tax Amount Type changed? ::",
                                      value
                                    );

                                    // reset taxAmount to 0 when taxAmountType is changed
                                    setValue(`additionalTaxItem.taxAmount`, 0);
                                    handleGrandTotalAllCalculations();
                                  }}
                                  sx={{
                                    "&:hover": { bgcolor: "transparent" },
                                    pt: 0,
                                    pb: 0,
                                    minHeight: "auto",
                                  }}
                                >
                                  <Option
                                    value={TaxAmountTypes.percentage.value}
                                  >
                                    {TaxAmountTypes.percentage.label}
                                  </Option>
                                  <Option value={TaxAmountTypes.fixed.value}>
                                    {TaxAmountTypes.fixed.label}
                                  </Option>
                                </Select>
                              )}
                            />
                          </>
                        }
                      />
                    )}
                  />
                </FormControl>
              )}
            </>
          }
          outputValue={
            (getValues("currency") || "") +
              " " +
              formatPrice(additionalTaxDisplayAmount, getValues("currency")) ||
            0
          }
        />
      )}

      <Divider
        sx={{
          borderStyle: "dashed",
          borderWidth: "0.5px",
          borderColor: "#636B744D",
        }}
      />

      <FormControl orientation="horizontal" sx={{ width: "100%" }}>
        <FormLabel sx={{ flex: 1, fontWeight: 700 }}>
          {t("order.createOrder.grandTotalForm.grandTotalAmount")}
        </FormLabel>
        <Typography fontWeight={700} fontSize={{ xs: 18, lg: 20 }}>
          {getValues("currency") || ""}{" "}
          {formatPrice(
            getValues("grandTotalAmount") || 0,
            getValues("currency")
          )}
          {/* {Intl.NumberFormat("en-US").format(watchedGrandTotalAmount)} */}
        </Typography>
      </FormControl>

      {sellerCountry === "ID" &&
        getValues("additionalTaxItem.taxType") === null &&
        getValues("additionalTaxItem.taxAmount") === 0 && (
          <FormControl orientation="horizontal">
            <FormLabel sx={{ flex: 1 }}>
              {t("order.createOrder.grandTotalForm.totalTax")}
            </FormLabel>
            <Typography>
              {getValues("currency") || ""}{" "}
              {formatPrice(
                calculateOrderItemsTaxTotal(getValues("orderItems")) || 0,
                getValues("currency")
              )}
            </Typography>
          </FormControl>
        )}

      <FormControl orientation="horizontal">
        <FormLabel sx={{ flex: 1 }}>
          {t("order.createOrder.grandTotalForm.totalDiscount")}
        </FormLabel>
        <Typography>
          {getValues("currency") || ""}{" "}
          {formatPrice(
            calculateTotalDiscount(getValues("orderItems")) || 0,
            getValues("currency")
          )}
        </Typography>
      </FormControl>

      {getValues("paymentFlowType") === PaymentFlowTypeEnum.SPLIT_PAYMENT && (
        <>
          <Divider
            sx={{
              borderStyle: "dashed",
              borderWidth: "0.5px",
              borderColor: "#636B744D",
            }}
          />

          {/* Currently, Payable Amount behaves as Down (Initial) Payment */}
          <ThreeColumnGridRow
            label={t("order.createOrder.grandTotalForm.downPayment")}
            inputElement={
              <Controller
                name={"downPayment"}
                control={control}
                rules={{
                  validate: {
                    lessThanGrandTotal: (value) => {
                      if (isAdjustmentOrder) {
                        return true;
                      }

                      return (
                        value < getValues("grandTotalAmount") ||
                        "The amount must be less than Grand Total Amount"
                      );
                    },
                    greaterThanZero: (value) => {
                      return value > 0 || "The amount cannot be 0";
                    },
                  },
                }}
                render={({
                  field: { onChange, ref, name, value },
                  fieldState: { error },
                }) => (
                  <>
                    <FormControl error={!!error} ref={ref}>
                      <NumericFormat
                        name={name}
                        value={value}
                        customInput={Input}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={
                          getValues("currency") === "IDR" ? "." : ","
                        }
                        decimalSeparator={
                          getValues("currency") === "IDR" ? "," : "."
                        }
                        isAllowed={(values) => {
                          const { floatValue } = values;
                          return (
                            floatValue === undefined || floatValue >= 0
                            // (floatValue >= 0 &&
                            //   floatValue < getValues("grandTotalAmount")) // Limit input to grand total amount
                          );
                        }}
                        placeholder={""}
                        startDecorator={<>{getValues("currency")}</>}
                        onValueChange={(v) => {
                          onChange(Number(v.value) || 0);
                        }}
                        disabled={isReadOnly || isAdjustmentOrder}
                        sx={{ width: 260 }}
                      />
                      {/** This error message snippet can also be used
                       * to display error message outside of this component
                       * */}
                      {errors.downPayment && (
                        <FormHelperText
                          color="danger"
                          style={{ marginTop: "4px" }}
                        >{`${errors.downPayment.message}`}</FormHelperText>
                      )}
                    </FormControl>
                  </>
                )}
              />
            }
            outputValue={
              (getValues("currency") || "") +
              " " +
              formatPrice(getValues("downPayment") || 0, getValues("currency"))
            }
          />
        </>
      )}
    </Stack>
  );
};

export default GrandTotal;
