import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormLabel,
  Input,
  List,
  ListItem,
  Option,
  Select,
  Sheet,
  Stack,
  Table,
  Typography,
} from "@mui/joy";
import TablePagination from "./components/TablePagination";
import UploadImageBtn from "../../components/Inventory/Items/UploadImageBtn";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { ItemService } from "../../services/item.service";
import { useCallback, useEffect, useState } from "react";
import useContainer from "./useContainer";
import { getActiveBusiness } from "../../store/session";
import { useTranslation } from "react-i18next";
import { CatalogType } from "../../types/catalog";
import { InventoryService } from "../../services/inventory.service";
import { IfElse } from "../../components/Condition";
import UpdateQuantityModal from "../../components/Inventory/UpdateQuantityModal";
import { ItemType } from "../../types/item";
import PageTitleBox from "./components/PageTitleBox";
import { MagnifyingGlassIcon, PlusIcon } from "@heroicons/react/24/outline";
import InfiniteScroll from "react-infinite-scroller";
import { theme } from "../../components/Theme";
import { formatPrice } from "../../utils/formatPrice";

const ManageCatalogueItems = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { catalogId } = useParams();

  const [flexiColumns, setFlexiColumns] = useState<string[]>();
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [catalog, setCatalog] = useState<CatalogType>();
  const [unauthorized, setUnauthorized] = useState(false);
  const [selectedQuantityItem, setSelectedQuantityItem] = useState<
    { show: boolean; item: ItemType } | undefined
  >();

  const {
    handlePaging,
    handleItemFlexiCols,
    fetchInventoryItems,
    setIsLoading,
    setShowAlert,
    setAlertMessage,
    pagination,
    setPagination,
    inventoryItems,
    setInventoryItems,
    searchTerm,
    setSearchTerm,
    setShowToast,
    hasMore,
  } = useContainer();

  const initiateInventoryItems = useCallback((businessId: string) => {
    if (!catalogId) {
      return;
    }

    ItemService.getInventoryItems({
      businessId: businessId,
      paging: true,
      limit: 50,
      offset: 0,
      sortBy: "sku",
    })
      .then((itemRes) => {
        console.log("BANANA ITEM RES", itemRes);
        setShowAlert(false);
        setInventoryItems(itemRes.data);
        if (itemRes.pagination) {
          setPagination(itemRes.pagination);
        }
        return ItemService.getInventoryItemField({
          fields: ["flexiColumns", "inventoryType"],
          businessId,
        });
      })
      .then((filterRes) => {
        const { allFlexiColumns } = handleItemFlexiCols(filterRes);
        setFlexiColumns(allFlexiColumns);

        const cats: string[] = [];
        for (const element of filterRes) {
          cats.push(element.inventoryType);
        }

        const uniqueValues = cats.filter(
          (item, index) => cats.indexOf(item) === index
        );
        setCategories(uniqueValues);

        return InventoryService.getOneCatalog({ catalogId, withItems: true });
      })
      .then((catalogRes) => {
        if (catalogRes.business.id !== getActiveBusiness()?.id) {
          setUnauthorized(true);

          return;
        }

        const idsInCatalog: string[] = catalogRes.items.map(
          (ci) => ci.inventoryItem
        );
        setSelectedItems(idsInCatalog);
        setCatalog(catalogRes);
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  }, []);

  const query = searchParams.get("q");

  useEffect(() => {
    const businessId = getActiveBusiness()?.id;
    if (businessId) {
      initiateInventoryItems(businessId);
      if (query && !searchTerm) {
        console.log("BANANA HOW MANY TIMES THIS INVOKED");
        fetchInventoryItems({
          businessId,
          paging: true,
          limit: pagination.limit,
          offset: 0,
          sortBy: "sku",
          sortDirection: sortDirection,
          searchTerm: query,
          inventoryType: selectedFilter,
        });
      }
    }
  }, [getActiveBusiness()?.id]);

  useEffect(() => {
    const businessId = getActiveBusiness()?.id;

    if (businessId) {
      if (query && !searchTerm) {
        return;
      }
      fetchInventoryItems({
        businessId,
        paging: true,
        limit: pagination.limit,
        offset: 0,
        sortBy: "sku",
        sortDirection: sortDirection,
        searchTerm: searchTerm,
        inventoryType: selectedFilter,
      });
    }
  }, [searchTerm, selectedFilter, sortDirection]);

  useEffect(() => {
    const category = searchParams.get("category");
    const _sortDirection = searchParams.get("sortDirection");
    if (category) {
      setSelectedFilter(category);
    }
    if (_sortDirection) {
      setSortDirection(_sortDirection as any);
    }
    if (query) {
      setSearchTerm(query);
    }
    return () => {};
  }, []);

  const { tableData } = handleItemFlexiCols(inventoryItems, flexiColumns);

  const handleSelectAll = () => {
    const businessId = getActiveBusiness()?.id;

    if (businessId) {
      ItemService.getInventoryItemField({
        fields: ["id"],
        businessId,
        inventoryType: selectedFilter,
        searchTerm,
      })
        .then((res) => {
          const ids: string[] = res.map((i: { id: string }) => i.id);
          setSelectedItems(ids);
        })
        .catch((error) => console.error(error));
    }
  };

  const handleChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked) {
      setSelectedItems((prev) => [...prev, inventoryItems[index].id]);
    } else {
      setSelectedItems(
        selectedItems.filter((i) => i !== inventoryItems[index].id)
      );
    }
  };

  const handleSubmit = () => {
    if (!catalog || !catalogId) {
      return;
    }

    setIsLoading(true);
    InventoryService.manageCatalogItems({
      catalogId,
      inventoryItemIds: selectedItems,
    })
      .then((res) => {
        if (res) {
          window.location.href = `/client/catalogues/inventory/${catalogId}/items`;
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  };

  if (unauthorized) {
    return <> {t("catalog.noAccessCatalog")}</>;
  }

  return (
    <Sheet
      sx={{
        backgroundColor: "transparent",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <div className="w-full flex">
        <PageTitleBox
          title={t("commons.addItemBtn")}
          subtitle={t("catalog.manageSubtitle")}
        />

        <Button
          startDecorator={<PlusIcon width={18} height={18} />}
          className="w-[7.43rem] lg:hidden"
          type="button"
          onClick={handleSubmit}
        >
          {t("commons.addBtn")}
        </Button>
      </div>

      <hr
        className={`lg:hidden w-full border-b border-[${theme.colorSchemes.light.palette.others.disabled}]`}
      />

      {/* toolbar */}
      <div
        className={`flex flex-col lg:flex-row gap-2 w-[90vw] overflow-auto lg:w-[78vw] items-center hide-scrollbar`}
      >
        <FormControl size="sm" sx={{ width: "100%" }}>
          <FormLabel>{t("catalog.inventory.editForm.inventoryType")}</FormLabel>
          <Select
            value={selectedFilter}
            size="sm"
            placeholder={t("catalog.inventory.allFilterBtn")}
            slotProps={{
              button: { sx: { whiteSpace: "nowrap", width: "100%" } },
            }}
            onChange={(
              _event: React.SyntheticEvent | null,
              newValue: string | null
            ) => {
              const newParams = new URLSearchParams(searchParams);
              newParams.set("category", encodeURIComponent(newValue ?? ""));
              setSearchParams(newParams);
              setSelectedFilter(newValue ?? "");
            }}
          >
            <Option key={"all.filter"} value={""}>
              {t("catalog.inventory.allFilterBtn")}
            </Option>
            {categories.map((cat) => (
              <Option key={cat} value={cat}>
                {cat}
              </Option>
            ))}
          </Select>
        </FormControl>

        <FormControl size="sm" sx={{ width: "100%" }}>
          <FormLabel>{t("catalog.inventory.searchPlaceholder2")}</FormLabel>
          <Input
            defaultValue={searchTerm}
            // variant="linkz-input-search"
            size="sm"
            name="searchFormData.search"
            placeholder={t("catalog.inventory.searchPlaceholder2")}
            startDecorator={<MagnifyingGlassIcon width={24} height={24} />}
            fullWidth
            sx={{
              flexGrow: 1,
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newParams = new URLSearchParams(searchParams);
              const query = event.target.value;
              if (query) {
                newParams.set("q", query);
              } else {
                newParams.delete("q");
              }
              setSearchParams(newParams);
              setSearchTerm(event.target.value);
            }}
          />
        </FormControl>
      </div>

      {/* items table */}
      <div
        className={`w-[90vw] lg:w-[78vw] h-full lg:max-h-[27.5rem] overflow-auto lg:border-2`}
      >
        <Table
          borderAxis="x"
          sx={{
            "& th": { textAlign: "center" },
            minWidth: "100%",
            display: { xs: "none", lg: "block" },
          }}
        >
          <thead>
            <tr key={"header"}>
              <th key={"checkbox"} className="w-12">
                <Checkbox
                  size="sm"
                  onChange={(event) => {
                    if (event.target.checked) {
                      handleSelectAll();
                    } else {
                      setSelectedItems([]);
                    }
                  }}
                />
              </th>
              <th key={"index"} className="w-12">
                {t("catalog.items.no")}
              </th>
              <th key={"picture"} className="w-24">
                {t("catalog.items.picture")}
              </th>
              <th key={"sku"} className="w-36">
                {t("catalog.items.sku")}
              </th>
              <th key={"productName"} className="w-48">
                {t("catalog.items.productName")}
              </th>
              <th key={"quantity"} className="w-24">
                {t("catalog.items.quantity")}
              </th>
              <th key={"currency"} className="w-24">
                {t("catalog.items.currency")}
              </th>
              <th key={"price"} className="w-36">
                {t("catalog.items.price")}
              </th>
              <th key={"unit_of_measure"} className="w-24">
                {t("catalog.items.uom")}
              </th>
              <th key={"inventoryType"} className="w-36">
                {t("catalog.items.category")}
              </th>
              {flexiColumns?.map((col) => (
                <th key={col} className="w-28">
                  {col}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((d, index) => {
              const isSelected = selectedItems.includes(
                inventoryItems[index].id
              );
              return (
                <tr
                  key={inventoryItems[index].id}
                  className={`${isSelected ? "bg-primary-50 " : ""}`}
                >
                  <td className="text-center">
                    <Checkbox
                      size="sm"
                      checked={isSelected}
                      onChange={(e) => handleChecked(e, index)}
                    />
                  </td>
                  <td className="text-center">
                    {(pagination.current - 1) * pagination.limit + (index + 1)}
                  </td>
                  <td className="text-center">
                    <UploadImageBtn
                      item={inventoryItems[index]}
                      setIsLoading={setIsLoading}
                      setShowToast={setShowToast}
                      setAlertMessage={setAlertMessage}
                    />
                  </td>
                  {d.map((i, idx) => (
                    <td
                      key={
                        inventoryItems[index].id +
                        i.toString() +
                        "_col" +
                        index +
                        Math.random() * 695622
                      }
                      className="text-center break-all"
                    >
                      <IfElse
                        condition={idx === 2}
                        ifBlock={
                          <IfElse
                            condition={
                              Number(i) <=
                              (inventoryItems[index].lowStockQuantity || 0)
                            }
                            ifBlock={
                              <button
                                onClick={() => {
                                  setSelectedQuantityItem({
                                    show: true,
                                    item: inventoryItems[index],
                                  });
                                }}
                                className="bg-[#F7D5AC] w-fit h-fit mx-auto rounded-[4px] p-1"
                              >
                                {i}
                              </button>
                            }
                            elseBlock={<>{i}</>}
                          />
                        }
                        elseBlock={<>{i}</>}
                      />
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>

        <List sx={{ display: { xs: "block", lg: "none" } }}>
          <InfiniteScroll
            pageStart={0}
            // initialLoad
            loadMore={(page: number) => {
              console.log("BANANA INFINITE SCROLL", page, pagination);
              const businessId = getActiveBusiness()?.id;
              if (!businessId) {
                return;
              }

              fetchInventoryItems({
                businessId,
                paging: true,
                limit: pagination.limit,
                offset: pagination.limit * (page - 1),
                isReset: false,
                sortBy: "sku",
                sortDirection: sortDirection,
                searchTerm: searchTerm,
                inventoryType: selectedFilter,
              });
            }}
            hasMore={hasMore}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
          >
            {inventoryItems.map((item, index) => {
              const isSelected = selectedItems.includes(item.id);
              const catalogItem = catalog?.items.find(
                (i) => i.inventoryItem === item.id
              );

              return (
                <ListItem
                  key={item.id}
                  sx={{
                    display: "flex",
                    paddingX: "0.5rem",
                    paddingY: "1rem",
                    border: "1px solid #EAECF0",
                    borderRadius: "16px",
                    gap: "8px",
                    marginTop: index === 0 ? 0 : "8px",
                    marginBottom: "8px",
                  }}
                >
                  <Box alignSelf={"start"}>
                    <Checkbox
                      size="md"
                      checked={isSelected}
                      onChange={(e) => handleChecked(e, index)}
                    />
                  </Box>

                  {/* product image */}
                  <Stack direction={"column"} gap={"16px"}>
                    <div
                      className={`relative flex flex-col align-middle w-[100px] h-[100px] rounded-lg border-[1px] border-[#EAECF0]`}
                    >
                      <img
                        src={`${item.thumbnail ?? "/union.png"}`}
                        alt="item_image"
                        // width={100}
                        // height={100}
                        className="w-[100px] h-[100px] object-contain flex-shrink-0"
                      />

                      {catalogItem && (
                        <Chip
                          sx={{
                            position: "absolute",
                            borderRadius: "4px",
                            paddingY: "4px",
                            paddingX: "8px",
                            fontSize: 12,
                            fontWeight: 500,
                            color: "#475467",
                            top: "2px",
                            left: "2px",
                          }}
                        >
                          {t("catalog.added")}
                        </Chip>
                      )}
                    </div>

                    <Typography
                      fontSize={12}
                      textColor={"#000000"}
                      fontWeight={500}
                    >
                      Qty: {item.quantity}
                    </Typography>
                  </Stack>

                  {/* product details */}
                  <Stack direction={"column"} gap={"4px"}>
                    <Typography
                      fontSize={12}
                      textColor={"#101828"}
                      fontWeight={400}
                    >
                      {item.sku}
                    </Typography>

                    <Typography
                      fontSize={16}
                      textColor={"#101828"}
                      fontWeight={700}
                    >
                      {item.productName}
                    </Typography>

                    <Typography
                      fontSize={12}
                      textColor={"#475467"}
                      fontWeight={400}
                    >
                      {t("catalog.items.category")}: {item.inventoryType}
                    </Typography>

                    <Typography
                      fontSize={16}
                      textColor={"#101828"}
                      fontWeight={600}
                    >
                      {`${item.currency} ${formatPrice(
                        item.price,
                        item.currency
                      )}`}
                    </Typography>

                    {item.uom1 && (
                      <div className="border border-[#499873] rounded-[4px] p-1 w-fit">
                        <Typography
                          fontSize={12}
                          textColor={"#499873"}
                          fontWeight={500}
                        >
                          {item.uom1}
                        </Typography>
                      </div>
                    )}
                  </Stack>
                </ListItem>
              );
            })}
          </InfiniteScroll>
        </List>
      </div>

      {/* pagination and submit */}
      <Stack
        direction={"row"}
        display={{ xs: "none", lg: "flex" }}
        width={"100%"}
        justifyContent={"space-between"}
      >
        <div className="w-full">
          <TablePagination
            handlePaging={handlePaging}
            pagination={pagination}
            setPagination={setPagination}
            withSelected
            selected={selectedItems.length}
            total={inventoryItems.length}
            justifyContent="flex-start"
          />
        </div>

        <Link to={`/client/catalogues/inventory/${catalogId}/items`}>
          <Button
            type="button"
            variant="plain-gray"
            className={`ml-auto w-full lg:w-[8rem] text-nowrap`}
          >
            {t("commons.cancelBtn")}
          </Button>
        </Link>
        <Button
          startDecorator={<PlusIcon width={18} height={18} />}
          className="w-full lg:w-[8rem]"
          type="button"
          onClick={handleSubmit}
        >
          {t("commons.addBtn")}
        </Button>
      </Stack>

      {selectedQuantityItem?.item && (
        <UpdateQuantityModal
          show={selectedQuantityItem?.show ?? false}
          onClose={() => {
            setSelectedQuantityItem(undefined);
          }}
          item={[selectedQuantityItem?.item]}
        />
      )}
    </Sheet>
  );
};

export default ManageCatalogueItems;
