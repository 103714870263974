const estimatedAnualRevenueOptions = {
  "ID": [
    "-",
    "<500.000.000,00",
    "500.000.000,00 - 5.000.000.000,00",
    ">5.000.000.000,00",
  ],
  "MY": [
    "-",
    "<500.000",
    "500.000 - 5.000.000",
    ">5.000.000",
  ]
};

const averageValueOfTransactions = {
  "ID": [
    "-",
    "<500.000.000,00",
    "500.000.000,00 - 5.000.000.000,00",
    ">5.000.000.000,00",
  ],
  "MY": [
    "-",
    "<5000",
    "5000 - 50.000",
    ">50.000",
  ]
}

export function findEstimatedAnualRevenueOptionsByCountry(countryCode:string) {
  const options = (estimatedAnualRevenueOptions as any)[countryCode]
  return options ?? estimatedAnualRevenueOptions["ID"]
}


export function findAverageValueOfTransactionsByCountry(countryCode:string) {
  const options = (averageValueOfTransactions as any)[countryCode]
  return options ?? averageValueOfTransactions["ID"]
}
