import { Modal, Typography } from "@mui/joy";
import "prismjs/themes/prism-tomorrow.css";
import { useEffect, useState } from "react";
import "react-notion/src/styles.css";
import CloseIcon from "../../../components/Icons/Close";

import { useTranslation } from "react-i18next";
import { NotionRenderer } from "react-notion";

interface IProps {
  isOpen: boolean;
  close: () => void;
  title: string;
  content: string;
}
type Document = {
  [key: string]: string;
};

type TermsAndPolicies = {
  [key: string]: Document;
};

const TermsAndPoliciesModal = ({ isOpen, close, title, content }: IProps) => {
  const [blockMap, setBlockMap] = useState();
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;
  const contentMap: TermsAndPolicies = {
    "t&c": {
      id: "59442805594648528e41b8d01c0e8661",
      "en-US": "57451bd78ba548bab8b387c757b461a8",
      en: "57451bd78ba548bab8b387c757b461a8",
    },
    privacyPolicy: {
      id: "7902782ab61d44caaef648709af19da3",
      "en-US": "13a9397335104d26a6d3238a94d6e025",
      en: "13a9397335104d26a6d3238a94d6e025",
    },
  };
  useEffect(() => {
    async function fetchPage() {
      try {
        let activeContent = contentMap[content][currentLanguage];
        if (!activeContent && content === "t&c") {
          activeContent = contentMap[content]["en-US"];
        }
        const data = await fetch(
          `https://notion-api.splitbee.io/v1/page/${activeContent}`
        ).then((res) => res.json());
        setBlockMap(data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchPage();
    return () => {};
  }, []);

  return (
    <Modal
      open={isOpen}
      style={{
        backgroundColor: "rgba(50, 56, 62,.6)",
        backdropFilter: "blur(0px)",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="flex items-center justify-center w-screen h-screen">
        <div className="w-[672px] bg-white outline-none p-6 rounded-[32px] flex flex-col gap-y-8 overflow-y-scroll">
          <div className="flex items-center justify-between">
            <Typography color="primary" className="text-lg">
              {title}
            </Typography>
            <div className="cursor-pointer" onClick={() => close()}>
              <CloseIcon />
            </div>
          </div>
          <div className="flex flex-col gap-8 max-h-[500px] overflow-y-scroll">
            {blockMap && <NotionRenderer blockMap={blockMap} />}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TermsAndPoliciesModal;

// header
