import { Id, My, Ph, Sg, Th, Vn } from "react-flags-select";

const FlagsMap: Map<string, JSX.Element> = new Map([
  ["ID", <Id />],
  ["MY", <My />],
  ["SG", <Sg />],
  ["TH", <Th />],
  ["VN", <Vn />],
  ["PH", <Ph />],
]);

export default function FlagById({ id }: { id: string }) {
  return FlagsMap.get(id);
}
