import React from "react";
import { Divider } from "@mui/joy";
import EmployeeForm from "./EmployeeForm";

export default function UpdateEmployee() {
    return (
        <React.Fragment>
            <Divider />
            <EmployeeForm isEdit={true} />
        </React.Fragment>
    );
}