import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import i18next from "../../i18n/config";
import { AuthService } from "../../services/auth.service";
import { BrowserRouterConstants } from "../../utils/constants";
import { passwordValidation } from "../Verified";

const schema = z.object({
  password: z
    .string()
    .min(8)
    .regex(passwordValidation, i18next.t("commons.error.form.password")),
});
const useContainer = () => {
  //#region local states
  const {
    state: { token },
  } = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const {
    formState: { errors },
    watch,
    handleSubmit,
    register,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });
  const navigate = useNavigate();
  const { password } = watch();
  const mutation = useMutation({
    mutationFn: AuthService.resetPassword,
    onSuccess: () => {
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        navigate(BrowserRouterConstants.Signin);
      }, 4000);
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });
  //#endregion

  //#region handler functions
  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  const onSubmit: SubmitHandler<z.infer<typeof schema>> = (payload) => {
    mutation.mutate({ payload, token });
  };
  //#endregion

  //#region life cycle method
  useEffect(() => {
    if (!token) {
      return navigate("/");
    }
  }, []);

  //#endregion

  return {
    // states
    showModal,
    isLoading: mutation.isPending,
    showPassword,
    errors,
    password,
    // functions
    toggleShowPassword,
    register,
    handleSubmit,
    onSubmit,
  };
};

export default useContainer;
