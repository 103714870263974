import { KeyboardArrowDown } from "@mui/icons-material";
import { Option, Select } from "@mui/joy";
import { animateSelectStyle } from "../styles/select";
import i18next from "i18next";
import { useState } from "react";

const LanguageSelector = () => {
  // if no lang set, get default from browser locale
  const [selectedLang, setSelectedLang] = useState(
    i18next.resolvedLanguage ?? navigator.language.split("-")[0]
  );

  const langs = import.meta.env.VITE_LOCALES?.split(",") ?? ["en"];

  return (
    <Select
      variant="plain"
      defaultValue={selectedLang}
      indicator={<KeyboardArrowDown />}
      sx={{ ...animateSelectStyle }}
      renderValue={() => (
        <div className="flex gap-2">
          <span
            className={`fi fi-${selectedLang === "en" ? "us" : selectedLang}`}
          />
          {selectedLang.toUpperCase()}
        </div>
      )}
      onChange={(_, value) => {
        if (!value) return;
        setSelectedLang(value);
        i18next.changeLanguage(value);

        window.location.reload();
      }}
    >
      {langs.map((lang: string) => (
        <Option key={lang} value={lang} className="px-6">
          <span className={`fi fi-${lang === "en" ? "us" : lang}`} />
          {lang.toUpperCase()}
        </Option>
      ))}
    </Select>
  );
};

export default LanguageSelector;
