export const TRACKER_CONSTANTS = {
  LOGIN_SUCCESS: {
    events: {
      LoginPageVisited: "Login page visited",
      // ContinueWithGoogleClicked: "Continue with Google clicked",
      // EmailLoginClicked: "Email login clicked",
      LoginButtonClicked: "Login button clicked",
      CompanyOptionsClicked: "Company options clicked",
      HomeDashboardVisited: "Home dashboard visited",
    },
  },
  REGISTER_SUCCESS: {
    events: {
      events: {
        SignupPageVisited: "Signup page visited",
        UserProceedWithSignUp: "User proceed with sign up",
        ContinueWithGoogleClicked: "OTP success submitted",
      },
    },
  },
  REGISTRATION_VIA_CATALOG: {
    events: {
      SellerSendInvitationToBuyer: "Seller send invitation to buyer",
      SignupPageVisited: "Signup page visitted",
      UserProceedWithSignUp: "User proceed with sign up",
      OTPSuccessSubmitted: "OTP success submitted",
    },
  },
  ORDER: {
    events: {
      SalesOrderButtonClicked: "Sales order button clicked",
      SalesOrderCreated: "Sales order created",
      PurchaseOrderButtonClicked: "Purchase order button clicked",
      PurchaseOrderCreated: "Purchase order created",

      ConfirmOrderButtonClicked: "Confirm order button clicked",
      RequestPaymentButtonClicked: "Request payment button clicked",
      PaymentSuccessfullyMade: "Payment successfully made",
      CompleteOrderButtonClicked: "Complete order button clicked",
    },
  },
  CATALOG_SUPPLIER: {
    events: {
      AccessCatalogDetails: "Catalog detail page visitted",
      AddItemToCart: "Items added to cart",
      CartPageVisited: "Cart page visitted",
      CreateOrderBtnClick: "Cart create order button clicked",
    },
  },
};
