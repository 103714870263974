import { privateAxios } from "../lib/api";
import { ApiConstants } from "../utils/constants";

export class TaxService {
  static async getTaxesOption(country: string) {
    return await privateAxios
      .get(`${ApiConstants.businessTaxURL}/${country}/region`)
      .then((res) => res.data);
  }
  static async associateTaxesOption({
    businessId,
    taxIds,
  }: {
    businessId: string;
    taxIds: string[];
  }) {
    return await privateAxios
      .post(`${ApiConstants.businessTaxURL}/associate/${businessId}`, {
        taxIds,
      })
      .then((res) => res.data);
  }

  static async getBusinessTaxes(businessId: string) {
    return await privateAxios
      .get(`${ApiConstants.businessTaxURL}/${businessId}`)
      .then((res) => res.data);
  }
  static async getTaxesIdsByBusiness(businessId: string) {
    return await privateAxios
      .get(`${ApiConstants.businessTaxURL}/${businessId}/ids`)
      .then((res) => res.data);
  }
}
