import React from "react";
import { Divider } from "@mui/joy";
import EmployeeForm from "./EmployeeForm";

export function AddEmployee() {
    return (
        <React.Fragment>
            <Divider/>
            {/* FROM */}
            <EmployeeForm/>
        </React.Fragment>
    );
}