import { FileUploadOutlined } from "@mui/icons-material";
import { Button, List, ListItem, Typography } from "@mui/joy";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import DownloadTemplateButton from "./DownloadTemplateButton";
import InfoSnackbar from "../../../pages/Inventory/components/InfoSnackbar";

type Props = {
  onDrop: (acceptedFiles: any) => void;
};

const InventoryUploadDropzone = ({ onDrop }: Props) => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
  });

  return (
    <div
      className="border-[1px] hover:border-[#6BAB8D] ease-in-out duration-300 lg:w-[29.93rem] min-h-[20rem] lg:h-[34.37rem] rounded-lg flex flex-col gap-5 items-center cursor-pointer py-[4.5rem] px-20"
      {...getRootProps()}
    >
      <Typography fontSize={18} fontWeight={700} sx={{ color: "#101828" }}>
        {t("catalog.inventory.empty.option")} 1
      </Typography>
      <img
        src="/bulk.png"
        width={100}
        height={100}
        className="object-contain"
      />
      <div className="flex flex-col  gap-[4px] text-center w-full">
        <Typography fontSize={18} fontWeight={600} sx={{ color: "#101828" }}>
          {t("catalog.inventory.empty.option1")}
        </Typography>
        <p className="text-sm">{t("catalog.inventory.empty.option1Prompt")}</p>
      </div>
      <List
        sx={{
          width: "15.62rem",
          gap: "8px",
        }}
      >
        <ListItem sx={{ display: "flex", gap: "8px", padding: 0 }}>
          <Typography fontSize={14}>1.</Typography>
          <DownloadTemplateButton />
        </ListItem>
        <ListItem sx={{ display: "flex", gap: "8px", padding: 0 }}>
          <Typography fontSize={14}>2.</Typography>
          <Button
            {...getRootProps({ className: "dropzone" })}
            startDecorator={<FileUploadOutlined />}
            fullWidth
            className="whitespace-nowrap"
          >
            {t("catalog.reuploadBtn")}
            <input {...getInputProps()} />
          </Button>
        </ListItem>
      </List>

      <InfoSnackbar message={t("catalog.inventory.uploadRemarks")} />
    </div>
  );
};

export default InventoryUploadDropzone;
