import { Typography } from "@mui/joy";
import { calculatePrice, getConversionRate } from "../../../utils/cart";
import { CatalogInventoryItemType } from "../../../types/item";
import { formatPrice } from "../../../utils/formatPrice";
import { IfElse } from "../../Condition";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";

interface Props {
  item: CatalogInventoryItemType;
  hidePrice?: boolean;
  catalogType?: string;
  activeUOM: "uom1" | "uom2" | "uom3";
  isDisabled?: boolean;
  setActiveUOM: (uom: "uom1" | "uom2" | "uom3") => void;
  size: "sm" | "lg";
  withPriceLabel?: boolean;
}

const UOMSelector = ({
  item,
  hidePrice = false,
  catalogType = "PUBLIC",
  activeUOM,
  isDisabled = false,
  setActiveUOM,
  size,
  withPriceLabel = false,
}: Props) => {
  const { t } = useTranslation();
  const currency = item.currency ?? item.inventoryItem.currency;
  // TODO: replace with /public
  const isGuestMode = window.location.pathname.startsWith("/catalog");

  return (
    <div className={`flex ${size === "lg" && "w-[61%]"}`}>
      <div className="flex flex-col items-center justify-start gap-2">
        <div
          className={`flex ${
            size === "lg" ? "w-full" : "mr-auto"
          } flex-wrap gap-2 justify-start`}
        >
          {item.inventoryItem.uom1 && (
            // <Button
            //   variant="outlined"
            //   className={`rounded-lg p-2 min-w-20 h-10 border-[#499873] ${
            //     activeUOM === "uom1"
            //       ? "bg-[#CFE5DA] border-none text-[#1B392D]"
            //       : "text-[#499873]"
            //   }`}
            //   onClick={() => {
            //     setActiveUOM("uom1");
            //   }}
            // >
            //   {item.inventoryItem.uom1}
            // </Button>
            <button
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                setActiveUOM("uom1");
              }}
              disabled={isDisabled}
              className={`w-fit rounded-lg  py-[10px] px-[12px] cursor-pointer border-[#499873] border ease-in-out duration-200 ${
                activeUOM === "uom1"
                  ? isDisabled
                    ? "bg-[#EAECF0] border-[#EAECF0] text-[#98A2B3]"
                    : "bg-[#CFE5DA] border-[#CFE5DA] text-[#1B392D]"
                  : isDisabled
                  ? "bg-transparent border-[#EAECF0] text-[#98A2B3]"
                  : "text-[#499873]"
              }`}
            >
              {item.inventoryItem.uom1}
            </button>
          )}
          {item.inventoryItem.uom2 && (
            // <Button
            //   variant="outlined"
            //   disabled={isDisabled}
            //   className={`rounded-lg p-2 min-w-20 h-10 border-[#499873] ${
            //     activeUOM === "uom2"
            //       ? "bg-[#CFE5DA] border-none text-[#1B392D]"
            //       : "text-[#499873]"
            //   }`}
            //   onClick={() => {
            //     setActiveUOM("uom2");
            //   }}
            // >
            //   {item.inventoryItem.uom2}
            // </Button>
            <button
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                setActiveUOM("uom2");
              }}
              disabled={isDisabled}
              className={`w-fit rounded-lg  py-[10px] px-[12px] cursor-pointer border-[#499873] border ease-in-out duration-200 ${
                activeUOM === "uom2"
                  ? isDisabled
                    ? "bg-[#EAECF0] border-[#EAECF0] text-[#98A2B3]"
                    : "bg-[#CFE5DA] border-[#CFE5DA] text-[#1B392D]"
                  : isDisabled
                  ? "bg-transparent border-[#EAECF0] text-[#98A2B3]"
                  : "text-[#499873]"
              } `}
            >
              {item.inventoryItem.uom2}
            </button>
          )}
          {item.inventoryItem.uom3 && (
            // <Button
            //   variant="outlined"
            //   disabled={isDisabled}
            //   className={`rounded-lg p-2 min-w-20 h-10 border-[#499873] ${
            //     activeUOM === "uom3"
            //       ? "bg-[#CFE5DA] border-none text-[#1B392D]"
            //       : "text-[#499873]"
            //   }`}
            //   onClick={() => {
            //     setActiveUOM("uom3");
            //   }}
            // >
            //   {item.inventoryItem.uom3}
            // </Button>
            <button
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                setActiveUOM("uom3");
              }}
              disabled={isDisabled}
              className={`w-fit rounded-lg  py-[10px] px-[12px] cursor-pointer border-[#499873] border ease-in-out duration-200 ${
                activeUOM === "uom3"
                  ? isDisabled
                    ? "bg-[#EAECF0] border-[#EAECF0] text-[#98A2B3]"
                    : "bg-[#CFE5DA] border-[#CFE5DA] text-[#1B392D]"
                  : isDisabled
                  ? "bg-transparent border-[#EAECF0] text-[#98A2B3]"
                  : "text-[#499873]"
              } `}
            >
              {item.inventoryItem.uom3}
            </button>
          )}
        </div>

        <div className="flex flex-col gap-[4px] self-start">
          <IfElse
            condition={withPriceLabel}
            ifBlock={
              <Stack direction={"row"} gap={"8px"}>
                <Typography
                  fontSize={14}
                  fontWeight={400}
                  textColor={"#101828"}
                >
                  {t("catalog.items.price")}
                </Typography>{" "}
                <Typography
                  fontSize={size === "sm" ? 14 : 18}
                  fontWeight={600}
                  textColor={"#101828"}
                >
                  {`${currency} ${
                    isGuestMode
                      ? catalogType === "PRIVATE"
                        ? "-"
                        : hidePrice
                        ? "-"
                        : formatPrice(calculatePrice(item, activeUOM), currency)
                      : hidePrice
                      ? "-"
                      : formatPrice(calculatePrice(item, activeUOM), currency)
                  }`}
                  {/* {item.inventoryItem[activeUOM] &&
                `/${item.inventoryItem[activeUOM]}`} */}
                </Typography>
              </Stack>
            }
            elseBlock={
              <Typography
                fontSize={size === "sm" ? 14 : 18}
                fontWeight={500}
                textColor={"#101828"}
              >
                {`${currency} ${
                  isGuestMode
                    ? catalogType === "PRIVATE"
                      ? "-"
                      : hidePrice
                      ? "-"
                      : formatPrice(calculatePrice(item, activeUOM), currency)
                    : hidePrice
                    ? "-"
                    : formatPrice(calculatePrice(item, activeUOM), currency)
                }`}
                {/* {item.inventoryItem[activeUOM] &&
                  `/${item.inventoryItem[activeUOM]}`} */}
              </Typography>
            }
          />

          {item.inventoryItem[activeUOM] && (
            <Typography
              fontSize={size === "sm" ? 12 : 14}
              fontWeight={size === "sm" ? 500 : 600}
              textColor={"#101828"}
            >
              {`1 ${item.inventoryItem[activeUOM]} ${
                activeUOM !== "uom1"
                  ? `(${getConversionRate(activeUOM, item.inventoryItem)} ${
                      item.inventoryItem.uom1
                    })`
                  : ""
              }`}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default UOMSelector;
