import { Button, Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import { useRef, useState } from "react";

interface Props {
  options: { label: string; id?: string; action: () => void }[];
  element: JSX.Element | string;
  isReadOnly?: boolean;
}

const DropdownButton = ({ options, element, isReadOnly = false }: Props) => {
  const menuBtnRef = useRef<any>(null);
  const [open, setOpen] = useState(false);

  return (
    <Dropdown open={open} onOpenChange={() => setOpen(!open)}>
      <MenuButton
        ref={menuBtnRef}
        disabled={isReadOnly}
        fullWidth
        slots={{ root: Button }}
        slotProps={{
          root: {
            variant: "plain",
            color: "neutral",
            sx: {
              "&:hover": {
                bgcolor: "transparent",
              },
              "&:focus": {
                bgcolor: "transparent",
              },
              padding: 0
            },
          },
        }}
      >
        {element}
      </MenuButton>
      <Menu sx={{ width: menuBtnRef.current?.clientWidth }}>
        {options.map((opt) => (
          <MenuItem id={opt.id || ""} onClick={opt.action} sx={{ padding: 1 }}>
            {opt.label}
            {/* {i18next.t("order.table.rowMenu.duplicate")} */}
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
};

export default DropdownButton;
