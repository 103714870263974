import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

export function StatsCard({
  bgColor,
  title,
  ongoing,
  outgoing,
  amount,
  Icon,
}: {
  bgColor: string;
  title: string;
  ongoing: number;
  outgoing: number;
  amount: string;
  Icon: any;
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="flex flex-col gap-4 p-4 bg-white shadow-lg rounded-2xl">
      <div className="flex items-center gap-2">
        <div className={`${bgColor}  p-2 rounded-lg`}>
          <Icon className="w-8 h-8" />
        </div>
        <p className="text-base text-[#101828] lg:text-[#475467] lg:font-normal font-bold flex-1">
          {title}
        </p>
        <div className="lg:hidden">
          {isOpen ? (
            <ChevronUpIcon
              onClick={() => setIsOpen((prev) => !prev)}
              className="w-6"
            />
          ) : (
            <ChevronDownIcon
              onClick={() => setIsOpen((prev) => !prev)}
              className="w-6"
            />
          )}
        </div>
      </div>
      <div className="items-center hidden lg:flex">
        <p className="text-lg font-bold text-[#101828]">{amount}</p>
      </div>
      <div className="items-center hidden lg:flex">
        <div className="flex flex-col flex-1 gap-1">
          <p className="text-sm text-[#475467] font-normal">Ongoing</p>
          <p className="text-[#101828] text-lg font-bold">{ongoing}</p>
        </div>
        <div className="flex flex-col flex-1 gap-1">
          <p className="text-sm text-[#475467] font-normal">Completed</p>
          <p className="text-[#101828] text-lg font-bold">{outgoing}</p>
        </div>
      </div>
      {isOpen && (
        <>
          <div className="flex items-center">
            <p className="text-lg font-bold text-[#101828]">{amount}</p>
          </div>
          <div className="flex items-center">
            <div className="flex flex-col flex-1 gap-1">
              <p className="text-sm text-[#475467] font-normal">Ongoing</p>
              <p className="text-[#101828] text-lg font-bold">{ongoing}</p>
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <p className="text-sm text-[#475467] font-normal">Completed</p>
              <p className="text-[#101828] text-lg font-bold">{outgoing}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
