import { formatNumber } from "./DahsboardChart";

export function StatsModal({
  data,
}: {
  data: {
    gmv: number;
    catalog: number;
    sale: number;
    purchase: number;
  };
}) {
  return (
    <div className="w-fit">
      <div className="relative rounded-2xl bg-[#14161A] p-2 shadow-lg">
        <div className="grid grid-cols-2 gap-3">
          <div className="flex items-center gap-2">
            <div className="h-2.5 w-2.5 rounded-full bg-emerald-500"></div>
            <span className="text-xs font-medium text-white">
              {formatNumber(data.sale)}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <div className="h-2.5 w-2.5 rounded-full bg-[#FDEDDB]"></div>
            <span className="text-xs font-medium text-white">
              {formatNumber(data.gmv)}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <div className="h-2.5 w-2.5 rounded-full bg-[#DEBBFD]"></div>
            <span className="text-xs font-medium text-white">
              {formatNumber(data.catalog)}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <div className="h-2.5 w-2.5 rounded-full bg-[#B0EFF0]"></div>
            <span className="text-xs font-medium text-white">
              {formatNumber(data.purchase)}
            </span>
          </div>
        </div>
        {/* Triangle pointer at the bottom */}
        <div className="absolute -bottom-2 left-1/2 h-4 w-4 -translate-x-1/2 rotate-45 transform bg-[#14161A]"></div>
      </div>
    </div>
  );
}
