import MiniOrderItemRow from "../Orders/MiniOrderItemRow";
import { Stack, Typography } from "@mui/joy";
import { Add, Remove } from "@mui/icons-material";
import { Toggler } from "../utils";
import { useTranslation } from "react-i18next";
import { IOrderRevision, OrderStatusEnum } from "../../types/order";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { formatDate } from "../../utils/formatDate";
import { getActiveRevision } from "../../utils/order";
import { If, IfElse } from "../Condition";
import { useParams } from "react-router-dom";
import { useAuthStore } from "../../store/session";

type Props = {
  revisions: IOrderRevision[];
  orderType: string;
  invoiceNumber: string;
  currentOrderStatus: OrderStatusEnum;
  mainOrderCreatedAt: string;
  currentRevision: IOrderRevision | undefined;
};

const RelatedOrders = ({
  revisions,
  orderType,
  invoiceNumber,
  currentOrderStatus,
  mainOrderCreatedAt,
}: Props) => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const mainOrder =
    getActiveRevision(revisions) ||
    currentOrderStatus === OrderStatusEnum.CANCELLED
      ? revisions.find((rev) => rev.isMain) ?? null
      : null;
  const pureRevisions = revisions.filter((rev) => !rev.isMain);
  const { session } = useAuthStore.getState();

  return (
    <Toggler
      renderToggle={({ open, setOpen, sxClasses }) => (
        <>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            onClick={() => setOpen(!open)}
            sx={sxClasses}
          >
            <Typography
              startDecorator={<DocumentDuplicateIcon width={24} height={24} />}
              fontWeight={600}
            >
              {t("order.createOrder.relatedOrdersInfo.relatedOrders")}
            </Typography>
            {open ? <Remove color="primary" /> : <Add color="primary" />}
          </Stack>
        </>
      )}
    >
      <Stack>
        <Stack>
          <Typography fontWeight={600}>
            {t("order.createOrder.relatedOrdersInfo.mainOrder")}
          </Typography>

          {/* Main Order is revision with version 1 */}
          <IfElse
            condition={mainOrder ? false : true}
            ifBlock={
              <MiniOrderItemRow
                orderType={orderType}
                orderNo={invoiceNumber}
                orderDate={formatDate(new Date())}
                orderStatus={currentOrderStatus}
                onClick={() => {
                  window.location.href = `${
                    session ? "/client/orders" : `/public/orders/${orderType}`
                  }/${orderId}`;
                }}
              />
            }
            elseBlock={
              <MiniOrderItemRow
                orderType={orderType}
                orderNo={mainOrder?.content.invoiceNumber}
                orderDate={formatDate(new Date(mainOrderCreatedAt))}
                orderStatus={mainOrder?.content.status}
                onClick={() => {
                  window.location.href = `${
                    session ? "/client/orders" : `/public/orders/${orderType}`
                  }/${orderId}`;
                }}
              />
            }
          />
        </Stack>
        <Stack mt={3}>
          <Typography fontWeight={600}>
            {t("order.createOrder.relatedOrdersInfo.adjustmentOrder")}
          </Typography>

          {pureRevisions.map((r) => (
            <MiniOrderItemRow
              orderType={orderType}
              orderNo={r.content.invoiceNumber}
              orderDate={formatDate(new Date(r.createdAt))}
              orderStatus={r.content.status}
              onClick={() => {
                window.location.href = `${
                  session ? "/client/orders" : `/public/orders/${orderType}`
                }/${orderId}`;
              }}
            />
          ))}

          <If condition={mainOrder ? true : false}>
            <MiniOrderItemRow
              orderType={orderType}
              orderNo={invoiceNumber}
              orderDate={formatDate(new Date())}
              orderStatus={currentOrderStatus}
              onClick={() => {
                window.location.href = `${
                  session ? "/client/orders" : `/public/orders/${orderType}`
                }/${orderId}`;
              }}
            />
          </If>
        </Stack>
      </Stack>
    </Toggler>
  );
};

export default RelatedOrders;
