import { XMarkIcon } from "@heroicons/react/24/outline";
import { InfoOutlined } from "@mui/icons-material";
import { Button } from "@mui/joy";
import React, { useState } from "react";
import { If } from "../../../components/Condition";

interface Props {
  icon?: React.ReactElement;
  message: string | React.ReactElement;
  withExit?: boolean;
  onExitCallback?: () => void;
}

const InfoSnackbar = ({
  icon,
  message,
  withExit = false,
  onExitCallback,
}: Props) => {
  const [show, setShow] = useState(true);

  const onExit = () => {
    setShow(false);
    if (onExitCallback) {
      onExitCallback();
    }
  };
  return (
    <div
      className={`w-full px-4 py-2 bg-[#CFE5DA] rounded-md ${
        show ? "opacity-100 scale-100" : "opacity-0"
      } flex transition-all ease-in-out transform items-center gap-2`}
    >
      {icon ?? (
        <InfoOutlined
          style={{
            width: 24,
            height: 24,
            color: "#408565",
          }}
        />
      )}

      {typeof message === "string" ? (
        <p className="text-xs text-[#101828]">{message}</p>
      ) : (
        message
      )}

      <If condition={withExit}>
        <Button
          onClick={onExit}
          variant="plain"
          className="ml-auto text-[#101828] p-0 min-w-6"
        >
          <XMarkIcon width={24} height={24} />
        </Button>
      </If>
    </div>
  );
};

export default InfoSnackbar;
