import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import { Card, Typography } from "@mui/joy"

export function SnackBarInfo({
  message
}: {
  message: string
}) {
  return (
    <Card
      sx={{
        padding: 1,
        width: "100%",
        flex: 1,
        borderRadius: 10,
      }}
      variant="soft"
      color="primary"
    >
      <Typography startDecorator={<ExclamationCircleIcon color="primary" width={20} height={20} />}>{message}</Typography>
    </Card>
  )
}