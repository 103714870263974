export function formatDate(dateObj: Date) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const year = dateObj.getFullYear();
  const hour = dateObj.getHours() % 12 || 12; // Use 12-hour format
  const minutes = dateObj.getMinutes().toString().padStart(2, "0");
  const ampm = dateObj.getHours() >= 12 ? "PM" : "AM";

  return `${day} ${monthNames[monthIndex]} ${year} ${hour}:${minutes} ${ampm}`;
}

export function getMonthName(monthNumber: number) {
  const date = new Date(Date.UTC(2000, monthNumber - 1, 1));
  return date.toLocaleString("en-US", { month: "long" });
}
