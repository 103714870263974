import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import IconButton from "@mui/joy/IconButton";
import ListContainer from "@mui/joy/List";
import ListDivider from "@mui/joy/ListDivider";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";

type Parms = {
 catalogs: any[];
}

export default function Catalogues({ catalogs }: Parms) {
  if (!catalogs.length) {
    return (
      <Stack
        alignItems="center"
        height="20vh"
        justifyContent="center"
        spacing={2}
      >
        <Typography level="h3">No catalogue in your list</Typography>
        <Typography textAlign="center" textColor="neutral.400" width="80%">
          Looks like you haven&apos;t created any catalogue yet.
        </Typography>
      </Stack>
    );
  }

  return (
    <ListContainer
      size="lg"
      sx={{
        "--List-gap": "0.5rem",
        "--ListItemDecorator-size": "3.5rem",
      }}
    >
      {catalogs.map(({ createdAt, name, uuid }, index) => (
        <Fragment key={uuid}>
          {index / 2 !== 0 ? <ListDivider inset="gutter" /> : undefined}
          <ListItem
            endAction={
              <IconButton aria-label="view" size="sm">
                <NavigateNextOutlinedIcon />
              </IconButton>
            }
          >
            <ListItemButton
              component={RouterLink}
              to={`/catalogues/${uuid}/items`}
            >
              <Stack spacing={0.5}>
                <Typography fontWeight="lg">{name}</Typography>
                <Typography fontSize="sm" textColor="neutral.400">
                  {createdAt}
                </Typography>
              </Stack>
            </ListItemButton>
          </ListItem>
        </Fragment>
      ))}
    </ListContainer>
  );
}
