import { Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import ContactForm from "../../pages/BusinessContact/v2/components/ContactForm";


export interface AddCompanyContactModalProps {
  state: {
    open: boolean
    companyName: string
  }
  handleOpen: () => void
  handleClose: () => void
  onSuccessAdd?: (data: any) => void
}
export default function AddCompanyContactModal(props: AddCompanyContactModalProps) {
  return <Modal
    aria-labelledby="modal-title"
    aria-describedby="modal-desc"
    open={props.state.open ?? false}
    onClose={props.handleClose}
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <ModalDialog
      layout="center"
      maxWidth={"lg"}
      sx={{
        width: "100%",
        maxWidth: "600px",
        borderRadius: "md",
        p: 2,
        overflow: "scroll",
      }}
    >
      <ModalClose variant="plain" sx={{ m: 1 }} />
      <Typography
        textColor={"primary.solidActiveBg"}
        textAlign={"center"}
        level="h4"
        fontWeight={600}
      >
        Add New Contact
      </Typography>
      <br />
      <ContactForm
        onCancel={() => props.handleClose()}
        onSuccess={(data) => {
          props.onSuccessAdd?.(data)
          props.handleClose()
        }} />
    </ModalDialog>
  </Modal>
}