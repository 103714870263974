export enum RoleType {
    BusinessAdmin = "Business Admin",
    BusinessUser = "Business User",
}

export const ROLE_TYPES: RoleType[] = [
    RoleType.BusinessAdmin, 
    RoleType.BusinessUser
]

export const RoleTypeTranslate = {
    [RoleType.BusinessAdmin]: "userRole.businessAdmin",
    [RoleType.BusinessUser]: "userRole.businessUser",
}