import { currencyToLocale } from "./formatPrice";
import i18next from "../i18n/config";
import { theme } from "../components/Theme";
import { CreateItemType } from "../types/item";
// import { getCountryInfo } from "./country";

const cellColor =
  theme.colorSchemes.light.palette.others.order_sales.split("#")[1];
export const cellStyle = {
  fill: {
    fgColor: { rgb: cellColor },
  },
};
const secondaryCellColor =
  theme.colorSchemes.light.palette.others.order_draft.split("#")[1];
export const secondaryCellStyle = {
  fill: {
    fgColor: { rgb: secondaryCellColor },
  },
};

export const expectedColumns = [
  "SKU*",
  "Product Name*",
  "Category*",
  "Currency*",
  "Price*",
  "Quantity*",
  "Base UOM*",
];
export const secondaryColumns = [
  // "UOM1",
  // "conversion1",
  "uom2",
  "conversion2",
  "uom3",
  "conversion3",
];

export const validateExcel = (excelArray: Array<Array<string | number>>) => {
  const firstRow = excelArray[0];
  const columnValidators = [
    validateFixedColumn,
    validateSecondaryColumn,
    validateFlexiColumn,
  ];
  const rowValidators = [validateFixedValues, validateCurrency];

  // validate columns
  for (const validator of columnValidators) {
    const result = validator(firstRow);
    if (!result.isValid) {
      return result;
    }
  }

  // validate rows
  for (const validator of rowValidators) {
    const result = validator(excelArray);
    if (!result.isValid) {
      return result;
    }
  }

  // TODO: refactor this when working on duplicate SKU ticket
  // 4. Validate duplicate SKU: will give prompt
  //   const skuSet = new Set();
  //   const duplicates: string[] = [];
  //   for (let row = 1; row < excelArray.length; row++) {
  //     const currentRow = excelArray[row];
  //     if (!currentRow) {
  //       continue; // Skip empty rows
  //     }

  //     const sku = currentRow[expectedColumns.indexOf("SKU*")];
  //     if (skuSet.has(sku)) {
  //       duplicates.push(sku.toString());
  //     }
  //     skuSet.add(sku);
  //   }

  return {
    isValid: true,
    message: null,
  };
};

const validateFixedColumn = (firstRow: Array<string | number>) => {
  for (let col = 0; col < expectedColumns.length; col++) {
    if (
      firstRow[col] !== expectedColumns[col]
      // expectedColumns[col] !== "Currency*"
    ) {
      console.error(
        `Error in fixed column ${col + 1} (expected "${
          expectedColumns[col]
        }", found "${firstRow[col]}")`
      );
      return {
        isValid: false,
        message: i18next.t("catalog.inventory.invalidExcel"),
      };
    }
  }
  return {
    isValid: true,
    message: null,
  };
};

const validateSecondaryColumn = (firstRow: Array<string | number>) => {
  for (let col = 0; col < secondaryColumns.length; col++) {
    if (firstRow[col + 7] !== secondaryColumns[col]) {
      console.error(
        `Error in secondary column ${col + 1} (expected "${
          secondaryColumns[col]
        }", found "${firstRow[col]}")`
      );
      return {
        isValid: false,
        message: i18next.t("catalog.inventory.invalidExcel"),
      };
    }
  }
  return {
    isValid: true,
    message: null,
  };
};

const validateFlexiColumn = (firstRow: Array<string | number>) => {
  for (let col = 0; col < firstRow.length; col++) {
    const currentCol = firstRow[col];
    if (!currentCol) {
      console.error(
        `Error in flexi column ${col + 1} (expected "{columnName}", found "${
          firstRow[col]
        }")`
      );
      return {
        isValid: false,
        message: i18next.t("catalog.inventory.invalidExcel"),
      };
    }
  }
  return {
    isValid: true,
    message: null,
  };
};

const validateFixedValues = (excelArray: Array<Array<string | number>>) => {
  for (let row = 1; row < excelArray.length; row++) {
    const currentRow = excelArray[row];
    if (!currentRow) {
      continue;
    }

    if (currentRow.length < expectedColumns.length) {
      console.error(
        `Error in row ${row + 1}: Missing values in fixed columns. Expected ${
          expectedColumns.length
        }, found ${currentRow.length}`
      );
      return {
        isValid: false,
        message: i18next.t("catalog.inventory.excelMissingValue", {
          row: row + 1,
        }),
      };
    }

    for (let col = 0; col < expectedColumns.length; col++) {
      const cellValue = currentRow[col];
      if (cellValue === "" || typeof cellValue === "undefined") {
        console.error(
          `Error in row ${row + 1}, column ${col + 1} (fixed column "${
            expectedColumns[col]
          }") is empty`
        );
        return {
          isValid: false,
          message: i18next.t("catalog.inventory.excelMissingValueWithColumn", {
            row: row + 1,
            column: expectedColumns[col],
          }),
        };
      }

      // validate currency
      if (
        expectedColumns[col] === "Currency*" &&
        !(cellValue in currencyToLocale)
      ) {
        console.error(
          `Error in row ${row + 1}, column ${col + 1} (fixed column "${
            expectedColumns[col]
          }") is invalid`
        );
        return {
          isValid: false,
          message: i18next.t("catalog.inventory.excelMissingValueWithColumn", {
            row: row + 1,
            column: expectedColumns[col],
          }),
        };
      }
    }
  }
  return {
    isValid: true,
    message: null,
  };
};

// const validateDuplicate = (excelArray: Array<Array<string | number>>) => {};

const validateCurrency = (excelArray: Array<Array<string | number>>) => {
  for (let row = 1; row < excelArray.length; row++) {
    const currentRow = excelArray[row];
    if (!currentRow) {
      continue; // Skip empty rows
    }

    const currency =
      currentRow[expectedColumns.indexOf("Currency*")].toString();
    if (!(currency in currencyToLocale)) {
      console.error(
        `Error: Invalid Currency found in row ${
          row + 1
        } (Currency: "${currency}")`
      );
      return {
        isValid: false,
        message: i18next.t("catalog.inventory.excelInvalidCurrency", {
          row: row + 1,
          currency: currency,
        }),
      };
    }
  }
  return {
    isValid: true,
    message: null,
  };
};

export const validateDuplicateSku = (
  processedData: CreateItemType[],
  databaseSku?: string[]
) => {
  // will keep track of sku in database
  const skuCountCheckDB: { [key: string]: number } = {};
  // will keep track of the number of times each base sku name appears in processedData and databaseSku
  const skuCount: { [key: string]: number } = {};
  // will keep track of sku in excelArray
  const skuCountCheck: { [key: string]: number } = {};
  const duplicates: string[] = [];
  let allNewItemsUnique = true;

  // intialize with databaseSku
  if (databaseSku) {
    for (const sku of databaseSku) {
      const baseSku = getBaseSku(sku);
      skuCount[baseSku] = (skuCount[baseSku] || 0) + 1;
      skuCountCheckDB[sku] = (skuCountCheckDB[sku] || 0) + 1;
    }
  }
  // console.log("SKU COUNT", {
  //   skuCount,
  //   skuCountCheckDB,
  // });

  for (let row = 0; row < processedData.length; row++) {
    const currentRow = processedData[row];
    const originalSku = currentRow.sku;
    // skuCountCheck[originalSku] = (skuCountCheck[originalSku] || 0) + 1;
    const baseSku = getBaseSku(originalSku);
    // console.log(
    //   "SKU COUNT",
    //   originalSku,
    //   skuCount[baseSku],
    //   skuCountCheck[originalSku]
    // );

    // console.log("SKU COUNT", {
    //   skuCount,
    //   baseSku,
    // });

    if (
      databaseSku && databaseSku.length > 0
        ? skuCount[baseSku]
        : skuCountCheck[originalSku]
    ) {
      skuCount[baseSku]++;
      skuCountCheck[originalSku]++;
      let newSku = generateUniqueSku(baseSku, skuCount[baseSku]);
      // console.log("INITIAL NEW SKU", newSku);
      let count = 1;
      while (
        (databaseSku && databaseSku.length > 0
          ? skuCountCheckDB[newSku] > 0
          : skuCountCheck[newSku] > 1) ||
        duplicates.includes(newSku)
      ) {
        // console.log("SKU ORIGINAL", originalSku);
        count++;
        newSku = generateUniqueSku(baseSku, count);
        // console.log("SKU NEW", newSku);
      }

      duplicates.push(newSku);
    } else {
      // console.log("ORIGINAL SKU", originalSku);
      skuCount[baseSku] = 1;
      skuCountCheck[originalSku] = 1;

      duplicates.push(originalSku);
    }

    // console.log("BANANA VALIDATION EXCEL", {
    //   originalSku,
    //   baseSku,
    //   skuCount,
    //   databaseSku,
    // });

    // console.log(
    //   "BANANA VALIDAITON RESULT",
    //   databaseSku && databaseSku.length > 0
    //     ? skuCount[baseSku] > 1 &&
    //       baseSku === originalSku.replace(/[^a-zA-Z0-9]/g, "")
    //       ? databaseSku.includes(baseSku) || skuCount[baseSku] > 1
    //       : databaseSku.includes(originalSku)
    //     : skuCountCheck[originalSku] > 1,
    //   originalSku.replace(/[^a-zA-Z0-9]/g, ""),
    //   baseSku,
    //   skuCountCheckDB
    // );

    if (
      databaseSku && databaseSku.length > 0
        ? skuCount[baseSku] > 1 &&
          baseSku === originalSku.replace(/[^a-zA-Z0-9]/g, "")
          ? databaseSku.includes(baseSku) || skuCount[baseSku] > 1
          : databaseSku.includes(originalSku)
        : skuCountCheck[originalSku] > 1
    ) {
      allNewItemsUnique = false;
    }
  }

  // console.log("SKU COUNT CHECK", skuCountCheck);
  // console.log("DUPLICATES ARRAY", duplicates);

  return allNewItemsUnique ? [] : duplicates;
};

export const getBaseSku = (sku: string) => {
  const match = sku.match(/(.*?)(_copy(\d+)?)?$/);
  return match
    ? match[1].replace(/[^a-zA-Z0-9]/g, "")
    : sku.replace(/[^a-zA-Z0-9]/g, "");
};

const generateUniqueSku = (baseSku: string, count: number) => {
  return `${baseSku}_copy${count > 2 ? `${count - 1}` : ""}`;
};

export const convertExcelToInventoryItems = (
  excelArray: Array<Array<string | number>>,
  business: any,
  accountId: string | undefined
): {
  data: CreateItemType[];
  message: string;
} => {
  const processedData = [];
  if (business.id && accountId) {
    for (let row = 1; row < excelArray.length; row++) {
      const currentRow = excelArray[row];
      const fixedColumns = {
        ["sku"]: currentRow[0].toString(),
        ["productName"]: currentRow[1].toString(),
        ["inventoryType"]: currentRow[2].toString(),
        // ["currency"]:
        //   business.bankInfo[0]?.currency ||
        //   getCountryInfo(business.countryCode, "LOCALE"),
        ["currency"]: currentRow[3].toString(),
        ["price"]: +currentRow[4],
        ["quantity"]: +currentRow[5],
        ["uom1"]: currentRow[6].toString() ?? "unit",
        ["uom2"]: currentRow[7]?.toString() ?? null,
        ["conversion2"]: currentRow[8] ? +currentRow[8] : null,
        ["uom3"]: currentRow[9]?.toString() ?? null,
        ["conversion3"]: currentRow[10] ? +currentRow[10] : null,
      };

      const flexiColumns: {
        columnName: string;
        cellValue: string | number;
      }[] = [];
      for (
        let col = expectedColumns.length + secondaryColumns.length;
        col < currentRow.length;
        col++
      ) {
        const cellValue = currentRow[col];
        if (cellValue !== "" && typeof cellValue !== "undefined") {
          flexiColumns.push({
            columnName: excelArray[0][col].toString(),
            cellValue,
          });
        }
      }

      const jsonData: CreateItemType = {
        ...fixedColumns,
        flexiColumns: { data: flexiColumns },
        business: business.id,
        productDescription: "",
        createdBy: accountId,
        isActive: true,
        isDeleted: false,
      };
      processedData.push(jsonData);
    }

    return {
      data: processedData,
      message: "",
    };
  } else {
    return {
      data: [],
      message: i18next.t("catalog.inventory.missingBusinessAndAccount"),
    };
  }
};
