
function WelcomeIllustratorSVG() {
  return (
     <svg
      xmlns="http://www.w3.org/2000/svg"
      width="235"
      height="225"
      fill="none"
      viewBox="0 0 235 225"
    >
      <path
        fill="#EBEBEB"
        d="M218.625 49.538c-21.209 0-21.245 30.013 0 30.013s21.245-30.013 0-30.013z"
      ></path>
      <path
        fill="#DBDBDB"
        d="M231.526 67.356c-1.133 5.167-5.436 9.642-12.901 9.642-17.632 0-17.601-24.906 0-24.906h.346c-16.565.303-16.484 23.89.325 23.89 6.854.004 10.94-3.945 12.23-8.626z"
      ></path>
      <path
        fill="#DBDBDB"
        d="M218.752 55.398a.532.532 0 01.549.506v.84a7.925 7.925 0 014.065 1.46c.208.147.32.273.32.48a.565.565 0 01-.594.506.581.581 0 01-.412-.147 6.887 6.887 0 00-3.445-1.355v5.85c3.491.668 4.959 1.86 4.959 3.909 0 2.214-2.032 3.776-4.913 3.862v1.629a.545.545 0 01-.549.546.55.55 0 01-.549-.546v-1.664a9.046 9.046 0 01-5.163-2.022.537.537 0 01-.254-.44.574.574 0 01.575-.506.602.602 0 01.411.147 7.732 7.732 0 004.477 1.9v-5.975c-3.445-.688-4.868-1.836-4.868-3.929s2.032-3.716 4.822-3.777v-.799a.53.53 0 01.569-.475zm-.508 7.943v-5.733c-2.226.045-3.694 1.254-3.694 2.76.005 1.421.737 2.296 3.699 2.948l-.005.025zm1.016 1.274v5.764c2.297-.04 3.791-1.254 3.791-2.841-.005-1.467-.828-2.346-3.796-2.948l.005.025zM178.524 13.064c-12.704 9.172.945 27.884 13.653 18.707 12.708-9.176-.925-27.893-13.653-18.707z"
      ></path>
      <path
        fill="#EBEBEB"
        d="M179.733 12.205c-12.088 8.742 1.524 27.454 13.653 18.707 12.129-8.747-1.539-27.464-13.653-18.707z"
      ></path>
      <path
        fill="#DBDBDB"
        d="M195.195 17.984c1.703 3.68 1.286 8.246-2.967 11.325-10.051 7.266-21.367-8.266-11.336-15.522l.203-.136c-9.309 7.018 1.473 21.68 11.052 14.748 3.912-2.831 4.441-6.962 3.048-10.415z"
      ></path>
      <path
        fill="#DBDBDB"
        d="M184.301 14.89a.397.397 0 01.508.237l.208.607a5.296 5.296 0 013.09.126c.178.06.284.127.335.278a.407.407 0 01-.264.506.395.395 0 01-.31 0 4.595 4.595 0 00-2.637-.197l1.484 4.252c2.489-.314 3.77.217 4.288 1.709.564 1.608-.396 3.215-2.286 3.938l.411 1.183a.377.377 0 01-.017.295.381.381 0 01-.222.196.389.389 0 01-.493-.238l-.426-1.203a6.128 6.128 0 01-3.954-.288.43.43 0 01-.279-.263.417.417 0 01.249-.506.428.428 0 01.315 0c1.078.51 2.3.636 3.46.36l-1.524-4.334c-2.47.288-3.709-.222-4.238-1.744-.528-1.522.402-3.165 2.251-3.858l-.203-.576a.395.395 0 01.254-.48zm1.677 5.89l-1.443-4.146c-1.474.54-2.145 1.76-1.763 2.851.381 1.093 1.072 1.532 3.206 1.295zm1.016.697l1.463 4.197c1.525-.561 2.206-1.785 1.799-2.938-.386-1.051-1.143-1.506-3.282-1.259h.02zM39.408 91.114c12.703 9.172-.945 27.884-13.653 18.707-12.708-9.176.945-27.899 13.653-18.707z"
      ></path>
      <path
        fill="#EBEBEB"
        d="M38.22 90.25c12.088 8.746-1.525 27.453-13.654 18.706-12.129-8.746 1.545-27.464 13.653-18.707z"
      ></path>
      <path
        fill="#DBDBDB"
        d="M22.757 96.033c-1.702 3.686-1.286 8.252 2.967 11.326 10.051 7.265 21.367-8.262 11.337-15.517-.066-.046-.132-.096-.203-.137 9.309 7.013-1.474 21.676-11.052 14.749-3.913-2.826-4.441-6.972-3.049-10.42z"
      ></path>
      <path
        fill="#DBDBDB"
        d="M33.895 92.722a.39.39 0 01.193.505l-.259.597a5.277 5.277 0 012.165 2.194.45.45 0 01.056.43.41.41 0 01-.549.197.377.377 0 01-.218-.222 4.595 4.595 0 00-1.79-1.937l-1.818 4.11c2.032 1.467 2.601 2.721 1.956 4.162-.696 1.557-2.505 2.078-4.38 1.314l-.508 1.148a.389.389 0 01-.51.195.382.382 0 01-.196-.508l.508-1.163a6.052 6.052 0 01-2.683-2.902.437.437 0 01-.026-.38.42.42 0 01.668-.113.403.403 0 01.09.134 5.23 5.23 0 002.276 2.614l1.88-4.187c-1.997-1.471-2.54-2.684-1.89-4.156.65-1.471 2.47-2.022 4.294-1.279l.248-.561a.396.396 0 01.493-.192zm-2.83 5.44l1.799-4.015c-1.443-.606-2.774-.171-3.247.885-.473 1.057-.254 1.826 1.448 3.13zm.249 1.183l-1.82 4.045c1.49.627 2.831.197 3.334-.915.453-1.006.214-1.851-1.514-3.13z"
      ></path>
      <path
        fill="#EBEBEB"
        d="M126.491 9.773l-14.41 28-4.05-6.24-48.043 30.908-9.065-13.95L98.96 17.59l-4.055-6.239 31.585-1.577zM32.803 60.156L41.858 74.1l-1.22.784-9.06-13.944 1.225-.784zM39.144 56.066l9.065 13.95-4.151 2.669-9.055-13.945 4.141-2.674zM48.087 50.317l9.065 13.944-6.407 4.126-9.065-13.95 6.407-4.12z"
      ></path>
      <path
        fill="#DBDBDB"
        d="M91.812 156.488l-9.31 18.09-2.616-4.029-31.021 19.956-5.859-9.005 31.026-19.961-2.611-4.03 20.39-1.021zM41.177 182.678l5.853 9.005-4.136 2.664-5.853-9.009 4.136-2.66zM35.405 186.383l-2.68 1.724 5.852 9.011 2.68-1.724-5.852-9.011zM31.299 189.029l5.858 9.009-.792.506-5.849-9.01.783-.505z"
      ></path>
      <path
        fill="#EBEBEB"
        d="M112.31 118.846l-12.038-18.54 2.032-1.3 12.048 18.541-2.042 1.299zm-11.347-18.394l11.494 17.696 1.163-.748-11.493-17.696-1.164.748zM137.436 102.677l-12.042-18.54 62.388-40.135-5.366-8.262 41.9-2.083L205.2 70.8l-5.371-8.262-62.393 40.14zm-11.341-18.389l11.489 17.696 62.398-40.14 5.168 7.949 18.292-35.595-40.142 2.023 5.168 7.953-62.373 40.114zM116.756 115.979l-12.048-18.535 5.813-3.742 12.053 18.541-5.818 3.736zm-11.347-18.383l11.494 17.696 4.964-3.191-11.493-17.696-4.965 3.19zM125.429 110.403l-12.042-18.546 8.734-5.617 12.048 18.545-8.74 5.618zm-11.341-18.394l11.494 17.696 7.896-5.056-11.494-17.696-7.896 5.056z"
      ></path>
      <path
        fill="#5BBE90"
        d="M38.331 209.753a2.433 2.433 0 01-1.505 2.25 2.456 2.456 0 01-2.665-.524 2.423 2.423 0 01.368-3.743 2.456 2.456 0 013.086.299 2.43 2.43 0 01.716 1.718zM41.299 205.117a1.18 1.18 0 01-.734 1.093 1.196 1.196 0 01-1.296-.257 1.178 1.178 0 01.18-1.82 1.194 1.194 0 011.501.147c.223.222.349.523.349.837zM93.504 208.595a1.177 1.177 0 01-.734 1.093 1.196 1.196 0 01-1.296-.256 1.185 1.185 0 01-.258-1.29 1.186 1.186 0 011.099-.73c.315 0 .617.125.84.347.223.221.349.522.349.836zM116.131 220.563c.656 0 1.189-.53 1.189-1.183 0-.654-.533-1.183-1.189-1.183-.657 0-1.189.529-1.189 1.183 0 .653.532 1.183 1.189 1.183z"
        opacity="0.6"
      ></path>
      <path
        fill="#5BBE90"
        d="M115.516 222.843a1.418 1.418 0 01-1.418 1.411H25.684a1.422 1.422 0 01-1.31-.871 1.407 1.407 0 01.767-1.843 1.456 1.456 0 01.878-.072c0-.554.165-1.094.476-1.553a2.797 2.797 0 012.891-1.17 2.798 2.798 0 011.43.781h.025c0-.172-.025-.344-.025-.505a5.231 5.231 0 01.662-2.557 5.266 5.266 0 011.829-1.914 5.3 5.3 0 015.13-.247 3.745 3.745 0 01.99-2.243 3.786 3.786 0 014.595-.736 3.759 3.759 0 011.647 1.822 5.255 5.255 0 011.913-1.804 5.282 5.282 0 012.553-.654c.34.001.681.033 1.016.096a7.078 7.078 0 011.022-5.063 7.136 7.136 0 013.035-2.625 7.168 7.168 0 017.64 1.08 1.426 1.426 0 011.26-.768c.187.001.371.037.544.106l1.326-125.465h7.79l1.326 125.465c.175-.069.361-.105.549-.106a1.416 1.416 0 011.255.768 7.176 7.176 0 018.176-.781 7.122 7.122 0 012.93 3.143 7.074 7.074 0 01.58 4.246 5.313 5.313 0 013.57.558 5.267 5.267 0 011.919 1.804 3.753 3.753 0 011.631-1.896 3.785 3.785 0 014.672.731c.577.635.919 1.447.968 2.302a5.305 5.305 0 015.131.247 5.27 5.27 0 011.829 1.914c.436.782.664 1.662.662 2.556.01.169.01.338 0 .506a2.808 2.808 0 013.055-.636 2.779 2.779 0 011.742 2.578 1.45 1.45 0 01.335-.036 1.424 1.424 0 011.316.885c.07.173.104.359.102.546z"
        opacity="0.6"
      ></path>
      <path
        fill="#5BBE90"
        d="M55.46 43.709c-.305 0-11.687 5.233-12.383 18.13-.62 11.857 7.622 23.117 8.684 22.368 1.3-.91-.915-8.297 1.616-16.745.9-2.998 4.827-5.658 4.827-5.658L55.46 43.71z"
      ></path>
      <path
        fill="#000"
        d="M58.204 61.804a18.01 18.01 0 00-3.12 2.796c-1.697-3.767-4.863-10.8-6.905-15.355 3.338-3.843 7.113-5.562 7.281-5.562l2.744 18.121z"
        opacity="0.1"
      ></path>
      <path
        fill="#5BBE90"
        d="M84.48 43.729c.304 0 11.686 5.253 12.357 18.151.605 11.856-7.653 23.1-8.715 22.358-1.3-.915.925-8.302-1.595-16.756-.89-2.993-4.812-5.663-4.812-5.663l2.764-18.09z"
      ></path>
      <path
        fill="#000"
        d="M91.796 49.33c-2.063 4.597-5.234 11.63-6.91 15.366a18.14 18.14 0 00-3.171-2.867l2.764-18.09c.173-.01 3.974 1.704 7.317 5.592z"
        opacity="0.1"
      ></path>
      <path
        fill="#DBDBDB"
        d="M78.59 73.372h-17.2s-8.11-14.435-7.15-31.625c.509-8.595 2.74-17.22 5.453-24.37C63.428 7.503 68.04.495 70.043.5c2.002.005 6.606 7.008 10.336 16.892 2.693 7.154 4.934 15.795 5.406 24.38.935 17.19-7.195 31.6-7.195 31.6z"
      ></path>
      <path
        fill="#C7C7C7"
        d="M80.313 39.547a9.84 9.84 0 01-1.676 5.486 9.913 9.913 0 01-4.456 3.635 9.97 9.97 0 01-5.735.558 9.939 9.939 0 01-5.08-2.707 9.858 9.858 0 01-2.712-5.058 9.829 9.829 0 01.57-5.706 9.885 9.885 0 013.66-4.428 9.959 9.959 0 0112.526 1.24 9.855 9.855 0 012.903 6.98z"
      ></path>
      <path
        fill="#5BBE90"
        d="M79.947 38.541a9.84 9.84 0 01-1.675 5.485 9.914 9.914 0 01-4.456 3.635 9.97 9.97 0 01-5.734.559 9.939 9.939 0 01-5.08-2.705 9.859 9.859 0 01-2.713-5.057 9.828 9.828 0 01.568-5.705 9.885 9.885 0 013.657-4.43 9.959 9.959 0 0112.53 1.235 9.87 9.87 0 012.15 3.204 9.83 9.83 0 01.753 3.78z"
      ></path>
      <path
        fill="#fff"
        d="M76.898 38.531c0 1.355-.404 2.68-1.16 3.806a6.873 6.873 0 01-3.092 2.52 6.912 6.912 0 01-7.5-1.492 6.814 6.814 0 01-1.478-7.467 6.854 6.854 0 012.542-3.069 6.905 6.905 0 018.679.87 6.833 6.833 0 012.009 4.832z"
      ></path>
      <path
        fill="#EBEBEB"
        d="M76.898 38.531a6.819 6.819 0 01-.462 2.483l-3.923 3.903a6.918 6.918 0 01-4.335.207l8.491-8.433c.16.6.237 1.22.229 1.84zM71.05 31.761l-7.815 7.776a6.829 6.829 0 011.94-5.849 6.898 6.898 0 015.875-1.927zM76.06 35.215l-9.325 9.308a4.775 4.775 0 01-.508-.324l9.553-9.505c.071.167.178.344.28.52z"
      ></path>
      <path
        fill="#5BBE90"
        d="M80.379 17.392H59.693C63.428 7.518 68.04.51 70.043.515 72.045.52 76.65 7.508 80.38 17.392z"
      ></path>
      <path
        fill="#fff"
        d="M63.255 15.253a.575.575 0 01-.357.532.582.582 0 01-.79-.416.573.573 0 01.242-.592.582.582 0 01.905.476zM66.934 15.253a.575.575 0 01-.358.533.583.583 0 01-.79-.42.574.574 0 01.247-.592.581.581 0 01.9.48zM70.612 15.258a.575.575 0 01-.357.533.582.582 0 01-.79-.42.574.574 0 01.568-.689.58.58 0 01.58.576zM74.291 15.258a.575.575 0 01-.579.577.58.58 0 01-.58-.577.58.58 0 011.16 0zM77.975 15.264a.575.575 0 01-.36.533.583.583 0 01-.632-.13.576.576 0 11.825-.806.58.58 0 01.167.402z"
      ></path>
      <path
        fill="#C7C7C7"
        d="M63.23 70.728a.574.574 0 01-.358.532.582.582 0 01-.79-.42.574.574 0 01.568-.689.58.58 0 01.58.577zM66.908 70.733a.575.575 0 01-.579.576.571.571 0 01-.574-.576.575.575 0 01.58-.577.571.571 0 01.573.577zM70.592 70.733a.575.575 0 01-.358.532.582.582 0 01-.79-.42.574.574 0 01.247-.591.581.581 0 01.901.479zM74.271 70.738a.574.574 0 01-.358.533.583.583 0 01-.79-.42.574.574 0 01.247-.592.581.581 0 01.901.48zM77.95 70.738a.575.575 0 01-.358.533.582.582 0 01-.79-.42.573.573 0 01.247-.592.582.582 0 01.9.48z"
      ></path>
      <path
        fill="#5BBE90"
        d="M77.005 78.52l-13.994-.01-.65-5.148 15.304.015-.66 5.142z"
      ></path>
      <path
        fill="#000"
        d="M77.665 73.377l-.325 2.503-14.73-.546-.25-1.967 15.305.01z"
        opacity="0.1"
      ></path>
      <path
        fill="#FCFCFC"
        d="M93.077 211.224l-1.834.339-.412 1.931-.412-1.931-1.84-.339 1.83-.445.422-1.82.417 1.82 1.829.445zM75.196 195.758l-1.84.339-.411 1.931-.412-1.931-1.84-.339 1.835-.445.417-1.82.416 1.82 1.835.445zM48.473 214.9l-1.031.187-.229 1.077-.229-1.077-1.026-.187 1.022-.248.233-1.021.234 1.021 1.026.248zM103.773 218.115l-1.032.188-.228 1.076-.229-1.076-1.026-.188 1.021-.252.234-1.017.233 1.017 1.027.252zM34.327 221.892l-1.026.187-.229 1.082-.234-1.082-1.026-.187 1.021-.247.239-1.022.234 1.022 1.021.247zM79.032 220.998l-1.026.187-.229 1.077-.228-1.077-1.032-.187 1.026-.253.234-1.017.234 1.017 1.021.253zM71.512 192.825l-1.032.193-.228 1.077-.229-1.077-1.026-.193 1.02-.247.235-1.017.233 1.017 1.027.247zM106.202 220.446a.3.3 0 000 .592.3.3 0 00.261-.296.3.3 0 00-.261-.296zM67.533 189.089a.3.3 0 00-.26.296.296.296 0 00.26.295.295.295 0 00.187-.099.295.295 0 000-.393.3.3 0 00-.187-.099zM42.112 221.892a.3.3 0 00-.186.493.3.3 0 00.186.099.3.3 0 00.26-.296.3.3 0 00-.26-.296zM60.994 217.817a.3.3 0 00-.186.493.305.305 0 00.186.099.3.3 0 00.186-.493.305.305 0 00-.186-.099zM98.748 215.112a.214.214 0 00-.18.21.213.213 0 00.18.21.212.212 0 000-.42zM72.696 211.973a.211.211 0 00-.13.348.216.216 0 00.13.071.216.216 0 00.13-.071.214.214 0 000-.277.211.211 0 00-.13-.071zM53.636 207.872a.212.212 0 000 .42.214.214 0 00.18-.21.213.213 0 00-.18-.21zM86.248 208.727a.574.574 0 00-.52.568.57.57 0 00.52.569.574.574 0 00.52-.569.568.568 0 00-.52-.568zM56.334 205.946a.574.574 0 00-.52.569.568.568 0 00.52.568.574.574 0 00.52-.568.57.57 0 00-.52-.569z"
      ></path>
      <path
        fill="#5BBE90"
        d="M119.992 190.545h-17.398v33.709h17.398v-33.709z"
      ></path>
      <path
        fill="#fff"
        d="M119.992 190.545h-17.398v33.709h17.398v-33.709z"
        opacity="0.6"
      ></path>
      <path
        fill="#5BBE90"
        d="M96.019 224.249h6.575V190.54h-6.575v33.709z"
      ></path>
      <path
        fill="#fff"
        d="M96.019 224.249h6.575V190.54h-6.575v33.709z"
        opacity="0.4"
      ></path>
      <path fill="#5BBE90" d="M154.189 168h-17.398v56.253h17.398V168z"></path>
      <path
        fill="#fff"
        d="M154.189 168h-17.398v56.253h17.398V168z"
        opacity="0.6"
      ></path>
      <path
        fill="#5BBE90"
        d="M130.216 224.249h6.575v-56.254h-6.575v56.254z"
      ></path>
      <path
        fill="#fff"
        d="M130.216 224.249h6.575v-56.254h-6.575v56.254z"
        opacity="0.4"
      ></path>
      <path
        fill="#5BBE90"
        d="M188.386 139.657h-17.398v84.592h17.398v-84.592z"
      ></path>
      <path
        fill="#fff"
        d="M188.386 139.657h-17.398v84.592h17.398v-84.592z"
        opacity="0.6"
      ></path>
      <path
        fill="#5BBE90"
        d="M164.413 224.254h6.575v-84.592h-6.575v84.592z"
      ></path>
      <path
        fill="#fff"
        d="M164.413 224.254h6.575v-84.592h-6.575v84.592z"
        opacity="0.4"
      ></path>
      <path
        fill="#5BBE90"
        d="M222.578 105.544H205.18v118.704h17.398V105.544z"
      ></path>
      <path
        fill="#fff"
        d="M222.578 105.544H205.18v118.704h17.398V105.544z"
        opacity="0.6"
      ></path>
      <path
        fill="#5BBE90"
        d="M198.61 224.254h6.575V105.549h-6.575v118.705z"
      ></path>
      <path
        fill="#fff"
        d="M198.61 224.254h6.575V105.549h-6.575v118.705z"
        opacity="0.4"
      ></path>
      <path
        fill="#263238"
        d="M24.276 224.269c65.94-.314 139.913-.314 205.853 0-65.94.308-139.913.308-205.853 0z"
      ></path>
      <path
        fill="#5BBE90"
        d="M106.212 217.099H73.666v7.154h32.546v-7.154z"
      ></path>
      <g fill="#000" opacity="0.1">
        <path d="M75.008 217.751a15.822 15.822 0 010 5.724 15.822 15.822 0 010-5.724zM77.431 217.751a15.822 15.822 0 010 5.724 15.707 15.707 0 010-5.724zM78.615 217.751c.351 1.892.351 3.832 0 5.724a15.822 15.822 0 010-5.724zM85.114 217.751c.351 1.892.351 3.832 0 5.724a15.822 15.822 0 010-5.724zM86.873 217.751c.35 1.892.35 3.832 0 5.724a15.822 15.822 0 010-5.724zM94.144 217.751c.35 1.892.35 3.832 0 5.724a15.818 15.818 0 010-5.724zM95.826 217.751a15.822 15.822 0 010 5.724 15.707 15.707 0 010-5.724zM98.91 217.751a15.822 15.822 0 010 5.724 15.707 15.707 0 010-5.724zM92.741 217.751a15.822 15.822 0 010 5.724 15.702 15.702 0 010-5.724zM80.765 217.751a15.822 15.822 0 010 5.724 15.702 15.702 0 010-5.724zM89.184 217.751c.351 1.892.351 3.832 0 5.724a15.822 15.822 0 010-5.724zM90.399 217.751a15.822 15.822 0 010 5.724 15.707 15.707 0 010-5.724zM102.929 217.751a15.822 15.822 0 010 5.724 15.822 15.822 0 010-5.724zM104.748 217.751a15.822 15.822 0 010 5.724 15.733 15.733 0 010-5.724zM100.343 217.751a15.822 15.822 0 010 5.724 15.707 15.707 0 010-5.724zM83.712 217.751a15.822 15.822 0 010 5.724 15.707 15.707 0 010-5.724z"></path>
      </g>
      <path
        fill="#000"
        d="M106.207 217.099v1.517l-32.54 1.021v-2.538h32.54z"
        opacity="0.1"
      ></path>
      <path
        fill="#5BBE90"
        d="M103.499 209.94H70.953v7.154h32.546v-7.154z"
      ></path>
      <g fill="#000" opacity="0.1">
        <path d="M102.03 216.321a15.822 15.822 0 010-5.724 15.69 15.69 0 010 5.724zM99.606 216.321a15.822 15.822 0 010-5.724c.351 1.892.351 3.832 0 5.724zM98.422 216.321a15.822 15.822 0 010-5.724c.351 1.892.351 3.832 0 5.724zM91.924 216.321a15.822 15.822 0 010-5.724c.35 1.892.35 3.832 0 5.724zM90.165 216.321a15.822 15.822 0 010-5.724c.35 1.892.35 3.832 0 5.724zM82.894 216.321a15.822 15.822 0 010-5.724c.35 1.892.35 3.832 0 5.724zM81.217 216.321a15.707 15.707 0 010-5.724 15.822 15.822 0 010 5.724zM78.133 216.321a15.707 15.707 0 010-5.724 15.822 15.822 0 010 5.724zM84.301 216.321a15.707 15.707 0 010-5.724 15.822 15.822 0 010 5.724zM96.273 216.321a15.818 15.818 0 010-5.724 15.602 15.602 0 010 5.724zM87.853 216.321a15.707 15.707 0 010-5.724 15.822 15.822 0 010 5.724zM86.639 216.321a15.822 15.822 0 010-5.724c.35 1.892.35 3.832 0 5.724zM74.108 216.321a15.822 15.822 0 010-5.724c.351 1.892.351 3.832 0 5.724zM72.295 216.321a15.707 15.707 0 010-5.724 15.822 15.822 0 010 5.724zM76.7 216.321a15.707 15.707 0 010-5.724 15.822 15.822 0 010 5.724zM93.331 216.321a15.707 15.707 0 010-5.724 15.822 15.822 0 010 5.724z"></path>
      </g>
      <path
        fill="#000"
        d="M103.494 209.945v1.517l-32.546 1.021v-2.538h32.546z"
        opacity="0.1"
      ></path>
      <path
        fill="#5BBE90"
        d="M106.004 202.846H73.458v7.155h32.546v-7.155z"
      ></path>
      <g fill="#000" opacity="0.1">
        <path d="M74.8 203.499a15.84 15.84 0 010 5.728 15.844 15.844 0 010-5.728zM77.223 203.498a15.845 15.845 0 010 5.729 15.738 15.738 0 010-5.729zM78.423 203.498a15.85 15.85 0 010 5.729 15.845 15.845 0 010-5.729zM84.906 203.498c.35 1.894.35 3.835 0 5.729a15.845 15.845 0 010-5.729zM86.665 203.498c.35 1.894.35 3.835 0 5.729a15.845 15.845 0 010-5.729zM93.936 203.498c.35 1.894.35 3.835 0 5.729a15.85 15.85 0 010-5.729zM95.618 203.498a15.845 15.845 0 010 5.729 15.738 15.738 0 010-5.729zM98.702 203.498a15.845 15.845 0 010 5.729 15.734 15.734 0 010-5.729zM92.533 203.498a15.845 15.845 0 010 5.729 15.738 15.738 0 010-5.729zM80.557 203.498a15.845 15.845 0 010 5.729 15.738 15.738 0 010-5.729zM88.976 203.498c.35 1.894.35 3.835 0 5.729a15.845 15.845 0 010-5.729zM90.19 203.498a15.85 15.85 0 010 5.729 15.734 15.734 0 010-5.729zM102.721 203.498a15.85 15.85 0 010 5.729 15.85 15.85 0 010-5.729zM104.54 203.498a15.85 15.85 0 010 5.729 15.76 15.76 0 010-5.729zM100.135 203.498a15.85 15.85 0 010 5.729 15.73 15.73 0 010-5.729zM83.504 203.498a15.845 15.845 0 010 5.729 15.734 15.734 0 010-5.729z"></path>
      </g>
      <path
        fill="#000"
        d="M105.999 202.846v1.522l-32.54 1.021v-2.543h32.54z"
        opacity="0.1"
      ></path>
      <path
        fill="#5BBE90"
        d="M214.768 217.094h-32.545v7.154h32.545v-7.154z"
      ></path>
      <g fill="#000" opacity="0.1">
        <path d="M183.564 217.751a15.822 15.822 0 010 5.724 15.733 15.733 0 010-5.724zM185.988 217.751a15.822 15.822 0 010 5.724 15.69 15.69 0 010-5.724zM187.162 217.751a15.822 15.822 0 010 5.724 15.69 15.69 0 010-5.724zM193.671 217.751a15.822 15.822 0 010 5.724 15.69 15.69 0 010-5.724zM195.429 217.751a15.822 15.822 0 010 5.724 15.822 15.822 0 010-5.724zM202.7 217.751a15.822 15.822 0 010 5.724 15.69 15.69 0 010-5.724zM204.382 217.751a15.822 15.822 0 010 5.724 15.602 15.602 0 010-5.724zM207.487 217.751a15.822 15.822 0 010 5.724 15.69 15.69 0 010-5.724zM201.293 217.751a15.69 15.69 0 010 5.724 15.822 15.822 0 010-5.724zM189.321 217.751a15.822 15.822 0 010 5.724 15.733 15.733 0 010-5.724zM197.741 217.751a15.69 15.69 0 010 5.724 15.822 15.822 0 010-5.724zM198.955 217.751a15.822 15.822 0 010 5.724 15.733 15.733 0 010-5.724zM211.486 217.751a15.822 15.822 0 010 5.724 15.69 15.69 0 010-5.724zM213.305 217.751a15.822 15.822 0 010 5.724 15.69 15.69 0 010-5.724zM208.894 217.751a15.69 15.69 0 010 5.724 15.822 15.822 0 010-5.724zM192.268 217.751a15.822 15.822 0 010 5.724 15.733 15.733 0 010-5.724z"></path>
      </g>
      <path
        fill="#000"
        d="M214.763 217.099v1.517l-32.54 1.021v-2.538h32.54z"
        opacity="0.1"
      ></path>
      <path
        fill="#5BBE90"
        d="M212.543 209.94h-32.546v7.154h32.546v-7.154z"
      ></path>
      <g fill="#000" opacity="0.1">
        <path d="M181.339 210.617a15.822 15.822 0 010 5.724 15.69 15.69 0 010-5.724zM183.762 210.617a15.822 15.822 0 010 5.724 15.733 15.733 0 010-5.724zM184.946 210.617a15.822 15.822 0 010 5.724 15.733 15.733 0 010-5.724zM191.445 210.617a15.822 15.822 0 010 5.724 15.733 15.733 0 010-5.724zM193.203 210.617a15.822 15.822 0 010 5.724 15.822 15.822 0 010-5.724zM200.475 210.617a15.822 15.822 0 010 5.724 15.69 15.69 0 010-5.724zM202.151 210.617a15.69 15.69 0 010 5.724 15.822 15.822 0 010-5.724zM205.241 210.617a15.822 15.822 0 010 5.724 15.602 15.602 0 010-5.724zM199.067 210.617a15.69 15.69 0 010 5.724 15.822 15.822 0 010-5.724zM187.096 210.617a15.822 15.822 0 010 5.724 15.69 15.69 0 010-5.724zM195.515 210.617a15.69 15.69 0 010 5.724 15.822 15.822 0 010-5.724zM196.73 210.617a15.822 15.822 0 010 5.724 15.69 15.69 0 010-5.724zM209.26 210.617a15.822 15.822 0 010 5.724 15.733 15.733 0 010-5.724zM211.079 210.617a15.822 15.822 0 010 5.724 15.602 15.602 0 010-5.724zM206.669 210.617c.35 1.892.35 3.832 0 5.724a15.822 15.822 0 010-5.724zM190.043 210.617a15.822 15.822 0 010 5.724 15.602 15.602 0 010-5.724z"></path>
      </g>
      <path
        fill="#000"
        d="M212.538 209.945v1.517l-32.541 1.021v-2.538h32.541z"
        opacity="0.1"
      ></path>
      <path
        fill="#5BBE90"
        d="M217.568 195.692h-32.546v7.154h32.546v-7.154z"
      ></path>
      <g fill="#000" opacity="0.1">
        <path d="M186.364 196.344a15.716 15.716 0 010 5.729 15.85 15.85 0 010-5.729zM188.788 196.344a15.76 15.76 0 010 5.729 15.85 15.85 0 010-5.729zM189.972 196.344a15.76 15.76 0 010 5.729 15.85 15.85 0 010-5.729zM196.471 196.344a15.76 15.76 0 010 5.729 15.85 15.85 0 010-5.729zM198.229 196.344a15.76 15.76 0 010 5.729 15.85 15.85 0 010-5.729zM205.5 196.344a15.716 15.716 0 010 5.729 15.85 15.85 0 010-5.729zM207.182 196.344a15.85 15.85 0 010 5.729 15.76 15.76 0 010-5.729zM210.266 196.344a15.85 15.85 0 010 5.729 15.76 15.76 0 010-5.729zM204.098 196.344a15.85 15.85 0 010 5.729 15.716 15.716 0 010-5.729zM192.121 196.344a15.716 15.716 0 010 5.729 15.85 15.85 0 010-5.729zM200.546 196.344a15.85 15.85 0 010 5.729 15.716 15.716 0 010-5.729zM201.755 196.344a15.716 15.716 0 010 5.729 15.85 15.85 0 010-5.729zM214.286 196.344a15.76 15.76 0 010 5.729 15.85 15.85 0 010-5.729zM216.125 196.344a15.85 15.85 0 010 5.729 15.716 15.716 0 010-5.729zM211.699 196.344a15.85 15.85 0 010 5.729 15.76 15.76 0 010-5.729zM195.068 196.344a15.85 15.85 0 010 5.729 15.76 15.76 0 010-5.729z"></path>
      </g>
      <path
        fill="#000"
        d="M217.563 195.692v1.517l-32.541 1.021v-2.538h32.541z"
        opacity="0.1"
      ></path>
      <path
        fill="#5BBE90"
        d="M216.75 188.533h-32.546v7.154h32.546v-7.154z"
      ></path>
      <g fill="#000" opacity="0.1">
        <path d="M215.282 194.913a15.817 15.817 0 010-5.723c.35 1.892.35 3.832 0 5.723zM212.858 194.913a15.817 15.817 0 010-5.723c.35 1.892.35 3.832 0 5.723zM211.674 194.913a15.817 15.817 0 010-5.723 15.684 15.684 0 010 5.723zM205.175 194.913a15.817 15.817 0 010-5.723 15.684 15.684 0 010 5.723zM203.422 194.913a15.817 15.817 0 010-5.723 15.684 15.684 0 010 5.723zM196.145 194.913a15.817 15.817 0 010-5.723 15.684 15.684 0 010 5.723zM194.469 194.913a15.684 15.684 0 010-5.723 15.817 15.817 0 010 5.723zM191.384 194.913a15.728 15.728 0 010-5.723 15.817 15.817 0 010 5.723zM197.553 194.913a15.684 15.684 0 010-5.723 15.817 15.817 0 010 5.723zM209.519 194.913a15.728 15.728 0 010-5.723 15.817 15.817 0 010 5.723zM201.105 194.913a15.684 15.684 0 010-5.723 15.817 15.817 0 010 5.723zM199.89 194.913a15.817 15.817 0 010-5.723 15.684 15.684 0 010 5.723zM187.36 194.913a15.817 15.817 0 010-5.723 15.684 15.684 0 010 5.723zM185.546 194.913a15.684 15.684 0 010-5.723 15.817 15.817 0 010 5.723zM189.951 194.913a15.728 15.728 0 010-5.723 15.817 15.817 0 010 5.723zM206.582 194.913a15.728 15.728 0 010-5.723 15.817 15.817 0 010 5.723z"></path>
      </g>
      <path
        fill="#000"
        d="M216.745 188.533v1.522l-32.541 1.021v-2.543h32.541z"
        opacity="0.1"
      ></path>
      <path
        fill="#5BBE90"
        d="M214.768 181.378h-32.545v7.155h32.545v-7.155z"
      ></path>
      <g fill="#000" opacity="0.1">
        <path d="M183.564 182.036a15.817 15.817 0 010 5.723 15.817 15.817 0 010-5.723zM185.988 182.036a15.817 15.817 0 010 5.723 15.728 15.728 0 010-5.723zM187.162 182.036a15.817 15.817 0 010 5.723 15.817 15.817 0 010-5.723zM193.671 182.036c.35 1.892.35 3.831 0 5.723a15.817 15.817 0 010-5.723zM195.429 182.036a15.684 15.684 0 010 5.723 15.817 15.817 0 010-5.723zM202.7 182.036a15.817 15.817 0 010 5.723 15.817 15.817 0 010-5.723zM204.382 182.036a15.817 15.817 0 010 5.723 15.728 15.728 0 010-5.723zM207.487 182.036a15.817 15.817 0 010 5.723 15.684 15.684 0 010-5.723zM201.298 182.036a15.817 15.817 0 010 5.723 15.684 15.684 0 010-5.723zM189.321 182.036a15.817 15.817 0 010 5.723 15.728 15.728 0 010-5.723zM197.741 182.036a15.684 15.684 0 010 5.723 15.817 15.817 0 010-5.723zM198.955 182.036a15.817 15.817 0 010 5.723 15.728 15.728 0 010-5.723zM211.486 182.036a15.817 15.817 0 010 5.723 15.684 15.684 0 010-5.723zM213.305 182.036a15.817 15.817 0 010 5.723 15.684 15.684 0 010-5.723zM208.894 182.036a15.684 15.684 0 010 5.723 15.728 15.728 0 010-5.723zM192.269 182.036a15.817 15.817 0 010 5.723 15.684 15.684 0 010-5.723z"></path>
      </g>
      <path
        fill="#000"
        d="M214.763 181.378v1.522l-32.54 1.022v-2.544h32.54z"
        opacity="0.1"
      ></path>
      <path
        fill="#5BBE90"
        d="M214.768 202.846h-32.545v7.155h32.545v-7.155z"
      ></path>
      <g fill="#000" opacity="0.1">
        <path d="M213.305 209.227a15.716 15.716 0 010-5.729 15.85 15.85 0 010 5.729zM210.881 209.227a15.76 15.76 0 010-5.729 15.85 15.85 0 010 5.729zM209.697 209.227a15.76 15.76 0 010-5.729 15.85 15.85 0 010 5.729zM203.198 209.227a15.76 15.76 0 010-5.729 15.716 15.716 0 010 5.729zM201.435 209.227a15.85 15.85 0 010-5.729 15.716 15.716 0 010 5.729zM194.169 209.227a15.716 15.716 0 010-5.729 15.85 15.85 0 010 5.729zM192.487 209.227a15.716 15.716 0 010-5.729 15.85 15.85 0 010 5.729zM189.403 209.227a15.716 15.716 0 010-5.729 15.85 15.85 0 010 5.729zM195.571 209.227a15.85 15.85 0 010-5.729 15.85 15.85 0 010 5.729zM207.548 209.227a15.716 15.716 0 010-5.729 15.85 15.85 0 010 5.729zM199.123 209.227a15.85 15.85 0 010-5.729 15.716 15.716 0 010 5.729zM197.914 209.227a15.716 15.716 0 010-5.729 15.85 15.85 0 010 5.729zM185.383 209.227a15.76 15.76 0 010-5.729 15.85 15.85 0 010 5.729zM183.564 209.227a15.755 15.755 0 010-5.728 15.844 15.844 0 010 5.728zM187.97 209.227a15.85 15.85 0 010-5.729 15.76 15.76 0 010 5.729zM204.601 209.227a15.716 15.716 0 010-5.729 15.85 15.85 0 010 5.729z"></path>
      </g>
      <path
        fill="#000"
        d="M214.763 202.846v1.522l-32.54 1.021v-2.543h32.54z"
        opacity="0.1"
      ></path>
      <path
        fill="#5BBE90"
        d="M179.509 192.36c21.418 3.822 15.752 35.392-5.696 31.57-21.448-3.823-15.762-35.402 5.696-31.57z"
      ></path>
      <path
        fill="#000"
        d="M179.509 192.36c21.418 3.822 15.752 35.392-5.696 31.57-21.448-3.823-15.762-35.402 5.696-31.57z"
        opacity="0.1"
      ></path>
      <path
        fill="#5BBE90"
        d="M177.507 192.001c20.417 3.646 14.736 35.22-5.696 31.57-20.432-3.651-14.766-35.2 5.696-31.57z"
      ></path>
      <path
        fill="#000"
        d="M161.699 208.529c.112 5.628 3.405 11.078 10.59 12.357 16.971 3.034 21.666-23.177 4.725-26.2l-.335-.056c15.889 3.165 11.336 27.965-4.842 25.078-6.601-1.178-9.787-6.032-10.138-11.179z"
        opacity="0.1"
      ></path>
      <path
        fill="#000"
        d="M176.415 197.977a.546.546 0 01.391.653l-.218.864a7.347 7.347 0 013.496 2.478.63.63 0 01.183.576.577.577 0 01-.707.399.547.547 0 01-.35-.247 6.433 6.433 0 00-2.917-2.22l-1.524 6.067c3.135 1.517 4.222 3.095 3.689 5.218-.575 2.295-2.922 3.443-5.666 2.852l-.422 1.688a.544.544 0 01-.655.39.537.537 0 01-.392-.648l.432-1.708a8.41 8.41 0 01-4.375-3.322.595.595 0 01-.127-.506.591.591 0 01.681-.409.577.577 0 01.356.247 7.234 7.234 0 003.75 3.034l1.55-6.173c-3.09-1.517-4.136-3.034-3.598-5.223.539-2.19 2.886-3.378 5.559-2.786l.203-.819a.55.55 0 01.661-.405zm-2.541 8.115l1.484-5.915c-2.124-.481-3.831.429-4.223 1.992-.37 1.451.097 2.533 2.734 3.898l.005.025zm.63 1.563l-1.499 5.976c2.185.505 3.918-.41 4.33-2.053.371-1.517-.163-2.614-2.836-3.944l.005.021z"
        opacity="0.1"
      ></path>
      <path
        fill="#5BBE90"
        d="M199.865 124.489l-91.732 50.145-36.098-36.954L.13 169.452l.081-36.313L78.55 98.334l37.023 37.379 69.039-36.676 15.254 25.452z"
      ></path>
      <path
        fill="#5BBE90"
        d="M200.734 146.058l34.136-58.862v-9.152l-68.298.005v9.152l34.162 58.857z"
      ></path>
      <g fill="#000" opacity="0.1">
        <path d="M199.865 124.489l-91.732 50.145-36.098-36.954L.13 169.452l.081-36.313L78.55 98.334l37.023 37.379 69.039-36.676 15.254 25.452z"></path>
        <path d="M200.734 146.058l34.136-58.862v-9.152l-68.298.005v9.152l34.162 58.857z"></path>
      </g>
      <path
        fill="#5BBE90"
        d="M199.865 115.342l-91.732 50.141-36.098-36.955L.13 160.3l.081-36.307L78.55 89.188l37.023 37.373 69.039-36.676 15.254 25.457z"
      ></path>
      <path
        fill="#5BBE90"
        d="M200.734 136.906l34.136-58.862-68.298.005 34.162 58.857z"
      ></path>
      <path
        fill="#EB9481"
        d="M132.421 191.071c-1.235-.753-26.422-30.7-26.656-32.444-.092-.663 4.065-4.551 9.248-9.142 1.118-.986 2.276-2.022 3.46-3.033 7.673-6.649 15.971-13.525 15.971-13.525l16.905 19.981-16.433 12.974-3.608 2.852s4.492 15.754 4.543 17.696c.051 1.941-2.2 5.394-3.43 4.641z"
      ></path>
      <path
        fill="#263238"
        d="M132.416 191.066c-1.23-.748-26.423-30.69-26.651-32.434-.081-.587 3.15-3.691 7.464-7.559.569 2.326 13.369 24.774 18.958 28.486 2.287 1.516.509-5.784.443-6.093 1.326 4.803 3.18 11.715 3.211 12.964.056 1.941-2.19 5.394-3.425 4.636z"
      ></path>
      <path
        fill="#263238"
        d="M132.818 174.083s-1.911-3.433-8.024-3.175l8.024 3.175zM177.924 126.395c-3.496 10.112-35.406 35.002-39.085 37.849l-.839.652-18.018-20.613.31-.268 17.739-14.91s-24.898 4.879-33.161-11.123c-3.282-6.371 1.637-20.841 1.637-20.841l26.539.47s-.147 4.51 3.557 4.707c12.734.693 22.764 1.077 29.289 2.665 8.399 2.047 15.243 12.099 12.032 21.412z"
      ></path>
      <path
        fill="#37474F"
        d="M137.309 102.353c8.212.304 16.428.708 24.589 1.795 1.016.147 2.032.309 3.048.506a19.75 19.75 0 013.008.799c.487.193.958.424 1.408.692.447.253.889.506 1.316.819.863.575 1.68 1.213 2.444 1.912a18.828 18.828 0 013.877 4.818 15.86 15.86 0 011.895 5.885c.11 1.032.12 2.071.031 3.105 0 .257-.051.505-.081.768-.031.263-.072.506-.122.774a11.928 11.928 0 01-.437 1.516 24.376 24.376 0 01-3.049 5.375 76.626 76.626 0 01-8.13 9.247c-2.917 2.887-5.95 5.648-9.055 8.328-3.105 2.679-6.301 5.248-9.507 7.796 3.166-2.604 6.301-5.248 9.36-7.973 3.059-2.725 6.097-5.501 8.953-8.408a80.766 80.766 0 008.038-9.238 24.03 24.03 0 002.973-5.288c.169-.471.306-.952.412-1.441.09-.494.154-.992.193-1.492.084-1.01.07-2.026-.041-3.033a15.232 15.232 0 00-1.824-5.729 18.51 18.51 0 00-3.76-4.727 20.382 20.382 0 00-2.388-1.886 19.323 19.323 0 00-1.296-.809c-.43-.26-.88-.483-1.347-.667-.959-.337-1.943-.6-2.942-.789a48.25 48.25 0 00-3.023-.506c-4.065-.607-8.13-1.011-12.241-1.319-4.111-.309-8.181-.617-12.302-.83z"
      ></path>
      <path
        fill="#37474F"
        d="M147.787 120.181a25.75 25.75 0 00-2.439 2.083c-.844.733-1.692 1.456-2.541 2.189h-.025c.168-.227.335-.46.508-.687.595-.779 1.113-1.603 1.773-2.326.041-.046-.025-.111-.066-.066-.675.718-1.422 1.375-2.032 2.149a24.98 24.98 0 00-.828 1.117c-.148.208-.295.42-.432.632-.061.096-.132.203-.188.309-.381.344-.757.687-1.123 1.041-.818.784-1.575 1.638-2.399 2.412-.04.04.026.101.071.061.819-.718 1.708-1.365 2.541-2.073s1.657-1.482 2.47-2.245c.813-.764 1.565-1.517 2.357-2.25a27.066 27.066 0 002.394-2.311c.025-.01-.005-.055-.041-.035zM141.232 160.518c-.457-.643-.975-1.259-1.468-1.876a170.15 170.15 0 00-1.479-1.825 204.64 204.64 0 00-3.089-3.691c-2.079-2.457-4.192-4.889-6.301-7.326-1.184-1.366-2.394-2.705-3.588-4.045 0 0-.071 0-.05.035a480.601 480.601 0 005.985 7.422 321.054 321.054 0 006.26 7.367c.59.674 1.189 1.348 1.799 2.022.61.673 1.215 1.365 1.87 2.023.031-.036.087-.061.061-.106z"
      ></path>
      <path
        fill="#37474F"
        d="M109.86 99.037a26.447 26.447 0 00-1.423 6.851c-.195 2.327.051 4.67.727 6.906a12.34 12.34 0 003.811 5.718 15.22 15.22 0 006.225 2.999c2.276.549 4.601.877 6.941.981 2.344.115 4.693.106 7.037-.026 4.692-.25 9.361-.822 13.974-1.714l.172-.03.056.152c.201.593.289 1.219.259 1.845a5.808 5.808 0 01-.355 1.81 6.934 6.934 0 01-.859 1.623c-.38.476-.792.926-1.235 1.345-1.737 1.663-3.557 3.205-5.401 4.742a361.85 361.85 0 01-5.589 4.551 395.394 395.394 0 01-5.651 4.429c1.863-1.5 3.715-3.017 5.554-4.55a424.558 424.558 0 005.493-4.617c1.819-1.552 3.623-3.124 5.32-4.793a12.95 12.95 0 001.179-1.309c.329-.471.599-.98.803-1.517a5.207 5.207 0 00.086-3.367l.229.121a100.844 100.844 0 01-14.035 1.765 66.408 66.408 0 01-7.083 0 36.673 36.673 0 01-7.017-1.012 15.168 15.168 0 01-6.347-3.119 12.639 12.639 0 01-3.821-5.946 18.12 18.12 0 01-.615-6.998c.214-2.339.74-4.639 1.565-6.84z"
      ></path>
      <path
        fill="#37474F"
        d="M121.105 100.19a43.92 43.92 0 01-1.377 3.513 8.896 8.896 0 01-1.763 2.872 6.963 6.963 0 01-2.78 1.517c-.595.189-1.2.343-1.814.46-.624.089-1.24.224-1.844.405a.122.122 0 00-.061.046.126.126 0 00.061.191 8.277 8.277 0 002.002 0 12.15 12.15 0 001.905-.323 6.955 6.955 0 003.049-1.679 8.252 8.252 0 001.784-3.135c.459-1.239.8-2.519 1.016-3.822a.091.091 0 00-.136-.101.089.089 0 00-.042.056z"
      ></path>
      <path
        fill="#263238"
        d="M124.581 59.59c-.333.057-.65.179-.935.359-1.296.799-1.773 2.346-1.926 3.766-.157 1.517.031 3.176-.63 4.596a2.565 2.565 0 01-1.23 1.31 2.378 2.378 0 01-1.941-.172c-.64-.293-1.336-.784-2.083-.627a1.978 1.978 0 00-1.306 1.218c-.63 1.436.092 3.075.076 4.55a7.904 7.904 0 01-1.433 4.465 7.682 7.682 0 01-3.47 2.71c-.678.27-1.392.441-2.119.506-.813.07-1.626-.03-2.439 0a2.833 2.833 0 00-2.104.834c-.386.47-.681 2.7.691 5.092.097.171-.218.333-.381.197a8.74 8.74 0 01-.894-.835 10.602 10.602 0 01-2.487-4.306 10.567 10.567 0 01-.247-4.961 11.026 11.026 0 012.297-4.824 18.54 18.54 0 001.839-2.386c.422-.778.65-1.644.666-2.528.066-1.694-.274-3.377.086-5.056a9.257 9.257 0 012.068-4.045 11.048 11.048 0 013.784-2.783 11.093 11.093 0 014.606-.963c3.155.015 9.69 3.852 9.512 3.883z"
      ></path>
      <path
        fill="#263238"
        d="M124.474 59.61a4.407 4.407 0 00-1.753.875 3.59 3.59 0 00-.945 1.491c-.437 1.264-.32 2.589-.249 3.898.071 1.229.076 2.528-.595 3.615a2.93 2.93 0 01-1.178 1.113 2.291 2.291 0 01-1.743.04c-1.052-.354-2.266-1.127-3.369-.46-1.103.667-1.052 2.139-1.057 3.22 0 2.645.371 6.068-2.073 7.863a9.834 9.834 0 01-4.39 1.517c-1.083.151-2.455.333-3.049 1.35 0 .035.04.065.061 0 .676-1.062 2.078-1.012 3.191-1.118a10.411 10.411 0 003.811-1.062 5.063 5.063 0 002.439-2.947c.381-1.297.534-2.65.452-4 0-.692-.041-1.385-.036-2.078a4.18 4.18 0 01.326-1.85 1.468 1.468 0 011.524-.83 7.904 7.904 0 011.839.602c.535.223 1.122.29 1.693.193a2.779 2.779 0 001.224-.658 4.272 4.272 0 001.296-2.81c.132-1.487-.234-2.979-.071-4.465.01-.785.282-1.544.773-2.159a3.56 3.56 0 011.94-1.234c.004-.01.005-.023.004-.034a.076.076 0 00-.01-.033.09.09 0 00-.023-.025.083.083 0 00-.032-.014zM113.991 54.802c-3.221-.061-6.173 1.88-7.764 4.596a11.013 11.013 0 00-1.443 4.995c-.091 1.633.046 3.317-.401 4.914-.508 1.765-1.875 2.948-3.049 4.283a9.93 9.93 0 00-2.033 3.726 10.5 10.5 0 00.915 7.978c.171.307.36.604.564.89 0 .036.081 0 .056-.035a10.076 10.076 0 01-1.382-7.908 9.873 9.873 0 011.661-3.66c.991-1.345 2.373-2.387 3.186-3.863.763-1.39.834-3.034.849-4.55 0-1.69.051-3.353.64-4.96a9.03 9.03 0 012.633-3.866 9.092 9.092 0 014.222-2.04c3.557-.596 6.88 1.153 9.71 3.11l1.128.788c.041.03.097-.03.051-.06-2.734-2.139-5.884-4.247-9.543-4.338z"
      ></path>
      <path
        fill="#5BBE90"
        d="M125.226 57.8a1.528 1.528 0 00-1.524.05c-.244.273-.407 3.095.091 3.595s1.55-.273 1.55-.273l-.117-3.372z"
      ></path>
      <path
        fill="#EB9481"
        d="M129.936 70.248c4.38-2.255 17.17-9.101 17.886-9.693.717-.591 14.411-13.434 16.311-15.673 1.347-1.578 10.539 10.855 9.289 12.164-2.922 3.034-11.748 11.275-16.26 14.188-3.699 2.376-21.733 5.354-27.078 5.637-5.346.283-7.348-2.917-.148-6.623z"
      ></path>
      <path
        fill="#EB9481"
        d="M179.357 52.132c-1.636 2.609-7.078 6.644-8.77 7.109-1.692.465-8.882-8.51-8.456-10.249.427-1.739 6.606-10.112 9.599-11.123 2.993-1.011 9.334 11.563 7.627 14.263z"
      ></path>
      <path
        fill="#EB9481"
        d="M167.472 40.367c.777 1.436 1.921.723 1.921.723s-2.48 1.977-1.855 3.665c.625 1.69 2.922.45 3.089.37-.132.106-2.362 1.976-1.56 3.61.803 1.633 2.958.116 3.09 0-.092.136-1.271 1.986-.371 3.084 1.524 1.896 4.69-2.746 6.651-3.034 0 0 .874 3.251.92 3.337 0 0 3.089-4.348 2.541-6.957a1.705 1.705 0 00-1.637-1.38s1.154-1.295.559-2.6c-.305-.667-1.656-.773-1.656-.773a2.131 2.131 0 00.279-2.204c-.477-.9-1.758-.87-1.758-.87s5.493-4.47 4.482-6.037c-1.738-2.7-12.947 7.524-14.314 7.22-.717-.146-.717 1.204-.381 1.846z"
      ></path>
      <path
        fill="#263238"
        d="M177.507 37.323c.026 0 0-.045 0-.045a15.277 15.277 0 00-8.196 3.852c-.071.066-.168.506-.081.44 2.063-1.78 5.228-3.312 8.277-4.247zM179.204 40.387c.031 0 0-.06 0-.056a14.285 14.285 0 00-8.678 4.753c-.077.091-.051.248.045.147 1.576-1.669 5.92-4.02 8.633-4.844zM172.07 48.987c2.206-2.482 4.985-4.014 8.075-5.182.035 0 0-.07-.026-.066-1.667.162-3.557 1.107-4.959 1.967a8.754 8.754 0 00-3.176 3.205c-.031.056.041.132.086.076z"
      ></path>
      <path
        fill="#263238"
        d="M169.337 41.085c-.082.08.274.126.193.212-.879.92-2.378 2.65-1.789 3.54.478.707 1.763.56 2.236.313l1.184-.622-.895 1.011a5.45 5.45 0 00-.97 1.481 1.948 1.948 0 00-.076 1.598 1.118 1.118 0 001.234.682 3.358 3.358 0 001.525-.732l1.255-.976-.859 1.325a5.045 5.045 0 00-.564 1.183c-.147.389-.175.813-.081 1.218a1.008 1.008 0 00.787.718 2.25 2.25 0 001.215-.298c.8-.447 1.565-.954 2.286-1.517.376-.273.752-.551 1.144-.809.378-.28.81-.478 1.27-.581a3.655 3.655 0 00-1.194.687c-.366.283-.717.587-1.072.89a14.928 14.928 0 01-2.256 1.679 2.64 2.64 0 01-1.443.41 1.435 1.435 0 01-1.2-1.012 2.55 2.55 0 01.056-1.517c.143-.468.348-.916.61-1.33l.391.35a3.853 3.853 0 01-1.798.879c-.374.067-.76 0-1.088-.192a1.829 1.829 0 01-.711-.814 2.444 2.444 0 01.096-2.022 5.746 5.746 0 011.093-1.613l.289.369c-.609.298-2.032.611-2.906-.39-.94-1.516 1.57-3.746 2.038-4.12z"
      ></path>
      <path
        fill="#EB9481"
        d="M162.131 48.992s.148-8.504 2.114-10.617c1.967-2.114 8.267-.936 10.498-.142 3.074 1.092 1.977 3.18.274 3.66-1.966.557-5.081.38-5.289.37 0 0 2.154 4.5.178 7.553-1.977 3.054-7.775-.824-7.775-.824z"
      ></path>
      <path
        fill="#263238"
        d="M170.2 37.288c1.296.07 2.583.263 3.842.576.93.253 2.032.551 2.607 1.43 1.59 3.363-4.955 3.54-6.957 3.196l.229-.333a8.636 8.636 0 01.971 3.57 7.412 7.412 0 01-.692 3.569c.195-.571.328-1.161.397-1.76a8.858 8.858 0 00-1.286-5.562c1.405.198 2.832.182 4.233-.045a7.37 7.37 0 001.804-.46c1.285-.551 1.524-1.765.325-2.594-1.621-.955-3.623-1.239-5.473-1.608v.02z"
      ></path>
      <path
        fill="#455A64"
        d="M172.06 59.833l-11.224-13.09s-12.704 13.433-13.811 13.904c-1.108.47-16.382 8.403-20.026 10.673-3.643 2.27-4.065 6.32 4.98 5.642 9.045-.677 20.015-3.337 23.785-4.464 3.039-.92 16.296-12.666 16.296-12.666z"
      ></path>
      <path
        fill="#455A64"
        d="M172.055 59.832s-13.252 11.745-16.29 12.67a141.79 141.79 0 01-15.808 3.48c-2.673.434-5.397.793-7.978.985-9.065.673-8.638-3.387-4.974-5.642.421-.263 1.016-.607 1.702-1.011 5.294-3.034 17.332-9.263 18.292-9.678 1.113-.47 13.811-13.904 13.811-13.904l11.245 13.1z"
      ></path>
      <path
        fill="#263238"
        d="M168.046 61.668c-.447-.425-3.887-4.697-4.619-5.643-.732-.945-3.74-4.783-4.411-5.82-.025-.045.031-.095.067-.055.853.89 3.902 4.748 4.664 5.698.762.95 4.065 5.248 4.37 5.774.025.046-.041.071-.071.046z"
      ></path>
      <path
        fill="#000"
        d="M139.957 75.981c-2.673.435-5.397.794-7.978.986-9.065.673-8.638-3.387-4.974-5.642.421-.263 1.016-.607 1.702-1.011l11.25 5.667z"
        opacity="0.1"
      ></path>
      <path
        fill="#EBEBEB"
        d="M104.52 99.547l33.191 2.265-2.084-10.01a10.335 10.335 0 001.947-8.595c-.641-2.948-4.198-7.514-6.367-10.406-5.29-7.043-10.356-4.247-12.012-2.589-6.321 6.34-14.675 29.335-14.675 29.335z"
      ></path>
      <path
        fill="#455A64"
        d="M121.857 68.468s2.81 8.772 3.979 10.805c1.168 2.032 4.73 5.748 4.73 5.748s.702-5.056.417-7.978c-.284-2.922-2.54-7.19-2.54-7.19a6.217 6.217 0 011.681 1.462c2.668 2.998 7.241 8.858 7.795 12.736a10.226 10.226 0 01-1.921 7.685l3.125 12.084-3.648 1.011-2.734-2.69-.543 3.109-30.046-5.086 4.853-8.06s7.174-16.017 9.873-19.465c3.079-3.944 4.979-4.171 4.979-4.171z"
      ></path>
      <path
        fill="#263238"
        d="M126.771 90.74a28.699 28.699 0 014.273.146c.706.086 1.403.208 2.099.34.35.065.696.12 1.046.176.364.067.734.09 1.103.066a.024.024 0 01.025.025.024.024 0 01-.007.018.026.026 0 01-.018.007c-.344.06-.692.097-1.042.112a6.79 6.79 0 01-1.016-.061c-.716-.081-1.428-.192-2.144-.283-1.438-.177-2.881-.354-4.334-.43-.061-.005-.061-.116.015-.116zM123.443 92.428a31.105 31.105 0 013.48-.617 22.625 22.625 0 011.758-.12h.844c.31 0 .62.04.93.034.03 0 .04.051 0 .051-.29 0-.585.076-.874.106l-.874.086c-.585.06-1.169.127-1.753.182-1.174.111-2.338.233-3.506.384-.056-.005-.061-.096-.005-.106zM121.705 68.66c-.453 2.275-.956 4.55-1.459 6.8l-.111-.197c1.336.425 2.662.88 3.988 1.32l.229.07-.112.213c-.447.865-.889 1.73-1.351 2.584l-.041-.248c1.362 1.31 2.744 2.599 4.065 3.933 1.321 1.335 2.698 2.655 4.014 4.01-1.428-1.239-2.835-2.528-4.232-3.782-1.398-1.254-2.775-2.573-4.162-3.863l-.117-.1.076-.148c.442-.864.905-1.713 1.362-2.573l.117.288c-1.326-.465-2.652-.91-3.969-1.385l-.147-.056.041-.141c.599-2.245 1.179-4.49 1.809-6.725zM129.118 70.4a98.187 98.187 0 011.896 2.23c.691.833 1.382 1.657 2.032 2.527l.137.172-.193.111-1.448.794.051-.313c.549.606 1.102 1.203 1.636 1.825l.076.08-.025.102c-.376 1.517-.722 3.094-1.123 4.631-.402 1.537-.783 3.08-1.22 4.606.28-1.567.595-3.12.92-4.677.325-1.557.676-3.099 1.016-4.651l.051.182c-.554-.597-1.087-1.219-1.626-1.83l-.173-.193.229-.116 1.458-.773-.056.283c-.67-.85-1.3-1.724-1.951-2.584-.65-.86-1.062-1.527-1.687-2.407z"
      ></path>
      <path
        fill="#263238"
        d="M130.953 87.145c.162.769.325 1.517.462 2.3.137.784.28 1.517.412 2.316v.04c-.036 1.7 1.016 8.682.914 10.381 0-1.704-1.255-8.692-1.184-10.39v.01a72.222 72.222 0 01-.33-2.326c-.096-.779-.188-1.552-.274-2.331zM130.399 89.147a.554.554 0 01-1.108-.055.571.571 0 01.234-.435.56.56 0 01.874.505v-.015zm0 0a.375.375 0 00-.137-.298.382.382 0 00-.295-.06.358.358 0 00-.178.124.358.358 0 00-.071.204.344.344 0 00.213.349.375.375 0 00.3-.03.38.38 0 00.168-.289zM130.805 94.102a.554.554 0 01-.707.488.573.573 0 01-.207-.109.56.56 0 01-.192-.456.579.579 0 01.237-.434.592.592 0 01.453-.086.558.558 0 01.416.597zm0 0a.346.346 0 00-.427-.359.36.36 0 00-.035.672.341.341 0 00.412-.152.344.344 0 00.05-.161zM131.639 98.647a.57.57 0 01-.473.531.6.6 0 01-.442-.131.572.572 0 01-.123-.7.58.58 0 01.169-.19.579.579 0 01.452-.086.56.56 0 01.417.576zm0 0a.353.353 0 00-.309-.37.329.329 0 00-.367.335.336.336 0 00.213.349.36.36 0 00.295 0 .383.383 0 00.168-.314z"
      ></path>
      <path
        fill="#EB9481"
        d="M123.224 68.938c-.051.612 1.474 5.198 4.131 5.92.869.238 2.368-4.408 2.368-4.408l.188-.319 2.652-4.454-4.486-5.45-.666-.749s-.64 1.27-1.428 2.852l-.112.227c-.045.087-.091.167-.132.253-.122.238-.244.476-.361.718-.116.243-.177.364-.264.562-.086.197-.178.384-.269.58a34.435 34.435 0 00-1.621 4.268z"
      ></path>
      <path
        fill="#263238"
        d="M125.983 62.33a10.351 10.351 0 001.101 4.33 10.397 10.397 0 002.827 3.471l2.652-4.454-4.486-5.45-.666-.749s-.64 1.27-1.428 2.852z"
      ></path>
      <path
        fill="#EB9481"
        d="M140.414 57.041c1.448 8.965-3.785 10.84-5.726 11.149-1.764.283-7.805 1.011-10.163-7.756-2.358-8.767 1.362-12.27 5.361-13.146 3.999-.874 9.09.784 10.528 9.754z"
      ></path>
      <path
        fill="#263238"
        d="M138.89 55.697a5.203 5.203 0 01-.625-.046 1.018 1.018 0 01-.635-.167.316.316 0 01-.056-.39.793.793 0 01.762-.292c.293 0 .576.11.792.308a.345.345 0 01.069.37.35.35 0 01-.307.217zM133.077 57.684a5.21 5.21 0 00.63-.051 1.02 1.02 0 00.63-.172.322.322 0 00.051-.39.805.805 0 00-.763-.287c-.294.006-.576.12-.792.318a.337.337 0 00-.09.175.335.335 0 00.021.195.34.34 0 00.313.212zM134.443 58.538s-.04.04-.035.066c.117.607.152 1.32-.371 1.613v.035c.671-.207.62-1.158.406-1.714z"
      ></path>
      <path
        fill="#263238"
        d="M133.808 57.992c-1.016.086-.757 2.063.168 1.987.925-.076.655-2.053-.168-1.987z"
      ></path>
      <path
        fill="#263238"
        d="M133.412 58.144c-.157.131-.305.354-.508.4-.203.045-.447-.132-.63-.314 0 0-.031 0-.031.025.041.384.234.753.651.758.416.005.609-.359.655-.753.005-.07-.071-.172-.137-.116zM137.873 57.992s.051.025.051.05c.051.617.214 1.315.798 1.457v.03c-.711-.015-.915-.945-.849-1.537z"
      ></path>
      <path
        fill="#263238"
        d="M138.336 57.29c.98-.188 1.29 1.784.376 1.956-.915.172-1.204-1.8-.376-1.957z"
      ></path>
      <path
        fill="#263238"
        d="M138.768 57.355c.172.08.355.248.543.233.188-.016.351-.258.458-.506 0-.025 0 0 .03 0 .071.38 0 .789-.345.915-.346.127-.615-.167-.768-.53-.025-.046.01-.142.082-.112zM137.147 61.066s.167.915.188 1.35c0 .04-.102.066-.249.08a2.082 2.082 0 01-2.033-.677.051.051 0 01-.009-.03.05.05 0 01.012-.028.046.046 0 01.058-.008 3.163 3.163 0 001.926.456c.025-.122-.346-1.558-.274-1.568.345.015.686.075 1.016.177-.3-1.916-.905-3.777-1.174-5.688 0-.07.092-.096.112-.035.711 1.966 1.222 3.999 1.524 6.067-.005.258-.919-.03-1.097-.096z"
      ></path>
      <path
        fill="#263238"
        d="M137.009 62.29a2.534 2.534 0 01-.914.9c-.209.109-.447.151-.681.12-.508-.07-.579-.505-.534-.924.026-.213.077-.422.153-.622.585.38 1.278.565 1.976.525z"
      ></path>
      <path
        fill="#FF9BBC"
        d="M136.095 63.19c-.209.109-.447.151-.681.12-.508-.07-.579-.505-.534-.924a1.189 1.189 0 011.215.804z"
      ></path>
      <path
        fill="#263238"
        d="M124.845 61.49a3.771 3.771 0 001.799-3.26 9.82 9.82 0 003.684-3.6c-.041.192-.346 1.587-.508 2.295 5.691-1.653 7.042-6.244 7.042-6.244a9.883 9.883 0 012.892 3.408s-.31-3.54-2.379-5.734a5.75 5.75 0 00-2.512-1.659 5.78 5.78 0 00-3.011-.166 8.275 8.275 0 00-4.324.562 8.235 8.235 0 00-3.43 2.679c-3.379 4.454.747 11.72.747 11.72z"
      ></path>
      <path
        fill="#263238"
        d="M134.113 55.535a10.928 10.928 0 01-2.845 1.38 9.15 9.15 0 003.999-2.978 8.575 8.575 0 001.077-1.866 5.92 5.92 0 00.549-2.275c0-.012.004-.023.013-.032a.046.046 0 01.078.032 6.934 6.934 0 01-.234 2.316 6.246 6.246 0 01-1.097 2.022 7.354 7.354 0 01-1.525 1.4h-.015z"
      ></path>
      <path
        fill="#EB9481"
        d="M124.83 63.528c.139.042.282.07.427.081.143.02.288.02.431 0 .183-.032.356-.103.509-.207.119-.082.226-.178.32-.288.133-.16.238-.341.31-.536l-.681-2.382s-2.195-2.68-3.43-1.88c-1.235.798.559 4.767 2.114 5.212z"
      ></path>
      <path
        fill="#263238"
        d="M123.259 59.443v.04c1.093.35 1.723 1.29 2.19 2.276a.841.841 0 00-.613-.364.859.859 0 00-.677.227c-.026 0 0 .06.035.05a.967.967 0 011.067.254c.215.247.402.517.559.803.056.091.224.03.178-.075v-.026c-.086-1.37-1.275-3.185-2.739-3.185z"
      ></path>
      <path
        fill="#EB9481"
        d="M116.898 71.254c-5.981 4.692-21.662 23.556-18.293 27.944 3.369 4.389 26.143 2.645 32.769.658 1.972-.587-3.252-13.05-5.371-13.651-1.763-.506-11.595 2.29-11.783 1.724-.188-.567 1.931-4.809 5.361-12.418 3.973-8.762-.046-6.33-2.683-4.257zM135.709 83.924c4.573.06 5.716.774 9.593-1.208 2.541-1.3 4.472-.238 2.759 2.184-2.794 3.959-8.694 5.107-10.853 4.92l-1.499-5.896z"
      ></path>
      <path
        fill="#EB9481"
        d="M123.188 86.391s5.443-2.022 10.31-2.416c3.364-.283 10.757-.096 16.596 8.09 1.382 1.94 1.976 5.677-1.443 3.331-2.429-1.668-3.461-3.458-5.051-4.171 0 0 .188 4.844-1.819 6.153a44.95 44.95 0 01-5.488 3.034 9.093 9.093 0 01-.666-.97s-1.986 1.046-5.304.211l-7.135-13.262z"
      ></path>
      <path
        fill="#EB9481"
        d="M136.755 84.218c1.423 1.041 4.416 3.301 6.215 5.99 1.997 2.994 5.035 9.289 2.8 10.735-1.637 1.057-3.009-1.244-3.009-1.244s.702 2.867-.985 3.504c-1.525.576-2.495-1.269-2.495-1.269s.127 1.81-1.469 2.083c-1.91.328-1.865-3.539-4.09-4.854-.503-.283 3.033-14.945 3.033-14.945z"
      ></path>
      <path
        fill="#263238"
        d="M139.438 91.802a14.248 14.248 0 013.354 7.851c0 .071-.046.117-.066.051a35.203 35.203 0 00-3.328-7.902s.03-.036.04 0zM136.466 93.874c.909 1.138 2.794 4.738 2.876 8.034 0 .071-.056.112-.071.046a37.507 37.507 0 00-2.836-8.09s.016-.005.031.01zM143.605 91.185c1.392.647 2.226 1.517 3.323 2.528a21.336 21.336 0 001.692 1.466c.544.41 1.347 1.011 2.033.622a1.202 1.202 0 00.452-1.143 5.798 5.798 0 00-.549-1.77s.026-.04.041 0c.27.51.487 1.045.645 1.598.104.378.09.778-.04 1.148-.341.769-1.301.728-1.936.41a6.9 6.9 0 01-1.062-.728 17.008 17.008 0 01-.93-.804c-.62-.572-1.21-1.178-1.819-1.755a19.667 19.667 0 00-1.901-1.517.037.037 0 01-.012-.026.035.035 0 01.01-.027.036.036 0 01.026-.012c.01 0 .02.003.027.01z"
      ></path>
      <path
        fill="#263238"
        d="M145.871 96.741a46.462 46.462 0 00-2.301-5.526c0-.025 0-.046.035-.025.971 1.243 2.353 4.363 2.637 5.339a7.938 7.938 0 01.437 3.19c-.233 1.517-1.285 1.643-1.671 1.659-.455.012-.9-.13-1.261-.405a4.052 4.052 0 01-1.016-1.274c0-.025 0-.05.036-.025.437.677 1.219 1.395 2.063 1.415a1.398 1.398 0 001.016-.364c.283-.327.436-.746.432-1.178a8.377 8.377 0 00-.407-2.806z"
      ></path>
      <path
        fill="#455A64"
        d="M123.188 86.392l4.772 15.805s-28.456 1.956-30.112-3.175C96.192 93.89 109.77 76.86 115.42 72.163c6.555-5.445 6.936-3.195 4.848 2.2-2.19 5.647-5.976 13.716-5.976 13.716l8.897-1.688z"
      ></path>
      <path
        fill="#263238"
        d="M105.323 90.73a31.496 31.496 0 018.907-2.79c.874-.148 1.753-.249 2.632-.36 0 0 .031.035 0 .04-1.543.248-3.07.586-4.573 1.011a146.27 146.27 0 00-4.461 1.305c-.839.263-1.662.561-2.49.85-.031.004-.051-.041-.015-.056z"
      ></path>
      <path
        fill="#263238"
        d="M123.138 86.341c-.143 0-8.054 1.34-8.318 1.416.05-.107 2.032-3.883 2.306-4.44l.082-.161c.178-.243.355-.506.508-.723.218-.319.432-.637.64-.95l-.681.914c-.106.142-.203.288-.305.435.199-.39.387-.783.58-1.178.305-.632.589-1.269.869-1.911h-.031c-.579 1.102-1.194 2.184-1.773 3.281-.58 1.097-2.79 5.137-2.775 5.137.016 0 8.735-1.648 8.908-1.689.056-.04.046-.141-.01-.131zM122.152 96.023c-.508-1.76-1.017-3.539-1.616-5.268a158.753 158.753 0 00-1.016-2.973c0-.035-.082 0-.071 0 .452 1.79.914 3.585 1.448 5.35.533 1.764 1.051 3.538 1.605 5.278a67.135 67.135 0 001.017 2.958h.035c-.361-1.795-.879-3.57-1.402-5.345z"
      ></path>
      <path
        fill="#263238"
        d="M128.275 102.48c-.204-.506-.453-1.204-.676-1.689-.061-.126-.158-.116-.112 0 .147.445.249.93.391 1.385l-1.194.051-1.382.076c-.904.045-1.804.081-2.708.111-1.83.054-3.657.071-5.483.051a106.406 106.406 0 01-10.986-.643 62.46 62.46 0 01-3.048-.419 30.326 30.326 0 01-2.973-.602s-.036.041 0 .051c.827.309 1.676.554 2.541.733.909.182 1.829.344 2.749.47 1.834.258 3.678.42 5.523.531a133.342 133.342 0 0014.09.096l1.555-.081a9.832 9.832 0 001.713-.121z"
      ></path>
    </svg>
  );
}

export default WelcomeIllustratorSVG;
