export interface ICredit {
  id: string;
  updatedAt: string;
  createdAt: string;
  deletedAt: null;
  name: string;
  noOfCredits: string;
  price: number;
  country: string;
  currency: string;
  discount: number;
  isCurrencyPrefix: boolean;
}

// Generated by https://quicktype.io

export interface TransactionGroup {
  month: number;
  year: number;
  transactions: Transaction[];
}

export interface Transaction {
  id: string;
  updated_at: string;
  created_at: string;
  deleted_at: null;
  no_of_credits: string;
  transaction_type: TransactionType;
  business_id: string;
  operation: Operation;
  status: string;
}

export enum Operation {
  Deduct = "Deduct",
  Addition = "Addition",
}

export enum TransactionType {
  CatalogueOrder = "CatalogueOrder",
  PurchaseOrder = "PurchaseOrder",
  SalesOrder = "SalesOrder",
}
