import { useEffect, useState } from "react";
import { FlexiColsType } from "../../../types/item";
import { Grid, Typography } from "@mui/joy";

interface Props {
  flexiCols: FlexiColsType | null;
  groupSize?: number;
}

const AttributesCollection = ({ flexiCols, groupSize = 6 }: Props) => {
  const [groupedCols, setGroupedCols] = useState<any[]>([]);

  useEffect(() => {
    if (flexiCols && flexiCols.data.length > 0) {
      const result = [];
      for (let i = 0; i < flexiCols.data.length; i += groupSize) {
        result.push(flexiCols.data.slice(i, i + groupSize));
      }

      setGroupedCols(result);
    }
  }, [flexiCols]);

  if (flexiCols) {
    return (
      <Grid container sx={{ width: "100%" }}>
        {groupedCols.map((col, index) => (
          <Grid
            key={index}
            xs={12}
            lg={4}
            display={"flex"}
            flexDirection={"column"}
            gap={"8px"}
          >
            {col.map((item: any, index: number) => {
              return (
                <div key={item.columnName + index} className="flex gap-4">
                  <span className="w-1/2">
                    <Typography
                      fontSize={14}
                      fontWeight={400}
                      sx={{ color: "black" }}
                    >
                      {item.columnName}
                    </Typography>
                  </span>

                  <span className="w-1/2 ml-auto lg:ml-0">
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      sx={{ color: "black" }}
                    >
                      {item.cellValue}
                    </Typography>
                  </span>
                </div>
              );
            })}
          </Grid>
        ))}
      </Grid>
    );
  }
};

export default AttributesCollection;
