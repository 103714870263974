import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
import Tabs from "@mui/joy/Tabs";
import { Helmet } from "react-helmet";
import { Link as RouterLink } from "react-router-dom";

import Shared from "../layouts/Shared";
import CataloguesComp from "../components/Catalogues";

const catalogs = [
  {
    id: 1,
    uuid: "catalog-1",
    name: "Catalog 1",
    type: "BUYER",
    items: [
      {
        id: 1,
        uuid: "item-1",
        description: "Item 1",
        name: "Item 1",
        price: 100,
        images: [
          "https://images.unsplash.com/photo-1661956601349-f61ddb82d179?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
          "https://images.unsplash.com/photo-1661956601349-f61ddb82d179?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
        ],
      },
    ]
  },
  {
    id: 2,
    uuid: "catalog-2",
    name: "Catalog 2",
    type: "SELLER",
    items: [
      {
        id: 1,
        uuid: "item-1",
        description: "Item 1",
        name: "Item 1",
        price: 100,
        images: [
          "https://images.unsplash.com/photo-1661956601349-f61ddb82d179?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
          "https://images.unsplash.com/photo-1661956601349-f61ddb82d179?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
        ],
      },
    ]
  },
];

export default function Catalogues() {
  let tabs = {
    buyer: catalogs.filter((catalog) => catalog.type === "BUYER"),
    seller: catalogs.filter((catalog) => catalog.type === "SELLER"),
  };

  return (
    <Shared
      sxProps={{
        marginBottom: 15,
      }}
      title="Catalogs"
      withDock
    >
      <Helmet
        link={[{ href: "https://linkzasia.com/catalogs", rel: "canonical" }]}
        meta={[{ content: "Catalogs", name: "description" }]}
        title="Catalogs - Linkz"
      />
      <Tabs
        aria-label="transactions"
        defaultValue={0}
        sx={{
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
        }}
      >
        <TabList
          sx={{
            [`&& .${tabClasses.root}`]: {
              "&:hover": {
                bgcolor: "transparent",
              },
              [`&.${tabClasses.selected}`]: {
                "&::after": {
                  bgcolor: "primary.500",
                  borderTopLeftRadius: 3,
                  borderTopRightRadius: 3,
                  height: 2,
                },
                color: "primary.plainColor",
              },
              bgcolor: "transparent",
              flex: "initial",
              paddingY: 2,
            },
            justifyContent: "space-evenly",
            paddingTop: 1,
          }}
        >
          <Tab>Seller ({tabs.seller.length})</Tab>
          <Tab>Buyer ({tabs.buyer.length})</Tab>
        </TabList>
        <TabPanel value={0}>
          <CataloguesComp catalogs={tabs.seller} />
        </TabPanel>
        <TabPanel value={1}>
          <CataloguesComp catalogs={tabs.buyer} />
        </TabPanel>
      </Tabs>
      <Box
        padding={4}
        sx={{
          backgroundColor: "white",
        }}
      >
        <Button
          component={RouterLink}
          fullWidth
          size="lg"
          to={"/catalogues/new"}
        >
          Create new
        </Button>
      </Box>
    </Shared>
  );
}
