import { Box, Button, Card, Modal, ModalDialog, Stack, Typography } from "@mui/joy";
import { ContentCopyOutlined, WarningOutlined } from "@mui/icons-material";
import moment from "moment"
import { formatCurrencyByCode } from "../../utils/formatPrice";

const paymentStatuses = [
  { key: "INITIAL_PAYMENT_PENDING", value: "Initial Payment Pending" },
  { key: "INITIAL_PAYMENT_PAID", value: "Initial Payment Paid" },
  { key: "INITIAL_PAYMENT_SUCCESS", value: "Initial Payment Success" },
  { key: "INITIAL_PAYMENT_FAILED", value: "Initial Payment Failed" },
  { key: "FINAL_PAYMENT_PENDING", value: "Final Payment Pending" },
  { key: "FINAL_PAYMENT_PAID", value: "Final Payment Paid" },
  { key: "FINAL_PAYMENT_SUCCESS", value: "Final Payment Success" },
  { key: "FINAL_PAYMENT_FAILED", value: "Final Payment Failed" }
]
export interface IPaymentInfoModalProps {
  open: boolean;
  setOpenModal: any;
  paymentInfo: any;
  currency?: string;
  status?: string,
  onFinish?: () => void;
}
export default function PaymentInfoModal(props: IPaymentInfoModalProps) {
  console.log("Info Modal", props.paymentInfo);
  const PaymentInfo = () => {
    switch (props.paymentInfo.paymentMethod) {
      case "va":
        return <>
          <Typography textColor={"text.primary"} fontWeight={"bold"} fontSize={"lg"}>Transfer with virtual account</Typography>
          {!props.paymentInfo.paymentUrl && <Typography startDecorator={<div>
            <img src={`${import.meta.env.VITE_SPACES_HOST}${props.paymentInfo.paymentChannelInfo?.icon}`} style={{ height: "30px", width: "30px", objectFit: "contain" }} />
          </div>} textColor={"text.primary"} fontWeight={"bold"} fontSize={"lg"}> Bank {props.paymentInfo.bankName}</Typography>}
          {!props.paymentInfo.paymentUrl && <Card color="neutral" variant="soft" size="sm">
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} p={2}>
              <Typography fontSize={20} color="primary" fontWeight={"900"}>{props.paymentInfo.vaNumber}</Typography>
              <Button onClick={() => navigator.clipboard.writeText(props.paymentInfo.vaNumber)} size="sm" color="primary" variant="outlined" sx={{ backgroundColor: "transparent", borderRadius: "10px" }}>
                <Typography color="primary" fontWeight={"lg"} fontSize={"sm"} startDecorator={<ContentCopyOutlined color="primary" />}>Copy</Typography>
              </Button>
            </Box>
          </Card>}
        </>
      case "retail":
        return <>
          <Typography textColor={"text.primary"} fontWeight={"bold"} fontSize={"lg"}>Transfer with retail</Typography>
          <Typography startDecorator={<div>
            <img src={`${import.meta.env.VITE_SPACES_HOST}${props.paymentInfo.paymentChannelInfo?.icon}`} style={{ height: "30px", width: "30px", objectFit: "contain" }} />
          </div>} textColor={"text.primary"} fontWeight={"bold"} fontSize={"lg"}> {props.paymentInfo.channel}</Typography>
          <Card color="neutral" variant="soft" size="sm">
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} p={2}>
              <Typography fontSize={20} color="primary" fontWeight={"900"}>{props.paymentInfo.paymentCode}</Typography>
              <Button onClick={() => navigator.clipboard.writeText(props.paymentInfo.paymentCode)} size="sm" color="primary" variant="outlined" sx={{ backgroundColor: "transparent", borderRadius: "10px" }}>
                <Typography color="primary" fontWeight={"lg"} fontSize={"sm"} startDecorator={<ContentCopyOutlined color="primary" />}>Copy</Typography>
              </Button>
            </Box>
          </Card>
        </>
      case "qris":
        return <>
          <Typography textColor={"text.primary"} fontWeight={"bold"} fontSize={"lg"}>Transfer Qris</Typography>
          <Card color="neutral" variant="soft" size="sm">
            <img src={`${props.paymentInfo.image}`} />
          </Card>
        </>
      case "e-wallet":
        return <>
          <>
            <Typography textColor={"text.primary"} fontWeight={"bold"} fontSize={"lg"}>Transfer with E-Wallet</Typography>
            <Typography startDecorator={<div>
              <img src={`${import.meta.env.VITE_SPACES_HOST}${props.paymentInfo.paymentChannelInfo?.icon}`} style={{ height: "30px", width: "30px", objectFit: "contain" }} />
            </div>} textColor={"text.primary"} fontWeight={"bold"} fontSize={"lg"}>{`${props.paymentInfo.channel}`.toUpperCase()}</Typography>
          </>

        </>
      case "credit-card":
        return <>
          <Typography textColor={"text.primary"} fontWeight={"bold"} fontSize={"lg"}>Payment with Credit/Debit Card</Typography>
        </>
      default:
        <>
          <Typography textColor={"text.primary"} fontWeight={"bold"} fontSize={"lg"}>Payment with {`${props.paymentInfo.channel}`.toUpperCase()}</Typography>
        </>
    }
  }
  return <Modal
    open={props.open}
    onClose={() => props.setOpenModal(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <ModalDialog
      layout="center"
      maxWidth={"lg"}
      sx={{
        width: "100%",
        maxWidth: "600px",
        borderRadius: "md",
        p: 2,
        overflow: "scroll",
      }}
    >


      <Typography
        textColor={"primary.solidActiveBg"}
        textAlign={"center"}
        level="h4"
        fontWeight={600}
      >
        Payment Info
      </Typography>

      {
        props.paymentInfo.paymentMethod === "loan-payment" && <Stack>
          <Typography textColor={"text.primary"} fontWeight={"bold"} fontSize={"lg"}>Transfer with loan payment</Typography>
          <Typography textColor={"text.primary"} fontSize={"sm"}>Our team will contact you within 3 days work and you can discuss with our team to proceed about the loan payment</Typography>

        </Stack>
      }
      {
        props.paymentInfo.paymentMethod !== "loan-payment" && <Stack gap={2} sx={{ width: "100%" }} spacing={1} direction={"column"} justifyContent={"stretch"}>
          <Card sx={{ width: "100%", justifyContent: "center", alignItems: "center", height: 50 }} color="warning" size="lg" variant="soft">
            <span><Typography startDecorator={<WarningOutlined color="warning" />} textColor={"black"} fontSize={"sm"} fontWeight={"lg"}>Please make the payment before &nbsp;&nbsp;<Typography color="warning">{moment(props.paymentInfo.expirationDate).format('hh:mm A (DD MMM YYYY)')}</Typography></Typography></span>
          </Card>
          {props.status && <Card sx={{ width: "100%", justifyContent: "center", alignItems: "center", height: 50 }} color="primary" size="lg" variant="soft">
            <span><Typography textColor={"black"} fontSize={"sm"} fontWeight={"lg"}>{paymentStatuses.find(s => s.key === props.status)?.value}</Typography></span>
          </Card>}
          <PaymentInfo />
          <Typography textColor={"text.primary"} fontWeight={"bold"} fontSize={"lg"}>Total transfer</Typography>
          <Card color="neutral" variant="soft" size="sm">
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} p={2}>
              <Typography fontSize={20} color="primary" fontWeight={"900"}>{formatCurrencyByCode(props.paymentInfo.totalAmount ?? 0, props.currency ?? '')}</Typography>
              <Button onClick={() => navigator.clipboard.writeText(props.paymentInfo.totalAmount)} size="sm" color="primary" variant="outlined" sx={{ backgroundColor: "transparent", borderRadius: "10px" }}>
                <Typography color="primary" fontWeight={"lg"} fontSize={"sm"} startDecorator={<ContentCopyOutlined color="primary" />}>Copy</Typography>
              </Button>
            </Box>
          </Card>
        </Stack>
      }
      {(!["credit-card", "e-wallet"].includes(props.paymentInfo.paymentMethod) && !props.paymentInfo.paymentUrl) && <Button variant="outlined" color="primary" sx={{ width: "100%", borderRadius: "10px" }} onClick={() => {
        props.setOpenModal(false)
        props.onFinish?.()
      }}>Finish Payment</Button>}
      {(["credit-card", "e-wallet"].includes(props.paymentInfo.paymentMethod) || props.paymentInfo.paymentUrl) && <Button variant="outlined" color="primary" sx={{ width: "100%", borderRadius: "10px" }} component="a" href={`${props.paymentInfo.paymentUrl ?? props.paymentInfo.url}`}>
        {props.paymentInfo.paymentMethod === "credit-card" ? "Proceed to Credit/Debit payment" : "Proceed to payment page"}
      </Button>}
    </ModalDialog>
  </Modal >
}