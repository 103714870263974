function DeleteIcon({ disabled = false }: { disabled?: boolean }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke={disabled ? "#98A2B3" : "#475467"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2.25 4.5h1.5m0 0h12m-12 0V15a1.5 1.5 0 001.5 1.5h7.5a1.5 1.5 0 001.5-1.5V4.5H3.75zM6 4.5V3a1.5 1.5 0 011.5-1.5h3A1.5 1.5 0 0112 3v1.5M7.5 8.25v4.5m3-4.5v4.5"
      ></path>
    </svg>
  );
}

export default DeleteIcon;
