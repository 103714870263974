import { Typography } from "@mui/joy";
import LeftIcon from "../../components/Icons/LeftIcon";

const TermsAndCondition = () => {
  const title = "Terms of Use";

  return (
    <div className="flex flex-col">
      {/* header */}
      <div className="flex items-center px-6 py-4">
        <LeftIcon />
        <Typography color="primary" className="text-lg">
          {title}
        </Typography>
      </div>
    </div>
  );
};

export default TermsAndCondition;

// header
