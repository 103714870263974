import { Card, Stack, Typography } from "@mui/joy";
import ContactForm from "./components/ContactForm";
import { useTranslation } from "react-i18next";

export default function AddBusinessContact() {
  const { t } = useTranslation()
  return <>
    <Card>
      <Stack alignItems={"center"}>
        <Typography textColor={"text.secondary"} fontWeight={"lg"} fontSize={"lg"}>{t("manage.businessContact.addNewContact")}</Typography>
      </Stack>
      <ContactForm />
    </Card>
  </>
}