export const countries = [
  { code: "AD", dial: "376", label: "Andorra" },
  {
    code: "AE",
    dial: "971",
    label: "United Arab Emirates",
  },
  { code: "AF", dial: "93", label: "Afghanistan" },
  {
    code: "AG",
    dial: "1-268",
    label: "Antigua and Barbuda",
  },
  { code: "AI", dial: "1-264", label: "Anguilla" },
  { code: "AL", dial: "355", label: "Albania" },
  { code: "AM", dial: "374", label: "Armenia" },
  { code: "AO", dial: "244", label: "Angola" },
  { code: "AQ", dial: "672", label: "Antarctica" },
  { code: "AR", dial: "54", label: "Argentina" },
  { code: "AS", dial: "1-684", label: "American Samoa" },
  { code: "AT", dial: "43", label: "Austria" },
  {
    code: "AU",
    dial: "61",
    label: "Australia",
    suggested: true,
  },
  { code: "AW", dial: "297", label: "Aruba" },
  { code: "AX", dial: "358", label: "Alland Islands" },
  { code: "AZ", dial: "994", label: "Azerbaijan" },
  {
    code: "BA",
    dial: "387",
    label: "Bosnia and Herzegovina",
  },
  { code: "BB", dial: "1-246", label: "Barbados" },
  { code: "BD", dial: "880", label: "Bangladesh" },
  { code: "BE", dial: "32", label: "Belgium" },
  { code: "BF", dial: "226", label: "Burkina Faso" },
  { code: "BG", dial: "359", label: "Bulgaria" },
  { code: "BH", dial: "973", label: "Bahrain" },
  { code: "BI", dial: "257", label: "Burundi" },
  { code: "BJ", dial: "229", label: "Benin" },
  { code: "BL", dial: "590", label: "Saint Barthelemy" },
  { code: "BM", dial: "1-441", label: "Bermuda" },
  { code: "BN", dial: "673", label: "Brunei Darussalam" },
  { code: "BO", dial: "591", label: "Bolivia" },
  { code: "BR", dial: "55", label: "Brazil" },
  { code: "BS", dial: "1-242", label: "Bahamas" },
  { code: "BT", dial: "975", label: "Bhutan" },
  { code: "BV", dial: "47", label: "Bouvet Island" },
  { code: "BW", dial: "267", label: "Botswana" },
  { code: "BY", dial: "375", label: "Belarus" },
  { code: "BZ", dial: "501", label: "Belize" },
  {
    code: "CA",
    dial: "1",
    label: "Canada",
    suggested: true,
  },
  {
    code: "CC",
    dial: "61",
    label: "Cocos (Keeling) Islands",
  },
  {
    code: "CD",
    dial: "243",
    label: "Congo, Democratic Republic of the",
  },
  {
    code: "CF",
    dial: "236",
    label: "Central African Republic",
  },
  {
    code: "CG",
    dial: "242",
    label: "Congo, Republic of the",
  },
  { code: "CH", dial: "41", label: "Switzerland" },
  { code: "CI", dial: "225", label: "Cote d'Ivoire" },
  { code: "CK", dial: "682", label: "Cook Islands" },
  { code: "CL", dial: "56", label: "Chile" },
  { code: "CM", dial: "237", label: "Cameroon" },
  { code: "CN", dial: "86", label: "China" },
  { code: "CO", dial: "57", label: "Colombia" },
  { code: "CR", dial: "506", label: "Costa Rica" },
  { code: "CU", dial: "53", label: "Cuba" },
  { code: "CV", dial: "238", label: "Cape Verde" },
  { code: "CW", dial: "599", label: "Curacao" },
  { code: "CX", dial: "61", label: "Christmas Island" },
  { code: "CY", dial: "357", label: "Cyprus" },
  { code: "CZ", dial: "420", label: "Czech Republic" },
  {
    code: "DE",
    dial: "49",
    label: "Germany",
    suggested: true,
  },
  { code: "DJ", dial: "253", label: "Djibouti" },
  { code: "DK", dial: "45", label: "Denmark" },
  { code: "DM", dial: "1-767", label: "Dominica" },
  {
    code: "DO",
    dial: "1-809",
    label: "Dominican Republic",
  },
  { code: "DZ", dial: "213", label: "Algeria" },
  { code: "EC", dial: "593", label: "Ecuador" },
  { code: "EE", dial: "372", label: "Estonia" },
  { code: "EG", dial: "20", label: "Egypt" },
  { code: "EH", dial: "212", label: "Western Sahara" },
  { code: "ER", dial: "291", label: "Eritrea" },
  { code: "ES", dial: "34", label: "Spain" },
  { code: "ET", dial: "251", label: "Ethiopia" },
  { code: "FI", dial: "358", label: "Finland" },
  { code: "FJ", dial: "679", label: "Fiji" },
  {
    code: "FK",
    dial: "500",
    label: "Falkland Islands (Malvinas)",
  },
  {
    code: "FM",
    dial: "691",
    label: "Micronesia, Federated States of",
  },
  { code: "FO", dial: "298", label: "Faroe Islands" },
  {
    code: "FR",
    dial: "33",
    label: "France",
    suggested: true,
  },
  { code: "GA", dial: "241", label: "Gabon" },
  { code: "GB", dial: "44", label: "United Kingdom" },
  { code: "GD", dial: "1-473", label: "Grenada" },
  { code: "GE", dial: "995", label: "Georgia" },
  { code: "GF", dial: "594", label: "French Guiana" },
  { code: "GG", dial: "44", label: "Guernsey" },
  { code: "GH", dial: "233", label: "Ghana" },
  { code: "GI", dial: "350", label: "Gibraltar" },
  { code: "GL", dial: "299", label: "Greenland" },
  { code: "GM", dial: "220", label: "Gambia" },
  { code: "GN", dial: "224", label: "Guinea" },
  { code: "GP", dial: "590", label: "Guadeloupe" },
  { code: "GQ", dial: "240", label: "Equatorial Guinea" },
  { code: "GR", dial: "30", label: "Greece" },
  {
    code: "GS",
    dial: "500",
    label: "South Georgia and the South Sandwich Islands",
  },
  { code: "GT", dial: "502", label: "Guatemala" },
  { code: "GU", dial: "1-671", label: "Guam" },
  { code: "GW", dial: "245", label: "Guinea-Bissau" },
  { code: "GY", dial: "592", label: "Guyana" },
  { code: "HK", dial: "852", label: "Hong Kong" },
  {
    code: "HM",
    dial: "672",
    label: "Heard Island and McDonald Islands",
  },
  { code: "HN", dial: "504", label: "Honduras" },
  { code: "HR", dial: "385", label: "Croatia" },
  { code: "HT", dial: "509", label: "Haiti" },
  { code: "HU", dial: "36", label: "Hungary" },
  { code: "ID", dial: "62", label: "Indonesia" },
  { code: "IE", dial: "353", label: "Ireland" },
  { code: "IL", dial: "972", label: "Israel" },
  { code: "IM", dial: "44", label: "Isle of Man" },
  { code: "IN", dial: "91", label: "India" },
  {
    code: "IO",
    dial: "246",
    label: "British Indian Ocean Territory",
  },
  { code: "IQ", dial: "964", label: "Iraq" },
  {
    code: "IR",
    dial: "98",
    label: "Iran, Islamic Republic of",
  },
  { code: "IS", dial: "354", label: "Iceland" },
  { code: "IT", dial: "39", label: "Italy" },
  { code: "JE", dial: "44", label: "Jersey" },
  { code: "JM", dial: "1-876", label: "Jamaica" },
  { code: "JO", dial: "962", label: "Jordan" },
  {
    code: "JP",
    dial: "81",
    label: "Japan",
    suggested: true,
  },
  { code: "KE", dial: "254", label: "Kenya" },
  { code: "KG", dial: "996", label: "Kyrgyzstan" },
  { code: "KH", dial: "855", label: "Cambodia" },
  { code: "KI", dial: "686", label: "Kiribati" },
  { code: "KM", dial: "269", label: "Comoros" },
  {
    code: "KN",
    dial: "1-869",
    label: "Saint Kitts and Nevis",
  },
  {
    code: "KP",
    dial: "850",
    label: "Korea, Democratic People's Republic of",
  },
  { code: "KR", dial: "82", label: "Korea, Republic of" },
  { code: "KW", dial: "965", label: "Kuwait" },
  { code: "KY", dial: "1-345", label: "Cayman Islands" },
  { code: "KZ", dial: "7", label: "Kazakhstan" },
  {
    code: "LA",
    dial: "856",
    label: "Lao People's Democratic Republic",
  },
  { code: "LB", dial: "961", label: "Lebanon" },
  { code: "LC", dial: "1-758", label: "Saint Lucia" },
  { code: "LI", dial: "423", label: "Liechtenstein" },
  { code: "LK", dial: "94", label: "Sri Lanka" },
  { code: "LR", dial: "231", label: "Liberia" },
  { code: "LS", dial: "266", label: "Lesotho" },
  { code: "LT", dial: "370", label: "Lithuania" },
  { code: "LU", dial: "352", label: "Luxembourg" },
  { code: "LV", dial: "371", label: "Latvia" },
  { code: "LY", dial: "218", label: "Libya" },
  { code: "MA", dial: "212", label: "Morocco" },
  { code: "MC", dial: "377", label: "Monaco" },
  {
    code: "MD",
    dial: "373",
    label: "Moldova, Republic of",
  },
  { code: "ME", dial: "382", label: "Montenegro" },
  {
    code: "MF",
    dial: "590",
    label: "Saint Martin (French part)",
  },
  { code: "MG", dial: "261", label: "Madagascar" },
  { code: "MH", dial: "692", label: "Marshall Islands" },
  {
    code: "MK",
    dial: "389",
    label: "Macedonia, the Former Yugoslav Republic of",
  },
  { code: "ML", dial: "223", label: "Mali" },
  { code: "MM", dial: "95", label: "Myanmar" },
  { code: "MN", dial: "976", label: "Mongolia" },
  { code: "MO", dial: "853", label: "Macao" },
  {
    code: "MP",
    dial: "1-670",
    label: "Northern Mariana Islands",
  },
  { code: "MQ", dial: "596", label: "Martinique" },
  { code: "MR", dial: "222", label: "Mauritania" },
  { code: "MS", dial: "1-664", label: "Montserrat" },
  { code: "MT", dial: "356", label: "Malta" },
  { code: "MU", dial: "230", label: "Mauritius" },
  { code: "MV", dial: "960", label: "Maldives" },
  { code: "MW", dial: "265", label: "Malawi" },
  { code: "MX", dial: "52", label: "Mexico" },
  { code: "MY", dial: "60", label: "Malaysia" },
  { code: "MZ", dial: "258", label: "Mozambique" },
  { code: "NA", dial: "264", label: "Namibia" },
  { code: "NC", dial: "687", label: "New Caledonia" },
  { code: "NE", dial: "227", label: "Niger" },
  { code: "NF", dial: "672", label: "Norfolk Island" },
  { code: "NG", dial: "234", label: "Nigeria" },
  { code: "NI", dial: "505", label: "Nicaragua" },
  { code: "NL", dial: "31", label: "Netherlands" },
  { code: "NO", dial: "47", label: "Norway" },
  { code: "NP", dial: "977", label: "Nepal" },
  { code: "NR", dial: "674", label: "Nauru" },
  { code: "NU", dial: "683", label: "Niue" },
  { code: "NZ", dial: "64", label: "New Zealand" },
  { code: "OM", dial: "968", label: "Oman" },
  { code: "PA", dial: "507", label: "Panama" },
  { code: "PE", dial: "51", label: "Peru" },
  { code: "PF", dial: "689", label: "French Polynesia" },
  { code: "PG", dial: "675", label: "Papua New Guinea" },
  { code: "PH", dial: "63", label: "Philippines" },
  { code: "PK", dial: "92", label: "Pakistan" },
  { code: "PL", dial: "48", label: "Poland" },
  {
    code: "PM",
    dial: "508",
    label: "Saint Pierre and Miquelon",
  },
  { code: "PN", dial: "870", label: "Pitcairn" },
  { code: "PR", dial: "1", label: "Puerto Rico" },
  {
    code: "PS",
    dial: "970",
    label: "Palestine, State of",
  },
  { code: "PT", dial: "351", label: "Portugal" },
  { code: "PW", dial: "680", label: "Palau" },
  { code: "PY", dial: "595", label: "Paraguay" },
  { code: "QA", dial: "974", label: "Qatar" },
  { code: "RE", dial: "262", label: "Reunion" },
  { code: "RO", dial: "40", label: "Romania" },
  { code: "RS", dial: "381", label: "Serbia" },
  { code: "RU", dial: "7", label: "Russian Federation" },
  { code: "RW", dial: "250", label: "Rwanda" },
  { code: "SA", dial: "966", label: "Saudi Arabia" },
  { code: "SB", dial: "677", label: "Solomon Islands" },
  { code: "SC", dial: "248", label: "Seychelles" },
  { code: "SD", dial: "249", label: "Sudan" },
  { code: "SE", dial: "46", label: "Sweden" },
  { code: "SG", dial: "65", label: "Singapore" },
  { code: "SH", dial: "290", label: "Saint Helena" },
  { code: "SI", dial: "386", label: "Slovenia" },
  {
    code: "SJ",
    dial: "47",
    label: "Svalbard and Jan Mayen",
  },
  { code: "SK", dial: "421", label: "Slovakia" },
  { code: "SL", dial: "232", label: "Sierra Leone" },
  { code: "SM", dial: "378", label: "San Marino" },
  { code: "SN", dial: "221", label: "Senegal" },
  { code: "SO", dial: "252", label: "Somalia" },
  { code: "SR", dial: "597", label: "Suriname" },
  { code: "SS", dial: "211", label: "South Sudan" },
  {
    code: "ST",
    dial: "239",
    label: "Sao Tome and Principe",
  },
  { code: "SV", dial: "503", label: "El Salvador" },
  {
    code: "SX",
    dial: "1-721",
    label: "Sint Maarten (Dutch part)",
  },
  {
    code: "SY",
    dial: "963",
    label: "Syrian Arab Republic",
  },
  { code: "SZ", dial: "268", label: "Swaziland" },
  {
    code: "TC",
    dial: "1-649",
    label: "Turks and Caicos Islands",
  },
  { code: "TD", dial: "235", label: "Chad" },
  {
    code: "TF",
    dial: "262",
    label: "French Southern Territories",
  },
  { code: "TG", dial: "228", label: "Togo" },
  { code: "TH", dial: "66", label: "Thailand" },
  { code: "TJ", dial: "992", label: "Tajikistan" },
  { code: "TK", dial: "690", label: "Tokelau" },
  { code: "TL", dial: "670", label: "Timor-Leste" },
  { code: "TM", dial: "993", label: "Turkmenistan" },
  { code: "TN", dial: "216", label: "Tunisia" },
  { code: "TO", dial: "676", label: "Tonga" },
  { code: "TR", dial: "90", label: "Turkey" },
  {
    code: "TT",
    dial: "1-868",
    label: "Trinidad and Tobago",
  },
  { code: "TV", dial: "688", label: "Tuvalu" },
  {
    code: "TW",
    dial: "886",
    label: "Taiwan",
  },
  {
    code: "TZ",
    dial: "255",
    label: "United Republic of Tanzania",
  },
  { code: "UA", dial: "380", label: "Ukraine" },
  { code: "UG", dial: "256", label: "Uganda" },
  {
    code: "US",
    dial: "1",
    label: "United States",
    suggested: true,
  },
  { code: "UY", dial: "598", label: "Uruguay" },
  { code: "UZ", dial: "998", label: "Uzbekistan" },
  {
    code: "VA",
    dial: "379",
    label: "Holy See (Vatican City State)",
  },
  {
    code: "VC",
    dial: "1-784",
    label: "Saint Vincent and the Grenadines",
  },
  { code: "VE", dial: "58", label: "Venezuela" },
  {
    code: "VG",
    dial: "1-284",
    label: "British Virgin Islands",
  },
  {
    code: "VI",
    dial: "1-340",
    label: "US Virgin Islands",
  },
  { code: "VN", dial: "84", label: "Vietnam" },
  { code: "VU", dial: "678", label: "Vanuatu" },
  { code: "WF", dial: "681", label: "Wallis and Futuna" },
  { code: "WS", dial: "685", label: "Samoa" },
  { code: "XK", dial: "383", label: "Kosovo" },
  { code: "YE", dial: "967", label: "Yemen" },
  { code: "YT", dial: "262", label: "Mayotte" },
  { code: "ZA", dial: "27", label: "South Africa" },
  { code: "ZM", dial: "260", label: "Zambia" },
  { code: "ZW", dial: "263", label: "Zimbabwe" },
];

export const findCountryByCode = (code  : string | null) => countries.find(c => c.code === code) || null 
