import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import Box from "@mui/joy/Box";
import ButtonGroup from "@mui/joy/ButtonGroup";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import { Link as RouterLink } from "react-router-dom";

export default function AppBar() {
  return (
    <Box padding={2}>
      <Stack
        alignItems="center"
        direction="row"
        id="app-bar"
        justifyContent="space-between"
      >
        <IconButton aria-label="account" size="lg">
          <AccountCircleOutlinedIcon
            sx={{ color: "primary.900", fontSize: 35 }}
          />
        </IconButton>
        <ButtonGroup
          aria-label="button group"
          sx={{ "--ButtonGroup-separatorColor": "transparent" }}
          variant="plain"
        >
          <IconButton
            aria-label="notifications"
            component={RouterLink}
            size="lg"
            to={"/notis"}
          >
            <NotificationsOutlinedIcon
              sx={{ color: "primary.900", fontSize: 35 }}
            />
          </IconButton>
        </ButtonGroup>
      </Stack>
    </Box>
  );
}
