import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Button, Card, CircularProgress, Stack, Typography } from "@mui/joy";
import { useQuery } from "@tanstack/react-query";
import { KycPaymentGatewayService } from "../../services/kyc-payment-gateway.service";
import { BusinessSessionHelper } from "../../helpers/business-session.helper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { KycPaymentGatewayStatus } from "../../types/kyc";

export default function KycSnackBar() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data, isLoading, isSuccess, refetch } = useQuery({
    queryKey: ["kyc-payment-gateway-status"],
    queryFn: () => KycPaymentGatewayService.getVerificationStatus(BusinessSessionHelper.GetBusinessId()),
  })

  var status = data?.status
  const color = isSuccess && (status !== null) ? (status === KycPaymentGatewayStatus.INREVIEW ? "warning" : "danger") : "primary"
  const content = isSuccess && status !== null
    ? (
      status == KycPaymentGatewayStatus.INREVIEW ? t("kycSnackBar.inreviewStatusDesc")
        : t("kycSnackBar.rejectedStatusDesc"))
    : t("kycSnackBar.unsubmitedStatusDesc")
  console.log("KYC", status)
  refetch()
  if (!["ID", "MY"].includes(BusinessSessionHelper.Business()?.countryCode ?? '')) return <></>
  return <>
    {isLoading
      ? <CircularProgress />
      : !(status === KycPaymentGatewayStatus.VALIDATED) && <Card
        sx={{
          padding: 1,
          width: "100%",
          flex: 1,
          borderRadius: 10,
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
        variant="soft"
        color={color}
      >
        <Stack direction={{
          xs: "column",
          md: "row",
        }}
          alignItems={{
            xs: "end",
            md: "center",
          }} gap={1}>
          <Typography
            startDecorator={<ExclamationCircleIcon color="primary" width={20} height={20} />}
            fontSize={"sm"} flex={1}>
            {content}
          </Typography>
          {(status === null || status === KycPaymentGatewayStatus.REJECTED) && BusinessSessionHelper.IsOwner() && <Button
            color={color}
            onClick={() => {
              navigate("/client/profile/kyc", { state: { backUrl: window.location.href } })
            }} variant="plain">
            {status === KycPaymentGatewayStatus.REJECTED ? t("kycSnackBar.reSubmitTitle") : t("kycSnackBar.setupNowTitle")}
          </Button>}
        </Stack>
      </Card >}
  </>;
}