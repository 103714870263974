import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Typography,
} from "@mui/joy";
import React from "react";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import PasswordPolicy from "../../components/PasswordPolicy/PasswordPolicy";
import { SuccessModal } from "../../components/SuccessModal";
import { useChangePasswordContainer } from "./useChangePasswordContainer";
import { useTranslation } from "react-i18next";

export default function ProfileChangePassword() {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    newPassword,
    onSubmit,
    errors,
    showCurrentPassword,
    handlShowCurrentPassword,
    showNewPassword,
    handlShowNewPassword,
    isLoading,
    showSuccesModal,
  } = useChangePasswordContainer();
  return (
    <React.Fragment>
      <Divider />
      <form
        id="change-password-form"
        aria-label="pforile-change-password"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{ display: "flex", marginTop: 5, flexDirection: "column" }}
          component="section"
        >
          <FormControl error={!!errors.currentPassword}>
            <FormLabel>
              <Typography
                className={`transition-opacity duration-300  "opacity-100 h-auto"`}
                fontSize={"sm"}
                fontWeight={500}
                textColor={"text.secondary"}
              >
                {t("profile.currentPasswordLabel")}
              </Typography>
            </FormLabel>
            <Input
              endDecorator={
                <IconButton onClick={handlShowCurrentPassword}>
                  {showCurrentPassword ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </IconButton>
              }
              placeholder={t("profile.currentPasswordLabel")}
              required
              size="md"
              sx={{
                width: { xs: "90vw", lg: 480 },
              }}
              type={showCurrentPassword ? "text" : "password"}
              {...register("currentPassword")}
            />
            {errors.currentPassword?.message ? (
              <FormHelperText>{errors.currentPassword?.message}</FormHelperText>
            ) : null}
          </FormControl>
          <br />
          <FormControl error={!!errors.newPassword}>
            <FormLabel>
              <Typography
                className={`transition-opacity duration-300 "opacity-100 h-auto"`}
                fontSize={"sm"}
                fontWeight={500}
                textColor={"text.secondary"}
              >
                {t("profile.newPasswordLabel")}
              </Typography>
            </FormLabel>
            <Input
              endDecorator={
                <IconButton onClick={handlShowNewPassword}>
                  {showNewPassword ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </IconButton>
              }
              placeholder={t("profile.newPasswordLabel")}
              required
              size="md"
              sx={{
                width: { xs: "90vw", lg: 480 },
              }}
              type={showNewPassword ? "text" : "password"}
              {...register("newPassword")}
            />
            {errors.newPassword?.message ? (
              <FormHelperText>{errors.newPassword?.message}</FormHelperText>
            ) : null}
          </FormControl>
          <br />
          <PasswordPolicy value={newPassword} />
          <Box
            sx={{
              alignSelf: "center",
              display: "flex",
              position: "absolute",
              maxWidth: 480,
              width: { xs: "90vw", lg: 480 },
              bottom: 0,
              marginBottom: { xs: 0, lg: 5 },
            }}
          >
            <Button
              disabled={!(!errors.newPassword && !errors.currentPassword)}
              fullWidth
              size="lg"
              type="submit"
            >
              {t("profile.changePasswordBtn")}
            </Button>
          </Box>
        </Box>
      </form>
      <SuccessModal
        open={showSuccesModal}
        title={t("commons.successModalTitle")}
        content={t("profile.passwordUpdated")}
      />
      <LoadingModal isLoading={isLoading} />
    </React.Fragment>
  );
}
