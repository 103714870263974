import { zodResolver } from "@hookform/resolvers/zod";
import { Typography } from "@mui/joy";
import { useMutation } from "@tanstack/react-query";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import { countries } from "../../components/Countries";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import { TRACKER_CONSTANTS } from "../../constants/tracker.events";
import { InvitationType } from "../../services/account.service";
import { AuthService } from "../../services/auth.service";
import { TrackerService } from "../../services/tracker.service";

export const passwordValidation = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*-.])[A-Za-z\d!@#$%&*-.]{8,}$/
);

const ssoSchema = z.object({
  mobile: z
    .string()
    .min(
      1,
      i18next.t("commons.error.form.required", {
        label: i18next.t("landing.signup.phoneNumber"),
      })
    )
    .max(
      100,
      i18next.t("commons.error.form.required", {
        label: i18next.t("landing.signup.phoneNumber"),
      })
    ),
  agreed: z.boolean().default(false),
  companyName: z.string(),
  country: z
    .object({
      code: z.string(),
      dial: z.string(),
      label: z.string(),
    })
    .optional(),
  fullName: z.string().min(1).max(100),
  companyCountryCode: z.string(),
  referralCode: z.string().optional().nullable(),
});

const generalSchema = z.object({
  companyName: z.string(),
  country: z
    .object({
      code: z.string(),
      dial: z.string(),
      label: z.string(),
    })
    .optional(),
  email: z.string().email({
    message: i18next.t("commons.error.form.required", {
      label: i18next.t("landing.signup.email"),
    }),
  }),
  fullName: z.string().min(1).max(100),
  mobile: z
    .string()
    .min(
      1,
      i18next.t("commons.error.form.required", {
        label: i18next.t("landing.signup.phoneNumber"),
      })
    )
    .max(
      100,
      i18next.t("commons.error.form.required", {
        label: i18next.t("landing.signup.phoneNumber"),
      })
    ),
  password: z
    .string()
    .min(8)
    .regex(passwordValidation, i18next.t("commons.error.form.password")),
  countryName: z.string(),
  referralCode: z.string().optional().nullable(),
  referrerUserId: z.string().optional().nullable(),
});
const invitationSchema = generalSchema.merge(
  z.object({
    companyName: generalSchema.shape.companyName.optional(),
    countryName: generalSchema.shape.countryName.optional(),
    referralCode: generalSchema.shape.referralCode.optional().nullable(),
    referrerUserId: generalSchema.shape.referrerUserId.optional().nullable(),
  })
);

const changePhoneNoSchema = generalSchema
  .partial({
    companyName: true,
    fullName: true,
    password: true,
    countryName: true,
  })
  .refine((data) => data.mobile !== undefined, {
    message: "Mobile number is required",
  });

const ssoChangePhoneNoSchema = generalSchema
  .partial({
    email: true,
    companyName: true,
    fullName: true,
    password: true,
    countryName: true,
  })
  .refine((data) => data.mobile !== undefined, {
    message: "Mobile number is required",
  });

const useContainer = () => {
  const [invitationType, setInvitationType] = useState<InvitationType | null>(
    null
  );
  const [showModal, setShowModal] = useState(false);
  const { state } = useLocation();
  const isFromSSO = state.origin === "sso";
  const [urlSearchParams] = useSearchParams();
  const token = urlSearchParams.get("token");
  const callbackUrl = urlSearchParams.get("callbackURL");
  const userSub = localStorage.getItem("userSub");
  const phoneVerificationToken = localStorage.getItem("phoneVerificationToken");
  const navigate = useNavigate();

  const isChangePhoneNo = phoneVerificationToken && userSub;
  const schema = isChangePhoneNo
    ? isFromSSO
      ? ssoChangePhoneNoSchema
      : changePhoneNoSchema
    : isFromSSO ? ssoSchema
      : token ? invitationSchema : generalSchema

  console.log(isChangePhoneNo
    ? isFromSSO
      ? "ssoChangePhoneNoSchema"
      : "changePhoneNoSchema"
    : isFromSSO ? "ssoSchema"
      : token ? "invitationSchema" : "generalSchema"
  )

  const {
    setError,
    formState: { errors, isValid },
    watch,
    handleSubmit,
    setValue,
    trigger,
    clearErrors,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    mode: "onChange",
  });

  console.log("BANANA ERRORS", errors);

  const { mobile, country, email } = watch() as any;
  const invitationTokenMutation = useMutation({
    mutationFn: AuthService.invitationTokenCheck,
    onSuccess: ({ data }) => {
      if (data.redirectURL) {
        navigate(data.redirectURL);
      } else {
        setInvitationType(data.invitationType);
        setValue("email", data.email);
        if (data.phoneNumber) {
          // setValue("mobile", data.phoneNumber.mobile);
          setValue("country", {
            code: data.phoneNumber.countryCode,
            dial: data.phoneNumber.countryCode + `${data.phoneNumber.mobile}`,
            label:
              countries.find((c) => c.code === data.phoneNumber.countryCode)
                ?.label ?? "",
          });
        }
      }
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
      navigate("/signup");
    },
  });

  // const referralCodeMutation = useMutation({
  //   mutationFn: AuthService.referralCodeCheck,
  //   onSuccess: ({ data }) => {
  //     console.log("BANANA REFERRAL CODE", data);
  //     // if (data.redirectURL) {
  //     //   navigate(data.redirectURL);
  //     // } else {
  //     //   setInvitationType(data.invitationType);
  //     //   setValue("email", data.email);
  //     //   if (data.phoneNumber) {
  //     //     // setValue("mobile", data.phoneNumber.mobile);
  //     //     setValue("country", {
  //     //       code: data.phoneNumber.countryCode,
  //     //       dial: data.phoneNumber.countryCode + `${data.phoneNumber.mobile}`,
  //     //       label:
  //     //         countries.find((c) => c.code === data.phoneNumber.countryCode)
  //     //           ?.label ?? "",
  //     //     });
  //     //   }
  //     // }
  //   },
  //   onError: (error: any) => {
  //     toast(showErrorToast(error), ErrorToastConfig);
  //     navigate("/signup");
  //   },
  // });

  // TODO: try with invitation token if can get the businessId: if with role and referrer businessId is the same, then invalid, if different business but there is an entry in ther eferral entity with user businessId, then invalid

  const mutation = useMutation({
    mutationFn: !token
      ? AuthService.signup
      : invitationTokenMutation.data?.data.hasBusinessProfile
        ? AuthService.signupInvitationWithRole
        : invitationTokenMutation.data?.data.hasAccount
          ? AuthService.signupInvitationWithoutRole
          : invitationType === InvitationType.BUSINESS_CONNECTION_INVITATION
            ? AuthService.signupInvitationBusinessConnect
            : AuthService.signup,
    onSuccess: ({ data }) => {
      if (data.token) {
        localStorage.setItem("phoneVerificationToken", data.token);
        localStorage.setItem("userSub", data.id);
        localStorage.setItem("email", email);
        localStorage.setItem("countryCode", country?.code.toString() ?? "");
        localStorage.setItem("mobile", mobile);
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          if (callbackUrl) {
            navigate(`/verify?callbackURL=${encodeURIComponent(callbackUrl)}`);
          } else {
            navigate("/verify");
          }
        }, 4000);
      }
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const ssoMutation = useMutation({
    mutationFn: !token
      ? AuthService.signupSSO
      : invitationTokenMutation.data?.data.hasBusinessProfile
        ? AuthService.signupSSOWithRoleInvitation
        : invitationTokenMutation.data?.data.hasAccount
          ? AuthService.signupSSOWithoutRoleInvitation
          : invitationType === InvitationType.BUSINESS_CONNECTION_INVITATION
            ? AuthService.signupSSOBusinessConnectInvitation
            : AuthService.signupSSO,
    onSuccess: ({ data: { activation, created, token, id } }) => {
      const _state = { origin: "sso" };
      if (!activation) {
        if (callbackUrl) {
          navigate(`/signin?callbackURL=${encodeURIComponent(callbackUrl)}`, {
            state: _state,
          });
        } else {
          navigate("/signin", {
            state: _state,
          });
        }
        return;
      }
      localStorage.setItem("userSub", id);
      localStorage.setItem("phoneVerificationToken", token);
      localStorage.setItem("countryCode", country?.code.toString() || "");
      localStorage.setItem("mobile", mobile);
      if (created === true) {
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          if (callbackUrl) {
            navigate(`/verify?callbackURL=${encodeURIComponent(callbackUrl)}`, {
              state: _state,
            });
          } else {
            navigate("/verify", {
              state: _state,
            });
          }
        }, 4000);
      } else {
        if (callbackUrl) {
          navigate(`/verify?callbackURL=${encodeURIComponent(callbackUrl)}`, {
            state: _state,
          });
        } else {
          navigate("/verify", {
            state: _state,
          });
        }
      }
    },
    onError: (error: any) => {
      const errorMessage =
        error.response.data.message || "Something went wrong.";
      toast(
        (t) => (
          <div
            style={{
              width: "100%",
              opacity: t.visible ? 1 : 0,
              transition: "opacity 100ms ease-in-out",
            }}
          >
            <Typography
              textColor={"danger.softColor"}
              fontSize={"14px"}
              fontWeight={500}
              textAlign={"center"}
              py={"12px"}
            >
              {errorMessage}
            </Typography>
          </div>
        ),
        {
          style: {
            padding: 0,
            margin: 0,
          },
          position: "bottom-center",
          duration: 4000,
        }
      );
    },
  });

  const updatePhoneMutation = useMutation({
    mutationFn: AuthService.changePhoneNumber,
    onSuccess: ({ data }) => {
      const _state = { ...(isFromSSO ? { origin: "sso" } : {}) };
      localStorage.setItem("phoneVerificationToken", data.token);
      localStorage.setItem("countryCode", country?.code.toString() ?? "");
      localStorage.setItem("mobile", mobile);
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        if (callbackUrl) {
          navigate(`/verify?callbackURL=${encodeURIComponent(callbackUrl)}`, {
            state: _state,
          });
        } else {
          navigate("/verify", { state: _state });
        }
      }, 4000);
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof schema>> = (data: any) => {
    if (isFromSSO && !isChangePhoneNo) {
      let payload: any = {};
      if (invitationTokenMutation.data?.data.hasBusinessProfile) {
        payload = {
          fullName: data.fullName,
          companyName: data.companyName,
          countryCode: data.country!.code,
          accessToken: state.accessToken,
          companyCountryCode: data.companyCountryCode,
          invitationToken: token,
          localPhoneNumber: data.mobile,
          referralCode: data.referralCode,
          referrerUserId: data.referrerUserId,
        };
      } else {
        payload = {
          fullName: data.fullName,
          companyName: data.companyName,
          countryCode: data.country!.code,
          accessToken: state.accessToken,
          companyCountryCode: data.companyCountryCode,
          localPhoneNumber: data.mobile,
          referralCode: data.referralCode,
          referrerUserId: data.referrerUserId,
        };
        if (token) {
          payload["invitationToken"] = token;
        }
        if (invitationType === InvitationType.BUSINESS_CONNECTION_INVITATION) {
          payload.token = token;
        }
      }
      ssoMutation.mutate(payload);
      return;
    }
    if (isChangePhoneNo) {
      updatePhoneMutation.mutate({
        token: phoneVerificationToken!,
        localPhoneNumber: data.mobile,
        countryCode: data.country!.code,
      });
      return;
    }
    let payload: any = {};

    if (invitationTokenMutation.data?.data.hasBusinessProfile) {
      payload = {
        fullName: data.fullName,
        localPhoneNumber: data.mobile,
        countryCode: data.country!.code,
        email: data.email,
        password: data.password!,
        referralCode: data.referralCode,
        referrerUserId: data.referrerUserId,
      };
    } else {
      payload = {
        fullName: data.fullName,
        companyName: data.companyName!,
        localPhoneNumber: data.mobile,
        countryCode: data.country!.code,
        email: data.email,
        password: data.password,
        companyCountryCode: data.countryName!,
        referralCode: data.referralCode,
        referrerUserId: data.referrerUserId,
      };
    }
    const { password, ...filteredPayload } = payload;
    TrackerService.identify(data.email, {
      ...filteredPayload,
    });
    TrackerService.track(
      TRACKER_CONSTANTS.REGISTRATION_VIA_CATALOG.events.UserProceedWithSignUp,
      {
        "Signup method": "Email signup",
      }
    );

    if (invitationType == InvitationType.BUSINESS_CONNECTION_INVITATION) {
      payload.token = token;
    }
    if (payload.localPhoneNumber.startsWith("0")) {
      payload.localPhoneNumber = payload.localPhoneNumber.replace(/^0+/, "");
    }
    payload.email = data.email.toLowerCase();
    mutation.mutate(payload);
  };

  useEffect(() => {
    // console.log("BANANA STATE", state);
    if (isFromSSO) {
      setValue("password", state.password);
      setValue("companyCountryCode", state.companyCountryCode);
      setValue("fullName", state.fullName);
      setValue("companyName", state.companyName);
      setValue("email", state.email);
      // setValue("email", "pepe@mailinator.com");
      setValue("referralCode", state.referralCode);
      setValue("referrerUserId", state.referrerUserId);
      trigger();
    } else if (isChangePhoneNo) {
      setValue("email", state.email);
      trigger();
    } else {
      if (!state) {
        navigate("/signup");
      } else {
        setValue("password", state.password);
        setValue("countryName", state.companyCountryCode);
        setValue("fullName", state.fullName);
        setValue("companyName", state.companyName);
        setValue("email", state.email);
        setValue("referralCode", state.referralCode);
        setValue("referrerUserId", state.referrerUserId);
        trigger();
      }
    }
  }, [state]);

  useEffect(() => {
    if (token) {
      invitationTokenMutation.mutate({ token });
    }
  }, [token]);

  return {
    isValid,
    errors,
    mobile,
    phoneNo: `${country?.code ?? ""}${country?.dial ?? ""}`,
    setError,
    setValue,
    isLoading:
      mutation.isPending ||
      invitationTokenMutation.isPending ||
      ssoMutation.isPending ||
      updatePhoneMutation.isPending,
    clearErrors,
    onSubmit,
    handleSubmit,
    trigger,
    showModal,
  };
};

export default useContainer;
