import { Typography } from "@mui/joy";
import LoadingModal from "../../../../components/LoadingModal/LoadingModal";
import { CreditItem } from "../CreditItem/CreditItem";
import useContainer from "./useContainer";
import { useTranslation } from "react-i18next";

export function CreditsSection() {
  const { t } = useTranslation();
  const { isPending, discountedCredits, availableCredits } = useContainer();
  if (isPending) {
    return <LoadingModal isLoading={isPending} />;
  }
  return (
    <>
      <div className="flex flex-col gap-3 p-4">
        <Typography fontWeight={700} fontSize={16} textColor={"neutral.900"}>
          {t("credits.creditSection.specialTitle")}
        </Typography>
        {discountedCredits.map((credit) => (
          <CreditItem key={credit.id} credit={credit} />
        ))}
      </div>
      <hr className="border-b border-[rgba(99, 107, 116, 0.3)] border-0" />
      <div className="flex flex-col gap-3 p-4">
        <Typography fontWeight={700} fontSize={16} textColor={"neutral.900"}>
          {t("credits.creditSection.allCredits")}
        </Typography>
        {availableCredits.map((credit) => (
          <CreditItem key={credit.id} credit={credit} />
        ))}
      </div>
    </>
  );
}
