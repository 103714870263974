import NotFoundIllustration from "../../components/Icons/NotFoundIcon";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen gap-10 mx-auto lg:w-screen w-[309px]">
      <div className="lg:w-[402px] lg:h-[402px] w-[309px] h-[309px]">
        <NotFoundIllustration />
      </div>
      <div className="flex flex-col gap-2">
        <h1 className="lg:text-2xl text-base font-semibold text-center text-[#171A1C]">
          You’re in wrong page!!!
        </h1>
        <p className="lg:text-lg font-medium text-center text-[#555E68] text-base">
          You can go back to the previous page and try find specific page that
          you want to visit
        </p>
      </div>

      {/* <p>
        <i>{error.statusText || error.message}</i>
      </p> */}
    </div>
  );
};

export default NotFound;
