import i18next from "i18next";
import { useCallback, useState } from "react";
import { ItemService } from "../../services/item.service";
import { getActiveBusiness } from "../../store/session";
import {
  CreateItemType,
  ItemType,
  PaginationType,
  UpdateItemSKUType,
} from "../../types/item";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useContainer = () => {
  //#region ----- local states
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [currentItem, setCurrentItem] = useState<ItemType>();
  const [inventoryItems, setInventoryItems] = useState<ItemType[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState<PaginationType>({
    current: 1,
    limit: 50,
    max: 1,
  });
  const [hasMore, setHasMore] = useState(true);
  const [allTags, setAllTags] = useState<string[]>([]);
  const [allSku, setAllSku] = useState<string[]>([]);
  const [allUOMs, setAllUOMs] = useState<string[]>([]);

  const [searchParams] = useSearchParams();

  const { t } = useTranslation();

  const duplicates: string[] = [];

  //#region ----- misc
  const fetchInventoryItems = useCallback(
    ({
      businessId,
      paging = true,
      limit = 50,
      offset = 0,
      sortBy = "updatedAt",
      sortDirection = "desc",
      searchTerm,
      inventoryType,
      isReset = true,
    }: {
      businessId: string;
      paging?: boolean;
      limit?: number;
      offset?: number;
      sortBy?: string;
      sortDirection?: "asc" | "desc";
      searchTerm?: string | null;
      inventoryType?: string | null;
      isReset?: boolean;
    }) => {
      ItemService.getInventoryItems({
        businessId,
        paging,
        limit,
        offset,
        sortBy,
        sortDirection,
        searchTerm,
        inventoryType,
      })
        .then((res) => {
          setShowAlert(false);
          if (isReset) {
            setInventoryItems(res.data);
            setHasMore(true);
          } else {
            setInventoryItems((prev) => [...prev, ...res.data]);
          }

          if (res.pagination) {
            setPagination(res.pagination);
          }

          if (res.data.length === 0) {
            setHasMore(false);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    },
    []
  );

  const fetchCurrentItem = useCallback(
    ({ inventoryItemId }: { inventoryItemId: string }) => {
      ItemService.getInventoryItemById({
        id: inventoryItemId,
      })
        .then((res) => {
          setShowAlert(false);

          if (searchParams.get("mode") === "create") {
            res.sku = "";
            res.productName = "";
            res.productDescription = "";
            res.inventoryType = "";
          }
          setCurrentItem(res);
        })
        .catch((err) => console.error(err));
    },
    []
  );

  const fetchInventoryFormData = (
    businessId: string,
    inventoryItemId?: string
  ) => {
    setIsLoading(true);
    inventoryItemId && fetchCurrentItem({ inventoryItemId });
    ItemService.getInventoryItemField({
      fields: ["uom1", "uom2", "uom3", "tags", "sku"],
      businessId,
      isDistinct: false,
    })
      .then((res) => {
        const uoms: string[] = [];
        const tags: string[] = [];

        for (let i = 0; i < res.length; i++) {
          if (res[i].tags) {
            tags.push(...res[i].tags.tags);
          }

          for (const key in res[i]) {
            if (
              res[i][key] !== undefined &&
              res[i][key] !== null &&
              !uoms.includes(res[i][key]) &&
              key !== "tags" &&
              key !== "sku" &&
              res[i][key] !== ""
            ) {
              uoms.push(res[i][key]);
            }
          }
        }

        setAllTags(Array.from(new Set(tags)));
        setAllUOMs(uoms);
        setAllSku(res.map((item: any) => item.sku));
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  };

  const handleItemFlexiCols = (items: ItemType[], flexiCols?: string[]) => {
    const allFlexiColumns: string[] = [];
    for (const item of items) {
      const flexiData = item.flexiColumns?.data || [];
      for (const column of flexiData) {
        if (!allFlexiColumns.includes(column.columnName)) {
          allFlexiColumns.push(column.columnName);
        }
      }
    }

    const tableData: Array<string | number>[] = [];

    if (flexiCols) {
      for (const item of items) {
        // defined the fixed values
        const fixedValues = [
          item.sku,
          item.productName,
          item.quantity ?? 0,
          item.currency,
          item.price,
          item.uom1 ?? "unit",
          item.inventoryType,
        ];

        // get the flexi values
        const flexiValues = [];
        for (const column of flexiCols) {
          const flexiData = item.flexiColumns?.data || [];
          const value =
            flexiData.find((d) => d.columnName === column)?.cellValue || "n/a";
          flexiValues.push(value);
        }

        tableData.push([...fixedValues, ...flexiValues]);
      }
    }

    return {
      allFlexiColumns: allFlexiColumns,
      tableData: tableData,
    };
  };

  const splitIntoChunks = (array: any, chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const deleteInventoryItemBatch = (ids: string[]) => {
    setIsLoading(true);
    ItemService.deleteInventoryItemBatch({ ids })
      .then((res) => {
        if (res) {
          setAlertMessage(
            t("catalog.inventory.removeItemsSuccessModalLabel", {
              selected: ids.length,
            })
          );
          setShowAlert(true);

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  };

  //#region ----- handler functions
  const handlePaging = (action: string | number) => {
    const pagingConfig = {
      limit: pagination.limit,
      offset: 0,
    };

    if (typeof action === "string") {
      switch (action) {
        case "first":
          pagingConfig.offset = 0;
          break;

        case "previous":
          pagingConfig.offset = pagination.limit * (pagination.current - 2);
          break;

        case "next":
          pagingConfig.offset = pagination.limit * pagination.current;
          break;

        case "last":
          pagingConfig.offset =
            pagination.max * pagination.limit - pagination.limit;
          break;
      }
    } else {
      pagingConfig.offset = (action - 1) * pagination.limit;
    }

    const businessId = getActiveBusiness()?.id;
    if (businessId) {
      try {
        fetchInventoryItems({
          businessId: businessId,
          paging: true,
          ...pagingConfig,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleExcelUpload = async (data: CreateItemType[]) => {
    const chunks = splitIntoChunks(data, 200);
    const uploadPromises = chunks.map(async (chunk) => {
      try {
        await ItemService.createBatchInventoryItems({
          items: chunk,
        });
      } catch (error: any) {
        console.error(error);
        handleError(error.message);
      }
    });

    await Promise.all(uploadPromises);
    setShowAlert(true);
    setAlertMessage(i18next.t("catalog.inventory.excelUploaded"));
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleUpdateSku = async (businessId: string, data: ItemType[]) => {
    const chunks = splitIntoChunks(data, 200);
    const uploadPromises = chunks.map(async (chunk) => {
      try {
        await ItemService.patchInventoryItemSKU({
          businessId,
          updateSkuDto: chunk as UpdateItemSKUType[],
        });
      } catch (error: any) {
        console.error(error);
        handleError(error.message);
      }
    });

    await Promise.all(uploadPromises);
    setShowAlert(true);
    setAlertMessage(
      i18next.t("catalog.inventory.editForm.inventoryItemUpdated")
    );
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleError = (message: string) => {
    setIsLoading(false);
    setAlertMessage(message);
    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    }, 2000);
  };

  return {
    handlePaging,
    handleExcelUpload,
    handleError,
    handleUpdateSku,
    handleItemFlexiCols,
    fetchInventoryItems,
    fetchCurrentItem,
    fetchInventoryFormData,
    deleteInventoryItemBatch,
    isLoading,
    setIsLoading,
    showAlert,
    setShowAlert,
    alertMessage,
    setAlertMessage,
    showCreateModal,
    setShowCreateModal,
    pagination,
    setPagination,
    inventoryItems,
    setInventoryItems,
    searchTerm,
    setSearchTerm,
    showToast,
    setShowToast,
    currentItem,
    setCurrentItem,
    allTags,
    setAllTags,
    allSku,
    setAllSku,
    allUOMs,
    setAllUOMs,
    hasMore,
    setHasMore,
    duplicates,
  };
};

export default useContainer;
