import { Box, Button, Modal, Stack, Table, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CatalogInventoryItemType } from "../../types/item";
import { CartItemDataType } from "../../types/cart";
import { calculatePrice } from "../../utils/cart";
import { formatPrice } from "../../utils/formatPrice";

interface Props {
  show?: boolean;
  onClick: () => void;
  onClose: () => void;
  updatedItems: {
    cartItem: CartItemDataType;
    item: CatalogInventoryItemType;
  }[];
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  width: "94%",
  maxWidth: 900,
  px: 4,
  py: 4,
  borderRadius: "32px",
  borderColor: "transparent",
  outline: "none",
};

const ItemUpdatedModal = ({
  show = false,
  onClick,
  onClose,
  updatedItems,
}: Props) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const handleClick = () => {
    onClick();
    setShowModal(false);
    onClose();
  };

  return (
    <Modal
      open={showModal}
      style={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} justifyContent={"center"}>
        <Typography
          mt={4}
          textAlign={"left"}
          fontWeight={600}
          fontSize={24}
          id="modal-modal-title"
          sx={{
            color: "#265641",
          }}
        >
          {t("catalog.updatedCatalogModalTitle")}
        </Typography>
        <div className="w-full flex flex-col">
          {/* copy link  */}
          <Stack direction={"row"} justifyContent={"left"} mt={4}>
            <Table
              borderAxis="both"
              sx={{ "& th": { textAlign: "center" }, minWidth: "100%" }}
            >
              <thead>
                <tr key={"header"}>
                  <th key={"sku"} className="w-24 lg:w-36">
                    {t("catalog.items.sku")}
                  </th>
                  <th key={"productName"} className="w-48">
                    {t("catalog.items.productName")}
                  </th>
                  <th key={"initPrice"} className="w-24 lg:w-36">
                    {t("catalog.cartInitPrice")}
                  </th>
                  <th key={"newPrice"} className="w-24 lg:w-36">
                    {t("catalog.cartNewPrice")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {updatedItems.map((d) => {
                  const currency =
                    d.item.currency ?? d.item.inventoryItem.currency;
                  return (
                    <tr key={d.item.id}>
                      <td>{d.item.inventoryItem.sku}</td>
                      <td>{d.item.inventoryItem.productName}</td>
                      <td className="text-right break-all">
                        {`${currency} ${formatPrice(
                          d.cartItem.initPrice ?? 0,
                          currency
                        )}`}
                      </td>
                      <td className="text-right break-all">
                        {`${currency} ${formatPrice(
                          calculatePrice(d.item, d.cartItem.uom),
                          currency
                        )}`}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Stack>

          {/* buttons */}
          <Stack direction={"row"} justifyContent={"left"} mt={4}>
            <Button
              type="button"
              onClick={handleClick}
              sx={{
                marginLeft: "auto",
                padding: 1.5,
              }}
            >
              {t("commons.confirmBtn")}
            </Button>
          </Stack>
        </div>
      </Box>
    </Modal>
  );
};

export default ItemUpdatedModal;
