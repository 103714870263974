import { useQuery } from "@tanstack/react-query";
import { BusinessSessionHelper } from "../../../helpers/business-session.helper";
import { BusinessService } from "../../../services/business.service";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { BrowserRouterConstants } from "../../../utils/constants";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

export default function CreditLimitCard() {

  const { t, i18n } = useTranslation();

  const creditData = useQuery({
    queryKey: ["business-credits", BusinessSessionHelper.Business()?.id],
    queryFn: () => BusinessService.getBusinessCredit(),
  });

  return (
    <>
      <div className="w-full lg:max-w-[570px] flex flex-col bg-white rounded-2xl overflow-hidden shadow-lg ">
        <div className="flex flex-col gap-2 p-4">
          <div>
            <p className="text-[#101828] text-base ">
              {t("dashboard.credits")}
            </p>
          </div>
          <div className="flex items-center">
            <div className="flex flex-col flex-1 gap-2">
              <p className="text-2xl font-bold text-[#171A1C]">
                {creditData.data?.credits}
              </p>
              <p className="text-xs font-bold text-[#475467]">
                {t("commons.dateUpdated")}{" "}
                {dayjs(creditData.data?.data?.updatedAt)
                  .locale(i18n.resolvedLanguage ?? "en")
                  .format("ddd D MMM hh:mm A")}
              </p>
            </div>
            <Link
              to={BrowserRouterConstants.Credit}
              className="bg-[#499873] px-4 py-[10px] rounded-lg text-white text-sm font-semibold"
            >
              {t("dashboard.topUpBtn")}
            </Link>
          </div>
        </div>
        <Link
          to={BrowserRouterConstants.CreditTransactionTab}
          className="flex items-center justify-between text-[#499873] py-2 px-4 bg-[#F9FAFB] cursor-pointer"
        >
          <p className="py-[10px] text-sm font-semibold">
            {t("dashboard.creditHistoryBtn")}
          </p>
          <ChevronRightIcon className="w-6 h-6" />
        </Link>
      </div>
    </>
  );
}
