import { Modal } from "@mui/joy";

interface Props {
  isLoading?: boolean;
}

const LoadingModal = ({ isLoading = false }: Props) => {
  return (
    <Modal
      open={isLoading}
      style={{
        backgroundColor: "rgba(50, 56, 62,.6)",
        backdropFilter: "blur(0px)",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="w-[42px] h-[42px] border-white rounded-full border-l-transparent top-[50%] left-[50%] absolute outline-none animate-spin border-[4px]"></div>
    </Modal>
  );
};

export default LoadingModal;
