import { ObjectCannedACL, PutObjectCommand } from "@aws-sdk/client-s3";
import { resizeFile } from "../utils/file";
import { s3Client } from "../lib/spaces";

export class UploadService {
  static async upload(
    file: File,
    name: string,
    folder?: string,
    isPrivate = false,
    compresed = true
  ) {
    var params: any = {
      Bucket: import.meta.env.VITE_SPACES_BUCKET_NAME,
      Key: `${folder ? folder + "/" : ""}${name}`,
    };
    const compresedFile = compresed ? await resizeFile(file) : file;

    if (compresedFile) {
      params = {
        ...params,
        Body: compresedFile,
        ACL: ObjectCannedACL.public_read,
        ContentType: file.type,
      };
      const data = await s3Client.send(
        new PutObjectCommand({
          ...params,
          ACL: isPrivate
            ? ObjectCannedACL.private
            : ObjectCannedACL.public_read,
        })
      );
      return data;
    } else {
      throw new Error("Failed to compress file");
    }
  }
}
