import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Button, FormHelperText, IconButton, Input, Stack } from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import PasswordPolicy from "../../components/PasswordPolicy/PasswordPolicy";
import { SuccessModal } from "../../components/SuccessModal";
import Shared from "../../layouts/Shared";
import useContainer from "./useContainer";

export default function SetPassword() {
  const { t } = useTranslation();

  const {
    handleSubmit,
    onSubmit,
    toggleShowPassword,
    register,
    showPassword,
    showModal,
    isLoading,
    errors,
    password,
  } = useContainer();

  return (
    <>
      <Shared
        // className="px-8 mx-auto lg:px-0"
        sxProps={{ backgroundColor: "transparent" }}
        width={{
          md: "600px",
        }}
      >
        <Helmet
          link={[{ href: "https://linkzasia.com/auth/otp", rel: "canonical" }]}
          meta={[{ content: "OTP", name: "description" }]}
          title="OTP - Linkz"
        />
        <Box paddingY={2} paddingX={4}>
          <Typography
            textAlign={"center"}
            fontSize={"18px"}
            fontWeight={600}
            py={2}
            textColor={"primary.solidActiveBg"}
          >
            {t("landing.forgotPassword.resetPasswordTitle")}
          </Typography>
          <Typography
            my={2}
            py={2}
            textColor={"text.primary"}
            fontSize={16}
            textAlign={"left"}
            fontWeight={600}
          >
            {t("landing.forgotPassword.resetPasswordPrompt")}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Input
                endDecorator={
                  <IconButton onClick={toggleShowPassword}>
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                }
                placeholder={t("landing.signup.password")}
                required
                size="lg"
                type={showPassword ? "text" : "password"}
                {...register("password")}
              />
              {errors.password?.message ? (
                <FormHelperText>{errors.password?.message}</FormHelperText>
              ) : null}
              <PasswordPolicy value={password} />
            </Stack>
            <Button
              fullWidth
              size="lg"
              type="submit"
              disabled={isLoading}
              sx={{ mt: 7 }}
            >
              {t("landing.forgotPassword.resetPasswordBtn")}
            </Button>
          </form>
        </Box>
      </Shared>
      <LoadingModal isLoading={isLoading} />
      <SuccessModal
        title={t("commons.successModalTitle")}
        content="Your password has been changed. Please login again."
        open={showModal}
      />
    </>
  );
}
