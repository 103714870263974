import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import showErrorToast, { ErrorToastConfig } from "../../../components/Error";
import { AuthService } from "../../../services/auth.service";
import { BusinessService } from "../../../services/business.service";
import { IAuth, mixpanelProfilePayload, useAuthStore } from "../../../store/session";
import { IBusinessResponse } from "../../../types/business";
import { TrackerService } from "../../../services/tracker.service";
import { TRACKER_CONSTANTS } from "../../../constants/tracker.events.ts";

interface IProps {
  isOpen: boolean;
}

const useContainer = ({ isOpen }: IProps) => {
  const getSelectableBusinesses = useQuery<IBusinessResponse[]>({
    queryKey: ["selectable-businesses"],
    queryFn: BusinessService.getSelectableBusiness,
    enabled: isOpen,
  });
  const { login, session } = useAuthStore();
  const chooseBusiness = useMutation({
    mutationFn: AuthService.chooseBusinessAfterLogin,
    onSuccess: ({ data }: { data: IAuth }) => {
      localStorage.removeItem("businessChooseToken");
      if (data) {
        TrackerService.identify(data.account.email, mixpanelProfilePayload(data));
        TrackerService.track(
          TRACKER_CONSTANTS.LOGIN_SUCCESS.events.CompanyOptionsClicked,
          {
            ...session,
          }
        );
      }
      login(data);
    },
    onError: (error) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  //#region handler functions
  function itemClickHandler(businessId: string) {
    chooseBusiness.mutate(businessId);
  }
  //#endregion
  return {
    isLoading: getSelectableBusinesses.isPending || chooseBusiness.isPending,
    businesses: getSelectableBusinesses.data,
    itemClickHandler,
  };
};

export default useContainer;
