import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { Button, Modal, Stack } from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { theme } from "../Theme";
import { BusinessSessionHelper } from "../../helpers/business-session.helper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { KycPaymentGatewayStatus } from "../../types/kyc";

interface IProps {
  open: boolean;
  status?: KycPaymentGatewayStatus | string | null
  onClose?: () => void;
}

export function KYCModal({ open, onClose, status }: IProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      md: "550px",
      xs: "auto"
    },
    height: "auto",
    bgcolor: "#fff",
    boxShadow: 24,
    px: 4,
    py: "22px",
    borderRadius: "32px",
    borderColor: "transparent",
    outline: "none",
  };

  return (
    <Modal
      open={open}
      style={{ backgroundColor: "#32383E99", backdropFilter: "unset" }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack alignItems={"center"} justifyContent={"center"}>
          <ExclamationCircleIcon color={theme.colorSchemes.light.palette.primary[500]} width={80} />
          <Typography
            mt={4}
            textAlign={"center"}
            textColor={"text.primary"}
            fontWeight={"lg"}
            fontSize={"lg"}
            id="modal-modal-title"
          >
            {t("kycModal.title")}
          </Typography>
          <Typography
            textAlign={"center"}
            fontWeight={"md"}
            fontSize={"md"}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            { BusinessSessionHelper.IsOwner() ? t("kycModal.ownerDesc") : t("kycModal.desc")}
          </Typography>
          {onClose && !BusinessSessionHelper.IsOwner() && (<Button sx={{ marginTop: "1rem" }} onClick={onClose}>OK</Button>)}
          {BusinessSessionHelper.IsOwner() &&
            <Stack width={"100%"} mt={4} direction={"row"} gap={1}>
              <Button variant="outlined" sx={{ flex: 1 }} onClick={onClose}>{t("kycModal.proccessBtn")}</Button>
              <Button sx={{ flex: 1 }} onClick={() => {
                if (status === KycPaymentGatewayStatus.INREVIEW) navigate("/client/profile")
                else navigate("/client/profile/kyc", { state: { backUrl: window.location.href } })
              }}>{t("kycSnackBar.setupNowTitle")}</Button>
            </Stack>}
        </Stack>
      </Box>
    </Modal>
  );
}
