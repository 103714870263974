import { FileUploadOutlined } from "@mui/icons-material";
import { Button } from "@mui/joy";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

type Props = {
  onDrop: (acceptedFiles: any) => void;
  label?: string;
};

const UploadTemplateButton = ({ onDrop, label }: Props) => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
  });
  return (
    <Button
      {...getRootProps({ className: "dropzone" })}
      variant="outlined"
      startDecorator={<FileUploadOutlined />}
      className="min-w-[11rem] whitespace-nowrap"
    >
      {label ?? t("catalog.reuploadBtn")}
      <input {...getInputProps()} />
    </Button>
  );
};

export default UploadTemplateButton;
