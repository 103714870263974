import { privateAxios } from "../lib/api";
import { ICredit, TransactionGroup } from "../types/credits";
import { ApiConstants } from "../utils/constants";

export class CreditsService {
  static async getAvailableCredits(): Promise<ICredit[]> {
    const response = await privateAxios.get(
      ApiConstants.availableCreditsPackageURL
    );
    return response.data;
  }

  static async getDiscountedCredits(): Promise<ICredit[]> {
    const response = await privateAxios.get(
      ApiConstants.discountedCreditsPackageURL
    );
    return response.data;
  }

  static async getCreditsTransactions(): Promise<TransactionGroup[]> {
    const response = await privateAxios.get(
      ApiConstants.creditsTransactionsURL
    );
    return response.data;
  }

  static async buyCredit(creditId: string) {
    const response = await privateAxios.post(`credits/${creditId}/topup`);
    return response.data;
  }
}
