import { Divider } from "@mui/joy";
import React from "react";
import BusinessForm from "./BusinessForm";

export function CreateBusiness() {
  return (
    <React.Fragment>
      <Divider />
      {/* FORM */}
      <br />
      <BusinessForm />
    </React.Fragment>
  );
}
