import { Chip, Grid, Stack, Typography } from "@mui/joy";
import { OrderTypeTag } from "../utils";
import { orderStatuses } from "../../types/order";

export default function MiniOrderItemRow({
  orderType,
  orderNo,
  orderDate,
  orderStatus,
  onClick,
}: any) {
  return (
    <Grid
      container
      columns={{ xs: 12 }}
      mt={2}
      flexGrow={1}
      alignItems={"center"}
      rowGap={2}
      onClick={onClick}
      border={"1px solid var(--joy-palette-neutral-outlinedBorder)"}
      borderRadius={"8px"}
      padding={"1rem"}
      sx={{
        cursor: "pointer",
      }}
    >
      <Grid xs={12} lg={6}>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <OrderTypeTag type={orderType} size={29} />
          <Typography fontWeight={600} color={"primary"}>
            {orderNo || "240924-0000123"}
          </Typography>
        </Stack>
      </Grid>

      <Grid xs={12} lg={6}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={2}
          justifyContent={"flex-end"}
        >
          <Typography fontSize={14}>{orderDate || "23 Sep 2024"}</Typography>
          <Chip
            variant="soft"
            size="sm"
            sx={{
              textWrap: "wrap",
              padding: 1,
              textTransform: "capitalize",
            }}
          >
            {orderStatuses.find((status) =>
              status.backendStatuses.includes(orderStatus)
            )?.label || orderStatus}
          </Chip>
        </Stack>
      </Grid>
    </Grid>
  );
}
