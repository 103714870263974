import { privateAxios } from "../lib/api";
import { ApiConstants } from "../utils/constants";

export class CreditTransactionService {
  static async isTransactionPending(transactionId: string) {
    const response = await privateAxios.post(
      `${ApiConstants.creditsTransactionsURL}/${transactionId}/isPending`
    );
    return response.data;
  }
}
