import { Box, Button, Modal, Stack, Typography, Grid } from "@mui/joy";
import { useEffect, useState } from "react";
import { SuccessModal } from "../SuccessModal";
import LoadingModal from "../LoadingModal/LoadingModal";
import { useTranslation } from "react-i18next";
import TagsCollection from "../Catalogue/Item/TagsCollection";
import { ItemService } from "../../services/item.service";
import UpdateQuantityInput from "./UpdateQuantityInput";
import { getActiveBusiness } from "../../store/session";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

interface Props {
  show?: boolean;
  onClose: () => void;
  item: any[];
}

type UpdateQuantityPayload = {
  items: { id: string; quantity: number | null }[];
};

const UpdateQuantityModal = ({
  show = false,
  onClose,
  item,
}: Props) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fff",
    boxShadow: 24,
    width: "94%",
    maxWidth: item.length > 0 ? "39rem" : "30rem",
    // px: 4,
    // py: 4,
    borderRadius: "32px",
    borderColor: "transparent",
    outline: "none",
  };

  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const { orderId } = useParams();

  const handleClose = () => {
    setShowModal(false);
    onClose();
  };

  const methods = useForm<UpdateQuantityPayload>({
    defaultValues: {
      items: item.map((i) => {
        return { id: i.id, quantity: i.quantity };
      }),
    },
  });
  const { control, handleSubmit, register, reset } = methods;
  const { fields } = useFieldArray({
    control,
    name: "items",
  });

  const onSubmit = (data: UpdateQuantityPayload) => {
    const businessId = getActiveBusiness()?.id;
    // console.log("BANANA DATA", data);
    if (!item || !businessId) {
      return;
    }

    setIsLoading(true);
    ItemService.patchInventoryItemQuantity({
      businessId,
      updateQuantityDto: data.items,
    })
      .then((res) => {
        if (res) {
          if (orderId) {
            const unsavedOrderData = localStorage.getItem(
              `unsavedOrderData_${orderId}`
            );

            if (unsavedOrderData) {
              const orderData = JSON.parse(unsavedOrderData);
              orderData.orderItems = orderData.orderItems.map((i: any) => {
                const inventoryItemData = i.inventoryItem
                  ? i.inventoryItem
                  : i.selectedItem;
                const match = data.items.find(
                  (j) => j.id === inventoryItemData.id
                );

                if (match) {
                  const temp = i.inventoryItem
                    ? {
                        inventoryItem: {
                          ...inventoryItemData,
                          quantity: match.quantity,
                        },
                      }
                    : {
                        selectedItem: {
                          ...inventoryItemData,
                          quantity: match.quantity,
                        },
                      };
                  return {
                    ...i,
                    ...temp,
                  };
                }

                return item;
              });

              localStorage.setItem(
                `unsavedOrderData_${orderId}`,
                JSON.stringify(orderData)
              );
            }
          }

          setShowAlert(true);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));

    setTimeout(() => {
      setShowAlert(false);
      window.location.reload();
    }, 2000);
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  useEffect(() => {
    reset({
      items: item.map((i) => {
        return { id: i.id, quantity: i.quantity };
      }),
    });
  }, [item]);

  return (
    <Modal
      open={showModal}
      style={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} justifyContent={"center"}>
        <div className="w-full py-8 flex justify-center border-b-2">
          <Typography
            textAlign={"center"}
            fontWeight={700}
            fontSize={20}
            id="modal-modal-title"
            sx={{
              color: "#101828",
            }}
          >
            {t("catalog.inventory.updateQty.title")}
          </Typography>
        </div>

        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(event) => {
              event.key === "Enter" && event.preventDefault();
            }}
          >
            <div
              className={`${
                item.length > 1 && "h-[424px]"
              } overflow-auto flex flex-col`}
            >
              {show &&
                fields.map((field, index) => (
                  <div
                    key={field.id}
                    className={`w-full flex ${
                      item.length > 1 ? "flex-row" : "flex-col"
                    } px-6`}
                  >
                    <div
                      className={`py-4 flex align-middle gap-4 w-full h-full rounded-lg`}
                    >
                      <img
                        src={`${item[index]?.thumbnail ?? "/union.png"}`}
                        alt="item_image"
                        // width={100}
                        // height={100}
                        className="lg:border border-gray-300 rounded-t-lg lg:rounded-lg w-[100px] h-[100px] object-contain flex-shrink-0"
                      />

                      <Stack
                        gap={"8px"}
                        display={"flex"}
                        flexDirection={"column"}
                        sx={{
                          width: { xs: "100%", lg: "40%" },
                          padding: { xs: 2, lg: 0 },
                          height: "100%",
                        }}
                      >
                        <Typography
                          fontSize={{ xs: 14 }}
                          fontWeight={500}
                          sx={{
                            color: "black",
                          }}
                        >
                          {item[index]?.sku}
                        </Typography>
                        <Typography
                          fontSize={{ xs: 14, lg: 20 }}
                          sx={{
                            color: "black",
                            fontWeight: 700,
                          }}
                        >
                          {item[index]?.productName}
                        </Typography>

                        {item[index]?.tags && (
                          <TagsCollection tags={item[index]?.tags} />
                        )}
                      </Stack>
                    </div>

                    <Grid
                      container
                      py={"1.5rem"}
                      spacing={2}
                      borderBottom={item.length > 1 ? 1 : 0}
                      borderColor={"#EAECF0"}
                    >
                      <UpdateQuantityInput
                        item={item[index]}
                        index={index}
                        field={field}
                        register={register}
                        threshold={item[index].threshold}
                      />
                    </Grid>
                  </div>
                ))}
            </div>

            {/* buttons */}
            <Stack
              direction={"row"}
              justifyContent={"right"}
              py={"1rem"}
              px={"1.5rem"}
              sx={{
                borderTopWidth: "2px",
              }}
            >
              <Button
                type="button"
                variant="plain-gray"
                className="ml-auto"
                onClick={handleClose}
              >
                {t("commons.cancelBtn")}
              </Button>
              <Button
                disabled={item ? false : true}
                type="submit"
                // onClick={onSubmit}
                sx={{
                  marginLeft: "1rem",
                  padding: 1.5,
                }}
              >
                {t("commons.updateBtn")}
              </Button>
            </Stack>
          </form>
        </FormProvider>

        {/* modals */}
        <SuccessModal
          title={t("commons.successModalTitle")}
          content={t("catalog.inventory.editForm.inventoryItemUpdated")}
          open={showAlert}
        />
        <LoadingModal isLoading={isLoading} />
      </Box>
    </Modal>
  );
};

export default UpdateQuantityModal;
