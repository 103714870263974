import { Divider } from "@mui/joy";
import React from "react";
import BusinessForm from "./BusinessForm";

export default function UpdateBusiness() {
  return (
    <React.Fragment>
      <Divider />
      {/* FORM */}
      <br />
      <BusinessForm isEditMode={true} />
    </React.Fragment>
  );
}
