import { Dispatch, SetStateAction, useState } from "react";
import { ItemService } from "../../../services/item.service";
import {
  calculatePrice,
  getCartItems,
  getConversionRate,
  updateCartQty,
  updateCartUOM,
} from "../../../utils/cart";
import { OrderService } from "../../../services/order.service";
import { getActiveBusiness, useAuthStore } from "../../../store/session";
import { TrackerService } from "../../../services/tracker.service";
import { CatalogType } from "../../../types/catalog";
import { CatalogInventoryItemType } from "../../../types/item";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { TRACKER_CONSTANTS } from "../../../constants/tracker.events";
import { Box, Button, Snackbar, Typography } from "@mui/joy";
import { theme } from "../../../components/Theme";
import { AddOutlined } from "@mui/icons-material";
import { CartItemDataType } from "../../../types/cart";

interface Props {
  catalog: CatalogType;
  toCart: CartItemDataType[];
  inventoryItems: CatalogInventoryItemType[];
  setCartUpdated?: Dispatch<SetStateAction<boolean>>;
  unsavedOrderData?: string | null;
  orderDataCallback?: () => void;
  cartDataCallback?: () => void;
  isDisabled?: boolean;
}

const AddToCartButton = ({
  catalog,
  toCart,
  inventoryItems,
  setCartUpdated,
  unsavedOrderData,
  orderDataCallback,
  cartDataCallback,
  isDisabled = false,
}: Props) => {
  const { t } = useTranslation();
  const { session } = useAuthStore.getState();
  const [searchParams, _setSearchParams] = useSearchParams();
  const orderId = searchParams.get("order");
  // TODO: replace with /public
  const isGuestMode = window.location.pathname.startsWith("/catalog");

  const [showAddToCartToast, setShowAddToCartToast] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function handleUpdateCart() {
    if (catalog.catalogType === "PRIVATE" && isGuestMode) {
      window.location.href = `/client/suppliers/${catalog.id}/items`;
      return;
    }

    const itemIds = toCart.map((c) => c.cid);
    const verifiedItem = await verifyItem(itemIds);
    if (!verifiedItem) {
      setErrorMessage(t("catalog.supplier.items.verifyItemError"));
      setShowError(true);

      setTimeout(() => {
        setShowError;
        window.location.reload();
      }, 1500);

      return;
    }

    const accountId =
      searchParams.get("cart") === "wildcart"
        ? "wildcart"
        : session?.account.id || "wildcart";
    const cartItems = getCartItems(
      catalog.id,
      accountId,
      session?.account.id ? "local" : "session"
    );

    toCart.forEach((c) => {
      const inventoryItem = inventoryItems.find((i) => i.id === c.cid);
      if (!inventoryItem) {
        return;
      }

      const isItemExist = cartItems?.items.find(
        (c) => c.cid === inventoryItem.id
      );

      updateCartQty({
        catalogId: catalog.id,
        accountId: session?.account.id || "wildcart",
        override: isItemExist ? false : true,
        quantityChange: c.quantity,
        inventoryItem: inventoryItem,
        direct: session?.account.id ? false : true,
        activeUOM: c.uom,
        storageType: session?.account.id ? "local" : "session",
      });

      updateCartUOM({
        catalogId: catalog.id,
        accountId: session?.account.id || "wildcart",
        inventoryItem: inventoryItem,
        uom: c.uom,
        storageType: session?.account.id ? "local" : "session",
      });

      TrackerService.track(
        TRACKER_CONSTANTS.CATALOG_SUPPLIER.events.AddItemToCart,
        {
          "Catalog ID": catalog.id,
          "Catalog Name": catalog.catalogName,
          "Catalog Owner Company Name": catalog.business.companyName,
          "Product ID": inventoryItem.inventoryItem.id,
          "Product Name": inventoryItem.inventoryItem.productName,
          Quantity: c.quantity,
        }
      );
    });

    setShowAddToCartToast(true);
    if (cartDataCallback) {
      cartDataCallback();
    }
    setTimeout(() => {
      setShowAddToCartToast(false);
      if (setCartUpdated) {
        setCartUpdated(true);
      }
    }, 1500);
  }

  function handleUpdateOrder() {
    const newProduct = toCart.map((c) => {
      const inventoryItem = inventoryItems.find((i) => i.id === c.cid);
      if (!inventoryItem) {
        return;
      }

      return {
        inventoryItem: inventoryItem,
        cartItem: c,
        orderItem: {
          productId: inventoryItem.inventoryItem.id,
          inventoryItem: inventoryItem.inventoryItem,
          sku: inventoryItem.inventoryItem.sku,
          productName: inventoryItem.inventoryItem.productName,
          quantity: c.quantity,
          uom: inventoryItem.inventoryItem[c.uom],
          uomItem: {
            conversion: getConversionRate(c.uom, inventoryItem.inventoryItem),
            price: calculatePrice(inventoryItem, c.uom),
            uom: inventoryItem.inventoryItem[c.uom],
          },
          uomList: [
            {
              conversion: 1,
              price: inventoryItem.price,
              uom: inventoryItem.inventoryItem.uom1,
            },
            inventoryItem.inventoryItem.uom2 && {
              conversion: inventoryItem.inventoryItem.conversion2,
              price: inventoryItem.price2 ?? inventoryItem.inventoryItem.price2,
              uom: inventoryItem.inventoryItem.uom2,
            },
            inventoryItem.inventoryItem.uom3 && {
              conversion: inventoryItem.inventoryItem.conversion3,
              price: inventoryItem.price3 ?? inventoryItem.inventoryItem.price3,
              uom: inventoryItem.inventoryItem.uom3,
            },
          ],
          conversion: getConversionRate(c.uom, inventoryItem.inventoryItem),
          price: calculatePrice(inventoryItem, c.uom),
          discount: 0,
          totalPrice: calculatePrice(inventoryItem, c.uom) * c.quantity,
          businessId: inventoryItem.inventoryItem.business,
          inventoryType: inventoryItem.inventoryItem.inventoryType,
          currency: inventoryItem.currency,
          productDescription: inventoryItem.inventoryItem.productDescription,
          createdById: session?.account.id || "",
        },
      };
    });

    const businessId = getActiveBusiness()?.id;
    if (orderId && businessId) {
      OrderService.getOrder(orderId)
        .then((res) => {
          // console.log("ORDER", res);
          // console.log("LOCAL STORAGE", unsavedOrderData);
          const business: any = catalog.business;
          if (!["DRAFT", "AWAITING_RESPONSE"].includes(res.status)) {
            window.alert(t("catalog.items.orderInvalid"));
            window.location.href = "/client/suppliers";
          }
          if (!unsavedOrderData) {
            const newUnsavedOrder: any = {
              additionalDiscount: 0,
              additionalDiscountType: "PERCENTAGE",
              additionalTaxItem: { taxLabel: "", taxAmount: 0, taxType: null },
              // additionalTax: 0,
              // additionalTaxLabel: "",
              // additionalTaxType: null,
              buyerIds: {
                businessId: res.buyer ? res.buyer.id : null,
                accountId: res.buyerUser ? res.buyerUser.id : null,
              },
              createdByBusiness: res.createdByBusiness,
              createdById: res.createdBy,
              currency: null,
              defaultTaxItem: { taxLabel: "", taxAmount: 0, taxType: null },
              deliveryDate: "",
              deliveryFee: 0,
              driverName: null,
              grandTotalAmount: 0,
              invoiceNumber: null,
              newBusiness: null,
              orderItems: [],
              downPayment: 0,
              paymentFlowType: null,
              paymentTerm: null,
              remarks: null,
              selectedExternalBusiness:
                session?.activeBusiness.id === catalog.business.id
                  ? null
                  : catalog.business,
              sellerIds: {
                businessId: res.seller ? res.seller.id : catalog.business.id,
                accountId: res.seller
                  ? res.sellerUser.id
                  : business.role?.[0]?.account,
              },
              shippingMethod: null,
              trackingNumber: null,
              vehicleNumber: null,
            };
            newUnsavedOrder.orderItems.push(
              ...newProduct.map((np) => np?.orderItem)
            );

            console.log("BANANA ORDER ITEM", newUnsavedOrder);
            localStorage.setItem(
              `unsavedOrderData_${orderId}`,
              JSON.stringify(newUnsavedOrder)
            );
          } else {
            const orderData = JSON.parse(unsavedOrderData);
            newProduct.forEach((np) => {
              if (!np) {
                return;
              }
              const currentOrderItems = orderData.orderItems.find(
                (i: any) => i.productId === np.orderItem.productId
              );

              // if there is orderItem with same id, add qty instead
              if (currentOrderItems) {
                const newQuantity =
                  currentOrderItems.quantity + np.cartItem.quantity;
                currentOrderItems.quantity = newQuantity;
                (currentOrderItems.totalPrice =
                  calculatePrice(np.inventoryItem, np.cartItem.uom) *
                  newQuantity),
                  (currentOrderItems.uom = np.orderItem.uom);
                currentOrderItems.uom = np.orderItem.uom;
                currentOrderItems.conversion = np.orderItem.conversion;
                currentOrderItems.price = np.orderItem.price;
                currentOrderItems.uomItem = {
                  ...np.orderItem.uomItem,
                };
              } else {
                orderData.orderItems.push(
                  ...newProduct.map((np) => np?.orderItem)
                );
              }
              orderData.selectedExternalBusiness =
                orderData.selectedExternalBusiness
                  ? orderData.selectedExternalBusiness
                  : session?.activeBusiness.id === catalog.business.id
                  ? null
                  : catalog.business;
              orderData.sellerIds = orderData.sellerIds
                ? orderData.sellerIds
                : {
                    businessId: res.seller ? res.seller.id : businessId,
                    accountId: res.seller
                      ? res.sellerUser.id
                      : business.role?.[0]?.account,
                  };
            });

            console.log("BANANA ORDER ITEM", orderData);

            localStorage.setItem(
              `unsavedOrderData_${orderId}`,
              JSON.stringify(orderData)
            );
          }
          if (orderDataCallback) {
            orderDataCallback();
          }
          setShowAddToCartToast(true);
          setTimeout(() => {
            setShowAddToCartToast(false);
          }, 2000);
          // const newOrderItems = res.orderItems.map((i: any) => ({
          //   productId: i.inventoryItem.id,
          //   sku: i.inventoryItem.sku,
          //   productName: i.inventoryItem.productName,
          //   quantity: i.quantity,
          //   uom: i.uom,
          //   conversion: i.conversion,
          //   price: i.price,
          //   discount: 0,
          //   totalPrice: i.price * quantity,
          //   businessId: i.inventoryItem.business,
          //   inventoryType: i.inventoryItem.inventoryType,
          //   currency: i.inventoryItem.currency,
          //   productDescription: i.inventoryItem.productDescription,
          //   createdById: session?.account.id || "",
          // }));
          // newOrderItems.push(newProduct);
          // const orderType = getOrderType(res, businessId);
          // const sellerBusiness: IExternalBusiness = catalog.business;
          // const roles = sellerBusiness.role as any[];
          // const sellerAccount: any =
          //   sellerBusiness.role.find((r: any) => r.isOwner) ?? roles[0];
          // OrderService.update(orderId, {
          //   currency: res.currency ?? newProduct.currency,
          //   orderItems: newOrderItems,
          //   grandTotalAmount: res.grandTotalAmount + newProduct.totalPrice,
          //   status: res.status,
          //   createdById: session?.account.id || "",
          //   // sellerId: res.seller ? res.seller.id : catalog.business.id,
          //   // sellerUserId: res.sellerUser
          //   //   ? res.sellerUser.id
          //   //   : sellerAccount.account,
          //   sellerIds: {
          //     businessId: res.seller ? res.seller.id : catalog.business.id,
          //     accountId: res.sellerUser
          //       ? res.sellerUser.id
          //       : sellerAccount.account,
          //   },
          //   // buyerId: res.buyer
          //   //   ? res.buyer.id
          //   //   : orderType === "purchase" && businessId,
          //   // buyerUserId: res.buyerUser
          //   //   ? res.buyerUser.id
          //   //   : session?.account.id || "",
          //   buyerIds: {
          //     businessId: res.buyer
          //       ? res.buyer.id
          //       : orderType === "purchase" && businessId,
          //     accountId: res.buyerUser
          //       ? res.buyerUser.id
          //       : session?.account.id || "",
          //   },
          //   additionalDiscountType: "PERCENTAGE",
          // })
          //   .then((_res) => {
          //     setShowAddToCartToast(true);
          //     setTimeout(() => {
          //       setShowAddToCartToast(false);
          //     }, 2000);
          //   })
          //   .catch((error) => {
          //     throw new Error(error);
          //   });
        })
        .catch((error: any) => {
          console.error(error);
          window.alert("Error adding item to order. Please try again.");
        });
    }
  }

  async function verifyItem(itemIds: string[]) {
    return await ItemService.verifyItem({
      items: itemIds,
      catalog: catalog.id,
    });
  }

  return (
    <>
      <div className="w-full lg:w-fit lg:my-auto justify-center">
        <Button
          disabled={isDisabled}
          onClick={() => {
            if (orderId) {
              handleUpdateOrder();
            } else {
              handleUpdateCart();
            }
          }}
          className="w-full lg:w-fit"
          startDecorator={<AddOutlined />}
        >
          {t(`catalog.${orderId ? "addToOrderBtn" : "addToCartBtn"}`)}
        </Button>
      </div>

      <Snackbar
        variant="solid"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showAddToCartToast}
        key={"topcenter" + "center"}
        sx={{
          width: "40%",
          maxWidth: 800,
          backgroundColor: theme.colorSchemes.light.palette.others.success,
        }}
      >
        <Box width={"100%"}>
          <Typography
            textAlign={"center"}
            textColor={"common.black"}
            fontSize={14}
          >
            {t(`catalog.${orderId ? "itemsAddedToOrder" : "itemsAddedToCart"}`)}
          </Typography>
        </Box>
      </Snackbar>
      <Snackbar
        variant="solid"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showError}
        key={"asjfkangdlknakjrsh"}
        sx={{
          width: "40%",
          maxWidth: 800,
        }}
      >
        <Box width={"100%"}>
          <Typography
            textAlign={"center"}
            textColor={"common.white"}
            fontSize={14}
          >
            {errorMessage}
          </Typography>
        </Box>
      </Snackbar>
    </>
  );
};

export default AddToCartButton;
