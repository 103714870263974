import { zodResolver } from "@hookform/resolvers/zod";
import { Typography } from "@mui/joy";
import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import { InvitationType } from "../../services/account.service";
import { AuthService } from "../../services/auth.service";
import {
  countryMap,
  getCountryCodeFromCountryName,
} from "../../utils/constants";

const ssoSchema = z.object({
  agreed: z.boolean().default(false),
  companyName: z.string(),
  country: z
    .object({
      code: z.string(),
      dial: z.string(),
      label: z.string(),
    })
    .optional(),
  fullName: z.string().min(1).max(100),
  companyCountryCode: z.string(),
  referralCode: z.string().optional(),
  referrerUserId: z.string().optional(),
});
const invitationSchema = ssoSchema.merge(
  z.object({
    companyName: ssoSchema.shape.companyName.optional(),
    companyCountryCode: ssoSchema.shape.companyCountryCode.optional(),
    referralCode: ssoSchema.shape.referralCode.optional(),
    referrerUserId: ssoSchema.shape.referrerUserId.optional(),
  })
);
const useContainer = () => {
  //#region ----- local states
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { pathname, state } = useLocation();
  const accessToken = state?.accessToken;
  const isSSORoute = pathname.includes("sso");
  const [urlSearchParams, setSearchParams] = useSearchParams();
  const token = urlSearchParams.get("token");
  const referralCode = urlSearchParams.get("referral_code");
  const referrerUserId = urlSearchParams.get("referrer_user_id");
  const schema = token ? invitationSchema : ssoSchema;
  const [invitationType, setInvitationType] = useState<InvitationType | null>(
    null
  );
  const [hasBusinessProfile, setHasBusinessProfile] = useState(false);
  const navigate = useNavigate();
  const {
    control,
    setError,
    formState: { errors, isValid },
    watch,
    handleSubmit,
    register,
    setValue,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });
  const { country, fullName, companyName, companyCountryCode, agreed } =
    watch();
  const callbackUrl = urlSearchParams.get("callbackURL");
  const invitationTokenMutation = useMutation({
    mutationFn: AuthService.invitationTokenCheck,
    onSuccess: ({ data }) => {
      if (data.redirectURL) {
        navigate(data.redirectURL);
      }
      if (data.invitationType) {
        setInvitationType(data.invitationType);
        setHasBusinessProfile(data.hasBusinessProfile);
      }
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
      navigate("/signup");
    },
  });
  const referralCodeMutation = useMutation({
    mutationFn: AuthService.referralCodeCheck,
    onSuccess: ({ data }) => {
      return data;
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
      setError("referralCode", error.message);
      // navigate("/signup");
    },
  });
  const ssoMutation = useMutation({
    mutationFn: !token
      ? AuthService.signupSSO
      : invitationTokenMutation.data?.data.hasBusinessProfile
      ? AuthService.signupSSOWithRoleInvitation
      : invitationTokenMutation.data?.data.hasAccount
      ? AuthService.signupSSOWithoutRoleInvitation
      : invitationType === InvitationType.BUSINESS_CONNECTION_INVITATION
      ? AuthService.signupSSOBusinessConnectInvitation
      : AuthService.signupSSO,
    onSuccess: ({ data: { activation, created, token } }) => {
      if (!activation) {
        if (callbackUrl) {
          navigate(`/signin?callbackURL=${encodeURIComponent(callbackUrl)}`);
        } else {
          navigate("/signin");
        }
        return;
      }
      localStorage.setItem("phoneVerificationToken", token);
      localStorage.setItem("countryCode", country?.code.toString() || "");
      if (created === true) {
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          if (callbackUrl) {
            navigate(`/verify?callbackURL=${encodeURIComponent(callbackUrl)}`);
          } else {
            navigate("/verify");
          }
        }, 4000);
      } else {
        if (callbackUrl) {
          navigate(`/verify?callbackURL=${encodeURIComponent(callbackUrl)}`);
        } else {
          navigate("/verify");
        }
      }
    },
    onError: (error: any) => {
      const errorMessage =
        error.response.data.message || "Something went wrong.";
      toast(
        (t) => (
          <div
            style={{
              width: "100%",
              opacity: t.visible ? 1 : 0,
              transition: "opacity 100ms ease-in-out",
            }}
          >
            <Typography
              textColor={"danger.softColor"}
              fontSize={"14px"}
              fontWeight={500}
              textAlign={"center"}
              py={"12px"}
            >
              {errorMessage}
            </Typography>
          </div>
        ),
        {
          style: {
            padding: 0,
            margin: 0,
          },
          position: "bottom-center",
          duration: 4000,
        }
      );
    },
  });

  //#region life cycle method
  useEffect(() => {
    if (isSSORoute && !accessToken) {
      navigate("/signup");
    }
  }, [isSSORoute, accessToken]);

  useEffect(() => {
    if (companyCountryCode) {
      const countryCode: any = countryMap[companyCountryCode];
      if (countryCode) {
        setValue("country", countryCode);
      }
    }
  }, [companyCountryCode]);

  useEffect(() => {
    if (country?.code) {
      const countryName: any = getCountryCodeFromCountryName[country.code];
      if (countryName) {
        setValue("companyCountryCode", countryName);
      }
    }
  }, [country?.code]);

  useEffect(() => {
    if (referralCode) {
      setValue("referralCode", referralCode);
    }

    if (referrerUserId) {
      setValue("referrerUserId", referrerUserId);
    }
  }, [referralCode, referrerUserId]);
  //#endregion

  //#region ----- handler functions
  const onSubmit: SubmitHandler<z.infer<typeof schema>> = async (data) => {
    let payload: any = {};
    if (invitationTokenMutation.data?.data.hasBusinessProfile) {
      payload = {
        fullName: data.fullName,
        companyName: data.companyName,
        countryCode: data.country!.code,
        accessToken,
        companyCountryCode: data.companyCountryCode,
        invitationToken: token,
        // referralCode: referralCode,
        // referrerUserId: referrerUserId,
      };
    } else {
      payload = {
        fullName: data.fullName,
        companyName: data.companyName,
        countryCode: data.country!.code,
        accessToken,
        companyCountryCode: data.companyCountryCode,
        referralCode: referralCode,
        referrerUserId: referrerUserId,
      };
      if (token) {
        payload["invitationToken"] = token;
      }
      if (invitationType === InvitationType.BUSINESS_CONNECTION_INVITATION) {
        payload.token = token;
      }
    }
    const currentParams = new URLSearchParams(window.location.search);

    const updatedParams = new URLSearchParams(currentParams);

    if (callbackUrl) {
      updatedParams.set("callbackURL", encodeURIComponent(callbackUrl));
    }

    if (token) {
      updatedParams.set("token", encodeURIComponent(token));
    }

    if (referralCode && !invitationTokenMutation.data?.data.hasBusinessProfile) {
      // console.log("BANANA EMAIL", localStorage.getItem("email"));
      const isReferralCodeValid = await referralCodeMutation.mutateAsync({
        email: localStorage.getItem("email") ?? "",
        // email: "pepe@mailinator.com",
        referralCode: referralCode,
        referrerUserId: referrerUserId ?? null,
      });

      if (!isReferralCodeValid) {
        return;
      }
    }

    const basePath = "/signup/phone-input";

    const url = `${basePath}?${updatedParams.toString()}`;
    payload.origin = "sso";
    navigate(url, { state: payload });
    // ssoMutation.mutate(payload);
  };

  const onReferralCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newParams = new URLSearchParams(urlSearchParams);
    if (event.target.value) {
      newParams.set("referral_code", event.target.value);
    } else {
      newParams.delete("referral_code");
    }

    setSearchParams(newParams);
  };

  //#region life cycle method
  useEffect(() => {
    if (token) {
      invitationTokenMutation.mutate({ token });
    }
  }, [token]);

  //#endregion

  //#endregion
  return {
    setError,
    handleSubmit,
    onSubmit,
    register,
    errors,
    control,
    setValue,
    isLoading: ssoMutation.isPending,
    fullName,
    showModal,
    companyName,
    isSSORoute,
    companyCountryCode,
    hasBusinessProfile,
    setShowTermsModal,
    showPrivacyPolicy,
    setShowPrivacyPolicy,
    showTermsModal,
    agreed,
    isValid,
    phoneNo: `${country?.code ?? ""}${country?.dial ?? ""}`,
    referralCode,
    onReferralCodeChange,
    alrdyHasBusiness: invitationTokenMutation.isPending
      ? {}
      : invitationTokenMutation.data?.data.hasBusinessProfile,
  };
};

export default useContainer;
