import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { getMessaging, getToken } from "firebase/messaging";
import { messagingApp } from "../lib/firebase";
export interface INotificationState {
  token: string;
  swActivated: boolean;
}

export const useNotification = create<INotificationState>()(
  devtools(
    persist(
      (_) => ({
        token: "",
        swActivated: false,
      }),
      { name: "userNotificationToken" }
    )
  )
);

export const initFCM = async () => {
  if (
    !useNotification.getState().token 
  ) {
    try {
      console.log("MESSAGING", getMessaging());
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messagingApp(), {
          vapidKey:
            import.meta.env.VITE_APP_VAVID_KEY,
        });
        console.log("FCM", token);
        useNotification.setState({ token });
      }
    } catch (error) {
      console.error(error);
    }
  }
};
