import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import { AuthService } from "../../services/auth.service";
import { BrowserRouterConstants } from "../../utils/constants";
import i18next from "../../i18n/config";
import { t } from "i18next";

const schema = z.object({
  email: z
    .string()
    .email({
      message: i18next.t("commons.error.form.required", {
        label: t("landing.signup.email"),
      }),
    }),
});

const useContainer = () => {
  //#region ----- local variables
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });
  const { email } = watch();

  //#region handler functions
  const onSubmit: SubmitHandler<z.infer<typeof schema>> = (data) => {
    forgotPasswordMutation.mutate(data);
  };
  //#endregion

  //#region ----- life cycle methods
  const forgotPasswordMutation = useMutation({
    mutationFn: AuthService.forgotPassword,
    onSuccess: () => {
      navigate(BrowserRouterConstants.PasswordResetVerify, {
        state: { email },
      });
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });
  //#endregion
  return {
    onSubmit,
    handleSubmit,
    register,
    // variables
    email,
    errors,
    isLoading: forgotPasswordMutation.isPending,
  };
};

export default useContainer;
