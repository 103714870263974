
function LinkzSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="85"
      height="29"
      fill="none"
      viewBox="0 0 85 29"
    >
      <g clipPath="url(#clip0_23_11358)">
        <path
          fill="#000"
          d="M76.667 14.713c-.372-.03-.638-.073-.906-.073-1.785-.006-3.57.011-5.355-.014-.988-.014-1.802-.443-2.22-1.356-.403-.878-.42-1.85.266-2.58.41-.438 1.085-.876 1.647-.888 4.086-.073 8.172-.042 12.259-.036.929 0 1.542.556 1.932 1.316.485.949.31 1.89-.348 2.694-1.765 2.163-3.558 4.298-5.337 6.447-.777.938-1.556 1.876-2.319 2.827-.136.17-.192.404-.282.607.234.068.466.195.7.198 1.762.017 3.525 0 5.284.014 1.265.012 2.022.548 2.415 1.664.536 1.516-.537 3.188-2.16 3.205-4.017.045-8.035.031-12.051.003-.912-.006-1.641-.514-2.011-1.333-.407-.901-.367-1.85.29-2.655 1.752-2.143 3.522-4.267 5.287-6.4.853-1.03 1.706-2.058 2.553-3.092.113-.135.195-.299.356-.553v.005zM45.659 22.822c0 1.149.014 2.298 0 3.448-.02 1.448-1.04 2.49-2.406 2.488-1.412 0-2.443-1.056-2.452-2.522-.008-1.878.017-3.753-.008-5.631-.034-2.579-1.556-4.663-3.875-5.366-2.398-.729-5.165.505-6.13 2.84-.34.823-.515 1.758-.557 2.65-.085 1.802-.012 3.612-.034 5.42-.017 1.318-.57 2.092-1.686 2.473-1.466.5-3.112-.452-3.135-1.988-.04-2.736-.164-5.515.226-8.204.531-3.66 2.776-6.227 6.247-7.614 4.643-1.855 9.622.017 12.183 4.025 1.045 1.632 1.607 3.425 1.624 5.374.008.867 0 1.737 0 2.604l.003.003zM.44 19.255c0-2.3-.015-4.598.005-6.9.014-1.607 1.04-2.609 2.595-2.592 1.432.017 2.4 1.05 2.41 2.632.013 3.24 0 6.476.008 9.715.005 1.886-.198 1.618 1.666 1.632 1.855.011 3.71-.011 5.569.009 1.387.014 2.344.74 2.663 1.962.384 1.466-.689 3.02-2.208 3.036-3.454.043-6.908.037-10.36 0C1.49 28.735.46 27.583.452 26.225c-.017-2.322-.006-4.646-.003-6.97H.439zM17.647 19.204c0-2.324-.003-4.646 0-6.97.002-1.486.934-2.446 2.38-2.471 1.55-.026 2.559.892 2.564 2.431.02 4.694.02 9.385 0 14.079-.005 1.477-1.075 2.51-2.488 2.488-1.434-.023-2.454-1.06-2.456-2.517-.006-2.346 0-4.693 0-7.04zM20.064 7.255c-1.748-.008-3.044-1.336-3.022-3.098.02-1.677 1.4-3.041 3.067-3.027 1.768.014 3.084 1.324 3.075 3.064-.008 1.836-1.265 3.067-3.12 3.058v.003z"
        ></path>
        <path
          fill="#499873"
          d="M52.965 17.878c.799-.652 1.432-1.152 2.044-1.7 2.166-1.94 4.31-3.897 6.47-5.84.735-.66 2.584-.582 3.254.121.723.763.677 2.347-.147 3.132-1.697 1.616-3.431 3.194-5.134 4.804-.856.808-.862.904.036 1.714a212.95 212.95 0 004.799 4.225c.737.627.985 1.367.912 2.299-.08 1.008-.68 1.61-1.703 1.7-1.05.09-1.844-.226-2.644-.971-2.445-2.288-4.987-4.468-7.495-6.688-.065-.056-.395-.319-.395-.319"
        ></path>
        <path
          fill="#000"
          d="M48.243 19.11c0-2.205-.003-4.413 0-6.619 0-.875.197-1.686.932-2.256 1.203-.935 3.553-.664 3.962 1.567.045.251.054.514.054.77.002 4.457.005 8.914 0 13.367 0 1.458-.608 2.302-1.884 2.706-1.22.387-2.773-.523-2.926-1.802-.127-1.067-.118-2.152-.133-3.228-.02-1.502-.005-3.005-.005-4.507v.003z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_23_11358">
          <path
            fill="#fff"
            d="M0 0H84.133V28.778H0z"
            transform="translate(.434)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LinkzSVG;
