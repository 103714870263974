import { DangerousOutlined } from "@mui/icons-material";
import { Stack, Typography } from "@mui/joy";

type Props = {
  message: string;
};
const ListUnavailable = ({ message }: Props) => {
  return (
    <Stack justifyContent={"center"} alignItems={"center"} mt={"32px"}>
      <DangerousOutlined
        color="primary"
        sx={{ width: "100px", height: "100px" }}
      />
      <br />
      <Typography fontWeight={"lg"} fontSize={"sm"}>
        {message}
      </Typography>
    </Stack>
  );
};

export default ListUnavailable;
