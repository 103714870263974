import { zodResolver } from "@hookform/resolvers/zod";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import Password from "../components/PasswordPolicy/PasswordPolicy";
import Shared from "../layouts/Shared";

export const mobileValidation = new RegExp(
  /^(?:\+?\d{1,3}[-. ]?)?\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/
);

export const passwordValidation = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!#)(%*?&]{8,}$/
);

const schema = z.object({
  password: z.string().min(8).regex(passwordValidation, "Invalid password"),
});

export default function Verified() {
  const [showPassword, setShowPassword] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<z.infer<typeof schema>> = (data) => {
    console.log(data);
  };

  return (
    <Shared sxProps={{ backgroundColor: "transparent" }} title="Reset Password">
      <Helmet
        link={[{ href: "https://linkzasia.com/reset", rel: "canonical" }]}
        meta={[{ content: "Reset", name: "description" }]}
        title="Reset - Linkz"
      />
      <Box padding={2}>
        <Typography>Please enter your new password.</Typography>
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 35 }}>
          <FormControl error={errors.password ? true : false}>
            <Input
              endDecorator={
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </IconButton>
              }
              placeholder="New password"
              required
              size="lg"
              type={showPassword ? "text" : "password"}
              {...register("password")}
            />
            {errors.password?.message ? (
              <FormHelperText>{errors.password?.message}</FormHelperText>
            ) : null}
          </FormControl>
          <Password />
          <Button fullWidth size="lg" sx={{ marginTop: 5 }} type="submit">
            Continue
          </Button>
        </form>
      </Box>
    </Shared>
  );
}
