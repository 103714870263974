import { selectClasses } from "@mui/joy";

export var animateSelectStyle = {
    [`& .${selectClasses.indicator}`]: {
        transition: '0.2s',
        [`&.${selectClasses.expanded}`]: {
            transform: 'rotate(-180deg)',
        },
    },
    backgroundColor: 'white',
}
