import {
  EditOutlined,
} from "@mui/icons-material";
import { Avatar, Box, Chip, Divider, Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BusinessSessionHelper } from "../../helpers/business-session.helper";
import { BusinessService } from "../../services/business.service";
import { ProfileService } from "../../services/profile.service";
import { IBusinessResponse } from "../../types/business";
import { AuthStrategy } from "../../utils/constants";
import ProfileBusinesses from "./ProfileBusinesses";
import { useTranslation } from "react-i18next";
import { UploadService } from "../../services/upload.service";
import toast from "react-hot-toast";
import showErrorToast, { ErrorToastConfig, showSuccessToast, SuccessToastConfig } from "../../components/Error";
import LoadingModal from "../../components/LoadingModal/LoadingModal";

export default function Profiles() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [profile, setProfile] = useState<IProfileResponse | null>(null);
  const [businesses, setBusinesses] = useState<IBusinessResponse[]>([]);
  const [loading, setLoading] = useState(false);

  async function getProfile() {
    const profileData = await ProfileService.getProfile();
    if (profileData) {
      setProfile(profileData);
      setDisplayPicture(import.meta.env.VITE_SPACES_HOST + "/profile/" + profileData.id);
    }
  }

  async function getBusinesses() {
    const businessData = await BusinessService.getBusiness({ paging: false });
    if (businessData) {
      setBusinesses(businessData);
    }
  }


  const [displayPicture, setDisplayPicture] = useState<string | null>(null);
  const handleUpload = (file: File) => {
    if (!profile) return;
    setLoading(true);
    UploadService.upload(file, profile.id, "profile").then((res) => {
      console.log(res);
      toast(showSuccessToast("Profile picture uploaded successfully"), SuccessToastConfig)
    }).catch((err) => {
      toast(showErrorToast(err), ErrorToastConfig)
      console.log(err);
    }).finally(() => {
      setLoading(false);
    })

  };
  const handleChangeFile = (e: any) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      console.log(file);
      setDisplayPicture(URL.createObjectURL(file));
      handleUpload(file);
    }
  };

  useEffect(() => {
    if (!profile) getProfile();
    if (!businesses || businesses.length === 0) getBusinesses();
  }, [profile, businesses]);

  const ProfileItem = ({
    title,
    description,
    isEmail = false,
    onEditClick,
  }: {
    title: string;
    description: string;
    isEmail?: boolean;
    onEditClick?: () => void;
  }) => {
    return (
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="stretch"
        sx={{ position: "relative" }}
      >
        <div style={{ marginLeft: "1rem" }}>
          <Typography fontSize={"sm"} fontWeight="sm">{title}</Typography>
          <Typography textColor={"neutral.900"} fontSize={"md"} fontWeight={"lg"}>
            {description}
          </Typography>
        </div>
        {!isEmail && (
          <EditOutlined
            onClick={onEditClick}
            color="primary"
            sx={{ marginLeft: "1rem" }}
          />
        )}
      </Stack>
    );
  };

  const ProfileSection = () => {
    return (
      <Stack
        alignItems="start"
        direction={
          {
            xs: "column",
            md: "row",
          }
        }
        justifyContent="start"
        marginTop={3}
        spacing={3}
        gap={2}
      >
        <Stack
          direction={"row"}
          justifyContent={"center"}
          width={{
            xs: "100%",
            md: "auto",
          }}
        >
          <Stack
            alignItems="center"
            direction="column"
            justifyContent="center"
            gap={2}
            sx={{ position: "relative" }}
          >
            <Avatar
              alt="Linkz User"
              size="lg"
              src={displayPicture ?? ""}
              sx={{ height: 80, marginX: "auto", width: 80, }}
              onClick={() => document.getElementById("display-picture")?.click()}
            />
            <Chip color="primary" variant="outlined">
              {t("profile.businessAccount")}
            </Chip>
            <input id="display-picture" accept="image/*" type="file" style={{ display: "none" }} onChange={handleChangeFile} />
          </Stack>
        </Stack>
        <Stack
          alignItems="start"
          direction="column"
          justifyContent="start"
          spacing={1}
          sx={{ position: "relative" }}
        >
          <ProfileItem
            title={t("landing.signup.fullName")}
            description={profile?.fullName || "Linkz User"}
            onEditClick={() => navigate("fullname")}
          />
          <br />
          <ProfileItem
            title={t("landing.signup.email")}
            description={`${BusinessSessionHelper.Account()?.email}`}
            isEmail
          />
        </Stack>
        <Stack
          alignItems="start"
          direction="column"
          justifyContent="start"
          spacing={1}
          sx={{ position: "relative" }}
        >
          <ProfileItem
            title={t("landing.signup.phoneNumber")}
            description={
              `(+${profile?.countryCode}) ${profile?.localNumber}` || "Not set"
            }
            onEditClick={() => navigate("mobile")}
          />
          <br />
          {BusinessSessionHelper.Account()?.authStrategy !==
            AuthStrategy.GOAUTH && (
              <ProfileItem
                title={t("landing.signup.password")}
                description="••••••••••"
                onEditClick={() => navigate("password")}
              />
            )}
        </Stack>
      </Stack>
    )
  }

  return (
    <Box aria-label="profiles" component="section" id="me">
      <Divider />
      <ProfileSection />
      <br />
      <Divider sx={{
        display: {
          xs: "block",
          md: "none"
        }
      }} />
      <Stack
        alignItems="stretch"
        direction="column"
        justifyContent="center"
        width={{ xs: "90vw", lg: "100%" }}
        sx={{ position: "relative" }}
        marginTop={3}
        spacing={3}
      >
        <ProfileBusinesses
          {...{ businesses, onRefreshBusinesses: getBusinesses }}
        />
        <LoadingModal isLoading={loading} />
      </Stack>
    </Box>
  );
}
