import { Button } from "@mui/joy";
import React, { useRef } from "react";

export default function UploadButton({
    title,
    onChange,
}: {
    title: string;
    onChange: (file: File | undefined) => void;
}) {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        onChange(file)
    };
    return (
        <div>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
            />
            <Button onClick={handleButtonClick} variant="outlined">{title}</Button>
        </div>
    )
}