/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";
import { Fragment, useEffect, useState } from "react";
import { Stack } from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import OrderListCards from "../../components/OrdersV2/OrderListCards";
import OrderListTable from "../../components/OrdersV2/OrderListTable";
import { Pagination } from "../../components/Pagination";
import { OrderService } from "../../services/order.service";
import { IOrderList } from "../../types/order";
import { BrowserRouterConstants } from "../../utils/constants";
import toast from "react-hot-toast";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import { useAuthStore } from "../../store/session";
import CancelOrderReasonModal from "../../components/OrdersV2/CancelOrderReasonModal";

const rows = [
  {
    id: "INV-1234",
    date: "Feb 3, 2023",
    status: "Refunded",
    orderType: "sales",
    customer: {
      initial: "O",
      name: "Olivia Ryhe",
      email: "olivia@email.com",
    },
  },
];

export default function PublicViewListOutstandingOrders() {
  const { t } = useTranslation();
  // const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState<readonly (string | number | null)[]>(
    []
  );
  const [loading, setLoading] = useState<any>(true);
  const [confirmationModal, setConfirmationModal] = useState<any>({
    open: false,
    title: "",
    content: "",
    onConfirm: () => {},
    onCancel: () => {},
  });
  const [cancelOrderWithReasonModal, setCancelOrderWithReasonModal] =
    useState<any>({
      open: false,
      onConfirm: () => {},
      onCancel: () => {},
    });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { search } = useLocation(); // url search string beginning with '?'
  const { session } = useAuthStore();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  // const [searchTerm, setSearchTerm] = useState<string>();
  const [orderList, setOrderList] = useState<IOrderList[] | any>([]);
  const [selectedOrderTypeFilter, setSelectedOrderTypeFilter] = useState<any>();
  const [selectedOrderStatusFilter, setSelectedOrderStatusFilter] =
    useState<any>();
  const [selectedOrderDateFilter, setSelectedOrderDateFilter] = useState<any>();
  const [pagination, setPagination] = useState<any>({
    currentLast: 0,
    max: 0,
    limit: 20,
    offset: 0,
  });

  const paramStatus = searchParams.get("status");
  const paramDate = searchParams.get("date");
  const paramType = searchParams.get("type");
  const paramQ = searchParams.get("q");

  const paramSellerBusinessId = searchParams.get("sellerBusinessId");
  const paramBuyerBusinessId = searchParams.get("buyerBusinessId");

  /** Redirection to client view order list if user is already logged in */
  session &&
    [paramSellerBusinessId, paramBuyerBusinessId].includes(
      session?.activeBusiness?.id
    ) &&
    navigate(
      BrowserRouterConstants.ClientPrefix +
        BrowserRouterConstants.Orders +
        `/outstanding/${search}`
    );

  useEffect(() => {
    if (paramDate && !selectedOrderDateFilter) {
      setSelectedOrderDateFilter(paramDate);
    }
    if (paramStatus && !selectedOrderStatusFilter) {
      setSelectedOrderStatusFilter(paramStatus);
    }
    if (paramType && !selectedOrderTypeFilter) {
      setSelectedOrderTypeFilter(paramType);
    }
    if (paramQ && !searchTerm) {
      setSearchTerm(paramQ ?? "");
      getAndSetOrderList(paramQ ?? "");
    }
  }, [searchParams]);

  useEffect(() => {
    getAndSetOrderList();
  }, [pagination.offset, pagination.limit]);

  async function getAndSetOrderList(searchTerm?: string) {
    console.log(
      "getAndSetOrderList",
      paramSellerBusinessId,
      paramBuyerBusinessId
    );
    setLoading(true);
    try {
      const responseData = await OrderService.getOutstandingOrderListPublic({
        sellerBusinessId: paramSellerBusinessId,
        buyerBusinessId: paramBuyerBusinessId,
        searchTerm: searchTerm,
        hasPagination: true,
        limit: pagination.limit,
        offset: pagination.offset,
      });
      console.log("Outstanding Order List Response :::", responseData);
      const initialOrderList = responseData.items.map((item: any) => {
        // const orderType =
        //   item.seller && item.seller.id === session?.activeBusiness?.id
        //     ? "sales"
        //     : item.buyer && item.buyer.id === session?.activeBusiness?.id
        //     ? "purchase"
        //     : "draft";
        const orderType = "purchase";
        return {
          ...item,
          orderType,
        };
      });

      console.log("Initial Order List :::", initialOrderList);

      setOrderList(initialOrderList);
      setPagination(responseData.pagination);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error when Fetching Outstanding Orders: ", error);
      toast(showErrorToast(error), ErrorToastConfig);
    }
  }

  const handleOnClick = (action: any, orderId: any) => {
    switch (action) {
      case "duplicate":
        setConfirmationModal({
          open: true,
          title: "Are you sure you want to duplicate this order?",
          content: "",
          btnConfirmText: "Duplicate",
          onConfirm: () => duplicateOrder(orderId),
          onCancel: () => setConfirmationModal({ open: false }),
        });
        break;
      case "delete":
        setConfirmationModal({
          open: true,
          title: "Are you sure you want to delete this draft?",
          content: "This cannot be undone.",
          btnConfirmText: "Delete",
          onConfirm: () => deleteOrder(orderId),
          onCancel: () => setConfirmationModal({ open: false }),
        });
        break;
      case "cancel":
        setConfirmationModal({
          open: true,
          title: "Are you sure you want to cancel this order?",
          content: "This cannot be undone.",
          onConfirm: () => {
            setCancelOrderWithReasonModal({
              open: true,
              onConfirm: (reason: string) => {
                cancelOrder(orderId, reason);
              },
              onCancel: () => setCancelOrderWithReasonModal({ open: false }),
            });
          },
          onCancel: () => setConfirmationModal({ open: false }),
        });
        break;
      default:
        break;
    }
  };

  const duplicateOrder = async (orderId: string) => {
    setLoading(true);
    const duplicatedOrder = await OrderService.duplicateOrder(orderId);
    setLoading(false);
    setConfirmationModal({ open: false });
    if (duplicatedOrder)
      navigate(
        BrowserRouterConstants.ClientPrefix +
          BrowserRouterConstants.Orders +
          `/${duplicatedOrder.id}`
      );
  };

  const cancelOrder = async (orderId: string, reason?: string) => {
    console.log("To Cancel", orderId);
    setLoading(true);
    const deletedTimestamp = await OrderService.cancelOrder(orderId, {
      reason,
    });
    setLoading(false);
    setConfirmationModal({ open: false });
    if (deletedTimestamp) getAndSetOrderList();
  };

  const deleteOrder = async (orderId: string) => {
    setLoading(true);
    const isDeleted = await OrderService.deleteOrder(orderId);
    setLoading(false);
    setConfirmationModal({ open: false });
    if (isDeleted) getAndSetOrderList();
  };

  const filterByDate = (orderDate: any) => {
    const now = dayjs();
    switch (selectedOrderDateFilter) {
      case "today":
        return dayjs(orderDate).isSame(now, "day");
      case "yesterday":
        return dayjs(orderDate).isSame(now.subtract(1, "day"), "day");
      case "this_week":
        return dayjs(orderDate).isSame(now, "week");
      case "this_month":
        return dayjs(orderDate).isSame(now, "month");
      default:
        return true;
    }
  };

  const filteredOrderList = orderList?.filter((order: any) => {
    console.log("Order to be filtered :::", selectedOrderStatusFilter, order);
    return (
      (selectedOrderTypeFilter
        ? order.orderType === selectedOrderTypeFilter
        : true) &&
      (selectedOrderStatusFilter
        ? selectedOrderStatusFilter.backendStatuses.includes(order.status)
        : true) &&
      filterByDate(order.updatedAt)
    );
  });

  const handlePaging = (action: string | number) => {
    console.log("Paging Action :::", action);
    if (typeof action === "string") {
      /** when pagination button is string */
      switch (action) {
        case "first":
          setPagination({ ...pagination, offset: 0 });
          break;

        case "previous":
          setPagination({
            ...pagination,
            offset: pagination.offset - pagination.limit,
          });
          break;

        case "next":
          setPagination({
            ...pagination,
            offset: pagination.offset + pagination.limit,
          });
          break;

        case "last":
          setPagination({
            ...pagination,
            offset:
              (Math.floor(pagination.max / pagination.limit) - 1) *
                pagination.limit +
              pagination.limit,
          });
          break;
      }
    } else {
      /** when pagination button is number */
      console.log({ pagination });
      setPagination({
        ...pagination,
        offset: (action - 1) * pagination.limit,
      });
    }
  };

  /** UI Rendering part */
  return (
    <Fragment>
      <Box m={4}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={2}
          py={2}
        >
          <Typography level="h3" component="h1">
            {t("order.outstandingOrders")}
          </Typography>
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <Typography flexGrow={1}>
              Login to your account to proceed with purchase
            </Typography>
            <Button
              variant="soft"
              sx={{
                color: "var(--joy-palette-primary-500)",
                "&:hover": {
                  backgroundColor: "var(--joy-palette-primary-100)",
                },
              }}
              component={Link}
              to={
                BrowserRouterConstants.Signin +
                `?callbackURL=${encodeURIComponent(
                  BrowserRouterConstants.ClientPrefix +
                    BrowserRouterConstants.Orders +
                    "/outstanding" +
                    search
                )}`
              }
            >
              {t("landing.loginBtn")}
            </Button>
            <Button
              variant="solid"
              component={Link}
              to={
                BrowserRouterConstants.Signup +
                `?callbackURL=${encodeURIComponent(
                  BrowserRouterConstants.ClientPrefix +
                    BrowserRouterConstants.Orders +
                    "/outstanding" +
                    search
                )}`
              }
            >
              {t("landing.createAccountBtn")}
            </Button>
          </Stack>
        </Stack>

        {filteredOrderList && filteredOrderList?.length > 0 ? (
          isMobile ? (
            <OrderListCards
              // isPublicView={true}
              orderList={filteredOrderList}
              ThreeDotsRowMenu={""}
              handleOnClick={handleOnClick}
            />
          ) : (
            <>
              <OrderListTable
                isPublicView={true}
                orderList={filteredOrderList}
                selected={selected}
                setSelected={setSelected}
                handleOnClick={handleOnClick}
                rows={rows}
                ThreeDotsRowMenu={""}
              />

              {/** Bottom Pagination */}

              <Stack
                direction={"row"}
                width={"100%"}
                justifyContent={"center"}
                alignContent={"center"}
                gap={0}
                mt={8}
              >
                <Pagination
                  count={Math.ceil(pagination.max / pagination.limit)}
                  page={Math.ceil(pagination.currentLast / pagination.limit)}
                  // defaultPage={pagination.currentLast}
                  onChange={(_, value) => {
                    console.log(
                      "pagination value",
                      value,
                      pagination.currentLast
                    );
                    handlePaging(value);
                  }}
                  showFirstButton
                  showLastButton
                />
              </Stack>
            </>
          )
        ) : (
          <Box textAlign={"center"} mt={4}>
            <Typography level={"h3"} mb={2}>
              {t("order.table.noOrders")}
            </Typography>
            <Typography> {t("order.table.noOrdersPrompt")}</Typography>
          </Box>
        )}
      </Box>

      {/** Modals */}
      <ConfirmationModal
        title={confirmationModal.title}
        content={confirmationModal.content}
        open={confirmationModal.open}
        btnConfirmText={confirmationModal.btnConfirmText}
        onCancel={confirmationModal.onCancel}
        onConfirm={confirmationModal.onConfirm}
      />
      <CancelOrderReasonModal
        show={cancelOrderWithReasonModal.open}
        onConfirm={cancelOrderWithReasonModal.onConfirm}
        onClose={cancelOrderWithReasonModal.onCancel}
      />
      <LoadingModal isLoading={loading} />
    </Fragment>
  );
}
