import {
  CheckCircle,
  KeyboardArrowDown,
  ViewSidebarOutlined,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Link,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import { useQueryClient } from "@tanstack/react-query";
import "flag-icons/css/flag-icons.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { BusinessSessionHelper } from "../helpers/business-session.helper";
import { BusinessService } from "../services/business.service";
import { animateSelectStyle } from "../styles/select";
import { IBusinessResponse } from "../types/business";
import FlagById from "./FlagById";
import HamburgerIcon from "./Icons/Hamburger";
import LanguageSelector from "./LanguageSelector";
import { openSidebar } from "./utils";

interface Props {
  paths: {
    name: string;
    path: string;
  }[];
}

const BreadcrumbHeader = ({ paths }: Props) => {
  const { t } = useTranslation();
  const [availableBusinesses, setAvailableBusinesses] = useState<
    IBusinessResponse[]
  >([]);
  const client = useQueryClient();
  const [activeBusiness, setActiveBusiness] = useState<IBusinessResponse>();
  const getAvailableBusinesses = () => {
    BusinessService.getBusiness({ paging: false }).then((data) => {
      setAvailableBusinesses(data);
      const activeBusiness = data.find(
        (b) => b.id === BusinessSessionHelper.GetBusinessId()
      );
      if (activeBusiness) setActiveBusiness(activeBusiness);
    });
  };

  useEffect(() => {
    getAvailableBusinesses();
  }, []);
  const { pathname } = useLocation();
  return (
    <Stack>
      <Stack
        direction={"row"}
        // justifyContent={"center"}
        alignItems={"center"}
        sx={{
          width: "100%",
        }}
      >
        <span className="min-[900px]:block hidden">
          <ViewSidebarOutlined />
        </span>
        <Box className="max-[899px]:block hidden" onClick={() => openSidebar()}>
          <HamburgerIcon />
        </Box>
        <div className="min-[900px]:flex items-center hidden">
          <Breadcrumbs
            aria-label="breadcrumbs"
            sx={{
              marginLeft: "1rem",
            }}
          >
            {paths.map((p, index) => {
              if (index === paths.length - 1) {
                return (
                  <Typography
                    key={p.name}
                    sx={{
                      marginLeft: "1rem",
                    }}
                  >
                    {t(`breadcrumb.${p.name}`)}
                  </Typography>
                );
              }

              return (
                <Link
                  key={p.name}
                  color="neutral"
                  href={p.path}
                  sx={{
                    marginX: "1rem",
                  }}
                >
                  {t(`breadcrumb.${p.name}`)}
                </Link>
              );
            })}
          </Breadcrumbs>
        </div>
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Select
            className={`${
              ["/client", "/client/"].includes(pathname) ? "" : "hidden"
            }`}
            key={activeBusiness?.id}
            value={activeBusiness}
            onChange={async (_, value) => {
              if (!value) return;
              await BusinessSessionHelper.SetActiveBusiness(
                value as IBusinessResponse
              );
              setActiveBusiness(value as IBusinessResponse);
              client.invalidateQueries({
                queryKey: ["business-credits", "kyc-payment-gateway-status"],
              });
            }}
            renderValue={(option) => (
              <Typography
                fontWeight={600}
                mr={"auto"}
                startDecorator={
                  <span
                    className={`fi fi-${activeBusiness?.companyCountry.toLocaleLowerCase()} shadow-md`}
                  ></span>
                }
              >
                {option?.label}
              </Typography>
            )}
            variant="plain"
            size="md"
            indicator={<KeyboardArrowDown />}
            sx={{ ...animateSelectStyle, backgroundColor: "white" }}
          >
            {availableBusinesses
              ?.sort(
                (a, b) =>
                  (a.id === activeBusiness?.id ? 0 : 1) -
                  (b.id === activeBusiness?.id ? 0 : 1)
              )
              .map((business) => (
                <Option
                  key={business.id}
                  value={business}
                  label={business.companyName}
                >
                  <Stack
                    sx={{ width: "100%" }}
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap={5}
                  >
                    <div>
                      <Typography
                        sx={{ flex: 1 }}
                        startDecorator={
                          <FlagById id={business.companyCountry} />
                        }
                      >
                        {business.companyName}
                      </Typography>
                      {business.id === activeBusiness?.id && (
                        <Typography fontSize={12} textColor="neutral.500">
                          {t("profile.business.currentLoggedInCompanyLabel")}
                        </Typography>
                      )}
                    </div>
                    {business.id === activeBusiness?.id && (
                      <CheckCircle color="primary" />
                    )}
                  </Stack>
                </Option>
              ))}
          </Select>
          <LanguageSelector />
          {/* <div
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              width: "10px",
              height: "10px",
              right: "15px",
              top: "5px",
              borderRadius: "10px",
              position: "absolute",
              backgroundColor: "#ff8360",
            }}
          />
          <NotificationsOutlined
            style={{
              fontSize: "2rem",
              margin: "0 0.6rem",
            }}
          />
        </div>
        <AccountCircleOutlined
          style={{
            fontSize: "2.3rem",
          }}
        /> */}
        </div>
      </Stack>
      {/* {!(window.location.href.endsWith("/profile") || window.location.href.endsWith("/profile/kyc")) && !BusinessSessionHelper.IsValidatedKYCPaymentGateway() && <KycSnackBar />} */}
    </Stack>
  );
};

export default BreadcrumbHeader;
