import { Box, Modal, Stack, Typography } from "@mui/joy";
import CheckCircleIcon from "../../../components/Icons/CheckCircle";

interface IProps {
  open: boolean;
}

export function TopupSuccessModal({ open }: IProps) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 578,
    height: 260,
    bgcolor: "#fff",
    boxShadow: 24,
    px: 4,
    py: "22px",
    borderRadius: "32px",
    borderColor: "transparent",
    outline: "none",
  };

  return (
    <Modal
      open={open}
      style={{ backgroundColor: "#32383E99", backdropFilter: "unset" }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack alignItems={"center"}>
          <CheckCircleIcon className="w-[80px] h-[80px]" />
          <Typography
            mt={4}
            textColor={"text.primary"}
            fontWeight={600}
            fontSize={24}
            id="modal-modal-title"
          >
            Success
          </Typography>
          <Typography
            textAlign={"center"}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Top up payment has been
            <br /> succesfully received.
          </Typography>
        </Stack>
      </Box>
    </Modal>
  );
}
