function LeftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        stroke="#499873"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M20 24l-7.58-7.057a1.27 1.27 0 010-1.886L20 8"
      ></path>
    </svg>
  );
}

export default LeftIcon;
