export function InactiveCheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <rect
        width="15"
        height="15"
        x="0.5"
        y="0.5"
        fill="#FBFCFE"
        stroke="#DDE7EE"
        rx="3.5"
      ></rect>
      <path
        fill="#9FA6AD"
        d="M5.445 10.772L3.134 8.46a.663.663 0 10-.94.94l2.785 2.785c.26.26.68.26.94 0l7.046-7.05a.664.664 0 10-.939-.94l-6.58 6.577z"
      ></path>
    </svg>
  );
}

export function ActiveCheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <rect
        width="15"
        height="15"
        x="0.5"
        y="0.5"
        fill="#E3FBE3"
        stroke="#91C4A9"
        rx="3.5"
      ></rect>
      <path
        fill="#499873"
        d="M5.445 10.772L3.134 8.46a.663.663 0 10-.94.94l2.785 2.785c.26.26.68.26.94 0l7.046-7.05a.664.664 0 10-.939-.94l-6.58 6.577z"
      ></path>
    </svg>
  );
}

export function ActiveCheckIconWithoutBG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#499873"
        d="M5.863 10.583l-2.78-2.78-.946.94 3.726 3.727 8-8-.94-.94-7.06 7.053z"
      ></path>
    </svg>
  );
}
