import { Switch, Typography } from "@mui/joy";
import React from "react";
import { ItemService } from "../../../services/item.service";
import { ItemType } from "../../../types/item";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

type Props = {
  currentItem?: ItemType;
};

const ActiveItemToggle = ({ currentItem }: Props) => {
  const { t } = useTranslation();
  const { register, setValue } = useFormContext();

  const handleToggleActiveItem = (isActive: boolean) => {
    // setIsLoading(true);
    if (currentItem) {
      ItemService.patchInventoryItem({
        id: currentItem?.id,
        updateInventoryItemDto: {
          ...currentItem,
          isActive: isActive,
        },
      }).catch((error) => console.error(error));
      // .finally(() => setIsLoading(false));
    } else {
      setValue("isActive", isActive);
    }
  };

  return (
    <div className="flex items-center mb-8">
      <span className="w-full flex lg:justify-end items-center gap-3">
        <Typography fontSize={14}>
          <p className="mr-auto lg:mr-0 my-auto">
            {t("catalog.inventory.editForm.activeItem")}
          </p>
        </Typography>
        <div className="ml-auto lg:ml-0">
          <Switch
            {...register("isActive")}
            defaultChecked={currentItem?.isActive || true}
            size="lg"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleToggleActiveItem(event.target.checked);
            }}
          />
        </div>
      </span>
    </div>
  );
};

export default ActiveItemToggle;
