import { privateAxios } from "../lib/api";
import { ApiConstants } from "../utils/constants";

interface IPayload {
  role: string;
  size: string;
  source: string;
  sourceOther?: string;
  industry: string;
  industryOther?: string;
}
export class KYCService {
  static async setKYCData(payload: IPayload) {
    const res = await privateAxios.post(ApiConstants.kycUrl, payload);
    return res.data;
  }
  static async isKYCDataVerified() {
    const res = await privateAxios.get(`${ApiConstants.kycUrl}/isVerified`);
    return res.data;
  }
}
