import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import showErrorToast from "../../components/Error";
import { AuthService } from "../../services/auth.service";
import i18next from "../../i18n/config";

export const passwordValidation = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#])[A-Za-z\d@$!%*?&^#]+$/
);

const changePasswordScheme = z.object({
  currentPassword: z
    .string()
    .min(8)
    .regex(passwordValidation, "Current password invalid"),
  newPassword: z
    .string()
    .min(8)
    .regex(passwordValidation, "Please fulfill the requirement below"),
});
export const useChangePasswordContainer = () => {
  const nav = useNavigate();

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccesModal, setShowSuccesModal] = useState(false);

  const {
    formState: { errors },
    watch,
    handleSubmit,
    register,
    setError,
  } = useForm<z.infer<typeof changePasswordScheme>>({
    resolver: zodResolver(changePasswordScheme),
  });

  const { newPassword } = watch();

  const mutation = useMutation({
    mutationFn: AuthService.changePassword,
    onSuccess: () => {
      setIsLoading(false);
      setShowSuccesModal(true);
      setTimeout(() => {
        setShowSuccesModal(false);
        nav("/client/profile", { replace: true });
      }, 3000);
    },
    onError: (error: any) => {
      setIsLoading(false);
      if (error.response.status === 400) {
        setError("currentPassword", {
          message: i18next.t("commons.error.form.invalid", {
            label: i18next.t("profile.currentPasswordLabel"),
          }),
        });
      } else {
        toast(showErrorToast(error), { duration: 4000 });
      }
    },
  });

  const handlShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handlShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const onSubmit: SubmitHandler<z.infer<typeof changePasswordScheme>> = (
    data
  ) => {
    const payload: IChangePassword = {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    };
    setIsLoading(true);
    mutation.mutate(payload);
  };

  return {
    handleSubmit,
    register,
    newPassword,
    onSubmit,
    errors,
    showCurrentPassword,
    handlShowCurrentPassword,
    showNewPassword,
    handlShowNewPassword,
    isLoading,
    showSuccesModal,
  };
};
