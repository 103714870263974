import { Stack, Typography } from "@mui/joy";
import ComingSoonSVG from "../../components/Icons/ComingSoonSVG";
import { useTranslation } from "react-i18next";

const ComingSoon = () => {
  const { t } = useTranslation();
  return (
    <Stack justifyContent={"center"} alignItems={"center"} spacing={3} my={16}>
      <ComingSoonSVG />
      <Stack spacing={2} alignItems={"center"}>
        <Typography level="h3">{t("commons.comingSoon.title")}</Typography>
        <Typography>{t("commons.comingSoon.description")}</Typography>
      </Stack>
    </Stack>
  );
};

export default ComingSoon;
