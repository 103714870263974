import { useQuery } from "@tanstack/react-query";
import { CreditsService } from "../../../../services/credit.service";

const useContainer = () => {
  const creditTransactionsQuery = useQuery({
    queryKey: ["credits-transactions"],
    queryFn: CreditsService.getCreditsTransactions,
  });
  return {
    isPending: creditTransactionsQuery.isPending,
    transactions: creditTransactionsQuery.data || [],
  };
};

export default useContainer;
