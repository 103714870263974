import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { Helmet } from "react-helmet";
import Shared from "../layouts/Shared";
import Carousel from "../components/carousel/Carousel";

const item = {
  id: 1,
  uuid: "item-1",
  name: "Item 1",
  description: "Item 1",
  images: [
    "https://images.unsplash.com/photo-1661956601349-f61ddb82d179?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
    "https://images.unsplash.com/photo-1661956601349-f61ddb82d179?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
  ],
}

export default function Item() {
  return (
    <Shared sxProps={{ marginBottom: 15 }}>
      <Helmet
        link={[
          {
            href: `https://linkzasia.com/items/${item.uuid}`,
            rel: "canonical",
          },
        ]}
        meta={[{ content: item.description, name: "description" }]}
        title={`${item.name} - Linkz`}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={2}
        paddingY={0.5}
      >
        <IconButton
          aria-labelledby="navigate-before"
          onClick={() => window.history.back()}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <Stack direction="row" spacing={2}>
          <IconButton>
            <FavoriteBorderOutlinedIcon />
          </IconButton>
          <IconButton>
            <FilterAltOutlinedIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Carousel alt={item.name} slides={item.images} />
      <Stack
        divider={<Divider />}
        padding={2}
        spacing={3}
        sx={{ backgroundColor: "white" }}
      >
        <Stack spacing={1}>
          <Typography fontSize="lg" fontWeight="bold">
            {item.name}
          </Typography>
          <Typography fontWeight="lg" textColor="primary.500">
            $120.00
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography fontWeight="bold">Description</Typography>
          <Typography>{item.description}</Typography>
        </Stack>
        <Stack spacing={2}>
          <Typography fontWeight="bold">Category</Typography>
          <Stack direction="row" spacing={1}>
            <Chip color="success" variant="solid">
              Pokemon
            </Chip>
            <Chip color="success" variant="solid">
              Card
            </Chip>
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <Typography fontWeight="bold">Shopping fee</Typography>
          <Box>
            <Typography>Free shipping with self collection.</Typography>
            <Typography>Received by 16 Jan - 17 Jan</Typography>
          </Box>
        </Stack>
      </Stack>
      <Box
        padding={2}
        sx={{
          bottom: 0,
          left: 0,
          position: "fixed",
          right: 0,
        }}
      >
        <Button fullWidth size="lg">
          Add to cart
        </Button>
      </Box>
    </Shared>
  );
}
