import { Modal, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { IfElse } from "../../../components/Condition";
import ComapnyIcon from "../../../components/Icons/Company";
import useContainer from "./useContainer";

interface IProps {
  isOpen: boolean;
  close: () => void;
}
const BusinessSelectionModal = ({ isOpen, close }: IProps) => {
  const { t } = useTranslation();

  const { isLoading, businesses, itemClickHandler } = useContainer({ isOpen });

  return (
    <Modal
      open={isOpen}
      style={{
        backgroundColor: "rgba(50, 56, 62,.6)",
        backdropFilter: "blur(0px)",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="flex items-center justify-center w-screen h-screen">
        <div className="w-[672px] h-[272px] bg-white outline-none p-6 rounded-[32px] flex flex-col gap-y-8 overflow-y-scroll hide-scrollbar">
          <IfElse
            condition={!isLoading}
            ifBlock={
              <>
                <div className="flex items-center justify-between">
                  <div className="flex items-center justify-between gap-2">
                    <ComapnyIcon />
                    <Typography
                      color="neutral"
                      className="font-semibold text-[16px]"
                    >
                      {t("landing.login.selectBusinessModalTitle")}
                    </Typography>
                  </div>

                  <Typography
                    onClick={close}
                    color="primary"
                    className="font-semibold cursor-pointer"
                  >
                    {t("commons.cancelBtn")}
                  </Typography>
                </div>
                {(businesses || []).map((business) => (
                  <Typography
                    onClick={() => itemClickHandler(business.id)}
                    key={business.id}
                    className="text-[#171A1C] font-normal cursor-pointer"
                  >
                    {business.companyName}
                  </Typography>
                ))}
              </>
            }
            elseBlock={
              <div className="w-[42px] h-[42px] border-black rounded-full border-l-transparent top-[50%] left-[50%] absolute outline-none animate-spin border-[4px]"></div>
            }
          ></IfElse>
        </div>
      </div>
    </Modal>
  );
};

export default BusinessSelectionModal;
