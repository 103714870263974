interface IEProps {
  condition: boolean;
  ifBlock: React.ReactNode;
  elseBlock: React.ReactNode;
}

export const IfElse = ({ condition, ifBlock, elseBlock }: IEProps) => {
  return <>{condition ? <>{ifBlock}</> : <>{elseBlock}</>}</>;
};

interface Props {
  condition: boolean;
  children: React.ReactNode;
}

export const If = ({ condition, children }: Props) => {
  return <>{condition && <>{children}</>}</>;
};
