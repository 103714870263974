import { Box, Chip, Grid, Stack, Typography } from "@mui/joy";
import { OrderTypeTag } from "../utils";
import {
  IOrderList,
  orderReportTypes,
  OrderStatusEnum,
  paymentFlowTypes,
} from "../../types/order";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { getInvoiceNumber } from "../../utils/order";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { formatPrice } from "../../utils/formatPrice";
import { ReactElement } from "react";

const InfoGridRowSlot = ({
  column1,
  column2,
}: {
  column1: ReactElement;
  column2: ReactElement;
}) => (
  <Grid container spacing={2} sx={{ flexGrow: 1 }}>
    <Grid xs={3}>
      <Typography fontWeight={"bold"}>{column1}</Typography>
    </Grid>
    <Grid>{column2}</Grid>
  </Grid>
);

export default function OrderReportCards({
  orderList,
  reportType,
  handleDownloadPdf,
  DownloadPdfButton,
}: any) {
  const { t } = useTranslation();
  return (
    <Stack spacing={3}>
      {(orderList as IOrderList[])?.map((order: IOrderList) => (
        <Stack
          className="cardItem"
          key={order.id}
          sx={{
            width: "100%",
            borderRadius: "sm",
            my: 2,
            p: 2,
            boxShadow: "sm",
          }}
          spacing={2}
        >
          <Stack
            direction={"row"}
            justifyContent={"end"}
            alignItems={"center"}
            gap={2}
          >
            {reportType === orderReportTypes.PAYMENT_HISTORY && (
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <OrderTypeTag type={order.orderType} />
              </Box>
            )}
            <Typography
              fontWeight={"600"}
              marginRight={"auto"}
              endDecorator={
                order?.orderRevisions?.length > 0 && (
                  <div className="rounded-lg bg-[#F7D5AC] w-[24px] h-[24px] flex justify-center items-center ml-3">
                    <DocumentDuplicateIcon
                      width={16}
                      height={18}
                      color="#D48434"
                    />
                  </div>
                )
              }
            >
              {order.invoiceNumber
                ? getInvoiceNumber(order, order.orderRevisions)
                : "-"}
            </Typography>
            <DownloadPdfButton
              handleDownloadPdf={handleDownloadPdf}
              orderId={order.id}
            />
          </Stack>
          <Chip>
            {t(`order.filters.status.${OrderStatusEnum[order.status]}`)}
          </Chip>
          <Typography level="body-sm" px={0}>
            {order.orderType === "sales"
              ? order.buyer?.companyName || (order as any).companyName || "-"
              : order.orderType === "purchase"
              ? order.seller?.companyName || "-"
              : ""}
            <br />
            {[
              orderReportTypes.TOTAL_RECEIVABLE,
              orderReportTypes.TOTAL_PAYABLE,
            ].includes(reportType) && (
              <Typography level="body-sm">
                <Typography fontWeight={"bold"}>
                  {reportType === orderReportTypes.TOTAL_RECEIVABLE
                    ? "Receivable: "
                    : reportType === orderReportTypes.TOTAL_PAYABLE
                    ? "Payable: "
                    : ""}
                </Typography>
                {order.currency || order.seller?.companyCountry}{" "}
                {formatPrice(
                  order.remainingAmount || 0,
                  order.currency as string | undefined
                )}
              </Typography>
            )}
            {reportType === orderReportTypes.PAYMENT_HISTORY && (
              <>
                <InfoGridRowSlot
                  column1={
                    <Typography fontWeight={"bold"}>Payment Type</Typography>
                  }
                  column2={
                    <>
                      {
                        paymentFlowTypes.find(
                          (pft) => pft.value === order.paymentFlowType
                        )?.label
                      }
                    </>
                  }
                />

                <InfoGridRowSlot
                  column1={
                    <Typography fontWeight={"bold"}>Total Amount</Typography>
                  }
                  column2={
                    <>
                      {order.currency || order.seller?.companyCountry}{" "}
                      {formatPrice(
                        order.grandTotalAmount || 0,
                        order.currency as string | undefined
                      )}
                    </>
                  }
                />

                <InfoGridRowSlot
                  column1={<Typography fontWeight={"bold"}>Paid</Typography>}
                  column2={
                    <>
                      {order.currency + " "}
                      {formatPrice(
                        order.paidAmount || 0,
                        order.currency as string | undefined
                      )}
                    </>
                  }
                />
                <InfoGridRowSlot
                  column1={
                    <Typography fontWeight={"bold"}>Settlement</Typography>
                  }
                  column2={
                    <>{dayjs(order.settlementDate).format("DD MMM YYYY")}</>
                  }
                />
                <InfoGridRowSlot
                  column1={
                    <Typography fontWeight={"bold"}>Payment Method</Typography>
                  }
                  column2={
                    <>
                      {`${order.paymentMode}`.replace(/-/g, " ").toUpperCase()}
                    </>
                  }
                />
              </>
            )}
            <br />
            {/* Updated: {dayjs(order.updatedAt).format("DD MMM YYYY hh:mm A")} */}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}
