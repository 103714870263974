
export const BANK_OPTIONS = {
  "ID": [
    {
      code: null,
      name: "Select Bank Name",
      icon: "-",
    },
    {
      icon: `${import.meta.env.VITE_SPACES_HOST}/public/bca.png`,
      code: "BCA",
      name: "BCA",
    },
    {
      icon: `${import.meta.env.VITE_SPACES_HOST}/public/bni.png`,
      code: "BNI",
      name: "BNI",
    },
    {
      icon: `${import.meta.env.VITE_SPACES_HOST}/public/mandiri.png`,
      code: "Mandiri",
      name: "Mandiri",
    },
    {
      icon: `${import.meta.env.VITE_SPACES_HOST}/public/bri.png`,
      code: "BRI",
      name: "BRI",
    },
    {
      icon: `${import.meta.env.VITE_SPACES_HOST}/public/permata.png`,
      code: "Permata",
      name: "Permata",
    },
    {
      icon: `${import.meta.env.VITE_SPACES_HOST}/public/cimb.png`,
      code: "CIMB",
      name: "CIMB",
    },
    {
      icon: `${import.meta.env.VITE_SPACES_HOST}/public/danamon.png`,
      code: "Danamon",
      name: "Danamon",
    },
  ],
  "MY": [
    {
      code: null,
      name: "Select Bank Name",
      icon: "-",
    },
    {
      icon: ``,
      code: "AEON",
      name: "AEON Bank",
    },
    {
      icon: ``,
      code: "AFFIN",
      name: "Affin Bank",
    },
    {
      icon: ``,
      code: "AL_RAJHI",
      name: "Al-Rajhi",
    },
    {
      icon: ``,
      code: "ALLIANCE",
      name: "Alliance Bank",
    },
    {
      icon: ``,
      code: "AMBANK",
      name: "Ambank",
    },
    {
      icon: ``,
      code: "BANK_ISLAM",
      name: "Bank Islam",
    },
    {
      icon: ``,
      code: "BANK_RAKYAT",
      name: "Bank Rakyat",
    },
    {
      icon: ``,
      code: "BANK_MUAMALAT",
      name: "Bank Muamalat",
    },
    {
      icon: ``,
      code: "BANK_OF_AMERICA",
      name: "Bank of America",
    },
    {
      icon: ``,
      code: "BANK_OF_CHINA",
      name: "Bank of China",
    },
    {
      icon: ``,
      code: "AGROBANK",
      name: "Agrobank",
    },
    {
      icon: ``,
      code: "BSN",
      name: "Bank Simpanan Nasional",
    },
    {
      icon: ``,
      code: "BIGPAY",
      name: "BigPay",
    },
    {
      icon: ``,
      code: "BNP_PARIBAS",
      name: "BNP Paribas",
    },
    {
      icon: ``,
      code: "BOOST",
      name: "Boost Bank",
    },
    {
      icon: ``,
      code: "CCB",
      name: "China Construction Bank",
    },
    {
      icon: ``,
      code: "CIMB",
      name: "CIMB Bank",
    },
    {
      icon: ``,
      code: "CITIBANK",
      name: "Citibank",
    },
    {
      icon: ``,
      code: "DEUTSCHE",
      name: "Deutsche Bank",
    },
    {
      icon: ``,
      code: "EXIM",
      name: "EXIM Bank",
    },
    {
      icon: ``,
      code: "FINEXUS",
      name: "Finexus Cards",
    },
    {
      icon: ``,
      code: "GX",
      name: "GX Bank",
    },
    {
      icon: ``,
      code: "HONG_LEONG",
      name: "Hong Leong Bank",
    },
    {
      icon: ``,
      code: "HSBC",
      name: "HSBC Bank",
    },
    {
      icon: ``,
      code: "IIB",
      name: "India International Bank",
    },
    {
      icon: ``,
      code: "ICBC",
      name: "ICBC Bank",
    },
    {
      icon: ``,
      code: "JP_MORGAN",
      name: "JP Morgan",
    },
    {
      icon: ``,
      code: "KFH",
      name: "Kuwait Finance House",
    },
    {
      icon: ``,
      code: "MAYBANK",
      name: "Maybank",
    },
    {
      icon: ``,
      code: "MBSB",
      name: "MBSB Bank",
    },
    {
      icon: ``,
      code: "MERCHANTRADE",
      name: "Merchantrade",
    },
    {
      icon: ``,
      code: "MIZUHO",
      name: "Mizuho Bank",
    },
    {
      icon: ``,
      code: "MUFG",
      name: "MUFG Bank",
    },
    {
      icon: ``,
      code: "OCBC",
      name: "OCBC Bank",
    },
    {
      icon: ``,
      code: "PUBLIC",
      name: "Public Bank",
    },
    {
      icon: ``,
      code: "RHB",
      name: "RHB Bank",
    },
    {
      icon: ``,
      code: "STANDARD_CHARTERED",
      name: "Standard Chartered",
    },
    {
      icon: ``,
      code: "UOB",
      name: "UOB Bank",
    },
  ]
};


export function findBankOptions(countryCode: string) {
  const options = (BANK_OPTIONS as any)[countryCode]
  return options ?? BANK_OPTIONS["ID"]
} 