import { TrashIcon } from "@heroicons/react/24/outline";
import { Stack, Typography } from "@mui/joy";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import CartQuantityManager from "../../../components/Catalogue/Item/CartQuantityManager";
import { CatalogType } from "../../../types/catalog";
import { CatalogInventoryItemType } from "../../../types/item";
import { formatPrice } from "../../../utils/formatPrice";
import UOMSelector from "../../../components/Catalogue/Item/UOMSelector";
import TagsCollection from "../../../components/Catalogue/Item/TagsCollection";
import { CartItemDataType } from "../../../types/cart";
import { calculatePrice } from "../../../utils/cart";
import { If } from "../../../components/Condition";
import { theme } from "../../../components/Theme";

type Props = {
  catalog?: CatalogType;
  item: CatalogInventoryItemType;
  type?: "supplier" | "inventory";
  onClick?: () => void;
  handleRemoveItem?: (id: string) => void;
  setToCart?: Dispatch<SetStateAction<CartItemDataType[]>>;
  resetFlag?: boolean;
};

export function ProductCard({
  item,
  type = "inventory",
  catalog,
  handleRemoveItem,
  onClick,
  setToCart,
  resetFlag = false,
}: Props) {
  const currency = item.currency ?? item.inventoryItem.currency;

  const [activeUOM, setActiveUOM] = useState<"uom1" | "uom2" | "uom3">("uom1");
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < theme.breakpoints.values.sm
  );

  useEffect(() => {
    if (setToCart) {
      setToCart((prev) => {
        const currentIndex = prev.findIndex((p) => p.cid === item.id);
        if (currentIndex !== -1) {
          const updatedItem = {
            ...prev[currentIndex],
            uom: activeUOM,
            initPrice: calculatePrice(item, activeUOM),
          };

          return [
            ...prev.slice(0, currentIndex),
            updatedItem,
            ...prev.slice(currentIndex + 1),
          ];
        }

        return prev;
      });
    }
  }, [activeUOM]);

  useEffect(() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  return (
    <div
      key={item.id}
      onClick={onClick}
      className={
        "border shadow-sm md:shadow-none border-[#EAECF0] rounded-lg h-full p-0 md:p-4 flex flex-col gap-4 hover:border-[#6BAB8D] transition-all hover:shadow-lg"
      }
    >
      <Stack
        direction={{ xs: "column", md: "row" }}
        gap={{ xs: 0, md: "16px" }}
      >
        <img
          src={`${
            item.inventoryItem.thumbnail ?? "/inventory-img-placeholder.png"
          }`}
          alt="item_image"
          // width={100}
          // height={100}
          className={`lg:border border-gray-300 rounded-t-lg lg:rounded-lg w-full h-[176.5px]  ${
            type === "supplier"
              ? "md:w-[114px] md:h-[114px]"
              : "md:w-[100px] md:h-[100px]"
          } object-contain flex-shrink-0`}
        />
        <Stack direction={"column"} gap={"8px"} p={"8px"} width={"100%"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography textColor={"#475467"} className={"text-sm"}>
              {item.inventoryItem.sku}
            </Typography>
            {type === "inventory" && (
              <TrashIcon
                onClick={(event) => {
                  event.stopPropagation();
                  if (handleRemoveItem) {
                    handleRemoveItem(item.id);
                  }
                }}
                className={"h-5 w-5 text-[#499873] cursor-pointer"}
              />
            )}
          </Stack>

          <Typography
            title={item.inventoryItem.productName}
            className={"font-bold text-base text-ellipsis line-clamp-1"}
          >
            {item.inventoryItem.productName}
          </Typography>

          <TagsCollection tags={item.inventoryItem.tags} />
          <hr className="hidden lg:block mt-auto" />
        </Stack>
      </Stack>

      {catalog && type === "supplier" && (
        <div className="hidden lg:block">
          <UOMSelector
            item={item}
            hidePrice={catalog.hidePrice}
            catalogType={catalog.catalogType}
            activeUOM={activeUOM}
            setActiveUOM={(uom) => {
              setActiveUOM(uom);
            }}
            size="sm"
          />
        </div>
      )}

      <If condition={isMobile || type === "inventory"}>
        <Stack direction={"column"} p={{ xs: "8px", md: 0 }} gap={"4px"}>
          <Typography className="text-[#101828] text-base font-semibold">
            {`${currency}`} {isMobile && <br />}
            {formatPrice(calculatePrice(item, activeUOM), currency)}
          </Typography>

          <If condition={isMobile && item.inventoryItem.uom1 ? true : false}>
            <div className="border-[#499873] p-2 rounded border w-fit">
              <Typography className="text-xs text-[#499873]">
                {item.inventoryItem.uom1}
              </Typography>
            </div>
          </If>
        </Stack>
      </If>

      <If condition={!isMobile && type === "supplier"}>
        <div className="mt-auto hidden lg:block">
          <CartQuantityManager
            hidePerItemPrice={true}
            caller="product-card"
            item={item}
            hidePrice={catalog?.hidePrice}
            defaultUOM={activeUOM}
            withAddToCart
            catalog={catalog!}
            setToCart={setToCart}
            resetFlag={resetFlag}
          />
        </div>
      </If>
    </div>
  );
}
