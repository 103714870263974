import Button from "@mui/joy/Button";
// import Shared from "../../layouts/Shared";
import { AccountCircleOutlined, Add, Remove } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteOption,
  Box,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Textarea,
  Typography,
} from "@mui/joy";
import Stack from "@mui/joy/Stack";
import { useEffect, useRef, useState } from "react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import {
  // useBlocker,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Toggler,
  TwentyEightDaysPicker,
  formatInventoryItemResponse,
  transformUomList,
} from "../../components/utils";
import { AccountService } from "../../services/account.service";
import { ItemService } from "../../services/item.service";
import { OrderService } from "../../services/order.service";

import {
  BanknotesIcon,
  BellIcon,
  NewspaperIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { getMessaging, onMessage } from "firebase/messaging";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { usePDF } from "react-to-pdf";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import showErrorToast, {
  ErrorToastConfig,
  showSuccessToast,
  SuccessToastConfig,
} from "../../components/Error";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import GrandTotal from "../../components/OrdersV2/GrandTotal";
import OrderSummary from "../../components/OrdersV2/OrderSummary";
import ProductAndService from "../../components/OrdersV2/ProductAndService";
import PurchaseOrderForm from "../../components/OrdersV2/PurchaseOrderForm";
import SalesOrderForm from "../../components/OrdersV2/SalesOrderForm";
import { SuccessModal } from "../../components/SuccessModal";
import PaymentInfoModal from "../../components/payments/PaymentInfoModal";
import PaymentModal from "../../components/payments/PaymentModal";
import { BusinessService } from "../../services/business.service";
import { PaymentService, PaymentType } from "../../services/payment.service";
import { TaxService } from "../../services/tax.service";
import { useAuthStore } from "../../store/session";
import {
  IOrderRevision,
  IOrderSavePayload,
  OrderStatusEnum,
  PaymentFlowTypeEnum,
  paymentFlowTypes,
  TaxAmountTypeEnum,
} from "../../types/order";
import { BrowserRouterConstants } from "../../utils/constants";
// import AddNewBusinessModal from "../../components/OrdersV2/AddNewBusinessModal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import AddCompanyContactModal from "../../components/Orders/AddCompanyContactModal";
import { KYCModal } from "../../components/kyc/KYCModal";
import { BusinessSessionHelper } from "../../helpers/business-session.helper";
import { KycPaymentGatewayService } from "../../services/kyc-payment-gateway.service";
import { PaymentLinkService } from "../../services/payment-link.service";
import { setDefaultCurrency } from "../../utils/misc";
import OrderDetailsPdf, { OrderDetailsProps } from "./pdf/OrderDetailsPDF";
import MiniOrderItemRow from "../../components/Orders/MiniOrderItemRow";
import {
  changeOrderFormToPayloadFormat,
  getActiveRevision,
  getBaseOrderPayloadForTracking,
  getInvoiceNumber,
  verifyAdjustmentOrder,
  verifyCanEdit,
} from "../../utils/order";
import RelatedOrders from "../../components/OrdersV2/RelatedOrders";
import { If } from "../../components/Condition";
import { formatPrice } from "../../utils/formatPrice";
import UpdateQuantityModal from "../../components/Inventory/UpdateQuantityModal";
import { TrackerService } from "../../services/tracker.service";
import { TRACKER_CONSTANTS } from "../../constants/tracker.events";
import { getCountryInfo } from "../../utils/country";
import CancelOrderReasonModal from "../../components/OrdersV2/CancelOrderReasonModal";

dayjs.extend(utc);

export default function EditOrders() {
  const { t } = useTranslation();

  const [orderType, setOrderType] = useState<any>("sales");
  const [currentOrderStatus, setCurrentOrderStatus] = useState<any>("");
  const [invoiceNumber, setInvoiceNumber] = useState<any>("");
  const [isReadOnly, setIsReadOnly] = useState<any>(false);
  const initSuccessModal = {
    open: false,
    title: "Success",
    content: "Order Saved Successfully.",
  };
  const [successModal, setSuccessModal] = useState<any>(initSuccessModal);

  const [autoSaveLoading, setAutoSaveLoading] = useState<any>(null);

  const [confirmationModal, setConfirmationModal] = useState<any>({
    open: false,
    title: "",
    content: "",
    onConfirm: () => {},
    onCancel: () => {},
  });
  const [cancelOrderWithReasonModal, setCancelOrderWithReasonModal] =
    useState<any>({
      open: false,
      onConfirm: () => {},
      onCancel: () => {},
    });

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [isConfimrAndPay, setIsConfirmAndPay] = useState(false);
  const [isBusinessAuthorized, setIsBusinessAuthorized] = useState(false);
  const [searchParam] = useSearchParams();
  /**
   * states and variables required for adjustment order:
   * @isAdjustmentOrder
   * @revisions
   * @revisionId
   * @currentRevision
   */
  const [isAdjustmentOrder, setIsAdjustmentOrder] = useState(false);
  const [revisions, setRevisions] = useState<any[]>([]);
  const [mainOrderCreatedAt, setMainOrderCreatedAt] = useState("");
  const [currentRevision, setCurrentRevision] = useState<
    IOrderRevision | undefined
  >();
  const revisionId = searchParam.get("revision");

  /** states required for quantity rework */
  const [showUpdateQuantityModal, setShowUpdateQuantityModal] = useState(false);
  const [lowStockItems, setLowStockItems] = useState<any[]>([]);

  const enquiryPaymentStatus = async (transactionId: string) => {
    setLoading(true);
    PaymentService.enquiryPaymentStatus(
      transactionId,
      [
        OrderStatusEnum.CONFIRMED,
        OrderStatusEnum.AWAITING_RESPONSE,
        OrderStatusEnum.PROCESSING,
      ].includes(currentOrderStatus) &&
        getValues("paymentFlowType") === PaymentFlowTypeEnum.SPLIT_PAYMENT
        ? PaymentType.INITIAL_PAYMENT
        : PaymentType.FINAL_PAYMENT
    )
      .then((paymentData: any) => {
        setLoading(false);
        setSuccessModal({
          ...successModal,
          open: true,
          content: t("order.createOrder.success.paymentSuccess"),
        });
        setTimeout(() => {
          TrackerService.track(
            TRACKER_CONSTANTS.ORDER.events.PaymentSuccessfullyMade,
            {
              ...getPaymentSuccessfullyMadeEventProperty(),
              paymentMethod: paymentData?.data?.paymentMode,
            }
          );
          setSuccessModal(initSuccessModal);
          window.location.href = window.location.href.split("?")[0];
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        toast(showErrorToast(error), ErrorToastConfig);
      });
  };

  // Listen to payment redirect url
  useEffect(() => {
    const transactionId = searchParam.get("transaction_id");
    if (transactionId && (transactionId?.length ?? 0) > 0) {
      enquiryPaymentStatus(searchParam.get("transaction_id") ?? "");
    }
  }, [searchParam, currentOrderStatus]);

  onMessage(getMessaging(), (payload: any) => {
    console.log("FCM Message received. ", payload);
    if (`${payload.data?.topic}`.includes("payment-success")) {
      setSuccessModal({
        ...successModal,
        open: true,
        content: t("order.createOrder.success.paymentSuccess"),
      });
      setTimeout(() => {
        TrackerService.track(
          TRACKER_CONSTANTS.ORDER.events.PaymentSuccessfullyMade,
          {
            ...getPaymentSuccessfullyMadeEventProperty(),
            paymentMethod: payload?.paymentMode,
          }
        );
        setSuccessModal(initSuccessModal);
      }, 2000);
    }
  });

  const [addNewBusinessModal, setAddNewBusinessModal] = useState<any>({
    open: false,
    companyName: "",
  });

  const [loading, setLoading] = useState<any>(true);
  // const [currencyList, setCurrencyList] = useState<any[]>();
  const [internalAccountList, setInternalAccountList] = useState<any[]>([]);
  const [selectedInternalAccount, setSelectedInternalAccount] = useState<any>(
    {}
  );
  const [externalBusinessList, setExternalBusinessList] = useState<any>([]);
  // const [selectedExternalBusiness, setSelectedExternalBusiness] = useState<any>(
  //   {}
  // );
  const [externalBuyerBusinessCreditInfo, setExternalBuyerBusinessCreditInfo] =
    useState<any>({});
  const [productSearchList, setProductSearchList] = useState<any[]>([]);

  const [nationalTaxes, setNationalTaxes] = useState<any[]>([]);

  // New national taxes by adding "Others" option
  const nationalTaxesWithOthers = nationalTaxes.concat([
    {
      taxLabel: "OTHERS",
      taxAmount: 0,
      taxType: "MANUAL",
    },
  ]);

  //TODO: Felix: Refactfor with useQuery
  // const {data: nationalTaxes} = useQuery({
  //   queryKey: ["national-taxes"],
  //   queryFn: () => TaxService.getBusinessTaxes(businessId || "")
  // });

  const [isSecondPartyNotInSystem, setIsSecondPartyNotInSystem] =
    useState(false);
  const [isCreator, setIsCreator] = useState(false);

  const [sellerCountry, setSellerCountry] = useState<any>("");

  const initialFormValuesRef = useRef({});
  const prevFocusedError = useRef<any>("");
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { session } = useAuthStore();
  const [pdfContent, setPdfContent] = useState<OrderDetailsProps>(
    {} as OrderDetailsProps
  );
  const methods = useForm<IOrderSavePayload>({
    mode: "onChange",
    defaultValues: {
      sellerIds: null,
      buyerIds: null,
      orderItems: [],
      grandTotalAmount: 0,
      totalTax: 0,
      additionalDiscount: 0,
      deliveryFee: 0,
      downPayment: 0,
    },
  });

  const {
    control,
    register,
    setValue,
    getValues,
    trigger,
    reset,
    clearErrors,
    setError,
    formState: {
      // isDirty,
      errors,
    },
  } = methods;

  /** Initial fetch and set Data: Internal Account List */
  useEffect(() => {
    if (session?.activeBusiness) {
      getAndSetInternalAccountList();
      getAndSetExternalBusinessList();
    }
  }, [session?.activeBusiness]);

  useEffect(() => {
    if (orderType === "sales") {
      fetchBuyerCreditInfo();
    }
  }, [getValues("selectedExternalBusiness")]);

  /** Initial fetch and set Data: Order */
  useEffect(() => {
    if (internalAccountList && orderId && session?.account)
      getAndSetInitialOrderData(orderId);
  }, [internalAccountList, orderId, session?.account]);

  async function getAndSetInternalAccountList() {
    const internalAccountList =
      await AccountService.getAccountListByCurrentBusinessInternal(
        session?.activeBusiness.id || null
      );
    if (internalAccountList) {
      console.log("Internal Account List", internalAccountList);
      setInternalAccountList(internalAccountList);
    }
  }

  async function deleteDraftOrder() {
    const isDeleted = await OrderService.deleteOrder(orderId);
    if (isDeleted) {
      navigate(
        BrowserRouterConstants.ClientPrefix + BrowserRouterConstants.Orders
      );
    }
  }

  async function cancelOrder(reason?: string) {
    console.log("To Cancel", orderId);
    setLoading(true);
    const softDeletedTime = await OrderService.cancelOrder(orderId, { reason });
    setLoading(false);
    if (softDeletedTime)
      navigate(
        BrowserRouterConstants.ClientPrefix + BrowserRouterConstants.Orders
      );
  }

  async function updateOrderDataToDB(data: IOrderSavePayload) {
    console.log("To Update Order Data:", data);
    const resData = await OrderService.update(orderId, data);
    if (resData) {
      localStorage.removeItem(`unsavedOrderData_${orderId}`);
    }
    return resData;
  }

  async function updateOrderStatusToDB(status: OrderStatusEnum) {
    console.log("To Update Order Status:", status);
    const resData = await OrderService.updateOrderStatus(orderId, status);
    if (resData) {
      localStorage.removeItem(`unsavedOrderData_${orderId}`);
    }
    return resData;
  }

  async function getAndSetExternalBusinessList() {
    const externalBusinessList = await BusinessService.getExternalBusinessList({
      businessId: session?.activeBusiness.id,
    });
    if (externalBusinessList) {
      console.log("External Business List", externalBusinessList);
      setExternalBusinessList(externalBusinessList);
    }
  }

  async function fetchBuyerCreditInfo() {
    // Check the selectedExternalBusiness is not null to avoid bad request 406
    if (getValues("selectedExternalBusiness")?.id) {
      const businessCreditInfo = await BusinessService.getBuyerCreditInfo({
        buyerBusinessId: getValues("selectedExternalBusiness")?.id,
      });
      if (businessCreditInfo) {
        console.log("External Business List", businessCreditInfo);
        setExternalBuyerBusinessCreditInfo(businessCreditInfo);
      }
    }
  }

  function getAndSetProductSearchList(
    orderT: string,
    sellerId: string,
    buyerId: string | null
  ) {
    /** If sales, only sellerId is necessary to fetch seller's products
     * because the current user is seller */
    if (orderT === "sales" && sellerId) {
      ItemService.getInventoryItemListByBusinessIdAsSeller(sellerId)
        .then((res) =>
          setProductSearchList(formatInventoryItemResponse(res.data))
        )
        .catch((err) => console.error(err));

      /** If purchase, buyerId and sellerId is necessary to fetch seller's products
       * because the current user is buyer and need to check seller's products first */
    } else if (orderT === "purchase" && buyerId && sellerId) {
      ItemService.getInventoryItemListByBusinessIdAsBuyer(buyerId, sellerId)
        .then((res) =>
          setProductSearchList(formatInventoryItemResponse(res.data))
        )
        .catch((err) => console.error(err));
    }
  }

  async function getAndSetNationalTaxes(businessId: any) {
    try {
      const res = await TaxService.getBusinessTaxes(businessId || "");
      console.log("Response Taxes::", res);
      setNationalTaxes(res.map((res: any) => res.tax));
    } catch (error) {
      console.error(error);
    }
  }

  function changeOrderResponseToFormFormat(responseOrderData: any) {
    let formOrderData = {} as IOrderSavePayload;
    formOrderData.createdById = responseOrderData.createdBy;
    formOrderData.createdByBusiness =
      responseOrderData.createdByBusiness || session?.activeBusiness.id;
    formOrderData.invoiceNumber = responseOrderData.invoiceNumber || null;
    if (responseOrderData.seller?.id === session?.activeBusiness.id) {
      /** Current user is seller */
      formOrderData.selectedExternalBusiness = responseOrderData.buyer || null;
      if (
        responseOrderData.additionalTax === null ||
        responseOrderData.additionalTax === undefined
      ) {
        /** If addtionalTax is not user-saved yet, set the default tax setting from seller */
        formOrderData.additionalTaxItem = {
          taxLabel: responseOrderData.seller?.defaultTax?.taxLabel || "NONE",
          taxAmount: responseOrderData.seller?.defaultTax?.taxAmount || 0,
          taxType: responseOrderData.seller?.defaultTax?.taxType || null,
          taxAmountType:
            responseOrderData.seller?.defaultTax?.taxAmountType ||
            TaxAmountTypeEnum.PERCENTAGE,
        };
      } else {
        /** If addtionalTax is already user-saved (even if it's 0), just set the user-saved value */

        formOrderData.additionalTaxItem = {
          taxLabel: responseOrderData.additionalTaxLabel || "NONE",
          taxAmount: responseOrderData.additionalTax || 0,
          taxType: responseOrderData.additionalTaxType || null,
          taxAmountType:
            responseOrderData.additionalTaxAmountType ||
            TaxAmountTypeEnum.PERCENTAGE,
        };

        // formOrderData.additionalTaxLabel =
        //   responseOrderData.additionalTaxLabel || "NONE";

        // formOrderData.additionalTax = responseOrderData.additionalTax || 0;

        // formOrderData.additionalTaxType =
        //   responseOrderData.additionalTaxType || null;
      }
    } else {
      /** Current user is buyer */

      formOrderData.selectedExternalBusiness = responseOrderData.seller || null;
      if (
        responseOrderData.additionalTax === null ||
        responseOrderData.additionalTax === undefined
      ) {
        /** Buyer's addtionalTax will be based on Seller. So, set the NONE tax setting initially */
        formOrderData.additionalTaxItem = {
          // taxLabel: responseOrderData.buyer?.defaultTax?.taxLabel || "NONE",
          // taxAmount: responseOrderData.buyer?.defaultTax?.taxAmount || 0,
          // taxType: responseOrderData.buyer?.defaultTax?.taxType || null,
          taxLabel: "NONE",
          taxAmount: 0,
          taxType: null,
          taxAmountType: TaxAmountTypeEnum.PERCENTAGE,
        };
      } else {
        /** If addtionalTax is already user-saved (even if it's 0), just set the user-saved value */

        formOrderData.additionalTaxItem = {
          taxLabel: responseOrderData.additionalTaxLabel || "NONE",
          taxAmount: responseOrderData.additionalTax || 0,
          taxType: responseOrderData.additionalTaxType || null,
          taxAmountType:
            responseOrderData.additionalTaxAmountType ||
            TaxAmountTypeEnum.PERCENTAGE,
        };
      }
    }

    formOrderData.sellerIds = responseOrderData.seller?.id
      ? {
          businessId: responseOrderData.seller.id,
          accountId: responseOrderData.sellerUser.id,
        }
      : null;

    formOrderData.buyerIds = responseOrderData.buyer?.id
      ? {
          businessId: responseOrderData.buyer?.id,
          accountId: responseOrderData.buyerUser?.id,
        }
      : null;

    formOrderData.paymentFlowType = responseOrderData.paymentFlowType;

    formOrderData.startMonth = responseOrderData.startMonth;
    formOrderData.endMonth = responseOrderData.endMonth;
    formOrderData.recurringDay = responseOrderData.recurringDay;
    formOrderData.recurringParentOrderId =
      responseOrderData.recurringParentOrderId || null;

    formOrderData.paymentTerm = responseOrderData.paymentTerm;
    formOrderData.currency =
      responseOrderData.currency ||
      responseOrderData.seller?.bankInfo?.[0]?.currency ||
      setDefaultCurrency(responseOrderData.seller?.companyCountry);
    formOrderData.deliveryDate = responseOrderData.deliveryDate
      ? dayjs(responseOrderData.deliveryDate).format("YYYY-MM-DD")
      : "";
    formOrderData.shippingMethod = responseOrderData.shippingMethod;
    formOrderData.vehicleNumber = responseOrderData.vehicleNumber;
    formOrderData.trackingNumber = responseOrderData.trackingNumber;
    formOrderData.driverName = responseOrderData.driverName;
    formOrderData.deliveryFee = responseOrderData.deliveryFee || 0;
    formOrderData.additionalDiscount =
      responseOrderData.additionalDiscount || 0;
    formOrderData.additionalDiscountType =
      responseOrderData.additionalDiscountType;
    formOrderData.grandTotalAmount = responseOrderData.grandTotalAmount;
    formOrderData.remarks = responseOrderData.remarks;

    formOrderData.downPayment = responseOrderData.downPayment || 0;
    formOrderData.isFromCatalog = responseOrderData.isFromCatalog;

    /** This is for the newly added Order Items from "Add New" button */
    formOrderData.defaultTaxItem = {
      taxLabel: responseOrderData.seller?.defaultTax?.taxLabel || "NONE",
      taxAmount: responseOrderData.seller?.defaultTax?.Amount || 0,
      taxType: responseOrderData.seller?.defaultTax?.taxType || null,
      taxAmountType:
        responseOrderData.seller?.defaultTax?.taxType ||
        TaxAmountTypeEnum.PERCENTAGE,
    };

    /** One OrderItem, One Product.. (only if there's orderItem data) */
    console.log("Response Order Items :::", responseOrderData.orderItems);
    formOrderData.orderItems = responseOrderData.orderItems?.map(
      (responseOrderItem: any) => {
        let formOrderItem = { ...responseOrderItem } as any;
        formOrderItem.orderItemId = responseOrderItem.id;
        formOrderItem.productId = responseOrderItem.inventoryItem.id;
        formOrderItem.createdById = responseOrderItem.inventoryItem.createdBy;
        formOrderItem.businessId = responseOrderItem.inventoryItem.businessId;
        formOrderItem.selectedItem =
          responseOrderItem.sku ?? responseOrderItem.inventoryItem.sku;
        formOrderItem.sku =
          responseOrderItem.sku ?? responseOrderItem.inventoryItem.sku;
        formOrderItem.productName =
          responseOrderItem.productName ??
          responseOrderItem.inventoryItem.productName;
        formOrderItem.productDescription =
          responseOrderItem.productDescription ??
          responseOrderItem.inventoryItem.productDescription;
        formOrderItem.inventoryType =
          responseOrderItem.inventoryItem.inventoryType;
        formOrderItem.uomList = transformUomList(
          responseOrderItem.inventoryItem
        );
        formOrderItem.uomItem =
          responseOrderItem.uomList?.find(
            (option: any) => option.uom === responseOrderItem.uom
          ) || responseOrderItem.uom;
        formOrderItem.uom = responseOrderItem.uom;

        if (
          responseOrderItem.tax === null ||
          responseOrderItem.tax === undefined
        ) {
          // orderItem.taxLabel = responseOrderData.seller?.defaultTax?.taxLabel;
          // orderItem.tax = responseOrderData.seller?.defaultTax?.taxAmount;
          // orderItem.taxType = responseOrderData.seller?.defaultTax?.taxType || null;

          formOrderItem.taxItem = {
            taxLabel: responseOrderData.seller?.defaultTax?.taxLabel || "NONE",
            taxAmount: responseOrderData.seller?.defaultTax?.taxAmount || 0,
            taxType: responseOrderData.seller?.defaultTax?.taxType || null,
            taxAmountType:
              responseOrderData.seller?.defaultTax?.taxAmountType ||
              TaxAmountTypeEnum.PERCENTAGE,
          };
        } else {
          formOrderItem.taxItem = {
            taxLabel: responseOrderItem.taxLabel || "NONE",
            taxAmount: responseOrderItem.tax || 0,
            taxType: responseOrderItem.taxType || null,
            taxAmountType:
              responseOrderItem.taxAmountType || TaxAmountTypeEnum.PERCENTAGE,
          };
        }

        formOrderItem.conversion = responseOrderItem.conversion;
        return formOrderItem;
      }
    );

    return formOrderData;
  }

  async function getAndSetInitialOrderData(orderId: string | undefined) {
    setLoading(true);

    /** defined tempCurrentRevision to be used directly */
    let tempCurrentRevision = undefined;

    const responseOrderData = await OrderService.getOrder(orderId);
    if (responseOrderData) {
      // TODO: Review this part where i assign current orderType
      // setOrderType(
      //   getOrderType(responseOrderData, session?.activeBusiness?.id ?? "")
      // );
      const verifyResult = await verifyCanEdit(
        responseOrderData,
        session?.activeBusiness.id ?? "",
        session?.activeBusiness.isOwner ?? false,
        session?.account.id ?? ""
      );
      setIsBusinessAuthorized(verifyResult);

      const verifyAdjustment = verifyAdjustmentOrder(responseOrderData);
      setIsAdjustmentOrder(verifyAdjustment);

      const tempOrderType =
        responseOrderData.seller?.id === session?.activeBusiness.id
          ? "sales"
          : "purchase";

      // TODO: Felix i edited this part, please check if the logic is ok
      setCurrentOrderStatus(responseOrderData.status || OrderStatusEnum.DRAFT);
      const formEditableOrderStatuses = [
        OrderStatusEnum.DRAFT,
        OrderStatusEnum.AWAITING_RESPONSE,
      ];
      if (formEditableOrderStatuses.includes(responseOrderData.status)) {
        if (verifyAdjustment) {
          setIsReadOnly(tempOrderType !== "sales");
        } else {
          setIsReadOnly(!verifyResult);
        }
      } else {
        setIsReadOnly(true);
      }

      /**
       * assigning values to states required by Adjustment Order
       */
      setInvoiceNumber(
        getInvoiceNumber(responseOrderData, responseOrderData.orderRevisions) ||
          null
      );
      setRevisions(responseOrderData.orderRevisions);
      setMainOrderCreatedAt(responseOrderData.createdAt);
      tempCurrentRevision = revisionId
        ? responseOrderData.orderRevisions.find(
            (r: IOrderRevision) => r.id === revisionId
          )
        : undefined;
      setCurrentRevision(tempCurrentRevision);

      if (
        ((getValues("createdByBusiness") as any)?.id ??
          getValues("createdByBusiness")) === session?.activeBusiness.id
      ) {
        /** If the order is created by current business, then it is the creator */
        setIsCreator(true);
      } else {
        /** If the order is not created by current business, then it is not editable */
        setIsCreator(false);

        /** If order is adjustment order and orderType is sales, allow edit */
        if (!verifyAdjustment && tempOrderType !== "sales") {
          setIsReadOnly(true);
        }
      }

      if (responseOrderData.seller?.id === session?.activeBusiness.id) {
        /** Current active business is seller */

        setOrderType("sales");
        setSelectedInternalAccount(responseOrderData.sellerUser);

        // responseOrderData.buyer &&
        //   setSelectedExternalBusiness(responseOrderData.buyer);
        getAndSetNationalTaxes(responseOrderData.seller?.id);
        getAndSetProductSearchList("sales", responseOrderData.seller?.id, null);

        /** Check if 2nd party (buyer, in this case) is not in system
         * - "uid" is checked because unregistered user doesn't have uid
         */
        setIsSecondPartyNotInSystem(
          responseOrderData.buyerUser && !responseOrderData.buyerUser.uid
        );
      } else if (responseOrderData.buyer?.id === session?.activeBusiness.id) {
        /** Current active business is buyer */

        setOrderType("purchase");
        setSelectedInternalAccount(responseOrderData.buyerUser);

        // responseOrderData.seller &&
        //   setSelectedExternalBusiness(responseOrderData.seller);

        // TODO: FH: review this part. it should be buyer.id. But waiting decision.
        getAndSetNationalTaxes(responseOrderData.seller?.id);
        getAndSetProductSearchList(
          "purchase",
          responseOrderData.seller?.id,
          responseOrderData.buyer?.id
        );

        /** Check if 2nd party (seller, in this case) is not in system
         * - "uid" is checked because unregistered user doesn't have uid
         */
        setIsSecondPartyNotInSystem(
          responseOrderData.sellerUser && !responseOrderData.sellerUser.uid
        );
      }

      setSellerCountry(responseOrderData.seller?.companyCountry || "");
    }
    console.log("Param Order Id :::", orderId);

    /** Handling Unsaved Data */
    /** if there is revisionId (accessing revision order), then get data from revision */
    let initialOrderData: IOrderSavePayload =
      tempCurrentRevision?.content ||
      changeOrderResponseToFormFormat(responseOrderData);
    initialFormValuesRef.current = initialOrderData;

    if (
      [OrderStatusEnum.DRAFT, OrderStatusEnum.AWAITING_RESPONSE].includes(
        responseOrderData.status as OrderStatusEnum
      ) &&
      !revisionId
    ) {
      /** Take LocalStorage data only when status is DRAFT or AWAITING_RESPONSE */
      const unsavedOrderData = localStorage.getItem(
        `unsavedOrderData_${orderId}`
      );
      if (unsavedOrderData) {
        console.log("UnsavedOrderData :::", JSON.parse(unsavedOrderData));
        console.log(
          "Yes Unsaved:: Watched Values from getAndSetInitialOrderData :::",
          JSON.parse(unsavedOrderData),
          initialFormValuesRef.current
        );
        // TODO: need help to make this part simpler
        /** get inventoryItem id and qty from database */
        const tempOrderItems = responseOrderData.orderItems.map((i: any) => {
          if (i.inventoryItem) {
            return {
              id: i.id,
              quantity: i.inventoryItem.quantity,
            };
          }

          return {
            id: i.id,
            quantity: i.selectedItem.quantity,
          };
        });
        initialOrderData = JSON.parse(unsavedOrderData);

        /** updated initialOrderData orderItems.inventoryItem to use qty from db (latest qty) */
        initialOrderData.orderItems?.forEach((i: any) => {
          const dbOrderItem = tempOrderItems?.find((o: any) => o.id === i.id);
          if (dbOrderItem) {
            if (i.inventoryItem) {
              i.inventoryItem.quantity = dbOrderItem?.quantity;
            } else {
              i.selectedItem.quantity = dbOrderItem?.quantity;
            }
          }
        });
      }
    } else {
      /** If status is not DRAFT or AWAITING_RESPONSE, then remove LocalStorage
       * cos it's not necessary anymore */
      localStorage.removeItem(`unsavedOrderData_${orderId}`);
    }

    console.log("initial Order Data (before reset)", initialOrderData);
    reset(initialOrderData);
    setLoading(false);
  }

  const onSuccessAddContact = (data: any) => {
    console.log("onSuccessAddContact", data);
    if (orderType === "sales") {
      setValue("buyerIds.businessId", data.contactBusiness.id);
      setValue("buyerIds.accountId", data.contactBusinessAccount.id);
    } else if (orderType === "purchase") {
      setValue("sellerIds.businessId", data.contactBusiness.id);
      setValue("sellerIds.accountId", data.contactBusinessAccount.id);
    }
    const externalBusiness = {
      ...data,
      id: data?.contactBusiness,
      role: [
        {
          account: {
            ...data?.contactBusinessAccount,
            profile: {
              fullName: data?.nameOfContact,
            },
          },
        },
      ],
    };
    // setSelectedExternalBusiness(externalBusiness);
    setValue("selectedExternalBusiness", externalBusiness);
  };

  const handleDraftSave = async () => {
    const isValidForDraft = await trigger(["remarks"]); // switch on the form validation manually
    if (!isValidForDraft) return;
    const payload = changeOrderFormToPayloadFormat(getValues());
    payload.status = currentOrderStatus;
    console.log("To Draft Save :::", payload);
    setLoading(true);
    try {
      const resData = await updateOrderDataToDB(payload);
      if (resData) {
        setLoading(false);
        setSuccessModal({
          title: t("commons.successModalTitle"),
          content: t("order.createOrder.success.orderDraftSaved"),
          open: true,
        });
        setTimeout(() => {
          setSuccessModal({ open: false });
          navigate(
            BrowserRouterConstants.ClientPrefix + BrowserRouterConstants.Orders
          );
        }, 1500);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error when Saving Draft: ", error);
      toast(showErrorToast(error), ErrorToastConfig);
    }
  };

  const [showKycModal, setShowKycModal] = useState({
    open: false,
    status: null,
  });

  const isValidReucurringOrder = () => {
    if (
      getValues("paymentFlowType") !== PaymentFlowTypeEnum.RECURRING_PAYMENT
    ) {
      return true;
    }
    var startMonth = 0;
    var endMonth = 0;
    var startDate = getValues("startMonth");
    var endDate = getValues("endMonth");
    if (!startDate || !endDate) {
      return false;
    }

    if (typeof startDate === "object" && (startDate as any).$isDayjsObject) {
      console.log("startDate", startDate);
      startMonth = (startDate as any).$M;
    } else startMonth = new Date(`${startDate}`).getMonth();

    if (typeof endDate === "object" && (endDate as any).$isDayjsObject) {
      console.log("endDate", endDate);
      endMonth = (endDate as any).$M;
    } else endMonth = new Date(`${endDate}`).getMonth();

    console.log(startMonth, endMonth);
    const startDateLTEndDate = startMonth < endMonth;
    if (!startDateLTEndDate) {
      toast("Start month should be less than end month", ErrorToastConfig);
      return false;
    }
    const recurringDayIsNotNull = !!getValues("recurringDay");
    if (!recurringDayIsNotNull) {
      toast("Recurring day is required", ErrorToastConfig);
    }
    return startDateLTEndDate && recurringDayIsNotNull;
  };

  const handleCheckKYCBeforeSendSave = async () => {
    if (
      orderType === "sales" &&
      !BusinessSessionHelper.IsValidatedKYCPaymentGateway() &&
      ["ID", "MY"].includes(
        BusinessSessionHelper.Business()?.companyCountry ?? ""
      )
    ) {
      const kycValidated =
        await KycPaymentGatewayService.checkOrderVerificationStatus();
      if (!kycValidated.valid) {
        setShowKycModal({
          open: true,
          status: kycValidated.status,
        });
      } else {
        handleSendSave();
      }
      return;
    }
    handleSendSave();
  };
  const handleSendSave = async () => {
    const isValidRecurringOrderData = isValidReucurringOrder();
    if (!isValidRecurringOrderData) {
      return;
    }
    const isValidated = await handleValidation();

    if (!isValidated) return;

    console.log("After clicking handle Send Save ::", selectedInternalAccount);

    const isCreditEnough = await checkCreditAmount();
    if (!isCreditEnough) return;

    const payload = changeOrderFormToPayloadFormat(getValues());
    payload.status = OrderStatusEnum.AWAITING_RESPONSE;
    payload.deductedCredit = 1;

    console.log("To Send Save :::", payload);
    setLoading(true);
    try {
      const resData = await updateOrderDataToDB(payload);
      if (resData) {
        setLoading(false);
        setSuccessModal({
          title: "Success",
          content: t("order.createOrder.success.orderSentAndSaved"),
          open: true,
        });

        /** Mixpanel Tracking */
        TrackerService.track(TRACKER_CONSTANTS.ORDER.events.SalesOrderCreated, {
          ...getBaseOrderPayloadForTracking(
            payload,
            selectedInternalAccount,
            orderId,
            orderType,
            session,
            resData
          ),
        });

        setTimeout(() => {
          setSuccessModal({ open: false });
          navigate(
            BrowserRouterConstants.ClientPrefix + BrowserRouterConstants.Orders
          );
        }, 1500);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error while Sending and Saving Order: ", error);
      toast(showErrorToast(error), ErrorToastConfig);
    }
  };

  const handleConfirm = async (onSuccess?: () => void | null) => {
    const isValidated = await handleValidation();

    if (!isValidated) return;

    // const isCreditEnough = await checkCreditAmount();
    // if (!isCreditEnough) return;

    const payload = changeOrderFormToPayloadFormat(getValues());
    if (
      getValues("paymentFlowType") === PaymentFlowTypeEnum.FULL_AFTER_DELIVERY
    ) {
      payload.status = OrderStatusEnum.PROCESSING;
    } else if (
      getValues("paymentFlowType") === PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY
    ) {
      payload.status = isAdjustmentOrder
        ? OrderStatusEnum.PROCESSING
        : OrderStatusEnum.CONFIRMED;
    } else if (
      getValues("paymentFlowType") === PaymentFlowTypeEnum.RECURRING_PAYMENT
    ) {
      payload.status = OrderStatusEnum.PENDING_FINAL_PAYMENT;
    } else {
      payload.status =
        !payload.downPayment ||
        (payload.grandTotalAmount &&
          payload.downPayment >= payload.grandTotalAmount) ||
        isAdjustmentOrder
          ? OrderStatusEnum.PROCESSING
          : OrderStatusEnum.CONFIRMED;
    }
    // payload.deductedCredit = 1;

    console.log("To Confirm :::", payload);
    setLoading(true);
    try {
      const resData = await updateOrderDataToDB(payload);
      if (resData) {
        /** Mixpanel Tracking */
        TrackerService.track(
          TRACKER_CONSTANTS.ORDER.events.ConfirmOrderButtonClicked,
          {
            "User who clicks": orderType === "sales" ? "Seller" : "Buyer",
            "Confirm order type": onSuccess
              ? "Confirm & pay order"
              : "Confirm order",
            ...getBaseOrderPayloadForTracking(
              payload,
              selectedInternalAccount,
              orderId,
              orderType,
              session,
              resData
            ),
          }
        );

        setLoading(false);
        if (onSuccess) onSuccess();
        else {
          setSuccessModal({
            title: t("commons.successModalTitle"),
            content: t("order.createOrder.success.orderConfirmed"),
            open: true,
          });
          setTimeout(() => {
            setSuccessModal({ open: false });
            navigate(
              BrowserRouterConstants.ClientPrefix +
                BrowserRouterConstants.Orders
            );
          }, 1500);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error while Confirming Order: ", error);
      toast(showErrorToast(error), ErrorToastConfig);
    }
  };

  const handleProceedProcess = async () => {
    const payload = getValues();
    payload.status = OrderStatusEnum.PROCESSING;

    console.log("To Proceed Process :::", payload);
    setLoading(true);
    try {
      const resData = await updateOrderDataToDB(payload);
      if (resData) {
        setLoading(false);
        setSuccessModal({
          title: t("commons.successModalTitle"),
          content: t("order.createOrder.success.proceedProcess"),
          open: true,
        });
        setTimeout(() => {
          setSuccessModal({ open: false });
          navigate(
            BrowserRouterConstants.ClientPrefix + BrowserRouterConstants.Orders
          );
        }, 1500);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error while Proceed to Processing Order: ", error);
      toast(showErrorToast(error), ErrorToastConfig);
    }
  };

  const handleRequestPayment = async () => {
    const isValidated = await handleValidation();
    if (!isValidated) return;

    const payload = getValues();
    // payload.status = OrderStatusEnum.PENDING_PAYMENT;
    payload.status = OrderStatusEnum.PENDING_FINAL_PAYMENT;

    setLoading(true);
    try {
      const resData = await updateOrderDataToDB(payload);
      if (resData) {
        /** Mixpanel Tracking */
        TrackerService.track(
          TRACKER_CONSTANTS.ORDER.events.RequestPaymentButtonClicked,
          getBaseOrderPayloadForTracking(
            payload,
            selectedInternalAccount,
            orderId,
            orderType,
            session,
            resData
          )
        );

        setLoading(false);
        setSuccessModal({
          title: t("commons.successModalTitle"),
          content: t("order.createOrder.success.paymentRequested"),
          open: true,
        });
        setTimeout(() => {
          setSuccessModal({ open: false });
          navigate(
            BrowserRouterConstants.ClientPrefix + BrowserRouterConstants.Orders
          );
        }, 1500);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error while Order Payment Request: ", error);
      toast(showErrorToast(error), ErrorToastConfig);
    }
  };

  const handleComplete = async () => {
    const payload = getValues();
    setLoading(true);
    try {
      const resData = await updateOrderStatusToDB(OrderStatusEnum.COMPLETE);
      if (resData) {
        const activeRevision = getActiveRevision(revisions);
        if (activeRevision) {
          activeRevision.content.status = OrderStatusEnum.COMPLETE;
          const updatedRevision = await OrderService.updateAdjustment(
            activeRevision.id,
            activeRevision.content,
            activeRevision.isMain
          );
          if (!updatedRevision) throw new Error("Failed to update revision");
        }

        /** Mixpanel Tracking */
        TrackerService.track(
          TRACKER_CONSTANTS.ORDER.events.CompleteOrderButtonClicked,
          getBaseOrderPayloadForTracking(
            payload,
            selectedInternalAccount,
            orderId,
            orderType,
            session,
            resData
          )
        );

        setLoading(false);
        setSuccessModal({
          title: t("commons.successModalTitle"),
          content: t("order.createOrder.success.completed"),
          open: true,
        });
        setTimeout(() => {
          setSuccessModal({ open: false });
          navigate(
            BrowserRouterConstants.ClientPrefix + BrowserRouterConstants.Orders
          );
        }, 1500);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error while Completing Order: ", error);
      toast(showErrorToast(error), ErrorToastConfig);
    }
  };

  const handleDeleteDraftOrder = () => {
    setConfirmationModal({
      open: true,
      title: "Are you sure you want to delete?",
      content: "This cannot be undone",
      btnConfirmText: "Delete",
      onConfirm: () => deleteDraftOrder(),
      onCancel: () => setConfirmationModal({ open: false }),
    });
  };

  const handleTopUpCredit = async () => {
    const payload = getValues();
    payload.status = currentOrderStatus;
    console.log("To Draft Save :::", payload);
    setLoading(true);
    try {
      const resData = await updateOrderDataToDB(payload);
      if (resData) {
        setLoading(false);
        navigate(
          BrowserRouterConstants.ClientPrefix + BrowserRouterConstants.Credit
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error while Draft Saving Order: ", error);
      toast(showErrorToast(error), ErrorToastConfig);
    }
  };

  const handleCancel = async () => {
    setConfirmationModal({
      open: true,
      title: "Are you sure you want to cancel this order?",
      content: "This cannot be undone.",
      btnConfirmText: "Confirm",
      onConfirm: () => {
        setCancelOrderWithReasonModal({
          open: true,
          onConfirm: (reason: string) => {
            cancelOrder(reason);
          },
          onCancel: () => setCancelOrderWithReasonModal({ open: false }),
        });
      },
      onCancel: () => setConfirmationModal({ open: false }),
    });
  };

  const handleAutoDraftSave = async (data: IOrderSavePayload) => {
    data.status = currentOrderStatus;
    // data.grandTotalAmount = data.grandTotalAmount || 0;
    console.log("To Auto Save Data:", data);
    setAutoSaveLoading(true);
    setTimeout(async () => {
      const resData = await updateOrderDataToDB(data);
      if (resData) {
        setAutoSaveLoading(false);

        console.log("Response Data:", resData);
      }
    }, 2000);
  };

  const handleRecurringSaveChanges = async () => {
    const payload = getValues();
    payload.status = currentOrderStatus;
    console.log("To Recurring Save :::", payload);
    setLoading(true);
    try {
      const resData = await updateOrderDataToDB(payload);
      if (resData) {
        setLoading(false);
        setSuccessModal({
          title: t("commons.successModalTitle"),
          content: t("order.createOrder.success.orderRecurringSaved"),
          open: true,
        });
        setTimeout(() => {
          setSuccessModal({ open: false });
          navigate(
            BrowserRouterConstants.ClientPrefix + BrowserRouterConstants.Orders
          );
        }, 1500);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error when Saving Recurring Order: ", error);
      toast(showErrorToast(error), ErrorToastConfig);
    }
  };

  const [paymentTransactionDetails, setPaymentTransactionDetails] =
    useState<any>(null);
  const [paymentInfo, setPaymentInfo] = useState<any>(null);
  const [showPaymentInfoModal, setShowPaymentInfoModal] = useState(false);
  const [paymentFee, setPaymentFee] = useState<any>(0);

  const getPaymentStatus = (onSuccess?: () => void) => {
    console.log(
      "::PAYMENT::",
      currentOrderStatus,
      getValues("paymentFlowType")
    );
    PaymentService.enquiryPaymentStatus(
      orderId ?? "",
      [
        OrderStatusEnum.CONFIRMED,
        OrderStatusEnum.AWAITING_RESPONSE,
        OrderStatusEnum.PROCESSING,
      ].includes(currentOrderStatus) &&
        getValues("paymentFlowType") === PaymentFlowTypeEnum.SPLIT_PAYMENT
        ? PaymentType.INITIAL_PAYMENT
        : PaymentType.FINAL_PAYMENT
    ).then((response) => {
      setPaymentInfo(response.data.transactionInfo);
      setPaymentTransactionDetails(response.data);
      onSuccess?.();
    });
  };

  const [paymentLink, setPaymentLink] = useState(null);
  const getPaymentLink = () => {
    PaymentLinkService.getPaymentLinkByTransactionID(
      orderId ?? "",
      [
        OrderStatusEnum.CONFIRMED,
        OrderStatusEnum.AWAITING_RESPONSE,
        OrderStatusEnum.PROCESSING,
      ].includes(currentOrderStatus) &&
        getValues("paymentFlowType") === PaymentFlowTypeEnum.SPLIT_PAYMENT
        ? PaymentType.INITIAL_PAYMENT
        : PaymentType.FINAL_PAYMENT
    ).then((data) => {
      setPaymentLink(data);
    });
  };

  useEffect(() => {
    if (
      [
        OrderStatusEnum.CONFIRMED,
        OrderStatusEnum.PENDING_FINAL_PAYMENT,
      ].includes(currentOrderStatus)
    ) {
      getPaymentLink();
    }

    /** Get Payment Status if Order Status is NOT Draft or Awaiting Response */
    if (
      currentOrderStatus &&
      ![OrderStatusEnum.DRAFT, OrderStatusEnum.AWAITING_RESPONSE].includes(
        currentOrderStatus
      )
    ) {
      getPaymentStatus();
    }
  }, [currentOrderStatus]);

  const getPaymentSuccessfullyMadeEventProperty = () => {
    var seller =
      orderType === "purchase"
        ? {
            sellerEmail: getValues("selectedExternalBusiness")?.role?.[0]
              ?.account?.email,
            sellerName: getValues("selectedExternalBusiness")?.role?.[0]
              ?.account?.profile?.fullName,
            sellerCompanyName: getValues("selectedExternalBusiness")
              ?.companyName,
          }
        : {
            sellerEmail: selectedInternalAccount?.email,
            sellerName: selectedInternalAccount?.profile?.fullName,
            sellerCompanyName: session?.activeBusiness?.companyName,
          };
    var buyer =
      orderType === "sales"
        ? {
            buyerEmail: getValues("selectedExternalBusiness")?.role?.[0]
              ?.account?.email,
            buyerName: getValues("selectedExternalBusiness")?.role?.[0]?.account
              ?.profile?.fullName,
            buyerCompanyName: getValues("selectedExternalBusiness")
              ?.companyName,
          }
        : {
            buyerEmail: selectedInternalAccount?.email,
            buyerName: selectedInternalAccount?.profile?.fullName,
            buyerCompanyName: session?.activeBusiness?.companyName,
          };
    console.log("Seller :::", seller);
    console.log("Buyer :::", buyer);
    return {
      paymentMethod: getValues("paymentMethod"),
      orderPayment:
        getValues("paymentFlowType") === PaymentFlowTypeEnum.SPLIT_PAYMENT &&
        getValues("status") === OrderStatusEnum.CONFIRMED
          ? "PARTIAL PAYMENT"
          : "FULL PAYMENT",
      orderCategory: orderType,
      "Type of payment": getValues("paymentFlowType"),
      "Order ID": orderId,
      Currency: getValues("currency"),
      "Grand Total": getValues("grandTotalAmount"),
      totalTax: getValues("totalTax"),
      ...seller,
      ...buyer,
    };
  };

  const handlePaymentContinue = async () => {
    const payload = getValues();
    console.log("To Continue", payload);
    setOpenPaymentModal(false);
    const method = payload.paymentMethod?.split(":")[0];
    const option = payload.paymentMethod?.split(":")[1];
    if (method) {
      var downPayment = getPaymentAmount;
      console.log("Payable Amount", downPayment, getValues("status"));
      setLoading(true);
      PaymentService.requestPayment(
        PaymentService.createPaymentRequestPayload({
          method,
          option: option ?? "",
          transactionType: "order",
          transactionId: orderId,
          amount: downPayment + paymentFee,
          currency: getValues("currency"),
          redirectUrl: window.location.href,
          paymentType:
            [
              OrderStatusEnum.CONFIRMED,
              OrderStatusEnum.AWAITING_RESPONSE,
            ].includes(currentOrderStatus) &&
            getValues("paymentFlowType") === PaymentFlowTypeEnum.SPLIT_PAYMENT
              ? PaymentType.INITIAL_PAYMENT
              : PaymentType.FINAL_PAYMENT,
        })
      )
        .then((response) => {
          if (response) {
            if (getValues("paymentMethod") !== "offline-payment")
              getPaymentStatus(() => {
                setShowPaymentInfoModal(true);
              });
            else {
              TrackerService.track(
                TRACKER_CONSTANTS.ORDER.events.PaymentSuccessfullyMade,
                getPaymentSuccessfullyMadeEventProperty()
              );
              window.location.reload();
            }
          }
        })
        .catch((err) => {
          toast(showErrorToast(err), ErrorToastConfig);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handlePaymentSkip = () => {
    const payload = getValues();
    payload.paymentMethod = null;
    console.log("To Skip", payload);
    updateOrderDataToDB(payload);
    setOpenPaymentModal(false);
  };

  // TODO: adjust order data based on revision
  const handleGeneratePdf = async () => {
    toPDF({});
    // console.log("Generate Pdf clicked:");
    // setLoading(true);
    // try {
    //   const responseData = await OrderService.getOrderPdf(orderId);
    //   const blob = new Blob([responseData], { type: "application/pdf" });
    //   const url = window.URL.createObjectURL(blob);
    //   const a = document.createElement("a");
    //   a.href = url;
    //   a.download = `order-${invoiceNumber}.pdf`;
    //   document.body.appendChild(a);
    //   setLoading(false);
    //   a.click();
    //   window.URL.revokeObjectURL(url);
    // } catch (error) {
    //   setLoading(false);
    //   console.error("Error downloading PDF: ", error);
    // }
  };

  const handleValidation = async () => {
    prevFocusedError.current = "";
    const isValid = await trigger(); // switch on the form validation manually
    if (!isValid) {
      console.log("isValid Errors ::::", errors);
      if (
        (orderType === "sales" && errors.buyerIds) ||
        (orderType === "purchase" && errors.sellerIds)
      ) {
        getValues("newBusiness") && clearErrors("selectedExternalBusiness"); // clear selectedExternalBusiness errors
      }

      /** get and set the lowStockQuantity errors */
      if (errors.orderItems) {
        console.log("BANANA ERRORS", errors);
        validateLowStockQty(getValues("orderItems"));
      }
      return false;
    }
    return true;
  };

  const checkCreditAmount = async () => {
    if (currentOrderStatus !== OrderStatusEnum.DRAFT) return true;

    const currentBusiness = selectedInternalAccount?.role?.find(
      (b: any) => b.business.id === BusinessSessionHelper.Business()?.id
    )?.business;
    const remainingCredits = parseInt(currentBusiness?.credits ?? "0");
    console.log("Remaining Credits", currentBusiness);
    if (remainingCredits <= 0) {
      setConfirmationModal({
        open: true,
        title: "Your credit is low",
        content:
          "You need to top up your credit first before continue this transaction",
        btnConfirmText: "Top up credit",
        onConfirm: () => handleTopUpCredit(),
        onCancel: () => setConfirmationModal({ open: false }),
      });
      return false;
    }
    return true;
  };

  const handleOnChangeInternalAccount = (orderT: any, selectedAcc: any) => {
    setSelectedInternalAccount(selectedAcc);
    if (orderT === "sales") {
      setValue(
        "sellerIds",
        selectedAcc?.id
          ? {
              businessId: session?.activeBusiness.id || null,
              accountId: selectedAcc?.id,
            }
          : null
      );

      setValue(
        "currency",
        selectedAcc?.role?.[0]?.business?.bankInfo?.[0]?.currency ||
          setDefaultCurrency(selectedAcc?.role?.[0]?.business?.companyCountry)
      );
    } else if (orderT === "purchase") {
      setValue(
        "buyerIds",
        selectedAcc?.id
          ? {
              businessId: session?.activeBusiness.id || null,
              accountId: selectedAcc?.id,
            }
          : null
      );
    }
  };

  const handleOnChangeExternalBusiness = (orderT: any, selectedBiz: any) => {
    if (typeof selectedBiz === "string") {
      console.log("Selected External Business [STRING]", selectedBiz);
      /** Timeout to avoid instant validation of the dialog's form. */

      setTimeout(() => {
        setAddNewBusinessModal({
          ...addNewBusinessModal,
          open: true,
        });
      });
    } else if (selectedBiz && selectedBiz.newBusinessInput) {
      /** If the selected one is the adding new business */

      setAddNewBusinessModal({
        ...addNewBusinessModal,
        open: true,
        companyName: selectedBiz.newBusinessInput,
      });
    } else {
      /** If the selected one is the existing business */
      console.log(
        "Selected External Business [NORMAL EXISTING BUSINESS]",
        selectedBiz
      );
      // setSelectedExternalBusiness(selectedBiz);
      setValue("selectedExternalBusiness", selectedBiz);
      if (orderT === "sales") {
        setValue(
          `buyerIds`,
          selectedBiz?.id
            ? {
                businessId: selectedBiz?.id,
                accountId: selectedBiz?.role?.[0].account.id,
              }
            : null
        );
        errors.buyerIds && clearErrors("buyerIds");
        setValue("paymentTerm", selectedBiz?.companyPaymentTerms || "");
      } else if (orderT === "purchase") {
        setValue(
          `sellerIds`,
          selectedBiz?.id
            ? {
                businessId: selectedBiz?.id,
                accountId: selectedBiz?.role?.[0].account.id,
              }
            : null
        );
        errors.sellerIds && clearErrors("sellerIds");

        setSellerCountry(selectedBiz?.companyCountry || "");
        setValue(
          "currency",
          selectedBiz?.bankInfo?.[0]?.currency ||
            setDefaultCurrency(selectedBiz?.companyCountry)
        );
        getAndSetNationalTaxes(selectedBiz.id);

        getAndSetProductSearchList(
          "purchase",
          selectedBiz?.id,
          session?.activeBusiness.id || null
        );
      }
      /** Clear the new business input if existing business is selected */
      setValue("newBusiness", null);
    }
  };

  const handleUpdateCompanyInfo = () => {
    console.log("handleUpdateCompanyInfo :::", getValues());
    console.log(
      "handleUpdateCompanyInfo ::: seller",
      getValues("buyerIds.businessId")
    );
    console.log(
      "handleUpdateCompanyInfo ::: buyer",
      getValues("sellerIds.businessId")
    );
    handleAutoDraftSave(getValues());
    BusinessService.getBusinessById(
      orderType === "sales"
        ? getValues("buyerIds.businessId") ?? ""
        : getValues("sellerIds.businessId") ?? ""
    ).then((res) => {
      console.log("handleUpdateCompanyInfo :::", res);
      navigate(
        BrowserRouterConstants.ClientPrefix +
          "business-contact/update/" +
          `${(getValues("selectedExternalBusiness") as any)?.contactData.id}`,
        {
          state: {
            ...res,
            profileOnly: true,
            backUrl: window.location.href,
            contact: (getValues("selectedExternalBusiness") as any)
              ?.contactData,
          },
        }
      );
    });
  };

  const handleEditNewBusiness = () => {
    // const businessType =
    //   orderType === "sales"
    //     ? "Buyer"
    //     : orderType === "purchase"
    //     ? "Seller"
    //     : "Business";
    setAddNewBusinessModal({
      ...addNewBusinessModal,
      open: true,
    });
  };

  const handleRemoveNewBusiness = () => {
    setAddNewBusinessModal({
      ...addNewBusinessModal,
      businessName: "",
      accountName: "",
      email: "",
    });
    setValue("newBusiness", null);
  };

  /**
   * handle Create Adjustment button click
   * @returns window.location.reload()
   */
  const handleCreateAdjustment = () => {
    if (!orderId) {
      return;
    }

    const payload = getValues();
    payload.status =
      revisions.length > 0
        ? getActiveRevision(revisions)
          ? OrderStatusEnum.CANCELLED
          : currentOrderStatus
        : currentOrderStatus;
    payload.invoiceNumber = getInvoiceNumber(payload, revisions);
    OrderService.createAdjustment(
      orderId,
      payload,
      getActiveRevision(revisions) ? false : true
    ).then(async (res) => {
      if (res) {
        const updatedStatus = await updateOrderStatusToDB(
          OrderStatusEnum.DRAFT
        );

        if (updatedStatus) {
          window.location.reload();
        }
      }
    });
  };

  const handleSendOutStandingReminder = () => {
    setLoading(true);
    OrderService.sendOutStandingOrderReminder(
      getValues("buyerIds.accountId") ?? "",
      getValues("buyerIds.businessId") ?? ""
    )
      .then((_) => {
        toast(
          showSuccessToast("Reminder sent successfully"),
          SuccessToastConfig
        );
      })
      .catch((err) => {
        toast(showErrorToast(err), ErrorToastConfig);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * handle Cancel Adjustment (Delete Draft or Cancel Order) butotn click
   * @returns window.location.reload()
   */
  const handleCancelAdjustment = () => {
    if (!orderId) {
      return;
    }

    const payload = getValues();
    payload.status = OrderStatusEnum.CANCELLED;
    payload.invoiceNumber = invoiceNumber;

    const mainOrder = revisions.find((rev) => rev.isMain);

    if (!mainOrder) {
      return;
    }

    updateOrderDataToDB(mainOrder.content).then((res) => {
      if (res) {
        OrderService.updateAdjustment(mainOrder.id, payload, false).then(
          (res) => {
            if (res) {
              window.location.reload();
            }
          }
        );
      }
    });
  };

  /**
   * validate and set lowStockQty
   * @param orderItems
   * @returns
   */
  const validateLowStockQty = (orderItems?: any[] | null) => {
    if (!orderItems) {
      return;
    }

    const temp = orderItems.filter((i: any) => {
      const inventoryQuantity = i.inventoryItem
        ? i.inventoryItem.quantity
        : i.selectedItem?.quantity || 0;

      const businessId = i.inventoryItem
        ? i.inventoryItem.business
        : i.selectedItem?.business;
      const isOrphan = businessId === "" || !businessId;
      return (
        i.quantity * (i.uomItem.conversion || 1) > inventoryQuantity &&
        !isOrphan
      );
    });

    console.log("BANANA ORDER ITEM", temp);

    temp.forEach((_i, index) => {
      setError(`orderItems.${index}.quantity`, {
        type: "manual",
        message: t("catalog.inventory.updateQty.errorOrder"),
      });
    });
    const toLowStockItems = temp.map((i: any) => {
      if (i.inventoryItem) {
        return {
          ...i.inventoryItem,
          threshold: i.quantity * (i.uomItem.conversion ?? 1),
        };
      }

      return {
        ...i.selectedItem,
        threshold: i.quantity * (i.uomItem.conversion ?? 1),
      };
    });

    setLowStockItems(toLowStockItems);
  };

  const watchedValues = useWatch({ control });

  // useEffect(() => {
  //   if (
  //     isCreator &&
  //     (currentOrderStatus === OrderStatusEnum.DRAFT ||
  //       currentOrderStatus === OrderStatusEnum.AWAITING_RESPONSE)
  //   ) {
  //     const interval = setInterval(() => {
  //       const currentValues: any = watchedValues;

  //       /** Check only if there are changes in order form */
  //       if (
  //         JSON.stringify(currentValues) !==
  //         JSON.stringify(initialFormValuesRef.current)
  //       ) {
  //         handleAutoDraftSave(currentValues);
  //         initialFormValuesRef.current = currentValues;
  //         // handleSubmit(handleAutoDraftSave)();
  //       }
  //     }, 30000); // Autosave every 30 seconds
  //     // }, 3000); // Autosave every 3 seconds (testing purpose)

  //     return () => clearInterval(interval);
  //   }
  // }, [currentOrderStatus, watchedValues]);

  useEffect(() => {
    if (
      isCreator &&
      (currentOrderStatus === OrderStatusEnum.DRAFT ||
        currentOrderStatus === OrderStatusEnum.AWAITING_RESPONSE)
    ) {
      const currentValues: any = watchedValues;

      /** Check only if there are changes in order form */
      if (
        JSON.stringify(currentValues) !==
        JSON.stringify(initialFormValuesRef.current)
      ) {
        console.log(
          "watched Values?: ",
          currentValues,
          initialFormValuesRef.current
        );
        localStorage.setItem(
          `unsavedOrderData_${orderId}`,
          JSON.stringify(watchedValues)
        );
      } else {
        console.log("not gonna watch Values?: ", watchedValues);
        localStorage.removeItem(`unsavedOrderData_${orderId}`);
      }
    }
  }, [watchedValues]);

  // Error validation function to focus on the first field with an error
  useEffect(() => {
    // const firstErrorField = Object.keys(errors)[0];
    const firstErrorField = Object.keys(errors).find(
      (key) => (errors as any)[key]
    );
    console.log(
      "Use Effect Error Current Field ::",
      firstErrorField,
      prevFocusedError.current
    );
    if (
      JSON.stringify(firstErrorField) !==
      JSON.stringify(prevFocusedError.current)
    ) {
      (
        document.querySelector(
          `input[name="${firstErrorField}"]`
        ) as HTMLInputElement | null
      )?.focus();

      prevFocusedError.current = firstErrorField;
    }
  }, [Object.keys(errors)]);

  useEffect(() => {
    if (orderId) {
      async function fetchOrder() {
        try {
          const result = await OrderService.getOrderPDFContent(orderId);
          result.invoiceNumber = getInvoiceNumber(result, result.revisions);
          console.log("PDF Content ::", result);
          setPdfContent(result);
        } catch (error) {
          console.log(error);
        }
      }
      fetchOrder();
    }
  }, [orderId]);

  const getPaymentAmount =
    currentOrderStatus === OrderStatusEnum.PENDING_FINAL_PAYMENT &&
    getValues("paymentFlowType") === PaymentFlowTypeEnum.SPLIT_PAYMENT
      ? (getValues("grandTotalAmount") ?? 0) - (getValues("downPayment") ?? 0)
      : (getValues("downPayment") ?? 0) > 0
      ? getValues("downPayment")
      : getValues("grandTotalAmount");

  const OrderActionButtons = () => {
    switch (currentRevision?.content.status || currentOrderStatus) {
      case OrderStatusEnum.DRAFT:
        /** check if isAdjustmentOrder, if not, show full action buttons, if yes check if it's a purchase order, if not show full action buttons */
        return isAdjustmentOrder ? (
          orderType !== "purchase" ? (
            <>
              {/* <DropdownButton
            element={
              <Button
                endDecorator={<ExpandMoreOutlined />}
                fullWidth
                // onClick={initiateOrderForm}
              >
                {t("commons.submitBtn")}
              </Button>
            }
            options={[
              {
                label: t("order.createOrder.form.submitOrderBtn"),
                action: handleSendSave,
              },
              {
                label: t("order.createOrder.form.saveOrderBtn"),
                action: handleDraftSave,
              },
            ]}
          /> */}
              {lowStockItems.length > 0 ? (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    setShowUpdateQuantityModal(true);
                  }}
                >
                  {t("order.createOrder.updateInventoryBtn")}
                </Button>
              ) : (
                <Button
                  variant="solid"
                  fullWidth
                  onClick={() => handleCheckKYCBeforeSendSave()}
                >
                  {t(
                    isAdjustmentOrder
                      ? "order.createOrder.submitAdjustmentOrderBtn"
                      : "order.createOrder.form.submitOrderBtn"
                  )}
                </Button>
              )}

              <Button
                variant="outlined"
                fullWidth
                onClick={() => handleDraftSave()}
              >
                {t("order.createOrder.form.saveOrderBtn")}
              </Button>

              <Button
                variant="plain"
                fullWidth
                sx={{
                  color: "var(--joy-palette-danger-500)",
                  "&:hover": {
                    backgroundColor: "var(--joy-palette-danger-100)",
                  },
                }}
                onClick={() => {
                  // TODO: if adjustment order, when delete draft, revert the data to latest revision version
                  if (isAdjustmentOrder) {
                    handleCancelAdjustment();
                  } else {
                    handleDeleteDraftOrder();
                  }
                }}
              >
                {t("order.createOrder.deleteDraftBtn")}
              </Button>
            </>
          ) : (
            <></>
          )
        ) : (
          <>
            {/* <DropdownButton
            element={
              <Button
                endDecorator={<ExpandMoreOutlined />}
                fullWidth
                // onClick={initiateOrderForm}
              >
                {t("commons.submitBtn")}
              </Button>
            }
            options={[
              {
                label: t("order.createOrder.form.submitOrderBtn"),
                action: handleSendSave,
              },
              {
                label: t("order.createOrder.form.saveOrderBtn"),
                action: handleDraftSave,
              },
            ]}
          /> */}
            {lowStockItems.length > 0 ? (
              <Button
                variant="outlined"
                type="button"
                fullWidth
                onClick={() => {
                  setShowUpdateQuantityModal(true);
                }}
              >
                {t("order.createOrder.updateInventoryBtn")}
              </Button>
            ) : (
              <Button
                variant="solid"
                fullWidth
                onClick={() => handleCheckKYCBeforeSendSave()}
              >
                {t(
                  isAdjustmentOrder
                    ? "order.createOrder.submitAdjustmentOrderBtn"
                    : "order.createOrder.form.submitOrderBtn"
                )}
              </Button>
            )}
            <Button
              variant="outlined"
              fullWidth
              onClick={() => handleDraftSave()}
            >
              {t("order.createOrder.form.saveOrderBtn")}
            </Button>

            <Button
              variant="plain"
              fullWidth
              sx={{
                color: "var(--joy-palette-danger-500)",
                "&:hover": {
                  backgroundColor: "var(--joy-palette-danger-100)",
                },
              }}
              onClick={() => {
                // TODO: if adjustment order, when delete draft, revert the data to latest revision version
                if (isAdjustmentOrder) {
                  handleCancelAdjustment();
                } else {
                  handleDeleteDraftOrder();
                }
              }}
            >
              {t("order.createOrder.deleteDraftBtn")}
            </Button>
          </>
        );
      case OrderStatusEnum.AWAITING_RESPONSE:
        /** In Awaiting Response state, Seller/ Buyer is not necessary to check.
         * Only need to check First Party (Creator) or Second Party
         */
        if (isCreator) {
          /** First Party (Creator) */

          return isAdjustmentOrder ? (
            isSecondPartyNotInSystem ? (
              <Button
                variant="solid"
                fullWidth
                onClick={() => {
                  handleConfirm();
                }}
              >
                {t("order.createOrder.confirmAdjustmentOrderBtn")}
              </Button>
            ) : (
              <>
                {orderType === "purchase" ? (
                  <Button
                    variant="solid"
                    fullWidth
                    onClick={() => {
                      handleConfirm();
                    }}
                  >
                    {t("order.createOrder.confirmAdjustmentOrderBtn")}
                  </Button>
                ) : (
                  <>
                    {lowStockItems.length > 0 ? (
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                          setShowUpdateQuantityModal(true);
                        }}
                      >
                        {t("order.createOrder.updateInventoryBtn")}
                      </Button>
                    ) : (
                      <Button
                        variant="solid"
                        fullWidth
                        onClick={() => handleCheckKYCBeforeSendSave()}
                      >
                        {t("order.createOrder.resubmitOrderBtn")}
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => handleDraftSave()}
                    >
                      {t("order.createOrder.form.saveOrderBtn")}
                    </Button>

                    <Button
                      variant="plain"
                      fullWidth
                      sx={{
                        color: "var(--joy-palette-primary-500)",
                        "&:hover": {
                          backgroundColor: "var(--joy-palette-primary-100)",
                        },
                      }}
                      onClick={
                        isAdjustmentOrder
                          ? handleCancelAdjustment
                          : handleCancel
                      }
                    >
                      {t("order.createOrder.cancelOrderBtn")}
                    </Button>
                  </>
                )}
              </>
            )
          ) : (
            <>
              {isSecondPartyNotInSystem && (
                <Button
                  variant="solid"
                  fullWidth
                  onClick={() => handleConfirm()}
                >
                  {orderType === "sales"
                    ? t("order.createOrder.acceptForBuyerBtn")
                    : t("order.createOrder.acceptForSellerBtn")}
                </Button>
              )}
              {orderType === "sales" && lowStockItems.length > 0 ? (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    setShowUpdateQuantityModal(true);
                  }}
                >
                  {t("order.createOrder.updateInventoryBtn")}
                </Button>
              ) : (
                <Button
                  variant="solid"
                  fullWidth
                  onClick={() => handleCheckKYCBeforeSendSave()}
                >
                  {t("order.createOrder.resubmitOrderBtn")}
                </Button>
              )}
              <Button
                variant="outlined"
                fullWidth
                onClick={() => handleDraftSave()}
              >
                {t("order.createOrder.form.saveOrderBtn")}
              </Button>

              <Button
                variant="plain"
                fullWidth
                sx={{
                  color: "var(--joy-palette-primary-500)",
                  "&:hover": {
                    backgroundColor: "var(--joy-palette-primary-100)",
                  },
                }}
                onClick={
                  isAdjustmentOrder ? handleCancelAdjustment : handleCancel
                }
              >
                {t("order.createOrder.cancelOrderBtn")}
              </Button>
            </>
          );
        } else {
          /** Second Party */
          if (
            [
              PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY,
              PaymentFlowTypeEnum.SPLIT_PAYMENT,
            ].includes(getValues("paymentFlowType") as PaymentFlowTypeEnum) &&
            currentOrderStatus === OrderStatusEnum.AWAITING_RESPONSE &&
            orderType === "purchase"
          ) {
            /** If Second party is buyer */
            return isAdjustmentOrder ? (
              <Button
                variant="solid"
                fullWidth
                onClick={() => {
                  handleConfirm();
                }}
              >
                {t("order.createOrder.confirmAdjustmentOrderBtn")}
              </Button>
            ) : (
              <Button
                variant="solid"
                fullWidth
                onClick={() => {
                  setIsConfirmAndPay(true);
                  setOpenPaymentModal(true);
                }}
              >
                {t("order.createOrder.confirmPayOrderBtn")}
              </Button>
            );
          } else {
            /** If Second party is seller */
            return isAdjustmentOrder ? (
              <Button
                variant="solid"
                fullWidth
                onClick={() => {
                  handleConfirm();
                }}
              >
                {t("order.createOrder.confirmAdjustmentOrderBtn")}
              </Button>
            ) : lowStockItems.length > 0 ? (
              <Button
                variant="outlined"
                type="button"
                fullWidth
                onClick={() => {
                  setShowUpdateQuantityModal(true);
                }}
              >
                {t("order.createOrder.updateInventoryBtn")}
              </Button>
            ) : (
              <Button variant="solid" fullWidth onClick={() => handleConfirm()}>
                {t("order.createOrder.confirmOrderBtn")}
              </Button>
            );
          }
        }

      case OrderStatusEnum.CONFIRMED:
        let btnConfirmedElements = <></>;
        if (orderType === "purchase") {
          /** If the current user is buyer side (purchase) */
          if (paymentTransactionDetails) {
            // Not Paid (Initial Payment)
            if (Date.now() > Date.parse(paymentInfo.expirationDate)) {
              btnConfirmedElements = (
                <Stack
                  sx={{
                    width: "100%",
                  }}
                  gap={3}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Chip color="danger">
                    {t("order.createOrder.paymentExpired")}
                  </Chip>
                  <Button
                    variant="solid"
                    fullWidth
                    onClick={() => setOpenPaymentModal(true)}
                  >
                    {t("order.createOrder.makePaymentBtn")}
                  </Button>
                </Stack>
              );
            } else if (
              paymentTransactionDetails?.status
                ?.toLowerCase()
                .includes("failed")
            ) {
              btnConfirmedElements = (
                <Stack
                  sx={{
                    width: "100%",
                  }}
                  gap={3}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Chip color="danger">Payment Failed</Chip>
                  <Button
                    variant="solid"
                    fullWidth
                    onClick={() => setOpenPaymentModal(true)}
                  >
                    {t("order.createOrder.makePaymentBtn")}
                  </Button>
                </Stack>
              );
            } else if (!paymentTransactionDetails?.isCompleted) {
              btnConfirmedElements = (
                <Button
                  variant="plain"
                  fullWidth
                  onClick={() => setShowPaymentInfoModal(true)}
                >
                  {t("order.createOrder.viewPaymentInfo")}
                </Button>
              );
            }
            return btnConfirmedElements;
          } else {
            // Paid (Initial Payment)
            btnConfirmedElements = (
              <Button
                variant="solid"
                fullWidth
                onClick={() => setOpenPaymentModal(true)}
              >
                {t("order.createOrder.makePaymentBtn")}
              </Button>
            );
          }
        } else {
          /** If the current user is seller side (sales) */

          btnConfirmedElements = (
            <>
              <Typography>
                {t("order.createOrder.waitingForPayment")}
              </Typography>
              {/* {isSecondPartyNotInSystem && (
                <Button
                  variant="solid"
                  fullWidth
                  onClick={() => handleConfirm()}
                >
                  
                  {t("order.createOrder.acceptForSellerBtn")}
                </Button>
              )} */}
            </>
          );
        }
        return btnConfirmedElements;

      case OrderStatusEnum.PENDING_PAYMENT:
        let btnPendingPaymentElements = <></>;

        if (orderType === "purchase") {
          /** If the current user is buyer side (purchase) */

          if (paymentTransactionDetails) {
            // Not Paid (Initial Payment)
            if (Date.now() > Date.parse(paymentInfo.expirationDate)) {
              btnPendingPaymentElements = (
                <Stack
                  sx={{
                    width: "100%",
                  }}
                  gap={3}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Chip color="danger">
                    {t("order.createOrder.paymentExpired")}
                  </Chip>
                  <Button
                    variant="solid"
                    fullWidth
                    onClick={() => setOpenPaymentModal(true)}
                  >
                    {t("order.createOrder.makePaymentBtn")}
                  </Button>
                </Stack>
              );
            } else if (!paymentTransactionDetails?.isCompleted) {
              btnPendingPaymentElements = (
                <Button
                  variant="plain"
                  fullWidth
                  onClick={() => setShowPaymentInfoModal(true)}
                >
                  {t("order.createOrder.viewPaymentInfo")}
                </Button>
              );
            }
          } else {
            // Paid (Initial Payment)
            btnPendingPaymentElements = (
              <Button
                variant="solid"
                fullWidth
                onClick={() => setOpenPaymentModal(true)}
              >
                {t("order.createOrder.makePaymentBtn")}
              </Button>
            );
          }

          if (isSecondPartyNotInSystem) {
            btnPendingPaymentElements = (
              <>
                {btnPendingPaymentElements}
                <Button
                  variant="plain"
                  fullWidth
                  sx={{
                    color: "var(--joy-palette-primary-500)",
                    "&:hover": {
                      backgroundColor: "var(--joy-palette-primary-100)",
                    },
                  }}
                  onClick={handleCancel}
                >
                  {t("order.createOrder.cancelOrderBtn")}
                </Button>
              </>
            );
          }
        } else {
          /** If the current user is seller side (sales) */

          btnPendingPaymentElements = (
            <>
              <Typography>
                {t("order.createOrder.waitingForPayment")}
              </Typography>

              <Button variant="solid" fullWidth onClick={handleProceedProcess}>
                {t("order.createOrder.proceedProcessBtn")}
              </Button>

              <Button
                variant="plain"
                fullWidth
                sx={{
                  color: "var(--joy-palette-primary-500)",
                  "&:hover": {
                    backgroundColor: "var(--joy-palette-primary-100)",
                  },
                }}
                onClick={handleCancel}
              >
                {t("order.createOrder.cancelOrderBtn")}
              </Button>
            </>
          );
        }

        return btnPendingPaymentElements;

      case OrderStatusEnum.PROCESSING:
        let btnProcessingElements = <></>;
        let paymentMethodElement = <></>;

        console.log("paymentInfo", paymentInfo);

        if (
          getValues("paymentFlowType") ===
          PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY
        ) {
          paymentMethodElement = (
            <Typography>
              Payment has been paid via{" "}
              <Typography sx={{ textTransform: "capitalize" }}>
                {paymentInfo?.paymentMethod?.replaceAll("-", " ")}
              </Typography>
            </Typography>
          );
        } else if (
          getValues("paymentFlowType") === PaymentFlowTypeEnum.SPLIT_PAYMENT
        ) {
          paymentMethodElement = (
            <Typography>
              Initial Payment has been paid via{" "}
              <Typography sx={{ textTransform: "capitalize" }}>
                {paymentInfo?.paymentMethod?.replaceAll("-", " ")}
              </Typography>
            </Typography>
          );
        }

        if (orderType === "sales") {
          /** If the current user is seller side (sales) */
          if (
            getValues("paymentFlowType") !==
            PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY
          ) {
            const activeRevision = getActiveRevision(revisions);
            btnProcessingElements = (
              /** if there is activerevision and downpayment is less than grandtotal, show request payment, otherwise complete */
              <>
                {activeRevision ? (
                  Number(getValues("downPayment")) <
                  Number(getValues("grandTotalAmount")) ? (
                    <Button
                      variant="solid"
                      fullWidth
                      onClick={handleRequestPayment}
                    >
                      {t("order.createOrder.requestPaymentBtn")}
                    </Button>
                  ) : (
                    <Button variant="solid" fullWidth onClick={handleComplete}>
                      {t("order.createOrder.completeOrderBtn")}
                    </Button>
                  )
                ) : (
                  <Button
                    variant="solid"
                    fullWidth
                    onClick={handleRequestPayment}
                  >
                    {t("order.createOrder.requestPaymentBtn")}
                  </Button>
                )}
                {getValues("paymentFlowType") !==
                  PaymentFlowTypeEnum.RECURRING_PAYMENT &&
                  revisions.length < 3 && (
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={handleCreateAdjustment}
                    >
                      {t("order.createOrder.createAdjustmentOrderBtn")}
                    </Button>
                  )}
              </>
              // <Button variant="solid" fullWidth onClick={handleRequestPayment}>
              //   {t("order.createOrder.requestPaymentBtn")}
              // </Button>
            );
          } else {
            btnProcessingElements = (
              <Stack
                sx={{
                  width: "100%",
                }}
                gap={3}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                {!paymentTransactionDetails?.isCompleted && (
                  <Typography>Your payment has been paid upfront</Typography>
                )}
                <Button variant="solid" fullWidth onClick={handleComplete}>
                  {t("order.createOrder.completeOrderBtn")}
                </Button>
                {getValues("paymentFlowType") !==
                  PaymentFlowTypeEnum.RECURRING_PAYMENT &&
                  revisions.length < 3 && (
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={handleCreateAdjustment}
                    >
                      {t("order.createOrder.createAdjustmentOrderBtn")}
                    </Button>
                  )}
              </Stack>
            );
          }
        } else {
          /** If the current user is buyer side (purchase) */
          if (isSecondPartyNotInSystem) {
            /** If the second party (seller) is not in system */
            btnProcessingElements = (
              <>
                <Button
                  variant="solid"
                  fullWidth
                  onClick={handleRequestPayment}
                >
                  {t("order.createOrder.proceedPaymentBtn")}
                </Button>
                <Button
                  variant="plain"
                  sx={{
                    color: "var(--joy-palette-primary-500)",
                    "&:hover": {
                      backgroundColor: "var(--joy-palette-primary-100)",
                    },
                  }}
                  onClick={handleCancel}
                >
                  {t("order.createOrder.cancelOrderBtn")}
                </Button>
              </>
            );
          } else {
            /** If the second party (seller) is in system */
            btnProcessingElements = (
              <Typography>
                {t("order.createOrder.waitingForProcessing")}
              </Typography>
            );
          }
        }
        return (
          <>
            {paymentMethodElement}
            {btnProcessingElements}
          </>
        );

      case OrderStatusEnum.PENDING_FINAL_PAYMENT:
        let btnPendingFinalPaymentElements = <></>;
        if (orderType === "purchase") {
          /** If the current user is buyer side (purchase) */
          const recurringDay = getValues("recurringDay");
          const startMonthYear = dayjs(getValues("startMonth"));
          const today = dayjs();
          const concatDayMonthYear =
            recurringDay && startMonthYear.date(recurringDay);
          // console.log(
          //   "concatDayMonthYear",
          //   today,
          //   getValues("paymentFlowType"), concatDayMonthYear
          // );
          if (
            getValues("paymentFlowType") ===
              PaymentFlowTypeEnum.RECURRING_PAYMENT &&
            today.isBefore(concatDayMonthYear)
          ) {
            btnPendingFinalPaymentElements = (
              <Typography textAlign={"center"}>
                {t("order.createOrder.waitingForRecurringPayment")}.
              </Typography>
            );
          } else {
            if (!(paymentTransactionDetails && paymentInfo)) {
              btnPendingFinalPaymentElements = (
                <Button
                  variant="solid"
                  fullWidth
                  onClick={() => setOpenPaymentModal(true)}
                >
                  {t("order.createOrder.makePaymentBtn")}
                </Button>
              );
            } else if (
              paymentTransactionDetails &&
              Date.now() > Date.parse(paymentInfo.expirationDate) &&
              !paymentTransactionDetails?.isCompleted
            ) {
              btnPendingFinalPaymentElements = (
                <Stack
                  sx={{
                    width: "100%",
                  }}
                  gap={3}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Chip color="danger">
                    {t("order.createOrder.paymentExpired")}
                  </Chip>
                  <Button
                    variant="solid"
                    fullWidth
                    onClick={() => setOpenPaymentModal(true)}
                  >
                    {t("order.createOrder.makePaymentBtn")}
                  </Button>
                </Stack>
              );
            } else if (paymentTransactionDetails?.status.includes("SUCCESS")) {
              btnPendingFinalPaymentElements = !isSecondPartyNotInSystem ? (
                <Typography>
                  {t("order.createOrder.finalPaymentPaid")}
                </Typography>
              ) : (
                <Button variant="solid" fullWidth onClick={handleComplete}>
                  {t("order.createOrder.completeOrderBtn")}
                </Button>
              );
            } else {
              btnPendingFinalPaymentElements = (
                <Button
                  variant="plain"
                  fullWidth
                  onClick={() => setShowPaymentInfoModal(true)}
                >
                  {t("order.createOrder.viewPaymentInfo")}
                </Button>
              );
            }
          }
        } else {
          /** If the current user is seller side (sales) */

          let recurringSaveChangesBtn;
          if (
            getValues("paymentFlowType") ===
            PaymentFlowTypeEnum.RECURRING_PAYMENT
          ) {
            recurringSaveChangesBtn = (
              <Button
                variant="outlined"
                fullWidth
                onClick={handleRecurringSaveChanges}
              >
                {t("order.createOrder.recurringSaveChangesBtn")}
              </Button>
            );
          } else {
            recurringSaveChangesBtn = <></>;
          }

          btnPendingFinalPaymentElements = (
            <>
              {(`${paymentTransactionDetails?.status}`.includes("SUCCESS") && (
                <Typography>
                  {t("order.createOrder.buyerFinalPaymentPaid")}
                </Typography>
              )) || (
                <Typography>
                  {t("order.createOrder.waitingForPayment")}
                </Typography>
              )}
              <Button variant="solid" fullWidth onClick={handleComplete}>
                {t("order.createOrder.completeOrderBtn")}
              </Button>
              {recurringSaveChangesBtn}
              {/* {getValues("paymentFlowType") !==
                PaymentFlowTypeEnum.RECURRING_PAYMENT &&
                revisions.length < 3 && (
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={handleCreateAdjustment}
                  >
                    {t("order.createOrder.createAdjustmentOrderBtn")}
                  </Button>
                )} */}
              <Button
                variant="plain"
                fullWidth
                sx={{
                  color: "var(--joy-palette-primary-500)",
                  "&:hover": {
                    backgroundColor: "var(--joy-palette-primary-100)",
                  },
                }}
                onClick={
                  isAdjustmentOrder ? handleCancelAdjustment : handleCancel
                }
              >
                {t("order.createOrder.cancelOrderBtn")}
              </Button>
            </>
          );
        }
        return btnPendingFinalPaymentElements;

      case OrderStatusEnum.COMPLETE:
        return (
          paymentInfo && (
            <Typography>
              {t("order.createOrder.orderHasBeenPaidVia")}{" "}
              <Typography sx={{ textTransform: "capitalize" }}>
                {paymentInfo?.paymentMethod?.replace("-", " ")}
              </Typography>
            </Typography>
          )
        );

      default:
        return "";
    }
  };

  const { toPDF, targetRef } = usePDF({
    filename: `order-${pdfContent?.invoiceNumber || "00000000-0000000"}.pdf`,
  });

  useEffect(() => {
    console.log("ORDER ITEMS", getValues("orderItems"));
    const currency = getValues("currency");
    const orderItems = getValues("orderItems");
    if ((orderItems?.length ?? 0) > 0) {
      const firstItem = orderItems?.[0] as any;
      if (!currency || currency !== (firstItem?.currency ?? firstItem?.selectedItem?.currency))
        setValue("currency", (firstItem?.currency ?? firstItem?.selectedItem?.currency ?? currency));
    }
  }, [getValues("orderItems")]);

  /** UI Rendering part */
  return (
    <FormProvider {...methods}>
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
      <form>
        <Box
          className="SearchAndFilters-tabletUp"
          sx={{
            borderRadius: "sm",
            py: 2,
            display: "grid",
            gap: 4,
            gridTemplateColumns: { xs: "1fr", md: "1fr 30%" },
          }}
        >
          {/** Main Column */}
          <Box>
            {/** Mobile Order Summary */}
            <Stack
              display={{ xs: "block", md: "none" }}
              spacing={3}
              my={4}
              width={{ xs: "90vw", md: "100%" }}
            >
              {/* Order Summary */}
              <OrderSummary
                orderType={orderType}
                invoiceNumber={
                  currentRevision?.content.invoiceNumber || invoiceNumber
                }
                currentOrderStatus={
                  currentRevision?.content.status || currentOrderStatus
                }
                handleGeneratePdf={handleGeneratePdf}
                isReadOnly={isReadOnly}
                autoSaveLoading={autoSaveLoading}
                paymentLink={paymentLink}
                revisions={revisions}
              />
            </Stack>

            <Stack spacing={4} my={4} width={{ xs: "90vw", md: "100%" }}>
              <Toggler
                defaultExpanded={true}
                renderToggle={({ open, setOpen, sxClasses }) => (
                  <>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      onClick={() => setOpen(!open)}
                      sx={sxClasses}
                    >
                      <Typography
                        startDecorator={
                          <BanknotesIcon width={24} height={24} />
                        }
                        fontWeight={600}
                      >
                        {t("order.createOrder.typeOfPaymentFlow")}
                        <span className={`text-danger-500`}>*</span>
                      </Typography>
                      {open ? (
                        <Remove color="primary" />
                      ) : (
                        <Add color="primary" />
                      )}
                    </Stack>
                  </>
                )}
              >
                <Stack spacing={4}>
                  <Controller
                    name="paymentFlowType"
                    control={control}
                    render={({
                      field: { onChange, onBlur, name, value, ref },
                    }) => {
                      return (
                        <FormControl ref={ref}>
                          <FormLabel>
                            {t("order.createOrder.form.paymentFlowType")}
                          </FormLabel>
                          <Autocomplete
                            name={name}
                            freeSolo={true}
                            placeholder={t(
                              "order.createOrder.form.paymentFlowType"
                            )}
                            options={paymentFlowTypes}
                            getOptionLabel={(option) =>
                              typeof option !== "string" ? option?.label : ""
                            }
                            onChange={(_event, newValue) => {
                              if (typeof newValue !== "string") {
                                if (
                                  newValue &&
                                  newValue.value !==
                                    PaymentFlowTypeEnum.SPLIT_PAYMENT
                                ) {
                                  setValue("downPayment", 0);
                                }
                                if (
                                  newValue &&
                                  newValue.value ===
                                    PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY
                                ) {
                                  setValue("paymentTerm", null);
                                }
                                if (
                                  newValue &&
                                  newValue.value !==
                                    PaymentFlowTypeEnum.RECURRING_PAYMENT
                                ) {
                                  setValue("startMonth", null);
                                  setValue("endMonth", null);
                                  setValue("recurringDay", null);
                                }

                                onChange(
                                  newValue?.value as
                                    | keyof typeof PaymentFlowTypeEnum
                                    | null
                                ); // set the Payment Flow Type's value only when the option changes
                              }
                            }}
                            value={
                              paymentFlowTypes.find(
                                (pft) => pft.value === value
                              ) || null
                            }
                            onBlur={onBlur}
                            disabled={isReadOnly || isAdjustmentOrder}
                            sx={{ width: { xs: "100%", lg: "100%" } }}
                            renderOption={(props, option) => (
                              <AutocompleteOption
                                {...props}
                                key={option.value}
                                sx={{
                                  "&:hover": {
                                    backgroundColor:
                                      "var(--joy-palette-neutral-100)",
                                  },
                                }}
                              >
                                <Stack
                                  direction={"row"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  flex={1}
                                >
                                  <Stack>
                                    {option.label}
                                    <Typography>
                                      {option.description}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </AutocompleteOption>
                            )}
                          />
                        </FormControl>
                      );
                    }}
                  />

                  {getValues("paymentFlowType") ===
                    PaymentFlowTypeEnum.RECURRING_PAYMENT && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        spacing={2}
                      >
                        <FormControl sx={{ flexGrow: 1 }}>
                          <FormLabel>
                            {t("order.createOrder.form.startMonth")}
                          </FormLabel>

                          <Controller
                            name="startMonth"
                            control={control}
                            render={({
                              field: { name, value, ref, onChange },
                            }) => (
                              <DesktopDatePicker
                                views={["year", "month"]}
                                slots={
                                  {
                                    // calendarHeader: CustomDatePickerHeader,
                                  }
                                }
                                // openTo=
                                minDate={dayjs(new Date()).utc(true)}
                                slotProps={{
                                  popper: {
                                    sx: {
                                      "& .MuiDateCalendar-root": {
                                        height: "auto !important",
                                      },
                                    },
                                  },
                                  yearButton: {
                                    sx: {
                                      width: "100%",
                                      height: "100%",
                                      borderRadius: 0,
                                      border:
                                        "1px solid var(--joy-palette-neutral-200)",
                                      "&:hover, &:focus, &.Mui-selected": {
                                        backgroundColor:
                                          "var(--joy-palette-primary-500) !important",
                                        color:
                                          "var(--joy-palette-common-white)",
                                      },
                                      "&.Mui-disabled": {
                                        background:
                                          "var(--joy-palette-neutral-100)",
                                        color: "var(--joy-palette-neutral-400)",
                                      },
                                    },
                                  },
                                  monthButton: {
                                    sx: {
                                      width: "100%",
                                      height: "100%",
                                      borderRadius: 0,
                                      border:
                                        "1px solid var(--joy-palette-neutral-200)",
                                      "&:hover, &:focus, &.Mui-selected": {
                                        backgroundColor:
                                          "var(--joy-palette-primary-500) !important",
                                        color:
                                          "var(--joy-palette-common-white)",
                                      },
                                      "&.Mui-disabled": {
                                        background:
                                          "var(--joy-palette-neutral-100)",
                                        color: "var(--joy-palette-neutral-400)",
                                      },
                                    },
                                  },
                                }}
                                disabled={isReadOnly}
                                name={name}
                                value={dayjs(value).utc(true)}
                                onChange={onChange}
                                inputRef={ref}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl sx={{ flexGrow: 1 }}>
                          <FormLabel>
                            {t("order.createOrder.form.endMonth")}
                          </FormLabel>
                          <Controller
                            name="endMonth"
                            control={control}
                            render={({
                              field: { name, value, ref, onChange },
                            }) => (
                              <DesktopDatePicker
                                views={["year", "month"]}
                                minDate={dayjs(
                                  getValues("startMonth") || new Date()
                                ).utc(true)}
                                slotProps={{
                                  yearButton: {
                                    sx: {
                                      width: "100%",
                                      height: "100%",
                                      borderRadius: 0,
                                      border:
                                        "1px solid var(--joy-palette-neutral-200)",
                                      "&:hover, &:focus, &.Mui-selected": {
                                        backgroundColor:
                                          "var(--joy-palette-primary-500) !important",
                                        color:
                                          "var(--joy-palette-common-white)",
                                      },
                                      "&.Mui-disabled": {
                                        background:
                                          "var(--joy-palette-neutral-100)",
                                        color: "var(--joy-palette-neutral-400)",
                                      },
                                    },
                                  },
                                  monthButton: {
                                    sx: {
                                      width: "100%",
                                      height: "100%",
                                      borderRadius: 0,
                                      border:
                                        "1px solid var(--joy-palette-neutral-200)",
                                      "&:hover, &:focus, &.Mui-selected": {
                                        backgroundColor:
                                          "var(--joy-palette-primary-500) !important",
                                        color:
                                          "var(--joy-palette-common-white)",
                                      },
                                      "&.Mui-disabled": {
                                        background:
                                          "var(--joy-palette-neutral-100)",
                                        color: "var(--joy-palette-neutral-400)",
                                      },
                                    },
                                  },
                                }}
                                disabled={
                                  isReadOnly &&
                                  (orderType !== "sales" ||
                                    currentOrderStatus ===
                                      OrderStatusEnum.COMPLETE)
                                }
                                name={name}
                                value={dayjs(value)}
                                onChange={onChange}
                                inputRef={ref}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl sx={{ flexGrow: 1 }}>
                          <FormLabel>
                            {t("order.createOrder.form.recurringDay")}
                          </FormLabel>
                          <Controller
                            name="recurringDay"
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                              <TwentyEightDaysPicker
                                name={name}
                                value={value}
                                onChange={onChange}
                                disabled={
                                  isReadOnly &&
                                  (orderType !== "sales" ||
                                    currentOrderStatus ===
                                      OrderStatusEnum.COMPLETE)
                                }
                              />
                            )}
                          />
                        </FormControl>
                      </Stack>
                    </LocalizationProvider>
                  )}

                  {getValues("paymentFlowType") !==
                    PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY && (
                    <FormControl>
                      <FormLabel>
                        {t("order.createOrder.form.payment")}
                      </FormLabel>
                      <Input
                        placeholder={t("order.createOrder.form.payment")}
                        {...register("paymentTerm")}
                        disabled={isReadOnly || isAdjustmentOrder}
                      />
                    </FormControl>
                  )}
                </Stack>
              </Toggler>
            </Stack>

            <Divider />

            {/** //TODO: this is the condition based on current orderType */}
            <Stack spacing={4} my={4}>
              {orderType === "sales" ? (
                <>
                  <SalesOrderForm
                    internalAccountList={internalAccountList}
                    selectedInternalAccount={selectedInternalAccount}
                    externalBusinessList={externalBusinessList}
                    selectedExternalBusiness={getValues(
                      "selectedExternalBusiness"
                    )}
                    handleOnChangeInternalAccount={
                      handleOnChangeInternalAccount
                    }
                    handleOnChangeExternalBusiness={
                      handleOnChangeExternalBusiness
                    }
                    handleEditNewBusiness={handleEditNewBusiness}
                    handleRemoveNewBusiness={handleRemoveNewBusiness}
                    handleUpdateCompanyInfo={handleUpdateCompanyInfo}
                    isReadOnly={isReadOnly}
                    isCreator={isCreator}
                    isAdjustmentOrder={isAdjustmentOrder}
                    orderStatus={currentOrderStatus}
                  />

                  <Stack>
                    <Toggler
                      renderToggle={({ open, setOpen, sxClasses }) => (
                        <>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            onClick={() => setOpen(!open)}
                            sx={sxClasses}
                          >
                            <Typography
                              startDecorator={<AccountCircleOutlined />}
                              fontWeight={600}
                            >
                              {t("order.createOrder.buyerCredit")}
                              <span className={`text-danger-500`}>*</span>
                            </Typography>
                            {open ? (
                              <Remove color="primary" />
                            ) : (
                              <Add color="primary" />
                            )}
                          </Stack>
                        </>
                      )}
                    >
                      {getValues("selectedExternalBusiness") ? (
                        <Stack spacing={4}>
                          <Grid
                            container
                            spacing={1}
                            pt={2}
                            width={{ xs: "100%", lg: "70%" }}
                          >
                            <Grid xs={5}>
                              <Typography
                                flex={1}
                                fontSize={{ xs: 14, lg: 16 }}
                              >
                                {t("order.createOrder.form.companyCreditLimit")}{" "}
                                :{" "}
                              </Typography>
                            </Grid>
                            <Grid xs={6}>
                              <Typography
                                flex={1}
                                fontSize={{ xs: 14, lg: 16 }}
                              >
                                {getValues("currency")}{" "}
                                {formatPrice(
                                  externalBuyerBusinessCreditInfo?.creditLimit ||
                                    0,
                                  getValues("currency") as string | undefined
                                )}
                              </Typography>
                            </Grid>

                            <Grid xs={5}>
                              <Typography
                                flex={1}
                                fontSize={{ xs: 14, lg: 16 }}
                              >
                                {t("order.createOrder.form.outstandingPayment")}{" "}
                                :{" "}
                              </Typography>
                            </Grid>
                            <Grid xs={6}>
                              <Typography
                                flex={1}
                                fontSize={{ xs: 14, lg: 16 }}
                              >
                                {getValues("currency")}{" "}
                                {formatPrice(
                                  externalBuyerBusinessCreditInfo?.outstandingPayment ||
                                    0,
                                  getValues("currency") as string | undefined
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          {!!externalBuyerBusinessCreditInfo?.outstandingPayment && (
                            <>
                              <Stack>
                                <Typography fontWeight={600}>
                                  {t("order.createOrder.form.outstandingOrder")}
                                </Typography>
                                {/* That will be loop items */}
                                {externalBuyerBusinessCreditInfo?.outstandingOrders?.map(
                                  (order: any) => (
                                    <MiniOrderItemRow
                                      orderType={orderType}
                                      orderNo={order.invoiceNumber}
                                      orderDate={dayjs(order.updatedAt).format(
                                        "DD MMM YYYY"
                                      )}
                                      orderStatus={order.status}
                                      onClick={() => {
                                        navigate(
                                          BrowserRouterConstants.ClientPrefix +
                                            BrowserRouterConstants.Orders +
                                            "/" +
                                            order.id,
                                          { replace: true }
                                        );
                                      }}
                                    />
                                  )
                                )}
                              </Stack>
                              <Button
                                variant="outlined"
                                sx={{ alignSelf: "start" }}
                                startDecorator={
                                  <BellIcon width={18} height={18} />
                                }
                                onClick={handleSendOutStandingReminder}
                              >
                                {t("order.createOrder.form.sendReminder")}
                              </Button>
                            </>
                          )}
                        </Stack>
                      ) : (
                        <Stack>
                          <Box
                            sx={{
                              backgroundColor: "#F2F4F7",
                              borderRadius: "8px",
                              padding: "32px",
                              marginTop: "24px",
                            }}
                          >
                            <Typography
                              level="h4"
                              sx={{
                                color: "#667085",
                              }}
                            >
                              No buyer selected
                            </Typography>
                            <Typography
                              sx={{
                                color: "#667085",
                              }}
                            >
                              Choose a buyer to display this information.
                            </Typography>
                          </Box>
                        </Stack>
                      )}
                    </Toggler>
                  </Stack>
                  <Divider />
                </>
              ) : (
                <PurchaseOrderForm
                  internalAccountList={internalAccountList}
                  externalBusinessList={externalBusinessList}
                  selectedInternalAccount={selectedInternalAccount}
                  selectedExternalBusiness={getValues(
                    "selectedExternalBusiness"
                  )}
                  handleOnChangeInternalAccount={handleOnChangeInternalAccount}
                  handleOnChangeExternalBusiness={
                    handleOnChangeExternalBusiness
                  }
                  handleEditNewBusiness={handleEditNewBusiness}
                  handleRemoveNewBusiness={handleRemoveNewBusiness}
                  handleUpdateCompanyInfo={handleUpdateCompanyInfo}
                  isReadOnly={isReadOnly}
                  isAdjustmentOrder={isAdjustmentOrder}
                />
              )}
            </Stack>

            {/* Section - Related Orders */}
            <If condition={revisions.length > 0}>
              <Stack spacing={4} mt={4} width={{ xs: "90vw", md: "100%" }}>
                <RelatedOrders
                  revisions={revisions}
                  orderType={orderType}
                  invoiceNumber={invoiceNumber}
                  currentOrderStatus={currentOrderStatus}
                  mainOrderCreatedAt={mainOrderCreatedAt}
                  currentRevision={currentRevision}
                />

                <Divider />
              </Stack>
            </If>

            <Stack spacing={4} mt={4} width={{ xs: "90vw", md: "100%" }}>
              {/** Section - Add Product & Service */}
              <ProductAndService
                nationalTaxes={nationalTaxesWithOthers}
                productSearchList={productSearchList}
                isReadOnly={isReadOnly}
                isBusinessAuthorized={isBusinessAuthorized}
                orderType={orderType}
                sellerCountry={sellerCountry}
                isAdjustmentOrder={isAdjustmentOrder}
                currentRevision={currentRevision}
                lowStockItems={lowStockItems}
                updateItemCallback={(items: any[]) => {
                  validateLowStockQty(items);
                }}
                revisions={revisions}
              />

              <Divider />
            </Stack>

            {/* Section - Delivery Info */}
            <Stack spacing={4} mt={4} width={{ xs: "90vw", md: "100%" }}>
              <Toggler
                renderToggle={({ open, setOpen, sxClasses }) => (
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    onClick={() => setOpen(!open)}
                    sx={sxClasses}
                  >
                    <Typography
                      startDecorator={<TruckIcon width={24} height={24} />}
                      fontWeight={600}
                    >
                      {t("order.createOrder.deliveryForm.addDeliveryInfo")}
                    </Typography>
                    {open ? (
                      <Remove color="primary" />
                    ) : (
                      <Add color="primary" />
                    )}
                  </Stack>
                )}
              >
                <Grid container spacing={2} mt={1}>
                  <Grid xs={12}>
                    <FormControl>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.driverName")}
                      </FormLabel>
                      <Input
                        placeholder={t(
                          "order.createOrder.deliveryForm.driverName"
                        )}
                        defaultValue={""}
                        {...register("driverName")}
                        disabled={isReadOnly || isAdjustmentOrder}
                      />
                    </FormControl>
                  </Grid>

                  <Grid xs={12} lg={6}>
                    <FormControl sx={{ flex: "1" }}>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.deliveryDate")}
                      </FormLabel>
                      <Input
                        type="date"
                        aria-label="Date"
                        {...register("deliveryDate")}
                        error={!!errors.deliveryDate}
                        disabled={isReadOnly || isAdjustmentOrder}
                      />
                    </FormControl>
                  </Grid>

                  <Grid xs={12} lg={6}>
                    <FormControl sx={{ flex: "1" }}>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.shippingMethod")}
                      </FormLabel>
                      <Input
                        placeholder={t(
                          "order.createOrder.deliveryForm.shippingMethod"
                        )}
                        defaultValue={""}
                        {...register("shippingMethod")}
                        disabled={isReadOnly || isAdjustmentOrder}
                      />
                    </FormControl>
                  </Grid>

                  <Grid xs={12} lg={6}>
                    <FormControl sx={{ flex: "1" }}>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.vehicleNumber")}
                      </FormLabel>
                      <Input
                        placeholder={t(
                          "order.createOrder.deliveryForm.vehicleNumber"
                        )}
                        defaultValue={""}
                        {...register("vehicleNumber")}
                        disabled={isReadOnly || isAdjustmentOrder}
                      />
                    </FormControl>
                  </Grid>

                  <Grid xs={12} lg={6}>
                    <FormControl sx={{ flex: "1" }}>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.trackingNumber")}
                      </FormLabel>
                      <Input
                        placeholder={t(
                          "order.createOrder.deliveryForm.trackingNumber"
                        )}
                        defaultValue={""}
                        {...register("trackingNumber")}
                        disabled={isReadOnly || isAdjustmentOrder}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Toggler>
              <Divider />

              {/** Part - Remarks */}
              <Toggler
                renderToggle={({ open, setOpen, sxClasses }) => (
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    onClick={() => setOpen(!open)}
                    sx={sxClasses}
                  >
                    <Typography
                      startDecorator={<NewspaperIcon width={24} height={24} />}
                      fontWeight={600}
                    >
                      {t("order.createOrder.summary.remarksLabel")}
                    </Typography>
                    {open ? (
                      <Remove color="primary" />
                    ) : (
                      <Add color="primary" />
                    )}
                  </Stack>
                )}
              >
                <Stack rowGap={4}>
                  <Controller
                    name="remarks"
                    control={control}
                    defaultValue={""}
                    rules={{
                      maxLength: {
                        value: 255,
                        message: t(
                          "order.createOrder.error.remarksMaxLength255"
                        ),
                      },
                    }}
                    render={({
                      field: { name, ref, onChange, onBlur, value },
                      fieldState: { error },
                    }) => (
                      <FormControl error={!!error} ref={ref}>
                        <FormLabel>
                          {t("order.createOrder.summary.remarksLabel")}
                        </FormLabel>
                        <Textarea
                          placeholder={t(
                            "order.createOrder.summary.remarksPlaceholder"
                          )}
                          minRows={4}
                          name={name}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          onBlur={onBlur}
                          value={value ?? ""}
                          disabled={isReadOnly || (revisionId && true)}
                          // slotProps={{
                          //   textarea: { maxLength: 255 },
                          // }}
                        />
                        <FormHelperText>{error?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Stack>
              </Toggler>
              <Divider />

              {/** Part - Grand Total Amount */}
              <GrandTotal
                sellerCountry={sellerCountry}
                nationalTaxes={nationalTaxesWithOthers}
                isReadOnly={isReadOnly}
                isAdjustmentOrder={isAdjustmentOrder}
              />
            </Stack>

            {/** Part - Action Buttons */}
            {/* Mobile only */}
            <Stack
              direction={"column"}
              alignItems={"center"}
              gap={2}
              mt={4}
              width={{ xs: "90vw", md: "100%" }}
              display={{ xs: "flex", md: "none" }}
            >
              <OrderActionButtons />
            </Stack>
          </Box>

          {/** Right Sidebar Column - Laptop & Desktop */}
          <Box
            alignSelf={"start"}
            position={"sticky"}
            top={24}
            display={{ xs: "none", md: "grid" }}
            justifyContent={"stretch"}
            alignContent={"start"}
            gap={3}
          >
            {/** Order Summary */}
            <OrderSummary
              orderType={orderType}
              invoiceNumber={
                currentRevision?.content.invoiceNumber || invoiceNumber
              }
              currentOrderStatus={
                currentRevision?.content.status || currentOrderStatus
              }
              handleGeneratePdf={handleGeneratePdf}
              autoSaveLoading={autoSaveLoading}
              paymentLink={paymentLink}
              revisions={revisions}
            />

            {/** Part - Sidebar Action Buttons - Laptop & Desktop */}
            <Stack direction={"column"} alignItems={"center"} gap={2}>
              <OrderActionButtons />
            </Stack>
          </Box>
        </Box>

        {/** Modals */}
        {/* <AddNewBusinessModal
          modalData={addNewBusinessModal}
          setModalData={setAddNewBusinessModal}
          handleCancel={handleCancelAddNewBusinessModal}
          handleSubmit={handleSubmitAddNewBusinessModal}
        /> */}
        <AddCompanyContactModal
          state={addNewBusinessModal}
          handleOpen={() => setAddNewBusinessModal(true)}
          handleClose={() => setAddNewBusinessModal(false)}
          onSuccessAdd={onSuccessAddContact}
        />
        {getValues("currency") && (
          <PaymentModal
            open={openPaymentModal}
            setOpenPaymentModal={setOpenPaymentModal}
            handleContinue={() => {
              if (isConfimrAndPay) {
                handleConfirm(() => {
                  handlePaymentContinue();
                });
              } else {
                handlePaymentContinue();
              }
            }}
            handleSkip={handlePaymentSkip}
            control={control}
            setValue={setValue}
            onlinePaymentOnly={false}
            paymentAmount={getPaymentAmount}
            paymentCurrency={getValues("currency") ?? ""}
            handleChangePaymentFee={setPaymentFee}
            applicableAdminFee={
              (getValues("selectedExternalBusiness") as any)?.applicableAdminFee
            }
            sellerId={
              isSecondPartyNotInSystem
                ? null
                : getValues("sellerIds.businessId")
            }
            sellerCurrency={
              getCountryInfo(
                getValues("selectedExternalBusiness")?.companyCountry ?? "ID",
                "LOCALE"
              )?.currency
            }
          />
        )}
        <ConfirmationModal
          title={confirmationModal.title}
          content={confirmationModal.content}
          open={confirmationModal.open}
          btnConfirmText={confirmationModal.btnConfirmText}
          onCancel={confirmationModal.onCancel}
          onConfirm={confirmationModal.onConfirm}
        />
        <CancelOrderReasonModal
          show={cancelOrderWithReasonModal.open}
          onConfirm={cancelOrderWithReasonModal.onConfirm}
          onClose={cancelOrderWithReasonModal.onCancel}
        />
        {paymentTransactionDetails && paymentInfo && (
          <PaymentInfoModal
            open={showPaymentInfoModal}
            setOpenModal={setShowPaymentInfoModal}
            paymentInfo={paymentInfo}
            status={paymentTransactionDetails?.status}
            onFinish={() => window.location.reload()}
            currency={getValues("currency") ?? ""}
          />
        )}
        <SuccessModal
          open={successModal.open}
          title={successModal.title}
          content={successModal.content}
        />
        <KYCModal
          status={showKycModal.status}
          open={showKycModal.open}
          onClose={() => {
            setShowKycModal({ open: false, status: null });
            handleSendSave();
          }}
        />
        {lowStockItems.length > 0 && (
          <UpdateQuantityModal
            show={showUpdateQuantityModal}
            onClose={() => {
              setShowUpdateQuantityModal(false);
            }}
            item={lowStockItems}
          />
        )}
        <LoadingModal isLoading={loading} />
      </form>
      <div className="h-0 overflow-hidden w-[800px]">
        <div ref={targetRef}>
          <OrderDetailsPdf {...(pdfContent as OrderDetailsProps)} />
        </div>
      </div>
    </FormProvider>
  );
}
