import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
} from "@mui/joy";
import { t } from "i18next";
import startsWith from "lodash.startswith";
import PhoneInput from "react-phone-input-2";
import { SuccessModal } from "../../components/SuccessModal";
import Shared from "../../layouts/Shared";
import {
  CountryCode,
  PhNoFormat,
  PhNoWhiteListedCountries,
} from "../../utils/constants";
import { matchesFormat } from "../../utils/misc";
import useContainer from "./useContainer";

export default function PhoneInputPage() {
  const {
    showModal,
    mobile,
    errors,
    phoneNo,
    setError,
    setValue,
    isLoading,
    isValid,
    clearErrors,
    onSubmit,
    handleSubmit,
    trigger,
  } = useContainer();

  return (
    <Shared
      minWidth={"329px"}
      width={{
        md: "464px",
      }}
      showNavArrow={false}
      sxProps={{ backgroundColor: "transparent" }}
    >
      <div className="flex flex-col items-center p-4 bg-white md:max-w[464px] max-w-[393px] mx-auto">
        <div className="w-full space-y-8">
          {/* Main content */}
          <div className="flex flex-col items-center w-full gap-y-6">
            <h1 className="text-lg font-semibold tracking-tight text-[#2D5F49]">
              Enter Your Mobile Number
            </h1>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col w-full gap-y-10"
            >
              <p className="text-base text-center text-[#101828] font-semibold">
                Please enter your correct phone number
                <br />
                to receive the OTP for verification
              </p>
              <div className="flex w-full gap-2">
                <FormControl
                  error={errors.mobile ? true : false}
                  className="w-full"
                >
                  <FormLabel>
                    <Typography
                      fontSize={"sm"}
                      fontWeight={500}
                      className={`transition-opacity duration-300 ${
                        mobile ? "opacity-100 h-auto" : "opacity-0 h-0"
                      }`}
                      textColor={"text.secondary"}
                    >
                      {t("landing.signup.phoneNumber")}
                    </Typography>
                  </FormLabel>
                  <PhoneInput
                    inputProps={{
                      name: "input-phone",
                    }}
                    value={phoneNo}
                    onlyCountries={PhNoWhiteListedCountries}
                    masks={{
                      my: "..-....-....",
                      sg: "....-....",
                      th: "... ... ...",
                      id: "... ... ... ...",
                    }}
                    enableTerritories={false}
                    isValid={(inputNumber, meta: any, countries) => {
                      if (inputNumber === "") {
                        return true;
                      }
                      const result = (countries || []).some((country: any) => {
                        if (
                          startsWith(inputNumber, country.dialCode) ||
                          startsWith(country.dialCode, inputNumber)
                        ) {
                          const iso2: CountryCode = meta.iso2 as CountryCode;
                          const format = PhNoFormat[iso2];
                          if (!format) return false;
                          const isMatch = matchesFormat(
                            inputNumber,
                            format,
                            country.countryCode
                          );

                          return isMatch;
                        }
                        return false;
                      });

                      if (!result && !errors.mobile?.message) {
                        setError("mobile", {
                          message: "invalid mobile number.",
                        });
                      } else if (result && !!errors.mobile?.message) {
                        clearErrors("mobile");
                        trigger("mobile");
                      }
                      return result;
                    }}
                    inputStyle={{
                      height: "42px",
                      width: "100%",
                      fontSize: "16px",
                    }}
                    onChange={(phone, meta: any) => {
                      setValue("country.code", meta.dialCode);
                      setValue("country.label", meta.name);
                      setValue(
                        "country.dial",
                        phone.replace(new RegExp(meta.dialCode), "")
                      );
                      setValue(
                        "mobile",
                        phone.replace(new RegExp(meta.dialCode), "")
                      );
                    }}
                  />

                  {errors.mobile?.message ? (
                    <FormHelperText>{errors.mobile?.message}</FormHelperText>
                  ) : null}
                </FormControl>
              </div>

              <p className="text-base text-center text-[#101828]">
                Ensure the number is active,
                <br />
                as the OTP will be sent to it for verification.
              </p>

              <Button
                id="btn-create"
                className="mx-auto"
                sx={{ maxWidth: "352px" }}
                fullWidth
                size="lg"
                type="submit"
                disabled={isLoading || !isValid}
              >
                {t("commons.createBtn")}
              </Button>
            </form>
          </div>
        </div>
      </div>
      <SuccessModal
        title={t("commons.successModalTitle")}
        content={t("landing.signup.accountCreated")}
        open={showModal}
      />
    </Shared>
  );
}
