function CheckCircleIcon({ className = "" }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="161"
      height="160"
      fill="none"
      viewBox="0 0 161 160"
      className={className}
    >
      <g>
        <path
          fill="#499873"
          fillRule="evenodd"
          d="M15.5 80c0-35.898 29.102-65 65-65 35.899 0 65 29.102 65 65 0 35.899-29.101 65-65 65-35.898 0-65-29.101-65-65zm89.069-12.094a5 5 0 00-8.138-5.812L74.862 92.29 64.036 81.465a5 5 0 10-7.072 7.07l15 15.001a5.001 5.001 0 007.605-.63l25-35z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default CheckCircleIcon;
