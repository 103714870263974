import { privateAxios } from "../lib/api";
import { ICatalogResponse } from "../types/catalog";
import { ApiConstants } from "../utils/constants";

export class SupplierService {
  static async getAllCatalogs({
    businessId,
    sellerId = null,
    catalogType = "PUBLIC",
    paging = true,
    limit = 50,
    offset = 0,
    searchTerm,
  }: {
    businessId: string;
    sellerId?: string | null;
    catalogType?: string;
    paging: boolean;
    limit: number;
    offset: number;
    searchTerm?: string;
  }): Promise<ICatalogResponse> {
    const response = await privateAxios.get(
      `${ApiConstants.catalogURL}/suppliers`,
      {
        params: {
          businessId,
          sellerId,
          catalogType,
          paging,
          limit,
          offset,
          searchTerm,
        },
      }
    );

    return response.data as ICatalogResponse;
  }

  static async verifyAccess({
    catalog,
    business,
    accountId,
  }: {
    catalog: string;
    business: string | null;
    accountId: string | null;
  }): Promise<Boolean> {
    const response = await privateAxios.get(
      `${ApiConstants.catalogURL}/verify?catalogId=${catalog}&businessId=${business}&accountId=${accountId}`
    );

    return response.data;
  }
}
