import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Box, IconButton, Typography } from "@mui/joy";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import Input from "@mui/joy/Input";
import Link from "@mui/joy/Link";
import Stack from "@mui/joy/Stack";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import Gauth from "../../components/Gauth";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { TRACKER_CONSTANTS } from "../../constants/tracker.events.ts";
import Shared from "../../layouts/Shared";
import { TrackerService } from "../../services/tracker.service";
import { BrowserRouterConstants } from "../../utils/constants";
import BusinessSelectionModal from "./BusinessSelectionModal/BusinessSelectionModal";
import useContainer from "./useContainer";

export default function Signin() {
  const { t } = useTranslation();
  const [urlSearchParams] = useSearchParams();
  const callbackUrl = urlSearchParams.get("callbackURL");

  const {
    googleSignInHandler,
    onSubmit,
    handleSubmit,
    register,
    errors,
    isLoading,
    email,
    password,
    openBusinessSelectionModal,
    closeBusinessModalHandler,
  } = useContainer();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    TrackerService.track(
      TRACKER_CONSTANTS.LOGIN_SUCCESS.events.LoginPageVisited
    );
  }, []);

  return (
    <>
      <Shared
        minWidth={"329px"}
        width={{
          md: "464px",
        }}
        showNavArrow={false}
        sxProps={{ backgroundColor: "transparent" }}
      >
        <Helmet
          link={[
            { href: "https://linkzasia.com/auth/login", rel: "canonical" },
          ]}
          meta={[{ content: "Log In", name: "description" }]}
          title="Log In - Linkz"
        />
        <Stack
          component="section"
          data-testid="login"
          id="login"
          flex={1}
          padding={2}
          mt={{ lg: 5, sm: 0 }}
        >
          <Stack
            sx={{
              flex: 1,
              "@media (min-width: 500px)": {
                flex: "unset",
              },
            }}
            paddingBottom={4}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack
                alignItems="stretch"
                direction="column"
                justifyContent="center"
                marginTop={3}
                spacing={3}
              >
                <Stack rowGap={1}>
                  <Typography
                    className={`transition-opacity duration-300 ${
                      email ? "opacity-100 h-auto" : "opacity-0 h-0"
                    }`}
                    fontSize={"md"}
                    fontWeight={"500"}
                  >
                    {t("landing.signup.email")}
                  </Typography>
                  <Input
                    placeholder="hello@linkzasia.com"
                    size="lg"
                    type="email"
                    {...register("email", { required: true })}
                  />
                  {errors.email?.message ? (
                    <Typography fontSize={"sm"} color="danger">
                      {errors.email?.message}
                    </Typography>
                  ) : null}
                </Stack>
                <Stack rowGap={1}>
                  <Typography
                    className={`transition-opacity duration-300 ${
                      password ? "opacity-100 h-auto" : "opacity-0 h-0"
                    }`}
                    fontSize={"md"}
                    fontWeight={"500"}
                  >
                    {t("landing.signup.password")}
                  </Typography>
                  <Input
                    endDecorator={
                      <IconButton onClick={handleShowPassword}>
                        {showPassword ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    }
                    placeholder="••••••••••"
                    required
                    size="md"
                    type={showPassword ? "text" : "password"}
                    {...register("password")}
                  />
                  {errors.password?.message ? (
                    <Typography fontSize={"sm"} color="danger">
                      {errors.password?.message}
                    </Typography>
                  ) : null}
                </Stack>
                <Link
                  component={RouterLink}
                  fontSize="sm"
                  to={BrowserRouterConstants.Recover}
                >
                  {t("landing.login.forgotPassword")}
                </Link>
                <Box width={"300px"} marginX={"auto !important"}>
                  <Button
                    id={"btn-login"}
                    disabled={isLoading}
                    size="lg"
                    fullWidth
                    type="submit"
                  >
                    {t("landing.loginBtn")}
                  </Button>
                </Box>
              </Stack>
            </form>
            <Divider sx={{ my: 2 }}> {t("landing.or")}</Divider>
            <Box onClick={googleSignInHandler} width={"300px"} marginX={"auto"}>
              <Gauth />
            </Box>
          </Stack>
          <Stack
            columnGap={4}
            mt={12}
            justifyContent={"center"}
            direction={"row"}
          >
            <Typography>{t("landing.newToLinkz")}</Typography>
            <Link
              component={RouterLink}
              to={
                `/signup` + (callbackUrl ? `?callbackURL=${callbackUrl}` : ``)
              }
            >
              <Typography fontWeight={600} textColor="primary.500">
                {t("landing.createAccountLabel")}
              </Typography>
            </Link>
          </Stack>
        </Stack>
      </Shared>
      <LoadingModal isLoading={isLoading} />
      <BusinessSelectionModal
        close={closeBusinessModalHandler}
        isOpen={openBusinessSelectionModal}
      />
    </>
  );
}
