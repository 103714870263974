import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from "@mui/joy";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { SuccessModal } from "../../components/SuccessModal";
import { ProfileService } from "../../services/profile.service";
import { useTranslation } from "react-i18next";

const fullnameRegex = /^[a-zA-Z0-9/-\s]+$/;

const fullNameScheme = z.object({
  fullName: z
    .string()
    .min(3)
    .max(50)
    .regex(
      fullnameRegex,
      "Full Name must be alphanumeric or contain '/' or '.' characters"
    ),
});

export default function ProfileFullName() {
  const nav = useNavigate();
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<z.infer<typeof fullNameScheme>>({
    resolver: zodResolver(fullNameScheme),
  });

  const onSubmit: SubmitHandler<z.infer<typeof fullNameScheme>> = async (
    data
  ) => {
    setIsLoading(true);
    ProfileService.updateProfileName(data)
      .then((_) => {
        setIsLoading(false);
        setIsSuccess(true);
        setTimeout(() => {
          nav("/client/profile", { replace: true });
        }, 3000);
      })
      .catch((_) => {
        alert("Change name error");
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Divider />
      <form
        id="update-full-name-form"
        aria-label="profiles-full-name"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            display: "flex",
            marginTop: 5,
            flexDirection: "column",
            width: { xs: "90vw", lg: "100%" },
          }}
          component="section"
        >
          <FormControl error={!!errors.fullName}>
            <FormLabel>{t("profile.newNameLabel")}</FormLabel>
            <Input
              sx={{
                minWidth: 300,
                maxWidth: 480,
                width: { xs: "90vw", lg: 480 },
              }}
              placeholder={t("profile.newNameLabel")}
              {...register("fullName")}
            />
            {errors.fullName?.message ? (
              <FormHelperText>{errors.fullName?.message}</FormHelperText>
            ) : null}
          </FormControl>
          <Box
            sx={{
              alignSelf: "center",
              display: "flex",
              position: "absolute",
              maxWidth: 480,
              width: { xs: "90vw", lg: 480 },
              bottom: 0,
              marginBottom: 5,
            }}
          >
            <Button fullWidth size="lg" type="submit">
              {t("profile.updateNameBtn")}
            </Button>
          </Box>
        </Box>
      </form>
      <SuccessModal
        open={isSuccess}
        title={t("commons.successModalTitle")}
        content={t("profile.nameUpdated")}
      />
      <LoadingModal isLoading={isLoading} />
    </React.Fragment>
  );
}
