import { Typography } from "@mui/joy";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { ProfileService } from "../../services/profile.service";
import i18next from "../../i18n/config";

// const RESEND_CODE_COUNTDOWN = 60 * 3;
const RESEND_CODE_COUNTDOWN = 10;
const useContainer = () => {
  //#region local states
  const navigate = useNavigate();
  const { state } = useLocation();
  const [mobilePhone, setMobilePhone] = useState({
    countryCode: "",
    mobile: "",
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [otpInput, setOtpInput] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [maxTries, setMaxTries] = useState(3);
  const [seconds, setSeconds] = useState(RESEND_CODE_COUNTDOWN);
  const timerRef = useRef<NodeJS.Timeout | undefined>();
  const otpVerifyMutation = useMutation({
    mutationFn: ProfileService.verifyPhoneNumber,
    onSuccess: () => {
      setIsSuccess(true);
      setTimeout(() => {
        navigate("/client/profile");
      }, 2000);
    },
    onError: (error: any) => {
      const errorMessage = i18next.t(
        `commons.error.${
          error.response.data.message || "Something went wrong."
        }`
      );
      toast(
        (t) => (
          <div
            style={{
              width: "100%",
              opacity: t.visible ? 1 : 0,
              transition: "opacity 100ms ease-in-out",
            }}
          >
            <Typography
              textColor={"danger.softColor"}
              fontSize={"14px"}
              fontWeight={500}
              textAlign={"center"}
              py={"12px"}
            >
              {errorMessage}
            </Typography>
          </div>
        ),
        {
          style: {
            padding: 0,
            margin: 0,
          },
          position: "bottom-center",
          duration: 4000,
        }
      );
      setDisabled(false);
    },
  });
  const resendVerifyMutation = useMutation({
    mutationFn: ProfileService.updatePhoneNumber,
    onError: (error: any) => {
      const errorMessage = i18next.t(
        `commons.error.${
          error.response.data.message || "Something went wrong."
        }`
      );
      toast(
        (t) => (
          <div
            style={{
              width: "100%",
              opacity: t.visible ? 1 : 0,
              transition: "opacity 100ms ease-in-out",
            }}
          >
            <Typography
              textColor={"danger.softColor"}
              fontSize={"14px"}
              fontWeight={500}
              textAlign={"center"}
              py={"12px"}
            >
              {errorMessage}
            </Typography>
          </div>
        ),
        {
          style: {
            padding: 0,
            margin: 0,
          },
          position: "bottom-center",
          duration: 4000,
        }
      );
      setDisabled(false);
    },
  });

  //#endregion

  //#region ----- life cycle methods
  useEffect(() => {
    if (otpInput.trim().length === 6) {
      otpVerifyMutation.mutate({
        phoneNumber: mobilePhone.mobile,
        countryCode: mobilePhone.countryCode,
        code: otpInput,
      });
    }
  }, [otpInput]);

  useEffect(() => {
    timerRef.current = setInterval(countDownInterval, 1000);
    return () => clearInterval(timerRef.current);
  }, []);

  useEffect(() => {
    const countryCode = state.countryCode;
    const mobile = state.phoneNumber;
    setMobilePhone({
      countryCode,
      mobile,
    });
  }, []);

  //#endregion

  //#region ----- handler methods
  const countDownInterval = () => {
    setSeconds((prevSeconds) => {
      if (prevSeconds - 1 <= 0) {
        clearInterval(timerRef.current);
        return 0;
      }
      return prevSeconds - 1;
    });
  };

  const resendOTPCode = () => {
    resendVerifyMutation.mutate({
      phoneNumber: mobilePhone.mobile,
      countryCode: mobilePhone.countryCode,
    });
    setSeconds(RESEND_CODE_COUNTDOWN);
    setMaxTries((prev) => prev - 1);
    timerRef.current = setInterval(countDownInterval, 1000);
  };

  const onSubmit = (data: any) => {
    console.log(data);
  };

  const formatTime = () => {
    // const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  //#endregion

  return {
    // states
    phone: `+${mobilePhone.countryCode} ${mobilePhone.mobile}`,
    isSuccess,
    isError: otpVerifyMutation.isError,
    otpInput,
    maxTries,
    seconds,
    disabled,
    isLoading: otpVerifyMutation.isPending,
    // functions
    setOtpInput,
    onSubmit,
    formatTime,
    resendOTPCode,
  };
};

export default useContainer;
