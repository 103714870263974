import PhotoLibraryOutlinedIcon from "@mui/icons-material/PhotoLibraryOutlined";
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import { EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import styles from "./Carousel.module.css";

type Params = {
  alt: string;
  options?: EmblaOptionsType;
  slides: any[];
}

export default function Carousel({
  alt,
  options,
  slides,
}: Params) {
  const [emblaRef] = useEmblaCarousel(options);

  return (
    <Box position="relative">
      {slides.length ? (
        <Chip
          startDecorator={<PhotoLibraryOutlinedIcon />}
          sx={{
            bottom: 20,
            position: "absolute",
            right: 20,
            zIndex: 1,
          }}
        >
          {slides.length}
        </Chip>
      ) : null}
      <section className={styles.embla}>
        <div className={styles.embla__viewport} ref={emblaRef}>
          <div className={styles.embla__container}>
            {slides.map(({ src }, index: any) => (
              <div className={styles.embla__slide} key={index}>
                <div className={styles.embla__slide__number}>
                  <img alt={alt} src={src} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Box>
  );
}
