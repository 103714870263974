export enum ApplicableAdminFeeTypes {
  CHARGE_TO_SELLER = "CHARGE_TO_SELLER",
  CHARGE_TO_BUYER = "CHARGE_TO_BUYER",
}

export const APPLICABLE_ADMIN_FEE_OPTIONS = [
  {
    value: ApplicableAdminFeeTypes.CHARGE_TO_SELLER,
    labelKey: "profile.business.adminFeeOptions.chargeToSeller",
  },
  {
    value: ApplicableAdminFeeTypes.CHARGE_TO_BUYER,
    labelKey: "profile.business.adminFeeOptions.chargeToBuyer",
  }
];
