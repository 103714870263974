import { FormHelperText, FormLabel, Grid, Input } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { ItemType } from "../../types/item";
import { validateOnChange, validateOnKeyDown } from "../../utils/cart";
import { If } from "../Condition";
import { useState } from "react";

const UpdateQuantityInput = ({
  item,
  index,
  field,
  register,
  threshold,
}: {
  item: ItemType;
  index: number;
  field: any;
  register: any;
  threshold?: number;
}) => {
  const { t } = useTranslation();

  const [quantityInput, setQuantityInput] = useState(
    item?.quantity?.toString()
  );

  return (
    <Grid xs={12} sx={{ flex: "1", height: "100%", flexGrow: 1 }}>
      <FormLabel sx={{ marginBottom: "4px" }}>
        {t("catalog.inventory.editForm.quantity")}
        <span className="text-danger-500">*</span>
      </FormLabel>
      <input
        type="hidden"
        defaultValue={field.id}
        {...register(`items[${index}].id`)}
      />
      <Input
        placeholder={t("catalog.inventory.editForm.quantity")}
        defaultValue={item?.quantity ?? 0}
        {...register(`items[${index}].quantity`, {
          required: "Quantity is required",
          min: { value: 1, message: t("catalog.inventory.updateQty.error") },
          validate: (value: number) => {
            const errorMsg = threshold
              ? t("catalog.inventory.updateQty.errorOrder", {
                  lowStockQuantity: threshold,
                })
              : t("catalog.inventory.updateQty.errorInventory", {
                  lowStockQuantity: item?.lowStockQuantity ?? 0,
                });

            const comparator = threshold ?? item.lowStockQuantity ?? 0;

            return value >= comparator || errorMsg;
          },
          valueAsNumber: true,
        })}
        onChange={(e) => {
          validateOnChange(e.target.value, (sanitizedValue) => {
            setQuantityInput(sanitizedValue);
          });
        }}
        onKeyDown={(e) => {
          if (validateOnKeyDown(e.key)) {
            e.preventDefault();
          }
        }}
        className="mt-auto"
      />
      <FormHelperText sx={{ marginTop: "4px", color: "red" }}>
        <If
          condition={
            Number(quantityInput) <= (threshold || item?.lowStockQuantity || 0)
          }
        >
          {t("catalog.inventory.updateQty.errorInventory", {
            lowStockQuantity: threshold ?? item?.lowStockQuantity ?? 0,
          })}
        </If>
      </FormHelperText>
    </Grid>
  );
};

export default UpdateQuantityInput;
