import { Box } from "@mui/joy";
import { ActiveCheckIconWithoutBG } from "../Icons/Check";

interface Props {
  isActive?: boolean;
}

export function PolicyBullet({ isActive = false }: Props) {
  return isActive ? (
    <ActiveCheckIconWithoutBG />
  ) : (
    <Box
      className="w-[6px] h-[6px] rounded-full"
      bgcolor={isActive ? "#499873" : "#9FA6AD"}
    ></Box>
  );
}
