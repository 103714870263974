import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { getAccessToken, logout } from "../store/session";
import i18next from "../i18n/config";

export const privateAxios = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  // withCredentials: true,
});

export const publicAxios = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  // withCredentials: true,
});
const handleUnauthorized = () => {
  logout();
  // window.location.href = "/signin";
};

publicAxios.interceptors.request.use(
  (config: InternalAxiosRequestConfig<object>) => {
    const businessToken = localStorage.getItem("businessChooseToken");
    const authToken = getAccessToken();
    if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    } else if (businessToken) {
      if (window.location.href.includes("/signin")) {
        config.headers["Authorization"] = `Bearer ${businessToken}`;
      }
    }

    config.headers["Accept-Language"] = i18next.resolvedLanguage ?? "en";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
privateAxios.interceptors.request.use(
  (config: InternalAxiosRequestConfig<object>) => {
    const businessToken = localStorage.getItem("businessChooseToken");
    const authToken = getAccessToken();
    if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    } else if (businessToken) {
      if (window.location.href.includes("/signin")) {
        config.headers["Authorization"] = `Bearer ${businessToken}`;
      }
    }

    config.headers["Accept-Language"] = i18next.resolvedLanguage ?? "en";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

privateAxios.interceptors.response.use(
  (response: AxiosResponse<object, object>) => {
    return response;
  },
  (error) => {
    console.log({ error });
    if (
      (error.response && ([403, 401].includes(error.response.status)))
    ) {
      handleUnauthorized();
    }
    return Promise.reject(error);
  }
);
