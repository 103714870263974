import { privateAxios } from "../lib/api";
import { ApiConstants } from "../utils/constants";

export class BusinessContactService {
  static async createContact(payload: any) {
    const response = await privateAxios.post(
      ApiConstants.BusinessContacts,
      payload
    );
    return response.data;
  }

  static async getContacts(data?: {
    limit: number;
    offset: number;
    search?: string;
  }) {
    const response = await privateAxios.get(ApiConstants.BusinessContacts, {
      params: data,
    });
    return response.data;
  }

  static async getContact(id: string) {
    const response = await privateAxios.get(
      `${ApiConstants.BusinessContacts}/${id}`
    );
    return response.data;
  }

  static async updateContact(payload: any) {
    const response = await privateAxios.put(
      `${ApiConstants.BusinessContacts}/${payload.id}`,
      payload
    );
    return response.data;
  }

  static async deleteContact(id: string) {
    const response = await privateAxios.delete(
      `${ApiConstants.BusinessContacts}/${id}`
    );
    return response.data;
  }

  static async searchContactToAdd(email: string) {
    const response = await privateAxios.get(
      `${ApiConstants.BusinessContacts}/search/by-email`,
      {
        params: {
          email,
        },
      }
    );

    return response.data;
  }

  static async shareContact(data: { id: string; email: string[] }) {
    const response = await privateAxios.post(
      `${ApiConstants.BusinessContacts}/${data.id}/access`,
      data
    );
    return response.data;
  }
}
