import { useCallback, useState } from "react";
import { CatalogType } from "../../types/catalog";
import { PaginationType } from "../../types/item";
import { SupplierService } from "../../services/supplier.service";

const useContainer = () => {
  //#region ----- local states
  const [catalogs, setCatalogs] = useState<CatalogType[]>([]);
  const [pagination, setPagination] = useState<PaginationType>({
    current: 1,
    limit: 10,
    max: 1,
  });
  const [searchTerm, setSearchTerm] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  //#region ----- misc
  const fetchCatalogs = useCallback(
    ({
      businessId,
      sellerId = null,
      paging = true,
      limit = 10,
      offset = 0,
      catalogType = "PUBLIC",
      searchTerm,
      isReset = false,
    }: {
      businessId: string;
      sellerId?: string | null;
      paging?: boolean;
      limit?: number;
      offset?: number;
      catalogType?: string;
      searchTerm?: string;
      isReset?: boolean;
    }) => {
      console.log("LOADMORE BEFORE CATALOGS", isReset);
      setIsLoading(true);
      SupplierService.getAllCatalogs({
        businessId,
        sellerId,
        paging,
        limit,
        offset,
        catalogType,
        searchTerm,
      })
        .then((res) => {
          console.log("RESPONSE LOADMORE", res);
          if (isReset) {
            setCatalogs(res.data);
          } else {
            setCatalogs((prev) => [...prev, ...res.data]);
          }

          if (res.pagination) {
            setPagination(res.pagination);
          }

          if (res.data.length === 0) {
            setHasMore(false);
          }

          console.log("LOADMORE CATALOGS", catalogs);
        })
        .catch((error: any) => console.error(error))
        .finally(() => setIsLoading(false));
    },
    [catalogs, hasMore, pagination]
  );

  //#region ----- handler functions

  return {
    fetchCatalogs,
    catalogs,
    setCatalogs,
    pagination,
    setPagination,
    searchTerm,
    setSearchTerm,
    isLoading,
    setIsLoading,
    hasMore,
    setHasMore,
  };
};

export default useContainer;
