function Reciept() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#EA9A3E"
        fillRule="evenodd"
        d="M5.25 2A2.25 2.25 0 003 4.25v9a.75.75 0 001.183.613l1.692-1.195 1.692 1.195a.75.75 0 00.866 0l1.692-1.195 1.693 1.195A.75.75 0 0013 13.25v-9A2.25 2.25 0 0010.75 2h-5.5zm5.53 4.28a.75.75 0 10-1.06-1.06l-4.5 4.5a.75.75 0 101.06 1.06l4.5-4.5zM7 6.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm2.75 4.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Reciept;
