import {
  ChevronLeftOutlined,
  ChevronRightOutlined,
  FirstPageOutlined,
  LastPageOutlined,
} from "@mui/icons-material";
import {
  Button,
  IconButton,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import { PaginationType } from "../../../types/item";
import { If } from "../../../components/Condition";
import { t } from "i18next";

type Props = {
  handlePaging: (action: string | number) => void;
  pagination: PaginationType;
  setPagination: React.Dispatch<React.SetStateAction<PaginationType>>;
  withLimit?: boolean;
  withSelected?: boolean;
  selected?: number;
  total?: number;
  justifyContent?: string;
};

const TablePagination = ({
  handlePaging,
  pagination,
  setPagination,
  withLimit = false,
  withSelected = false,
  selected = 0,
  total = 0,
  justifyContent = "space-between",
}: Props) => {
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      width={"100%"}
      justifyContent={justifyContent}
      alignItems={"center"}
      gap={"1.5rem"}
    >
      <If condition={withLimit}>
        <Typography
          fontSize={14}
          fontWeight={400}
          textColor={"#101828"}
          display={"flex"}
          alignItems={"center"}
          gap={"0.5rem"}
        >
          {t("commons.pagination.show")}
          <Select
            value={pagination.limit}
            size="sm"
            slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
            onChange={(_event, value) => {
              if (value) {
                const newPagination = pagination;
                newPagination.limit = value;
                setPagination(newPagination);
                handlePaging("first");
              }
            }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={50}>50</Option>
            <Option value={100}>100</Option>
          </Select>
          {t("commons.pagination.itemsPerPage")}
        </Typography>
      </If>
      <If condition={withSelected}>
        <Typography fontSize={14}>
          <span className="hidden lg:block">
            {t("catalog.cartSelectedDescWithoutTotal", {
              selected: selected,
              total: total,
            })}
          </span>
        </Typography>
      </If>
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignContent={"center"}
        gap={0}
      >
        <IconButton
          key={"first"}
          disabled={pagination.current === 1}
          type="button"
          onClick={() => {
            handlePaging("first");
          }}
        >
          <FirstPageOutlined />
        </IconButton>
        <IconButton
          key={"previous"}
          disabled={pagination.current === 1}
          type="button"
          onClick={() => {
            handlePaging("previous");
          }}
        >
          <ChevronLeftOutlined />
        </IconButton>
        {Array.from({ length: pagination.max }, (_, i) => (
          <Button
            key={i}
            variant="plain"
            type="button"
            className={`min-w-fit py-1 px-4 ${
              pagination.current === i + 1
                ? "text-primary-500 bg-primary-100"
                : "text-gray-600"
            }`}
            onClick={() => {
              handlePaging(i + 1);
            }}
          >
            {i + 1}
          </Button>
        ))}
        <IconButton
          key={"next"}
          disabled={pagination.current === pagination.max}
          type="button"
          onClick={() => {
            handlePaging("next");
          }}
        >
          <ChevronRightOutlined />
        </IconButton>
        <IconButton
          key={"last"}
          disabled={pagination.current === pagination.max}
          type="button"
          onClick={() => {
            handlePaging("last");
          }}
        >
          <LastPageOutlined />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default TablePagination;
