import {
  AccountCircleOutlined,
  Add,
  MoreVert,
  Remove,
  Search,
} from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteOption,
  Button,
  createFilterOptions,
  Divider,
  Dropdown,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/joy";
import { Controller, useFormContext } from "react-hook-form";
import React from "react";
import { useTranslation } from "react-i18next";
import { Toggler } from "../utils";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import { BusinessSessionHelper } from "../../helpers/business-session.helper";

interface IPurchaseOrderForm {
  internalAccountList?: any;
  externalBusinessList?: any;
  selectedInternalAccount: any;
  selectedExternalBusiness: any;
  handleOnChangeInternalAccount: any;
  handleOnChangeExternalBusiness?: any;
  handleUpdateCompanyInfo?: any;
  handleEditNewBusiness?: any;
  handleRemoveNewBusiness?: any;
  isReadOnly: any;
  isAdjustmentOrder: boolean;
}

const PurchaseOrderForm: React.FC<IPurchaseOrderForm> = ({
  internalAccountList,
  externalBusinessList,
  selectedInternalAccount,
  selectedExternalBusiness,
  handleOnChangeInternalAccount,
  handleOnChangeExternalBusiness,
  handleUpdateCompanyInfo,
  handleEditNewBusiness,
  handleRemoveNewBusiness,
  isReadOnly,
  isAdjustmentOrder,
}) => {
  const { t } = useTranslation();
  const { control, getValues, register } = useFormContext();

  return (
    <Stack spacing={"2rem"} width={{ xs: "90vw", md: "100%" }}>
      <Toggler
        renderToggle={({ open, setOpen, sxClasses }) => (
          <>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              onClick={() => setOpen(!open)}
              width={{ xs: "90vw", md: "100%" }}
              sx={sxClasses}
            >
              <Typography
                startDecorator={<AccountCircleOutlined />}
                fontWeight={600}
              >
                {t("order.createOrder.buyerInfo")}
                <span className={`text-danger-500`}>*</span>
              </Typography>
              {open ? <Remove color="primary" /> : <Add color="primary" />}
            </Stack>
          </>
        )}
      >
        <Stack spacing={4} width={{ xs: "90vw", md: "100%" }}>
          {!isReadOnly && !isAdjustmentOrder && (
            <Controller
              name="buyerIds"
              control={control}
              rules={{ required: true }}
              render={({ field: { name, ref }, fieldState: { error } }) => (
                <FormControl error={!!error} ref={ref}>
                  <FormLabel>
                    {t("order.createOrder.form.purchasePerson")}
                  </FormLabel>
                  <Autocomplete
                    name={name}
                    options={internalAccountList || null}
                    variant="linkz-autocomplete-rounded"
                    startDecorator={<Search />}
                    getOptionLabel={(option) => option.profile?.fullName ?? ""}
                    getOptionKey={(option) => option?.id}
                    isOptionEqualToValue={(option, value) => {
                      if (Object.keys(value).length > 0)
                        return option.id === value.id;
                      else return !!option;
                    }}
                    value={selectedInternalAccount || null}
                    onChange={(_event, newValue) => {
                      handleOnChangeInternalAccount("purchase", newValue);
                    }}
                    disabled={isReadOnly || isAdjustmentOrder}
                    sx={{
                      width: { xs: "100%", lg: "50%" },
                      marginTop: "4px",
                    }}
                  />
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          )}

          {selectedInternalAccount &&
          Object.keys(selectedInternalAccount).length > 0 ? (
            <>
              <Stack>
                <Typography fontWeight={600}>
                  {BusinessSessionHelper.GetBusinessName()}
                </Typography>
                <Grid container spacing={1} width={{ xs: "100%", lg: "50%" }}>
                  <Grid xs={6}>
                    <Typography flex={1} fontSize={{ xs: 14, lg: 16 }}>
                      {t("order.createOrder.form.phone")} :{" "}
                    </Typography>
                  </Grid>
                  <Grid xs={6}>
                    <Typography flex={1} fontSize={{ xs: 14, lg: 16 }}>
                      {
                        selectedInternalAccount?.role?.[0]?.business
                          ?.companyNumber
                      }{" "}
                    </Typography>
                  </Grid>
                  <Grid xs={6}>
                    <Typography flex={1} fontSize={{ xs: 14, lg: 16 }}>
                      {t("order.createOrder.form.email")} :{" "}
                    </Typography>
                  </Grid>
                  <Grid xs={6}>
                    <Typography flex={1} fontSize={{ xs: 14, lg: 16 }}>
                      {selectedInternalAccount?.email}{" "}
                    </Typography>
                  </Grid>
                  <Grid xs={6}>
                    <Typography flex={1} fontSize={{ xs: 14, lg: 16 }}>
                      {t("order.createOrder.form.address")} :{" "}
                    </Typography>
                  </Grid>
                  <Grid xs={6}>
                    <Typography flex={1} fontSize={{ xs: 14, lg: 16 }}>
                      {
                        selectedInternalAccount?.role?.[0]?.business
                          ?.companyAddress
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            </>
          ) : (
            ""
          )}
        </Stack>
      </Toggler>

      <Divider />

      <Toggler
        defaultExpanded={true}
        renderToggle={({ open, setOpen, sxClasses }) => (
          <>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              onClick={() => setOpen(!open)}
              width={{ xs: "90vw", md: "100%" }}
              sx={sxClasses}
            >
              <Typography
                startDecorator={<BuildingOffice2Icon width={24} height={24} />}
                fontWeight={600}
              >
                {t("order.createOrder.sellerInfo")}
                <span className={`text-danger-500`}>*</span>
              </Typography>
              {open ? <Remove color="primary" /> : <Add color="primary" />}
            </Stack>
          </>
        )}
      >
        <Stack spacing={4} width={{ xs: "90vw", md: "100%" }}>
          {!isReadOnly && !isAdjustmentOrder && (
            <Controller
              name="selectedExternalBusiness"
              control={control}
              defaultValue={null}
              rules={{ required: "Seller is required" }}
              render={({
                field: { name, value, ref, onChange },
                fieldState: { error },
              }) => (
                <FormControl error={!!error} ref={ref}>
                  <Autocomplete
                    name={name}
                    variant="linkz-autocomplete-rounded"
                    startDecorator={<Search />}
                    placeholder={t("order.createOrder.form.searchSeller")}
                    options={externalBusinessList || null}
                    getOptionLabel={(_option) => ""}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    filterOptions={(options, params) => {
                      const filter = createFilterOptions({
                        // matchFrom: "start",
                        stringify: (option: any) =>
                          `${option?.companyName} ${option?.role?.[0]?.account?.profile?.fullName}`,
                      });

                      const filtered = filter(options, params);

                      if (params.inputValue !== "") {
                        filtered.push({
                          newBusinessInput: params.inputValue,
                          newBusinessOptionText: `${t("commons.addBtn")} "${
                            params.inputValue
                          }"`,
                        });
                      }

                      return filtered;
                    }}
                    // value={selectedExternalBusiness || null}
                    value={value}
                    renderOption={(props, option: any) => (
                      <AutocompleteOption
                        key={option?.id}
                        {...props}
                        sx={{
                          py: 1,
                          "&:hover": {
                            backgroundColor: "var(--joy-palette-neutral-100)",
                          },
                        }}
                      >
                        <Typography>
                          {option?.newBusinessInput
                            ? option?.newBusinessOptionText
                            : `${option?.companyName} (${option?.role?.[0]?.account?.profile?.fullName})`}
                        </Typography>
                      </AutocompleteOption>
                    )}
                    onChange={(_event, newValue) => {
                      onChange(newValue);
                      handleOnChangeExternalBusiness("purchase", newValue);
                    }}
                    disabled={isReadOnly || isAdjustmentOrder}
                    error={!!error}
                    sx={{
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                  <Input
                    type="hidden"
                    {...register("sellerIds")}
                    sx={{ display: "none" }}
                  />
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          )}

          <div className="flex w-full items-start">
            <div className="w-full lg:w-3/4">
              {(() => {
                if (
                  handleEditNewBusiness &&
                  handleRemoveNewBusiness &&
                  getValues("newBusiness")
                ) {
                  return (
                    <Stack>
                      <Typography fontWeight={600}>
                        {getValues("newBusiness").businessName}
                      </Typography>
                      <Stack
                        direction={"row"}
                        gap={5}
                        justifyContent={"space-between"}
                      >
                        <Typography flex={"0 0 70%"}>
                          {t("order.createOrder.form.contact")} :{" "}
                          {getValues("newBusiness").accountName}
                          <br />
                          {t("order.createOrder.form.email")} :{" "}
                          {getValues("newBusiness").email}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        justifyContent={"end"}
                      >
                        <Button
                          // fullWidth
                          variant="plain"
                          sx={{
                            // alignSelf: "start",
                            color: "var(--joy-palette-danger-500)",
                            "&:hover": {
                              backgroundColor: "var(--joy-palette-danger-100)",
                            },
                          }}
                          size="md"
                          onClick={handleRemoveNewBusiness}
                        >
                          {t("commons.removeBtn")}
                        </Button>
                        <Button
                          variant="outlined"
                          sx={{ maxWidth: "200px" }}
                          // fullWidth
                          size="md"
                          onClick={handleEditNewBusiness}
                        >
                          {t("commons.editBtn")}
                        </Button>
                      </Stack>
                    </Stack>
                  );
                } else {
                  return (
                    selectedExternalBusiness &&
                    Object.keys(selectedExternalBusiness).length > 0 && (
                      <>
                        <Stack>
                          <Typography fontWeight={600}>
                            {selectedExternalBusiness.companyName}
                          </Typography>
                          <Grid
                            container
                            spacing={1}
                            width={{ xs: "100%", lg: "70%" }}
                          >
                            <Grid xs={6}>
                              <Typography
                                flex={1}
                                fontSize={{ xs: 14, lg: 16 }}
                              >
                                {t("order.createOrder.form.contact")} :{" "}
                              </Typography>
                            </Grid>
                            <Grid xs={6}>
                              <Typography
                                flex={1}
                                fontSize={{ xs: 14, lg: 16 }}
                              >
                                {
                                  selectedExternalBusiness.role?.[0]?.account
                                    ?.profile?.fullName
                                }
                              </Typography>
                            </Grid>

                            <Grid xs={6}>
                              <Typography
                                flex={1}
                                fontSize={{ xs: 14, lg: 16 }}
                              >
                                {t("order.createOrder.form.phone")} :{" "}
                              </Typography>
                            </Grid>
                            <Grid xs={6}>
                              <Typography
                                flex={1}
                                fontSize={{ xs: 14, lg: 16 }}
                              >
                                {
                                  selectedExternalBusiness.role?.[0]?.account
                                    ?.profile?.localNumber
                                }
                              </Typography>
                            </Grid>

                            <Grid xs={6}>
                              <Typography
                                flex={1}
                                fontSize={{ xs: 14, lg: 16 }}
                              >
                                {t("order.createOrder.form.email")} :{" "}
                              </Typography>
                            </Grid>
                            <Grid xs={6}>
                              <Typography
                                flex={1}
                                fontSize={{ xs: 14, lg: 16 }}
                              >
                                {
                                  selectedExternalBusiness.role?.[0]?.account
                                    ?.email
                                }
                              </Typography>
                            </Grid>

                            <Grid xs={6}>
                              <Typography
                                flex={1}
                                fontSize={{ xs: 14, lg: 16 }}
                              >
                                {t("order.createOrder.form.companyAddress")} :{" "}
                              </Typography>
                            </Grid>
                            <Grid xs={6}>
                              <Typography
                                flex={1}
                                fontSize={{ xs: 14, lg: 16 }}
                              >
                                {selectedExternalBusiness.companyAddress}
                              </Typography>
                            </Grid>

                            <Grid xs={6}>
                              <Typography fontSize={{ xs: 14, lg: 16 }}>
                                {t("order.createOrder.form.companyPostalCode")}{" "}
                                :{" "}
                              </Typography>
                            </Grid>
                            <Grid xs={6}>
                              <Typography fontSize={{ xs: 14, lg: 16 }}>
                                {selectedExternalBusiness.companyPostalCode}
                              </Typography>
                            </Grid>

                            <Grid xs={6}>
                              <Typography
                                flex={1}
                                fontSize={{ xs: 14, lg: 16 }}
                              >
                                {t("order.createOrder.form.bankName")} :{" "}
                              </Typography>
                            </Grid>
                            <Grid xs={6}>
                              <Typography
                                flex={1}
                                fontSize={{ xs: 14, lg: 16 }}
                              >
                                {
                                  selectedExternalBusiness.bankInfo?.[0]
                                    ?.bankName
                                }{" "}
                              </Typography>
                            </Grid>

                            <Grid xs={6}>
                              <Typography
                                flex={1}
                                fontSize={{ xs: 14, lg: 16 }}
                              >
                                {t("order.createOrder.form.accountNo")} :{" "}
                              </Typography>
                            </Grid>
                            <Grid xs={6}>
                              <Typography
                                flex={1}
                                fontSize={{ xs: 14, lg: 16 }}
                              >
                                {
                                  selectedExternalBusiness.bankInfo?.[0]
                                    ?.accountNo
                                }{" "}
                              </Typography>
                            </Grid>

                            <Grid xs={6}>
                              <Typography
                                flex={1}
                                fontSize={{ xs: 14, lg: 16 }}
                              >
                                {t("order.createOrder.form.accountHolderName")}{" "}
                                :{" "}
                              </Typography>
                            </Grid>
                            <Grid xs={6}>
                              <Typography
                                flex={1}
                                fontSize={{ xs: 14, lg: 16 }}
                              >
                                {
                                  selectedExternalBusiness.bankInfo?.[0]
                                    ?.accountHolderName
                                }
                              </Typography>
                            </Grid>
                          </Grid>
                        </Stack>
                        {/* <Button
                          variant="outlined"
                          sx={{ alignSelf: "start" }}
                          onClick={handleUpdateCompanyInfo}
                        >
                          Update Company Info
                        </Button> */}
                        {/* <UpdateCompanyInfoModal /> */}

                        {/* <Controller
                          name="paymentTerm"
                          control={control}
                          render={({
                            field: { onChange, onBlur, name, value, ref },
                            fieldState: { error },
                          }) => (
                            <FormControl error={!!error} ref={ref}>
                              <FormLabel>Payment Term</FormLabel>
                              <Autocomplete
                                name={name}
                                placeholder="Payment term"
                                options={
                                  selectedExternalBusiness.companyPaymentTerms
                                }
                                isOptionEqualToValue={(option, value) => {
                                  // console.log('Comparing option payment term:', option, 'with value:', value);
                                  if (value) return option === value;
                                  else return !!option;
                                }}
                                onChange={(_event, newValue) => {
                                  onChange(newValue);
                                }}
                                onBlur={onBlur}
                                value={value}
                                disabled={isReadOnly}
                              />
                              <FormHelperText>{error?.message}</FormHelperText>
                            </FormControl>
                          )}
                        /> */}
                      </>
                    )
                  );
                }
              })()}
            </div>

            {selectedExternalBusiness &&
              Object.keys(selectedExternalBusiness).length > 0 && (
                <div className="ml-auto">
                  {!isReadOnly && !isAdjustmentOrder && (
                    <Dropdown>
                      <MenuButton
                        slots={{ root: IconButton }}
                        slotProps={{
                          root: {
                            variant: "plain",
                            color: "neutral",
                            size: "sm",
                          },
                        }}
                      >
                        <MoreVert color="primary" />
                      </MenuButton>
                      <Menu size="sm" sx={{ minWidth: 140 }}>
                        <MenuItem onClick={handleUpdateCompanyInfo}>
                          {t("commons.editBtn")}
                          {/* {i18next.t("order.table.rowMenu.duplicate")} */}
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleOnChangeExternalBusiness("purchase", {});
                          }}
                        >
                          {t("commons.removeBtn")}
                          {/* {i18next.t("order.table.rowMenu.duplicate")} */}
                        </MenuItem>
                      </Menu>
                    </Dropdown>
                  )}
                </div>
              )}
          </div>

        </Stack>
      </Toggler>

      <Divider />
    </Stack>
  );
};

export default PurchaseOrderForm;
