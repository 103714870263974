import { Box, Typography } from "@mui/joy";
import { If } from "../../../components/Condition";

type Props = {
  title: string;
  subtitle?: string;
};

const PageTitleBox = ({ title, subtitle }: Props) => {
  return (
    <Box width={"100%"}>
      <Typography fontSize={20} fontWeight={600} sx={{ color: "#101828" }}>
        {title}
      </Typography>
      <If condition={subtitle ? true : false}>
        <p className="text-sm text-[#667085]">{subtitle}</p>
      </If>
    </Box>
  );
};

export default PageTitleBox;
