import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AddOutlined, DeleteOutline } from "@mui/icons-material";
interface Props {
  isReadOnly?: boolean;
}

const AdditionalInfoInput = ({ isReadOnly = false }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  console.log("ERROR FLEXICOLSm", errors);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "flexiColumns.data",
  });

  return (
    <Grid container spacing={2}>
      {fields.map((_col: any, index: number) => {
        return (
          <Grid
            xs={12}
            md={6}
            sx={{
              display: "flex",
            }}
          >
            <FormControl
              sx={{ flex: "1", width: { xs: "30%", lg: "100%" } }}
              error={
                !!(errors?.flexiColumns as any)?.data?.[index]?.columnName
                  ?.message
              }
            >
              <FormLabel>
                {t("catalog.inventory.editForm.additionalInfo")}
              </FormLabel>
              <Input
                type="text"
                placeholder={t("catalog.inventory.editForm.additionalInfo")}
                disabled={isReadOnly}
                aria-label={"flexiColumns.data." + index + ".columnName"}
                {...register("flexiColumns.data." + index + ".columnName", {
                  required: t("commons.error.form.required", {
                    label: t("catalog.inventory.editForm.additionalInfo"),
                  }),
                })}
                className="mr-1 lg:mr-2"
              />
              <FormHelperText>
                {
                  (errors?.flexiColumns as any)?.data?.[index]?.columnName
                    ?.message
                }
              </FormHelperText>
            </FormControl>

            <FormControl
              sx={{ flex: "1", width: { xs: "30%", lg: "100%" } }}
              error={
                !!(errors?.flexiColumns as any)?.data?.[index]?.cellValue
                  ?.message
              }
            >
              <FormLabel>
                {t("catalog.inventory.editForm.additionalInfoValue")}
              </FormLabel>
              <Input
                type="text"
                placeholder={t(
                  "catalog.inventory.editForm.additionalInfoValue"
                )}
                disabled={isReadOnly}
                aria-label={"flexiColumns.data." + index + ".cellValue"}
                {...register("flexiColumns.data." + index + ".cellValue", {
                  required: t("commons.error.form.required", {
                    label: t("catalog.inventory.editForm.additionalInfoValue"),
                  }),
                })}
              />
              <FormHelperText>
                {
                  (errors?.flexiColumns as any)?.data?.[index]?.cellValue
                    ?.message
                }
              </FormHelperText>
            </FormControl>

            {!isReadOnly && (
              <Button
                variant="plain"
                className="whitespace-nowrap ml-2 px-4 min-w-0 mt-auto border-[#475467] text-[#475467] hover:text-white hover:bg-[#475467] hover:border-[#475467]"
                onClick={() => {
                  remove(index);
                }}
              >
                <DeleteOutline
                  style={{
                    width: 18,
                    height: 18,
                  }}
                />
              </Button>
            )}
          </Grid>
        );
      })}

      {!isReadOnly && (
        <Grid
          xs={12}
          sx={{
            display: "flex",
          }}
        >
          <Button
            variant="outlined"
            startDecorator={<AddOutlined />}
            className="ml-auto whitespace-nowrap mt-4"
            onClick={() => {
              append({
                columnName: "",
                cellValue: "",
              });
            }}
          >
            {t("catalog.inventory.editForm.addInfoBtn")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default AdditionalInfoInput;
