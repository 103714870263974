import { ReactNode } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

interface ProtectedProps {
  children: ReactNode;
  isValid: boolean;
  redirectRoute?: string;
}

const ProtectedRoute: React.FC<ProtectedProps> = ({
  children,
  isValid,
  redirectRoute = "/",
}: ProtectedProps) => {
  const [searchParams] = useSearchParams();
  const { pathname, search } = useLocation();
  if (!["/signin", "/signup", "/kyc"].includes(pathname)) {
    const callbackURL = encodeURIComponent(`${pathname}${search}`);
    redirectRoute += `?callbackURL=${callbackURL}`;
  } else if (["/", "/client"].includes(redirectRoute)) {
    const callbackURL = searchParams.get("callbackURL");
    redirectRoute = callbackURL ? decodeURIComponent(callbackURL) : "/client/";
  }
  if (
    (redirectRoute.startsWith("http://") ||
      redirectRoute.startsWith("https://")) &&
    !isValid
  ) {
    window.location.href = redirectRoute;
    return null;
  }
  return isValid ? children : <Navigate to={redirectRoute} />;
};

export default ProtectedRoute;
