import { privateAxios } from "../lib/api";
import { IUpdatePhoneNumberPayload, IUpdatePhoneNumberVerificationPayload } from "../types/update_phone_number";
import { IUpdateProfileNamePayload } from "../types/update_profile_name";
import { ApiConstants } from "../utils/constants";

export class ProfileService {
    static async getProfile() : Promise<IProfileResponse> {
        const response = await privateAxios.get(ApiConstants.profileURL);
        return response.data;
    }

    static async updateProfileName(payload: IUpdateProfileNamePayload) : Promise<IProfileResponse> {
        const response = await privateAxios.patch(ApiConstants.profileFullNameURL, payload);
        return response.data;
    }

    static async updatePhoneNumber(payload: IUpdatePhoneNumberPayload) : Promise<any> {
        const resepons = await privateAxios.patch(ApiConstants.profilePhoneNumberURL, payload);
        return resepons.data
    }

    static async verifyPhoneNumber(payload: IUpdatePhoneNumberVerificationPayload) : Promise<any> {
        const response = await privateAxios.patch(ApiConstants.profilePhoneNumberVerificationURL, payload);
        return response.data
    }
}