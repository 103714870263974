import { S3Client } from "@aws-sdk/client-s3";

export const s3Client = new S3Client({
  endpoint:
    import.meta.env.VITE_SPACES_ENDPOINT || "sgp1.digitaloceanspaces.com",
  forcePathStyle: false,
  region: "sgp1",
  credentials: {
    accessKeyId: import.meta.env.VITE_SPACES_ACCESS_KEY || "-",
    secretAccessKey: import.meta.env.VITE_SPACES_SECRET_KEY || "-",
  },
});
