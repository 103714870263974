import { privateAxios } from "../lib/api";
import {
  IBusinessResponse,
  ICreateBusiness,
  IExternalBusiness,
} from "../types/business";
import { ApiConstants } from "../utils/constants";

export class BusinessService {
  static async getBusiness({
    paging = true,
    limit = 10,
    page = 0,
  }: {
    paging?: boolean;
    limit?: number;
    page?: number;
  }): Promise<IBusinessResponse[]> {
    const response = await privateAxios.get(ApiConstants.businessURL, {
      params: {
        limit: limit,
        offset: limit * page,
        paging,
      },
    });
    return response.data as IBusinessResponse[];
  }

  static async getExternalBusinessList({
    businessId,
    paging = false,
    limit = 10,
    page = 0,
  }: {
    businessId: string | undefined;
    paging?: boolean;
    limit?: number;
    page?: number;
  }): Promise<IExternalBusiness[]> {
    const response = await privateAxios.get(
      `${ApiConstants.businessURL}/${businessId}/external`,
      {
        params: {
          limit: limit,
          offset: limit * page,
          paging,
        },
      }
    );
    return response.data as IExternalBusiness[];
  }

  static async createBusiness(payload: ICreateBusiness) {
    const response = await privateAxios.post(ApiConstants.businessURL, payload);
    return response.data;
  }

  static async deleteBusiness(businessId: string) {
    const response = await privateAxios.delete(
      `${ApiConstants.businessURL}/${businessId}`
    );
    return response.data;
  }

  static async updateBusiness({
    businessId,
    payload,
  }: {
    businessId: string;
    payload: ICreateBusiness;
  }) {
    const response = await privateAxios.put(
      `${ApiConstants.businessURL}/${businessId}`,
      payload
    );
    return response.data;
  }

  static async getSelectableBusiness(): Promise<IBusinessResponse[]> {
    const response = await privateAxios.get(
      `${ApiConstants.getSelectableBusinesses}`
    );
    return response.data;
  }

  static async getMyBusinessRoles(businessId: string) {
    const response = await privateAxios.get(
      `${ApiConstants.businessURL}/${businessId}/my-role`
    );
    return response.data;
  }

  static async getBusinessCredit() {
    const response = await privateAxios.get(
      `${ApiConstants.businessCreditURL}`
    );
    return response.data;
  }

  static async getBusinessResources() {
    const response = await privateAxios.get(
      `${ApiConstants.businessURL}/resource/list`
    );
    return response.data;
  }

  static async getBusinessById(businessId: string) {
    const response = await privateAxios.get(
      `${ApiConstants.businessURL}/${businessId}`
    );
    return response.data;
  }

  static async setBusinessDefaultTax({
    businessId,
    taxId,
  }: {
    businessId: string;
    taxId: string;
  }) {
    const response = await privateAxios.post(
      `${ApiConstants.businessURL}/${businessId}/default-tax/${taxId}`
    );
    return response.data;
  }

  static async getBuyerCreditInfo({
    buyerBusinessId,
  }: {
    buyerBusinessId: string;
  }) {
    const response = await privateAxios.get(
      `${ApiConstants.businessURL}/${buyerBusinessId}/credit-info`
    );
    return response.data;
  }

  static async getBusinessPublicData({ businessId }: { businessId: string }) {
    const response = await privateAxios.get(
      `${ApiConstants.businessURL}/public/data/${businessId}`
    );
    return response.data;
  }
}
