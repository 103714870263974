import { ArrowUpTrayIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Button, Card, Checkbox, Divider, FormControl, FormHelperText, FormLabel, IconButton, Input, Option, Select, Stack, Typography } from "@mui/joy";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { currencyList } from "../../Order/EditOrders";
import { animateSelectStyle } from "../../../styles/select";
import { KeyboardArrowDown } from "@mui/icons-material";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import useContainer from "./useContainer";
import { theme } from "../../../components/Theme";
import LoadingModal from "../../../components/LoadingModal/LoadingModal";
import { SuccessModal } from "../../../components/SuccessModal";
import { useLocation, useNavigate } from "react-router-dom";
import { BusinessSessionHelper } from "../../../helpers/business-session.helper";
import { getCountryInfo } from "../../../utils/country";
import { findBankOptions } from "../../../utils/bank-options";
import CurrencyDolar from "../../../components/Icons/CurrencyDolar";
import { findAverageValueOfTransactionsByCountry, findEstimatedAnualRevenueOptionsByCountry } from "../../../utils/kyc-form-options";


export default function ProfileKYC() {
  const { t } = useTranslation();
  const [isAcceptTerms, setIsAcceptTerms] = React.useState(false);
  const navigate = useNavigate();
  const { state } = useLocation()
  const BANK_OPTIONS = findBankOptions(BusinessSessionHelper.Business()?.countryCode ?? 'ID')
  const ESTIMATED_ANUAL_REVENUE = findEstimatedAnualRevenueOptionsByCountry(BusinessSessionHelper.Business()?.countryCode ?? 'ID')
  const AVERAGE_VALUE_OF_TRANSACTIONS = findAverageValueOfTransactionsByCountry(BusinessSessionHelper.Business()?.countryCode ?? 'ID')

  const {
    companyProfileFile,
    personalIDFile,
    isLoading,
    successModalOpen,
    setCompanyProfileFile,
    setPersonalIDFile,
    register,
    errors,
    setError,
    onSubmit,
    handleSubmit,
    setValue,
    watch,
    setSuccessModalOpen,
  } = useContainer();

  const initDragDropListener = (zoneClassName: string, fileHandler: (file: File | null) => void, errorHandler: () => void) => {
    const dropZone = document.querySelector(zoneClassName);
    dropZone?.addEventListener('dragover', (e) => {
      e.preventDefault();
      dropZone?.setAttribute('style', 'background: #f2f2f2;')
      console.log("Drag", e);
    });
    dropZone?.addEventListener('dragleave', () => {
      dropZone?.setAttribute('style', 'background: #ffffff;')
    });
    dropZone?.addEventListener('drop', (e) => {
      e.preventDefault();
      dropZone?.setAttribute('style', 'background: #ffffff;')
      console.log("Drop", e);
      onFileChange(e, (file: File) => {
        if (!["image/jpeg", "image/png", "application/pdf"].includes(file.type)) {
          errorHandler()
          return
        }
        fileHandler(file)
      })
    },);
  }

  const onFileChange = (e: any, handler?: (file: File) => void) => {
    const files = e.target?.files || e.dataTransfer?.files;
    console.log("FILE", files);
    if ((files?.length ?? 0) > 0) {
      handler?.(files[0]);
    }
  }

  useEffect(() => {
    initDragDropListener(".drop-zone-business-profile-file", (file) => {
      setCompanyProfileFile(file)
      setError("companyProfileFileUrl", {})
    }, () => setError("companyProfileFileUrl", {
      message: "File type not supported"
    }))
    initDragDropListener(".drop-zone-personal-id-file", (file) => {
      setPersonalIDFile(file)
      setError("personalIdFileUrl", {})
    }, () => setError("personalIdFileUrl", {
      message: "File type not supported"
    }))
  }, [])

  return <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2} width={{ xs: "90vw", lg: "100%" }}
      >
        <Typography
          fontWeight={"lg"}
          fontSize={"lg"}
          textColor="neutral.900"
        >
          {t("profileKYC.title")}
        </Typography>
        <Typography
          fontWeight={"md"}
          fontSize={"md"}
          textColor="neutral.900"
        >
          {t("profileKYC.subTitle")}
        </Typography>
        <Card sx={{ paddingLeft: "2rem", paddingRight: "2rem", paddingBottom: "2rem" }}>
          <Stack gap={2} justifyContent={"center"} alignItems={"center"}>
            <Typography>{t("profileKYC.scanCompanyFile")}
              [<Typography fontWeight={"lg"}>{t("profileKYC.scanCompanyFileName")}</Typography>] &
              [<Typography fontWeight={"lg"}>{t(`profileKYC.forms.${BusinessSessionHelper.Business()?.countryCode.toLowerCase()}.scanCompanyFileKTP`)}</Typography>].
            </Typography>
            {!companyProfileFile
              ? <Card
                className="drop-zone-business-profile-file"
                sx={{
                  width: "100%",
                  height: "160px",
                  border: '1px dashed #62A583',
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <Stack gap={2} justifyContent={"center"} alignItems={"center"}>
                  <Button onClick={() => document.getElementById("company-profile-file")?.click()} startDecorator={<ArrowUpTrayIcon width={20} height={20} />} variant="plain" color="primary">Upload Here</Button>
                  <Typography>{t("profileKYC.dragFileDesc")}</Typography>
                  <input
                    id="company-profile-file"
                    type="file"
                    accept="image/*, application/pdf"
                    style={{ display: "none" }}
                    onChange={(e) => onFileChange(e, (file: File) => {
                      setCompanyProfileFile(file)
                      setError("companyProfileFileUrl", {})
                    })}
                  />
                </Stack>
              </Card>
              : <Card sx={{ width: "100%" }}>
                <Stack direction={"row"} gap={2} justifyContent={"start"} alignItems={"center"}>
                  <img src="/images/imgIcon.svg" />
                  <Stack flex={1}>
                    <Typography fontWeight={"lg"} fontSize={"md"}>{companyProfileFile?.name}</Typography>
                    <Typography fontSize={"sm"}>{(companyProfileFile?.size / (1024 * 1024)).toFixed(2)} MB</Typography>
                  </Stack>
                  <IconButton onClick={() => setCompanyProfileFile(null)}><TrashIcon color={theme.colorSchemes.light.palette.danger[500]} /></IconButton>
                </Stack>
              </Card>}
            {errors.companyProfileFileUrl && <FormHelperText sx={{ color: "red" }}>{errors.companyProfileFileUrl.message}</FormHelperText>}
          </Stack>
        </Card>
        <Stack gap={2} direction={{ xs: "column", md: "row" }}>
          <div style={{ flex: 1 }}>
            <FormLabel>{t("profileKYC.formLabel1")}</FormLabel>
            <FormControl sx={{ flex: 1 }} error={!!errors.estimatedAnualIncome || !!errors.estimatedAnualIncomeCurrency}>
              <Select
                sx={{ ...animateSelectStyle }}
                indicator={<KeyboardArrowDown />}
                defaultValue={ESTIMATED_ANUAL_REVENUE[0]}
                onChange={(_, value) => setValue("estimatedAnualIncome", value ?? "")}
                startDecorator={
                  <React.Fragment>
                    <Select
                      disabled
                      variant="plain"
                      defaultValue={getCountryInfo(BusinessSessionHelper.Business()?.companyCountry ?? 'IDR', 'LOCALE')?.currency}
                      indicator={<></>}
                      onChange={(_, value) => setValue("estimatedAnualIncomeCurrency", value ?? 'IDR')}
                      sx={{ ...animateSelectStyle, paddingLeft: 0 }}
                    >
                      {currencyList.map(c => {
                        return <Option id={c} value={c}>{c}</Option>
                      })}
                    </Select>
                    <Divider orientation="vertical" />
                  </React.Fragment>
                }
              >
                {ESTIMATED_ANUAL_REVENUE.slice(1).map((option: any) => <Option key={option} value={option}>{option}</Option>)}
              </Select>
            </FormControl>
            {(errors.estimatedAnualIncome || errors.estimatedAnualIncomeCurrency) && <FormHelperText sx={{ color: "red" }}>{(errors.estimatedAnualIncome?.message || errors.estimatedAnualIncomeCurrency?.message)}</FormHelperText>}
          </div>
          <div style={{ flex: 1 }}>
            <FormLabel>{t("profileKYC.formLabel2")}</FormLabel>
            <FormControl sx={{ flex: 1 }} error={!!errors.averageValuePerTransaction || !!errors.averageValuePerTransactionCurrency}>
              <Select
                sx={{ ...animateSelectStyle }}
                indicator={<KeyboardArrowDown />}
                defaultValue={AVERAGE_VALUE_OF_TRANSACTIONS[0]}
                onChange={(_, value) => setValue("averageValuePerTransaction", value ?? "")}
                startDecorator={
                  <React.Fragment>
                    <Select
                      disabled
                      variant="plain"
                      defaultValue={getCountryInfo(BusinessSessionHelper.Business()?.companyCountry ?? 'IDR', 'LOCALE')?.currency}
                      indicator={<></>}
                      onChange={(_, value) => setValue("averageValuePerTransactionCurrency", value ?? 'IDR')}
                      sx={{ ...animateSelectStyle, paddingLeft: 0 }}
                    >
                      {currencyList.map(c => {
                        return <Option value={c}>{c}</Option>
                      })}
                    </Select>
                    <Divider orientation="vertical" />
                  </React.Fragment>
                }
              >
                {AVERAGE_VALUE_OF_TRANSACTIONS.slice(1).map((option: any) => <Option key={option} value={option}>{option}</Option>)}
              </Select>
            </FormControl>
            {(errors.averageValuePerTransaction || errors.averageValuePerTransactionCurrency) && <FormHelperText sx={{ color: "red" }}>{(errors.averageValuePerTransaction?.message || errors.averageValuePerTransactionCurrency?.message)}</FormHelperText>}
          </div>
        </Stack>
        <div>
          <FormLabel>{t("profileKYC.formLabel3")}</FormLabel>
          <FormControl error={!!errors.bankName}>
            <Select
              indicator={<KeyboardArrowDown />}
              sx={{ ...animateSelectStyle }}
              placeholder={t("profileKYC.formLabel3Placeholder")}
              defaultValue={null}
              onChange={(_, value) => setValue("bankName", (value as any)?.code ?? "")}
              startDecorator={
                <React.Fragment>
                  {BANK_OPTIONS.find((option: any) => option.code === watch("bankName"))?.icon && <Card sx={{
                    backgroundImage: `url(${BANK_OPTIONS.find((option: any) => option.code === watch("bankName"))?.icon ?? BANK_OPTIONS[0].icon})`,
                    backgroundSize: 'cover',
                    borderColor: "transparent",
                    marginRight: "0.5rem",
                  }} /> || <div style={{ marginRight: "0.5rem" }}><CurrencyDolar /></div>}
                  <Divider orientation="vertical" />
                </React.Fragment>
              }
            >
              {BANK_OPTIONS.slice(1).map((option: any) => <Option key={option.code} value={option}>
                {option.name}
              </Option>)}
            </Select>
          </FormControl>
          {(errors.bankName) && <FormHelperText sx={{ color: "red" }}>{(errors.bankName?.message)}</FormHelperText>}

        </div>
        <div>
          <FormLabel>{t("profileKYC.formLabel4")}</FormLabel>
          <FormControl error={!!errors.accountNumber}>
            <Input type="number" {...register("accountNumber")} />
          </FormControl>
          {(errors.accountNumber) && <FormHelperText sx={{ color: "red" }}>{(errors.accountNumber?.message)}</FormHelperText>}
        </div>
        <Card sx={{ paddingLeft: "2rem", paddingRight: "2rem", paddingBottom: "2rem" }}>
          <Stack gap={2} justifyContent={"center"} alignItems={"center"}>
            <Stack width={"100%"} direction={"column"} gap={2} justifyContent={"start"} alignItems={"start"}>
              <Typography>{t("profileKYC.scanPersonalIDDesc")}</Typography>
              <Stack width={"100%"} direction={"row"} gap={3} justifyContent={"center"} alignItems={"center"} >
                <Stack gap={1} justifyContent={"center"} alignItems={"center"}>
                  <img src={`/images/correct${BusinessSessionHelper.Business()?.countryCode}.svg`} />
                  <Typography startDecorator={<CheckIcon color="#489C5E" width={30} height={30} />} fontSize={"lg"} fontWeight={"lg"}>{t("profileKYC.personalIDCorrectTitle")}</Typography>
                  <Typography>{t("profileKYC.personalIDCorrectDesc")}</Typography>
                </Stack>
                <Stack gap={1} justifyContent={"center"} alignItems={"center"}>
                  <img src={`/images/inCorrect${BusinessSessionHelper.Business()?.countryCode}.svg`} />
                  <Typography startDecorator={<XMarkIcon color="darkred" width={30} height={30} />} fontSize={"lg"} fontWeight={"lg"}>{t("profileKYC.personalIDInCorrectTitle")}</Typography>
                  <Typography>{t("profileKYC.personalIDInCorrectDesc")}</Typography>
                </Stack>
              </Stack>
            </Stack>
            {!personalIDFile
              ? <Card
                className="drop-zone-personal-id-file"
                sx={{
                  width: "100%",
                  height: "160px",
                  border: '1px dashed #62A583',
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <Stack gap={2} justifyContent={"center"} alignItems={"center"}>
                  <Button onClick={() => document.getElementById("personal-id-file")?.click()} startDecorator={<ArrowUpTrayIcon width={20} height={20} />} variant="plain" color="primary">Upload Here</Button>
                  <Typography>{t("profileKYC.dragFileDesc")}</Typography>
                  <input
                    id="personal-id-file"
                    type="file"
                    accept="image/*, application/pdf"
                    style={{ display: "none" }}
                    onChange={(e) => onFileChange(e, (file: File) => {
                      setPersonalIDFile(file)
                      setError("personalIdFileUrl", {})
                    })}
                  />
                </Stack>
              </Card>
              : <Card sx={{ width: "100%" }}>
                <Stack direction={"row"} gap={2} justifyContent={"start"} alignItems={"center"}>
                  <img src="/images/imgIcon.svg" />
                  <Stack flex={1}>
                    <Typography fontWeight={"lg"} fontSize={"md"}>{personalIDFile?.name}</Typography>
                    <Typography fontSize={"sm"}>{(personalIDFile?.size / (1024 * 1024)).toFixed(2)} MB</Typography>
                  </Stack>
                  <IconButton onClick={() => setPersonalIDFile(null)}><TrashIcon color={theme.colorSchemes.light.palette.danger[500]} /></IconButton>
                </Stack>
              </Card>}
            {(errors.personalIdFileUrl) && <FormHelperText sx={{ color: "red" }}>{(errors.personalIdFileUrl?.message)}</FormHelperText>}
          </Stack>
        </Card>
        <p>
          <Typography startDecorator={<Checkbox onChange={(e) => setIsAcceptTerms(e.target.checked)} />}>
            <span>
              {t("profileKYC.terms")}
              <span className={"text-[#499873] cursor-pointer"} style={{ fontWeight: "bold", cursor: "pointer" }}>
                <a href={`${import.meta.env.VITE_TC_URL ?? '#'}`} target="_blank">
                  {t("landing.signup.terms")}
                </a>
              </span> &
              <span className={"text-[#499873] cursor-pointer"} style={{ fontWeight: "bold", cursor: "pointer" }}>
                <a href={`${import.meta.env.VITE_PP_URL ?? '#'}`} target="_blank">
                  {t("landing.signup.privacy")}
                </a>
              </span>
            </span>
          </Typography>
        </p>
        <Stack direction={"row"} gap={2} justifyContent={"end"} alignItems={"center"}>
          <Button disabled={!isAcceptTerms} sx={{ width: { xs: "100%", md: "auto" } }} type="submit">{t("commons.submitBtn")}</Button>
        </Stack>
      </Stack >
    </form >
    <LoadingModal isLoading={isLoading} />
    <SuccessModal
      open={successModalOpen}
      onClose={() => {
        setSuccessModalOpen(false)
        if (state?.backUrl) {
          window.location.href = state.backUrl
        } else {
          navigate("/client/profile")
        }
      }}
      title={t("commons.successModalTitle")}
      content={t("profileKYC.modalSuccessDesc")} />
  </>;
}