import { privateAxios } from "../lib/api";
import { ApiConstants } from "../utils/constants";

export class KycPaymentGatewayService {
  static async submit(payload: any) {
    const response = await privateAxios.post(
      ApiConstants.KycPaymentGateway,
      payload
    );
    return response.data;
  }

  static async getVerificationStatus(sellerId?: string | null) {
    const response = await privateAxios.get(
      `${ApiConstants.KycPaymentGateway}/verification/status` +
        (sellerId && typeof sellerId === "string" ? `?businessId=${sellerId}` : "")
    );
    return response.data;
  }

  static async checkOrderVerificationStatus() {
    const response = await privateAxios.get(
      `${ApiConstants.KycPaymentGateway}/order-verification/status`
    );
    return response.data;
  }
}
