import { Button, Modal, Stack } from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import CheckCircleIcon from "./Icons/CheckCircle";

interface IProps {
  open: boolean;
  title: string;
  content: string;
  onClose?: () => void;
}

export function SuccessModal({ open, title, content, onClose }: IProps) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 329,
    height: "auto",
    bgcolor: "#fff",
    boxShadow: 24,
    px: 4,
    py: "22px",
    borderRadius: "32px",
    borderColor: "transparent",
    outline: "none",
  };

  return (
    <Modal
      open={open}
      style={{ backgroundColor: "#32383E99", backdropFilter: "unset" }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack alignItems={"center"}>
          <CheckCircleIcon />
          <Typography
            mt={4}
            textColor={"text.primary"}
            fontWeight={600}
            fontSize={24}
            id="modal-modal-title"
          >
            {title}
          </Typography>
          <Typography
            textAlign={"center"}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            {content}
          </Typography>
        {onClose && (<Button sx={{ marginTop: "1rem" }} onClick={onClose}>OK</Button>)}
        </Stack>
      </Box>
    </Modal>
  );
}
