import { Box, Button, Modal, Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  show?: boolean;
  onClick: () => void;
  onClose: () => void;
  selectedItems: string[];
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  width: "94%",
  maxWidth: 600,
  px: 4,
  py: 4,
  borderRadius: "32px",
  borderColor: "transparent",
  outline: "none",
};

const ConfirmDeleteItemsModal = ({
  show = false,
  onClick,
  onClose,
  selectedItems,
}: Props) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const handleClick = () => {
    onClick();
    setShowModal(false);
    onClose();
  };

  const handleClose = () => {
    setShowModal(false);
    onClose();
  };

  return (
    <Modal
      open={showModal}
      style={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} justifyContent={"center"}>
        <Typography
          mt={4}
          textAlign={"left"}
          fontWeight={600}
          fontSize={24}
          id="modal-modal-title"
          sx={{
            color: "#265641",
          }}
        >
          {t("catalog.inventory.removeItemsModalLabel", {
            selected: selectedItems.length,
          })}
        </Typography>
        <div className="w-full flex flex-col">
          {/* copy link  */}
          <Stack direction={"row"} justifyContent={"left"} mt={4}>
            <Typography>
              {t("catalog.inventory.removeCatalogModalPrompt")}
            </Typography>
          </Stack>

          {/* buttons */}
          <Stack direction={"row"} justifyContent={"left"} mt={4}>
            <Button
              type="button"
              variant="plain-gray"
              className="ml-auto"
              onClick={handleClose}
            >
              {t("commons.cancelBtn")}
            </Button>
            <Button
              type="button"
              onClick={handleClick}
              sx={{
                marginLeft: "2rem",
                padding: 1.5,
              }}
            >
              {t("commons.deleteBtn")}
            </Button>
          </Stack>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmDeleteItemsModal;
