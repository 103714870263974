const countryInfo = [
  { locale: "ID", name: "Indonesia", currency: "IDR" },
  { locale: "US", name: "United States", currency: "USD" },
  { locale: "JP", name: "Japan", currency: "JPY" },
  { locale: "GB", name: "United Kingdom", currency: "GBP" },
  { locale: "IN", name: "India", currency: "INR" },
  { locale: "AU", name: "Australia", currency: "AUD" },
  { locale: "CA", name: "Canada", currency: "CAD" },
  { locale: "DE", name: "Germany", currency: "EUR" },
  { locale: "FR", name: "France", currency: "EUR" },
  { locale: "BR", name: "Brazil", currency: "BRL" },
  { locale: "CN", name: "China", currency: "CNY" },
  { locale: "RU", name: "Russia", currency: "RUB" },
  { locale: "KR", name: "South Korea", currency: "KRW" },
  { locale: "ZA", name: "South Africa", currency: "ZAR" },
  { locale: "MY", name: "Malaysia", currency: "MYR" },
  { locale: "TH", name: "Thailand", currency: "THB" },
  { locale: "SG", name: "Singapore", currency: "SGD" },
];

export const getCountryInfo = (
  identifier: string,
  type: "LOCALE" | "NAME" | "CURRENCY" = "LOCALE"
) => {
  return countryInfo.find((c) => {
    switch (type) {
      case "LOCALE":
        return c.locale === identifier;

      case "NAME":
        return c.name === identifier;

      case "CURRENCY":
        return c.currency === identifier;
    }
  });
};

/**
 *
 * @param business
 * @returns "country_name - currency"
 */
export const getCountryAndCurrency = (business: any) => {
  if (!business) {
    return "-";
  }

  const country = getCountryInfo(business.bankInfo?.[0]?.currency, "CURRENCY");
  return `${country?.name} - ${country?.currency}`;
};
