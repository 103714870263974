import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import { KYCService } from "../../services/kyc.service";
import { setKYCComplete } from "../../store/session";

// Define the Zod schema
const KYCSchema = z.object({
  role: z.string().min(1, "Role is required"),
  size: z.string().min(1, "Company size is required"),
  source: z.string().min(1, "Source is required"),
  sourceOther: z.string().optional(),
  industry: z.string().min(1, "Industry is required"),
  industryOther: z.string().optional(),
});

type KYCFormData = z.infer<typeof KYCSchema>;

const useContainer = () => {
  //#region ----- local state
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(KYCSchema),
    defaultValues: {
      role: "",
      size: "",
      source: "",
      sourceOther: "",
      industry: "",
      industryOther: "",
    },
  });
  const industry = watch("industry");
  const source = watch("source");

  //#region ----- life cycle method
  useEffect(() => {
    if (industry.toLowerCase() !== "other") {
      setValue("industryOther", "");
    }
    if (source.toLowerCase() !== "other") {
      setValue("sourceOther", "");
    }
  }, [industry, source, setValue]);

  //#region ----- handler function

  const kycQuery = useQuery({
    queryKey: ["kyc", "verification"],
    queryFn: KYCService.isKYCDataVerified,
  });

  useEffect(() => {
    if (kycQuery?.data?.isVerified) {
      setKYCComplete();
    }
  }, [kycQuery]);

  const kycMutation = useMutation({
    mutationFn: KYCService.setKYCData,
    onSuccess: () => {
      setKYCComplete();
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const onSubmit = (data: KYCFormData) => {
    kycMutation.mutate(data);
  };

  return {
    onSubmit,
    errors,
    handleSubmit,
    isValid,
    control,
    industry,
    source,
    isLoading: kycMutation.isPending || kycQuery.isPending,
  };
};

export default useContainer;
