import { DangerousOutlined, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Card, CardContent, Divider, Stack, Typography } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { If } from "../../../../components/Condition";
import { useTranslation } from "react-i18next";

export interface IContactListProps {
  contacts: any[]
}
export function ContactList(props: IContactListProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return <>
    <Card>
      <CardContent>
        <If condition={props.contacts.length === 0}>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <DangerousOutlined color="primary" sx={{ width: "100px", height: "100px" }} />
            <br />
            <Typography fontWeight={"lg"} fontSize={"sm"}>{t("manage.businessContact.emptyContactDesc")}</Typography>
          </Stack>
        </If>
        {props.contacts.map((contact) => (
          <Stack gap={1} marginBottom={"1rem"} onClick={() => { navigate(`/client/business-contact/update/${contact.id}`, { state: { contact } }) }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Stack flex={1} gap={0.1}>
                <Typography fontWeight={"lg"} fontSize={"md"}>{contact.nameOfContact}</Typography>
                <Typography fontWeight={"sm"} fontSize={"sm"}>{contact.companyName}</Typography>
              </Stack>
              <KeyboardArrowRight />
            </Box>
            <Divider />
          </Stack>
        ))}
      </CardContent>
    </Card>
  </>
}