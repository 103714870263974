import { Autocomplete, Box, Button, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { EmployeeService } from "../../../../services/employee.service";
import { useTranslation } from "react-i18next";

export interface IShareContactModalProps {
  open: boolean
  onClose: () => void
  onFinish?: (emails: string[]) => void
}
export default function ShareContactModal(props: IShareContactModalProps) {
  const { t } = useTranslation()
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState<any[]>([])

  const onGetRecipients = () => {
    EmployeeService.getAll({}).then((data) => {
      console.log("PARTNER", data)
      setOptions(data.map((d: any) => {
        return {
          label: d.email,
          value: d.email,
          name: d.fullName
        }
      }))
    })
  }
  useEffect(() => {
    onGetRecipients()
  }, [])
  return <Modal
    open={props.open}
    onClose={props.onClose}
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <ModalDialog
      layout="center"
      maxWidth={"lg"}
      sx={{
        width: "100%",
        maxWidth: "600px",
        borderRadius: "md",
        p: 2,
      }}
    >
      <ModalClose variant="plain" sx={{ m: 1 }} onClick={props.onClose} />
      <Typography
        textColor={"primary.solidActiveBg"}
        textAlign={"center"}
        level="h4"
        fontWeight={600}
      >
        Share Contact
      </Typography>
      <Autocomplete
        type="email"
        multiple
        placeholder="Search partner's email"
        options={options}
        renderOption={(props, option: any) => (
          <Typography padding={"1rem"} {...props}>{option.label} (<Typography>{option.name}</Typography>)</Typography>
        )}
        onChange={(_, value: any) => { setSelected([...selected, ...value.map((d: any) => d.value)]) }}
      />
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        gap={2}
        alignItems={"center"}
      >
        <Button
          variant="outlined"
          color="neutral"
          size="sm"
          sx={{
            borderRadius: "10px",
            height: "40px",
          }}>
          {t("commons.cancelBtn")}
        </Button>
        <Button
          onClick={() => {
            props.onFinish?.(selected)
          }}
          disabled={selected.length === 0}
          variant="solid"
          size="sm"
          sx={{
            borderRadius: "10px",
            height: "40px",
          }}>
          {t("commons.saveBtn")}
        </Button>
      </Box>
    </ModalDialog>
  </Modal>
}