import { Input, Option, Select } from "@mui/joy";
import { Controller } from "react-hook-form";
import LanguageSelector from "../../components/LanguageSelector";
import {
  KYC_INDUSTRIES,
  KYC_ROLE,
  KYC_SIZE,
  KYC_WHERE_DID_YOU_LEARN,
} from "../../utils/constants";
import useContainer from "./useContainer";

const KYC = () => {
  const {
    onSubmit,
    errors,
    handleSubmit,
    isValid,
    control,
    industry,
    source,
    isLoading,
  } = useContainer();
  return (
    <div className="flex flex-col gap-8 p-4 md:p-8">
      <div className="flex items-center justify-end w-full">
        <LanguageSelector />
      </div>
      <div className="flex flex-col gap-2">
        <h1 className="text-[#101828] text-2xl font-bold">
          Let us know your better
        </h1>
        <p className="text-[#101828] text-xl font-medium">
          Please fill these information below to proceed to your account.
        </p>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-8 p-0 md:p-8 rounded-lg border-0 md:border border-[#EAECF0] shadow-none md:shadow-[0px_1px_3px_0px_#1018281A]"
      >
        <div className="flex flex-col gap-1">
          <label htmlFor="industry" className="text-[#101828] text-sm ">
            Select industry
          </label>
          <Controller
            name="industry"
            control={control}
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  id="industry"
                  className="text-sm"
                  onChange={(_, value) => field.onChange(value)}
                >
                  <Option value={""} disabled className="text-sm">
                    Select industry that describes your
                  </Option>
                  {KYC_INDUSTRIES.map((industry) => (
                    <Option key={industry} value={industry} className="text-sm">
                      {industry}
                    </Option>
                  ))}
                </Select>
                {errors.industry && (
                  <p className="text-sm text-red-500">
                    {errors.industry.message}
                  </p>
                )}
              </>
            )}
          />
          {industry.toLowerCase() === "other" && (
            <Controller
              name="industryOther"
              control={control}
              render={({ field }) => (
                <Input {...field} placeholder="Type your industry" />
              )}
            />
          )}
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="role" className="text-[#101828] text-sm ">
            Role in the company
          </label>
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  id="role"
                  className="text-sm"
                  onChange={(_, value) => field.onChange(value)}
                >
                  <Option value={""} disabled className="text-sm">
                    Select your role in the company business
                  </Option>
                  {KYC_ROLE.map((role) => (
                    <Option key={role} value={role} className="text-sm">
                      {role}
                    </Option>
                  ))}
                </Select>
                {errors.role && (
                  <p className="text-sm text-red-500">{errors.role.message}</p>
                )}
              </>
            )}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="size" className="text-[#101828] text-sm ">
            Size company
          </label>
          <Controller
            name="size"
            control={control}
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  id="size"
                  className="text-sm"
                  onChange={(_, value) => field.onChange(value)}
                >
                  <Option value={""} className="text-sm">
                    Select your size of your company
                  </Option>
                  {KYC_SIZE.map((size) => (
                    <Option key={size} value={size} className="text-sm">
                      {size}
                    </Option>
                  ))}
                </Select>
                {errors.size && (
                  <p className="text-sm text-red-500">{errors.size.message}</p>
                )}
              </>
            )}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="source" className="text-[#101828] text-sm ">
            Where did you learn about us
          </label>
          <Controller
            name="source"
            control={control}
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  id="source"
                  className="text-sm"
                  onChange={(_, value) => field.onChange(value)}
                >
                  <Option value={""} disabled className="text-sm">
                    Select one of the resources
                  </Option>
                  {KYC_WHERE_DID_YOU_LEARN.map((source) => (
                    <Option key={source} value={source} className="text-sm">
                      {source}
                    </Option>
                  ))}
                </Select>
                {errors.source && (
                  <p className="text-sm text-red-500">
                    {errors.source.message}
                  </p>
                )}
              </>
            )}
          />
          {source.toLowerCase() === "other" && (
            <Controller
              name="sourceOther"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="So, where did you learn about us"
                />
              )}
            />
          )}
        </div>
        <button
          type="submit"
          disabled={!isValid || isLoading}
          className={`px-4 py-3 rounded-lg ${
            isValid && !isLoading ? "bg-[#499873] text-white" : "bg-[#EAECF0]"
          } w-fit ml-auto`}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default KYC;
