import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Stack,
  Typography,
} from "@mui/joy";
import React from "react";
import OTPInput from "react-otp-input";
import { If, IfElse } from "../../components/Condition";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { SuccessModal } from "../../components/SuccessModal";
import useContainer from "./useContainer";
import { useTranslation } from "react-i18next";

export default function MobileOtpVerification() {
  const { t } = useTranslation();
  const {
    phone,
    isSuccess,
    resendOTPCode,
    setOtpInput,
    formatTime,
    otpInput,
    maxTries,
    seconds,
    disabled,
    isError,
    isLoading,
  } = useContainer();

  return (
    <React.Fragment>
      <Divider />

      <form
        id="update-full-name-form"
        aria-label="profiles-full-name"
        onSubmit={() => {}}
      >
        <Box
          sx={{
            display: "flex",
            marginTop: 5,
            flexDirection: "column",
            width: { xs: "90vw", lg: "100%" },
          }}
          component="section"
        >
          <FormControl>
            <FormLabel>
              {t("profile.verify.prompt", { phoneNumber: phone })}
            </FormLabel>
            <Box
              sx={{
                marginTop: 5,
                alignSelf: "center",
                display: "flex",
                width: { xs: "90vw", lg: "100%" },
              }}
            >
              <OTPInput
                inputType="number"
                containerStyle={{ width: "100%" }}
                value={otpInput}
                onChange={setOtpInput}
                numInputs={6}
                renderSeparator={<span className="w-2"></span>}
                renderInput={(props: any) => (
                  <input
                    {...props}
                    disabled={disabled}
                    className={`
                      ${
                        isError
                          ? "focus:outline-none active:outline-none"
                          : "focus:outline-[#499873] active:outline-[#499873]"
                      }
                      w-[16%] lg:w-[59.4px] h-[50px] lg:h-[64px]
                    `}
                    style={{
                      textAlign: "center",
                      borderRadius: "8px",
                      border: `1px solid ${isError ? "#C41C1C" : "#CDD7E1"}`,
                      background: "#FFF",
                      boxShadow: "0px 1px 2px 0px rgba(21, 21, 21, 0.08)",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      flexShrink: 0,
                    }}
                  />
                )}
              />
            </Box>
          </FormControl>
          <div style={{ marginTop: 20 }}>
            <If condition={isError}>
              <Typography
                textColor={"danger.500"}
                textAlign={"center"}
                fontSize={"16px"}
                fontWeight={400}
                pb={4}
              >
                {t("landing.verify.invalid")}
              </Typography>
            </If>
            <Stack flexDirection={"row"} alignItems={"center"}>
              <Stack flex={1} rowGap={1}>
                <Typography
                  fontSize={16}
                  textColor={"text.secondary"}
                  fontWeight={600}
                >
                  {t("landing.verify.requestAgain")}
                </Typography>
                <Typography
                  fontSize={16}
                  textColor={"text.secondary"}
                  fontWeight={600}
                >
                  {t("landing.verify.requestChances", { chances: maxTries })}
                  {maxTries === 0 && t("landing.verify.requestChancesNull")}
                </Typography>
              </Stack>
              <IfElse
                condition={seconds !== 0 && maxTries > 0}
                ifBlock={
                  <Typography
                    textColor={"text.primary"}
                    fontSize={"16px"}
                    fontWeight={600}
                  >
                    {formatTime()}
                  </Typography>
                }
                elseBlock={
                  <If condition={maxTries > 0}>
                    <Button
                      onClick={resendOTPCode}
                      size="lg"
                      sx={{ margin: "auto" }}
                      variant="plain"
                    >
                      {t("landing.verify.resendBtn")}
                    </Button>
                  </If>
                }
              />
            </Stack>
          </div>
        </Box>
      </form>
      <SuccessModal
        open={isSuccess}
        title={t("commons.successModalTitle")}
        content={"Your mobile number has been changed."}
      />
      <LoadingModal isLoading={isLoading} />
    </React.Fragment>
  );
}
