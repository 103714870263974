import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import { BusinessService } from "../../services/business.service";
import { PaymentService } from "../../services/payment.service";
import { CreditTransactionService } from "../../services/transaction.service";
import { useAuthStore } from "../../store/session";
import { onMessage } from "firebase/messaging";
import { messagingApp } from "../../lib/firebase";


const useContainer = () => {
  const queryClient = useQueryClient();
  const { session } = useAuthStore();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isPending, data } = useQuery({
    queryKey: ["business-credits", session?.activeBusiness.id],
    queryFn: () => BusinessService.getBusinessCredit(),
  });
  const [searchParam] = useSearchParams();

  const isTransactionPendingMutation = useMutation({
    mutationFn: CreditTransactionService.isTransactionPending,
    onSuccess: () => {
      if (creditTransactionId && transactionId) {
        setLoading(true);
        PaymentService.enquiryPaymentStatus(transactionId)
          .then((_) => {
            setLoading(false);
            setShowSuccessModal(true);
            setTimeout(() => {
              queryClient.invalidateQueries({
                queryKey: ["credits-transactions"],
              });
              queryClient.invalidateQueries({
                queryKey: ["business-credits"],
              });
              setShowSuccessModal(false);
              navigate("/client/credit");
            }, 2000);
          })
          .catch((error) => {
            setLoading(false);
            toast(showErrorToast(error), ErrorToastConfig);
          });
      }
    },
    onError: (error) => {
      toast(showErrorToast(error), ErrorToastConfig);
      navigate("/client/credit");
    },
  });

  const activeTab = searchParams.get("activeTab") ?? "credits";
  const creditTransactionId = searchParams.get("creditTransactionId");
  const transactionId = searchParams.get("transaction_id");
  //#region ----- life cycle method
  useEffect(() => {
    if (creditTransactionId && transactionId) {
      isTransactionPendingMutation.mutate(creditTransactionId);
    }
  }, [creditTransactionId, transactionId]);

  useEffect(() => {
    const transactionId = searchParam.get("transaction_id");
    if (transactionId) {
      setLoading(true);
      PaymentService.enquiryPaymentStatus(transactionId)
        .then((_) => {
          setLoading(false);
          setShowSuccessModal(true);
          setTimeout(() => {
            window.location.href = window.location.href.split("?")[0];
            setShowSuccessModal(false);
          }, 2000);
        })
        .catch((error) => {
          setLoading(false);
          toast(showErrorToast(error), ErrorToastConfig);
        });
    }
  }, []);



  onMessage(messagingApp(), (payload) => {
    console.log("FCM Message received. ", payload);
    if (`${payload.data?.topic}`.includes("payment-success")) {
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
      }, 2000);
    }
  })


  return {
    isPending: isPending || isTransactionPendingMutation.isPending,
    data,
    activeTab,
    showSuccessModal,
    loading,
  };
};

export default useContainer;
