import {
  Box,
  Button,
  FormControl,
  Modal,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  show?: boolean;
  onConfirm: (reason?: string) => void;
  onClose: () => void;
}

type CancelReasonType = {
  reason: string;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  p: "24px",
  display: "flex",
  flexDirection: "column",
  borderRadius: "32px",
  borderColor: "transparent",
  outline: "none",
};

const CancelOrderReasonModal = ({
  show = false,
  onConfirm,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const methods = useForm<CancelReasonType>({});
  const { handleSubmit, register, setValue, watch } = methods;
  const reasonLength = watch("reason");

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const handleClick = (data: CancelReasonType) => {
    onConfirm(data.reason);
    setShowModal(false);
    setValue("reason", "");
    onClose();
  };

  const handleClose = () => {
    onConfirm();
    setShowModal(false);
    setValue("reason", "");
    onClose();
  };

  return (
    <Modal
      open={showModal}
      style={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        width={{ xs: 362, md: 550 }}
        justifyContent={"center"}
        gap={"24px"}
      >
        <Stack
          direction={"column"}
          justifyContent={"center"}
          gap={"12px"}
          width={"100%"}
        >
          <Typography
            textAlign={"center"}
            fontWeight={600}
            fontSize={24}
            id="modal-modal-title"
            sx={{
              color: "#265641",
            }}
          >
            {t("order.cancelWithReasonModal.modalTitle")}
          </Typography>
          <Typography
            textAlign={"center"}
            fontWeight={400}
            fontSize={16}
            px={"24px"}
          >
            {t("order.cancelWithReasonModal.modalSubtitle")}
          </Typography>
        </Stack>

        <form
          onSubmit={handleSubmit(handleClick)}
          className="flex flex-col gap-[24px]"
        >
          <FormControl>
            <Textarea
              placeholder={t(
                "order.cancelWithReasonModal.form.reasonPlaceholder"
              )}
              minRows={4}
              {...register(`reason`)}
            />
          </FormControl>

          {/* buttons */}
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Button type="button" variant="plain-gray" onClick={handleClose}>
              {t("commons.skipBtn")}
            </Button>
            <Button
              type="submit"
              disabled={reasonLength?.length === 0}
              //   onClick={handleClick}
              sx={{
                marginLeft: "2rem",
                padding: 1.5,
              }}
            >
              {t("commons.submitBtn")}
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export default CancelOrderReasonModal;
