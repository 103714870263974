import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { MoreVert } from "@mui/icons-material";
import { Checkbox } from "@mui/joy";
import { useMutation } from "@tanstack/react-query";
import { Dispatch, SetStateAction } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { BusinessService } from "../../../services/business.service";
import { ITax } from "../../../types";
import { IBusinessResponse } from "../../../types/business";

interface ITaxItemProps {
  tax: ITax;
  index: number;
  defaultTaxId?: string;
  selectedTaxes: string[];
  setSelectedTaxes: Dispatch<SetStateAction<string[]>>;
  setDefaultTaxId: Dispatch<SetStateAction<string | undefined>>;
}

export function TaxItem({
  defaultTaxId,
  tax,
  index,
  selectedTaxes,
  setSelectedTaxes,
  setDefaultTaxId,
}: Readonly<ITaxItemProps>) {
  const { state } = useLocation();
  const businessId = (state as IBusinessResponse)?.id;
  const mutation = useMutation({
    mutationFn: BusinessService.setBusinessDefaultTax,
    onSuccess: () => {
      toast.success("Successfully set default taxId.");
    },
  });
  function setDefaultTax(taxId: string) {
    if (businessId) {
      setDefaultTaxId(taxId);
      mutation.mutate({ businessId, taxId });
    } else {
      setDefaultTaxId(taxId);
    }
  }
  const isDefault = defaultTaxId === tax.id;
  return (
    <div
      key={tax.id}
      className={`flex items-center justify-between w-full lg:w-1/2 ${
        index % 2 === 0 ? "lg:pr-3 px-0" : "lg:pl-3 px-0"
      }`}
    >
      <Checkbox
        size="sm"
        checked={selectedTaxes.includes(tax.id)}
        color={selectedTaxes.includes(tax.id) ? "primary" : undefined}
        onChange={(event) => {
          setSelectedTaxes((ids) =>
            event.target.checked
              ? ids.concat(tax.id)
              : ids.filter((itemId) => itemId !== tax.id)
          );
        }}
        slotProps={{
          checkbox: {
            sx: {
              textAlign: "left",
            },
          },
        }}
        sx={{
          verticalAlign: "text-bottom",
        }}
        label={`${tax.taxLabel} ${isDefault ? "(Default)" : ""}`}
      />
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white text-sm font-semibold text-gray-900">
            <MoreVert />
          </MenuButton>
        </div>

        <MenuItems
          transition
          className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            <MenuItem>
              <div
                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer"
                onClick={() => setDefaultTax(tax.id)}
              >
                Set as default
              </div>
            </MenuItem>
          </div>
        </MenuItems>
      </Menu>
    </div>
  );
}
