import { useMutation } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import { AuthService } from "../../services/auth.service";
const RESEND_CODE_COUNTDOWN = 60 * 3;
const useContainer = () => {
  //#region local states
  const { state } = useLocation();
  const email = state?.email || "";
  const navigate = useNavigate();
  const [otpInput, setOtpInput] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [maxTries, setMaxTries] = useState(3);
  const [seconds, setSeconds] = useState(RESEND_CODE_COUNTDOWN);
  const timerRef = useRef<NodeJS.Timeout | undefined>();
  const passwordResetCodeVerificationMutation = useMutation({
    mutationFn: AuthService.verifyResetPasswordCode,
    onSuccess: ({ data }) => {
      navigate("/set-password", { state: { token: data.token } });
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
      setDisabled(false);
    },
  });
  const resenderifyMutation = useMutation({
    mutationFn: AuthService.resendPasswordResetCode,
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
      setDisabled(false);
    },
  });

  //#endregion

  //#region ----- life cycle methods
  useEffect(() => {
    if (otpInput.trim().length === 6) {
      passwordResetCodeVerificationMutation.mutate({
        code: otpInput,
      });
    }
  }, [otpInput]);

  useEffect(() => {
    timerRef.current = setInterval(countDownInterval, 1000);
    return () => clearInterval(timerRef.current);
  }, []);

  useEffect(() => {
    if (!email) {
      return navigate("/");
    }
  }, []);

  //#endregion

  //#region ----- handler methods
  const countDownInterval = () => {
    setSeconds((prevSeconds) => {
      if (prevSeconds - 1 <= 0) {
        clearInterval(timerRef.current);
        return 0;
      }
      return prevSeconds - 1;
    });
  };

  const resendOTPCode = () => {
    resenderifyMutation.mutate({
      email,
    });
    setSeconds(RESEND_CODE_COUNTDOWN);
    setMaxTries((prev) => prev - 1);
    timerRef.current = setInterval(countDownInterval, 1000);
  };

  const onSubmit = (data: any) => {
    console.log(data);
  };

  const formatTime = () => {
    // const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  //#endregion

  return {
    // states
    email: email,
    isError: passwordResetCodeVerificationMutation.isError,
    otpInput,
    maxTries,
    seconds,
    disabled,
    isLoading: passwordResetCodeVerificationMutation.isPending,
    // functions
    setOtpInput,
    onSubmit,
    formatTime,
    resendOTPCode,
  };
};

export default useContainer;
