import { Box, Button, Card, Stack } from "@mui/joy";
import ContactForm from "./components/ContactForm";
import { RemoveCircle, ShareOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { BusinessContactService } from "../../../services/business-contact.service";
import toast from "react-hot-toast";
import showErrorToast, { ErrorToastConfig, showSuccessToast, SuccessToastConfig } from "../../../components/Error";
import ShareContactModal from "./components/ShareContactModal";
import { useState } from "react";
import { If } from "../../../components/Condition";
import { BusinessSessionHelper } from "../../../helpers/business-session.helper";

export function EditBusinessContact() {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { state } = useLocation();
  const [openShareModal, setOpenShareModal] = useState(false)

  const deleteMutation = useMutation({
    mutationFn: BusinessContactService.deleteContact,
    onSuccess: () => {
      navigate(-1)
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  })

  const shareMutation = useMutation({
    mutationFn: BusinessContactService.shareContact,
    onSuccess: () => {
      toast(showSuccessToast("Contact shared successfully"), SuccessToastConfig);
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }
  })

  const deleteContact = () => {
    deleteMutation.mutate(state?.contact.id)
  }

  const onFinishShare = (emails: string[]) => {
    setOpenShareModal(false)
    shareMutation.mutate({
      id: state?.contact.id,
      email: emails
    })
  }
  return <>
    <Stack gap={1}>
      <If condition={BusinessSessionHelper.IsOwner()}>
        <Box
          display={"flex"}
          justifyContent={{
            md: "flex-end",
            xs: "space-between"
          }}
          gap={1}
          alignItems={"center"}
        >
          <Button onClick={deleteContact} size="sm" startDecorator={<RemoveCircle />} variant="outlined" color="neutral" sx={{ borderRadius: "10px", height: "40px" }}>{t("commons.removeBtn")}</Button>
          <Button onClick={() => { setOpenShareModal(true) }} size="sm" startDecorator={<ShareOutlined />} variant="outlined" color="primary" sx={{ borderRadius: "10px", height: "40px" }}>{t("commons.shareBtn")}</Button>
        </Box>
      </If>
      <Card>
        <ContactForm editMode={true} />
      </Card>
    </Stack>
    <ShareContactModal open={openShareModal} onClose={() => { setOpenShareModal(false) }} onFinish={onFinishShare} />
  </>
}