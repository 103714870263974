import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { signInWithPopup } from "firebase/auth";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import i18next from "../../i18n/config";
import { auth, googleProvider } from "../../lib/firebase";
import { AuthService } from "../../services/auth.service";
import { TrackerService } from "../../services/tracker.service";
import { TRACKER_CONSTANTS } from "../../constants/tracker.events.ts";

const schema = z.object({
  email: z.string().email({
    message: i18next.t("commons.error.form.required", {
      label: i18next.t("landing.signup.email"),
    }),
  }),
  password: z.string().min(8, {
    message: i18next.t("commons.error.form.required", {
      label: i18next.t("landing.signup.password"),
    }),
  }),
  remember: z.boolean().default(false),
});

const useContainer = () => {
  //#region ----- local states
  const [openBusinessSelectionModal, setOpenBusinessSelectionModal] =
    useState(false);
  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });
  const navigate = useNavigate();
  const { email, password } = watch();
  //#endregion

  //#region ----- misc
  const signinMutation = useMutation({
    mutationFn: AuthService.signin,
    onSuccess: ({ data }) => {
      if (!data.isVerified) {
        localStorage.setItem("phoneVerificationToken", data.token);
        localStorage.setItem("countryCode", data.countryCode.toString() ?? "");
        localStorage.setItem("mobile", data.phoneNo);
        navigate("/verify");
      } else {
        if (data.accessToken) {
          localStorage.setItem("businessChooseToken", data.accessToken);
        }
        setOpenBusinessSelectionModal(true);
      }
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const signinSSOMutation = useMutation({
    mutationFn: AuthService.signinSSO,
    onSuccess: ({ data }) => {
      if (!data.isVerified) {
        localStorage.setItem("phoneVerificationToken", data.token);
        localStorage.setItem("countryCode", data.countryCode.toString() ?? "");
        localStorage.setItem("mobile", data.phoneNo);
        navigate("/verify");
      } else {
        if (data.accessToken) {
          localStorage.setItem("businessChooseToken", data.accessToken);
        }
        setOpenBusinessSelectionModal(true);
      }
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });
  //#endregion

  //#region ----- handler functions

  function closeBusinessModalHandler() {
    setOpenBusinessSelectionModal(false);
  }

  const onSubmit: SubmitHandler<z.infer<typeof schema>> = (data) => {
    TrackerService.track(
      TRACKER_CONSTANTS.LOGIN_SUCCESS.events.LoginButtonClicked,
      {
        "Login method": "Email",
        // email: data.email,
      }
    );
    data.email = data.email.toLowerCase();
    signinMutation.mutate(data);
  };
  async function googleSignInHandler() {
    TrackerService.track(
      TRACKER_CONSTANTS.LOGIN_SUCCESS.events.LoginButtonClicked,
      {
        "Login method": "Google",
      }
    );
    try {
      const result = (await signInWithPopup(auth, googleProvider)) as any;
      const accessToken = result.user.accessToken;
      signinSSOMutation.mutate({ accessToken });
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  }
  //#endregion
  return {
    googleSignInHandler,
    onSubmit,
    handleSubmit,
    register,
    errors,
    isLoading: signinMutation.isPending,
    email,
    password,
    openBusinessSelectionModal,
    closeBusinessModalHandler,
  };
};

export default useContainer;
