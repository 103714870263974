import { useQuery } from "@tanstack/react-query";
import { CreditsService } from "../../../../services/credit.service";

const useContainer = () => {
  const availableCreditQuery = useQuery({
    queryKey: ["available-credits"],
    queryFn: CreditsService.getAvailableCredits,
  });

  const discountedCreditQuery = useQuery({
    queryKey: ["discounted-credits"],
    queryFn: CreditsService.getDiscountedCredits,
  });
  return {
    isPending:
      availableCreditQuery.isPending || discountedCreditQuery.isPending,
    availableCredits: availableCreditQuery.data || [],
    discountedCredits: discountedCreditQuery.data || [],
  };
};

export default useContainer;
