import { RoleType } from "./role-type"

export enum EmployeeStatusType {
    Active = "Active",
    Invited = "Invited",
    InvitationExpired = "Invitation Expired",   
}


export interface CreateEmployee {
    fullName: string
    email: string
    phoneNumber: string
    phoneNumberCountryCode: string
    role: RoleType
    resourcePermissions: { resource: string, permissions: string[] }[]
}

export interface UpdateEmployee extends CreateEmployee {
}

export interface IEmployee extends CreateEmployee {
    id: string
    isOwner?: boolean
    status: EmployeeStatusType
}

export const EmployeeStatusTranslate = {
    [EmployeeStatusType.Active]: "manage.employee.status.active",
    [EmployeeStatusType.Invited]: "manage.employee.status.invited",
    [EmployeeStatusType.InvitationExpired]: "manage.employee.status.invitationExpired",
}