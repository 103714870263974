
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import Button from "@mui/joy/Button";
import Grid from "@mui/joy/Grid";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import { NavLink } from "react-router-dom";

export default function NavDock() {
  return (
    <Grid
      alignItems="center"
      container
      direction="column"
      justifyContent="space-around"
      role="menubar"
      sx={{
        backgroundColor: "white",
        borderTop: "1px solid var(--joy-palette-divider)",
        bottom: 0,
        boxShadow: "var(--joy-shadow-md)",
        paddingY: 1,
        position: "fixed",
        zIndex: 10,
      }}
      width="100%"
    >
      <Grid xs={1}>
        <Button
          component={NavLink}
          sx={() => ({
            display: "flex",
            flexDirection: "column",
            fontSize: "sm",
          })}
          to={"/"}
          variant="plain"
          color="neutral"
        >
          <ListItemDecorator>
            <HomeOutlinedIcon sx={{ fontSize: "25px" }} />
          </ListItemDecorator>
          Home
        </Button>
      </Grid>
      <Grid xs={1}>
        <Button
          component={NavLink}
          sx={() => ({
            display: "flex",
            flexDirection: "column",
            fontSize: "sm",
          })}
          to={"/orders"}
          variant="plain"
          color="neutral"
        >
          <ListItemDecorator>
            <SwapHorizOutlinedIcon sx={{ fontSize: "25px" }} />
          </ListItemDecorator>
          Orders
        </Button>
      </Grid>
      <Grid xs={1}>
        <Button
          component={NavLink}
          sx={() => ({
            display: "flex",
            flexDirection: "column",
            fontSize: "sm",
          })}
          to={"/finance"}
          variant="plain"
          color="neutral"
        >
          <ListItemDecorator>
            <PaymentsOutlinedIcon sx={{ fontSize: "25px" }} />
          </ListItemDecorator>
          Finance
        </Button>
      </Grid>
      <Grid xs={1}>
        <Button
          component={NavLink}
          sx={() => ({
            display: "flex",
            flexDirection: "column",
            fontSize: "sm",
          })}
          to={"/catalogues"}
          variant="plain"
          color="neutral"
        >
          <ListItemDecorator>
            <ImportContactsOutlinedIcon sx={{ fontSize: "25px" }} />
          </ListItemDecorator>
          Catalogues
        </Button>
      </Grid>
      <Grid xs={1}>
        <Button
          component={NavLink}
          sx={() => ({
            display: "flex",
            flexDirection: "column",
            fontSize: "sm",
          })}
          to={"/manage"}
          variant="plain"
          color="neutral"
        >
          <ListItemDecorator>
            <MoreHorizOutlinedIcon sx={{ fontSize: "25px" }} />
          </ListItemDecorator>
          Manage
        </Button>
      </Grid>
    </Grid>
  );
}