import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

function useContainer() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const callbackUrl = searchParams.get("callbackURL");
  const { state } = useLocation();
  useEffect(() => {
    if (!state?.phoneVerified) {
      if (callbackUrl) {
        navigate(`/signin?callbackURL=${encodeURIComponent(callbackUrl)}`);
      } else {
        navigate("/signin");
      }
    }
  }, []);

  return { callbackUrl };
}

export default useContainer;
