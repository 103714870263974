import { Stack, Typography } from "@mui/joy";
import { PolicyBullet } from "./PasswordPolicy/PolicyBullet";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


interface ImageEventHandler extends GlobalEventHandlers {
    height: number
    width: number
}

export default function ImagePolicy({ file }: { file?: File | null }) {

    const [imageSize, setImageSize] = useState<number[]>([0, 0])
    const { t } = useTranslation()

    const isLessThenEq2MB = () => {
        return (file?.size || 0) <= 2 * 1024 * 1024
    }

    const isImage = () => {
        return (file?.type || '').startsWith("image")
    }

    const isRecomendedSize = () => {
        return (file && imageSize[0] === 2000 && imageSize[1] === 2000) || false
    }

    useEffect(() => {
        if (file) {
            var img = new Image()
            var imgSrc = URL.createObjectURL(file)
            img.onload = function () {
                var imgEvent = this as ImageEventHandler
                setImageSize([imgEvent.height, imgEvent.width])
                URL.revokeObjectURL(imgSrc)
            }
            img.src = imgSrc
        }
    }, [file])

    return (
        <ul>
            <li>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={2}
                >
                    <PolicyBullet isActive={(isLessThenEq2MB() && isImage())} />
                    <Typography textColor={isLessThenEq2MB() && isImage() ? "primary.400" : "neutral.500"}>
                        {t("imagePolicy.requirement1")}
                    </Typography>

                </Stack>
            </li>
            <li>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={2}
                >
                    <PolicyBullet isActive={(isRecomendedSize())} />
                    <Typography textColor={isRecomendedSize() ? "primary.400" : "neutral.500"}>
                        {t("imagePolicy.requirement2")}
                    </Typography>
                </Stack>
            </li>
        </ul>
    )
}