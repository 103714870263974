import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import en from "./en/translation.json";
import id from "./id/translation.json";

i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources: {
      en: {
        translation: en,
      },
      id: {
        translation: id,
      },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
