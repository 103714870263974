import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
// import Shared from "../layouts/Shared";
import Grid from "@mui/joy/Grid";

export default function Manage() {
  return (
    // <Shared fullHeight sxProps={{ marginBottom: 25 }} title="Manage" withDock>
    <Grid
      container
      marginTop={0.1}
      marginX={0}
      padding={3}
      spacing={3}
      sx={{
        backgroundColor: "white",
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25,
      }}
    >
      <Grid xs={6}>
        <Card
          size="lg"
          sx={{
            boxShadow: "xs",
            height: 150,
            textDecoration: "none",
          }}
          variant="outlined"
        >
          <CardContent
            sx={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <FormatListBulletedOutlinedIcon
              sx={{
                color: "primary.600",
                fontSize: 45,
              }}
            />
            <Typography
              fontSize="lg"
              level="title-md"
              sx={{ marginTop: 1 }}
              textColor="primary.600"
            >
              Log
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={6}>
        <Card
          size="lg"
          sx={{
            boxShadow: "xs",
            height: 150,
            textDecoration: "none",
          }}
          variant="outlined"
        >
          <CardContent
            sx={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <ReportOutlinedIcon
              sx={{
                color: "primary.600",
                fontSize: 45,
              }}
            />
            <Typography
              fontSize="lg"
              level="title-md"
              sx={{ marginTop: 1 }}
              textColor="primary.600"
            >
              Help & FAQ
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
