import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import {
  Box,
  Button,
  Dropdown,
  Grid,
  Input,
  Option,
  Select,
  Menu,
  MenuButton,
  Sheet,
  Snackbar,
  Typography,
  Stack,
} from "@mui/joy";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { SuccessModal } from "../../components/SuccessModal";
import { InventoryService } from "../../services/inventory.service";
import { formatDate } from "../../utils/formatDate";
import useContainer from "./useContainer";
// import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { KeyboardArrowDown, SearchOutlined } from "@mui/icons-material";
import debounce from "lodash.debounce";
import {
  EllipsisVerticalIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import CopyLinkButton from "../../components/CopyLinkButton";
import ConfirmDeleteModal from "../../components/Inventory/ConfirmDeleteModal";
import ConfirmDuplicateModal from "../../components/Inventory/ConfirmDuplicateModal";
import EditCatalogueItemModal from "../../components/Inventory/Items/EditCatalogueItemModal";
import ShareModal from "../../components/Inventory/ShareModal";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { theme } from "../../components/Theme";
import { getActiveBusiness } from "../../store/session";
import { CatalogInventoryItemType } from "../../types/item";
import { ProductCard } from "./components/ProductCard";
import { ItemService } from "../../services/item.service";
import { MenuItem } from "@mui/material";
import { CatalogService } from "../../services/catalog.service";
import { If } from "../../components/Condition";
import { currencyList } from "../Order/EditOrders";
// import { getCountryInfo } from "../../utils/country";

const sortOptions = [
  { value: "", label: "Sort By" },
  { value: "id_asc", label: "Newest first" },
  { value: "id_desc", label: "Latest first" },
  { value: "productName_asc", label: "A to Z (ascending)" },
  { value: "productName_desc", label: "Z to A (descending)" },
  { value: "price_desc", label: "Highest Price" },
  { value: "price_asc", label: "Lowest Price" },
];

const ListCatalogueItems = () => {
  const { t } = useTranslation();
  const { catalogId } = useParams();
  // const currentBusiness = getActiveBusiness() as any;
  const [searchParams, setSearchParams] = useSearchParams();
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<CatalogInventoryItemType>();
  // const [isOpen, setIsOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  // const [isHidePrice, setIsHidePrice] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < theme.breakpoints.values.sm
  );
  const [isEditCatalogName, setIsEditCatalogName] = useState(false);
  const inventoryType = searchParams.get("inventoryType");
  const query = searchParams.get("q");
  const sortBy = searchParams.get("sortBy");
  const sortDirection = searchParams.get("sortDirection") ?? "";
  const loaderRef = useRef(null);
  // const [openProductDetailsModal, setOpenProductDetailsModal] = useState(false);
  const [categories, setCategories] = useState<string[]>([]);
  const [errorUpdateName, setErrorUpdateName] = useState<string | undefined>();

  const {
    isPending,
    fetchAllItems,
    items,
    setItems,
    catalog,
    setCatalog,
    isLoading,
    setIsLoading,
    showAlert,
    showError,
    setShowAlert,
    showToast,
    unauthorized,
    setUnauthorized,
    alertMessage,
    setAlertMessage,
    confirmDelete,
    setConfirmDelete,
    showShareModal,
    setShowShareModal,
    confirmDuplicate,
    setConfirmDuplicate,
    fillerItems,
    pagination,
    hasMore,
    currency,
    setCurrency,
  } = useContainer();

  useEffect(() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (catalogId) {
      setIsLoading(true);
      InventoryService.getOneCatalog({
        catalogId: catalogId,
      })
        .then((res) => {
          if (res.business.id !== getActiveBusiness()?.id) {
            setUnauthorized(true);

            return;
          }

          setCatalog(res);
          // setIsHidePrice(res.hidePrice);
          return fetchAllItems({
            catalogId,
            paging: true,
            limit: 9,
            offset: 0,
            isReset: true,
            inventoryType: inventoryType !== "all" ? inventoryType : "",
            searchTerm: query,
            sortDirection: (["asc", "desc"].includes(sortDirection)
              ? sortDirection
              : "desc") as "asc" | "desc",
            sortBy: sortBy ? sortBy : "updatedAt",
          });
        })
        .then(() => {
          // getting all inventoryType for categories
          const businessId = getActiveBusiness()?.id;
          if (businessId) {
            ItemService.getInventoryItemField({
              fields: ["inventoryType"],
              businessId,
            }).then((res) => {
              const cats = res.map(
                (type: { inventoryType: string }) => type.inventoryType
              );
              setCategories(cats);
            });
          }

          setIsLoading(false);
        })
        .catch((err) => console.error(err));
    }
  }, [catalogId, query, inventoryType, sortDirection, sortBy]);

  const handleRemove = () => {
    setIsLoading(true);
    InventoryService.removeCatalog({
      catalogId: catalogId || "",
    })
      .then((res) => {
        if (res) {
          setAlertMessage(t("catalog.catalogRemoved"));
          setShowAlert(true);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoading(false);
        window.location.href = "/client/catalogues/list";
      });
  };

  const handleRemoveItem = (id: string) => {
    InventoryService.removeCatalogItem({
      catalogInventoryItemId: id,
    })
      .then((res) => {
        if (res && catalogId) {
          setAlertMessage(t("catalog.inventory.catalogItemRemoved"));
          setShowAlert(true);

          fetchAllItems({
            catalogId,
            paging: true,
            limit: 9,
            offset: 0,
            isReset: true,
          });
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleDuplicate = () => {
    setIsLoading(true);

    const businessId = getActiveBusiness()?.id;

    if (catalogId && businessId) {
      InventoryService.duplicateCatalog({
        catalogId,
      })
        .then((res) => {
          if (res.id) {
            setShowAlert(true);
            setAlertMessage(
              t("catalog.inventory.duplicateCatalog.successMessage")
            );

            setTimeout(() => {
              setShowAlert(false);
            }, 3000);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setIsLoading(false);
          window.location.href = `/client/catalogues/list?type=${
            catalog?.catalogType.toLowerCase() ?? "public"
          }`;
        });
    }
  };

  const handleUpdateName = async (value: string) => {
    setIsLoading(true);

    const isDuplicate = await CatalogService.validateCatalogName(value);
    console.log("BANANA ISDUPLICATE", isDuplicate);
    if (isDuplicate) {
      setErrorUpdateName(t("catalog.duplicateCatalogError"));
      setIsLoading(false);
      return;
    }

    if (catalog) {
      InventoryService.patchCatalog({
        catalog: {
          id: catalog.id,
          catalogType: catalog.catalogType,
          catalogName: value,
          business: catalog.business.id,
          hidePrice: catalog.hidePrice,
        },
      })
        .then((res) => {
          setCatalog(res);
          setErrorUpdateName(undefined);
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setIsLoading(false);
          setIsEditCatalogName(false);
        });
    }
  };

  const debouncedSetSearchTerm = useCallback(
    debounce((value: string) => {
      if (value === "") {
        searchParams.delete("q");
      } else {
        searchParams.set("q", value);
      }
      setSearchParams(searchParams);
    }, 300),
    []
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearchTerm(event.target.value);
  };

  const CatalogTypeButton = () => {
    return (
      <button
        className={`py-3 px-4 rounded-md ${
          catalog?.catalogType === "PUBLIC"
            ? "bg-primary-200"
            : "bg-gray-700 text-white"
        } capitalize font-semibold items-center flex cursor-default`}
      >
        {catalog?.catalogType === "PUBLIC" ? (
          <LockOpenOutlinedIcon
            style={{
              width: 18,
              height: 18,
              marginRight: "0.5rem",
            }}
          />
        ) : (
          <LockOutlinedIcon
            style={{
              color: "white",
              width: 18,
              height: 18,
              marginRight: "0.5rem",
            }}
          />
        )}

        {catalog?.catalogType.toLowerCase()}
      </button>
    );
  };

  if (unauthorized) {
    return <> {t("catalog.noAccessCatalog")}</>;
  }
  return (
    <Sheet sx={{ backgroundColor: "transparent" }}>
      <div className="w-[90vw] lg:w-full">
        {/* page header */}
        <Grid container spacing={"2px"}>
          <Grid xs={12} lg={6} display={"flex"} position={"relative"}>
            {!isEditCatalogName ? (
              <h3
                className="text-2xl font-bold"
                onClick={() => setIsEditCatalogName(true)}
              >
                {catalog?.catalogName}
              </h3>
            ) : (
              <Stack direction={"column"}>
                <Input
                  placeholder={t("catalog.inventory.createCatalog.placeholder")}
                  defaultValue={catalog?.catalogName}
                  type="text"
                  className="text-2xl font-bold"
                  fullWidth
                  autoFocus
                  onBlur={(event) => {
                    if (event.target.value !== catalog?.catalogName) {
                      handleUpdateName(event.target.value);
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      if (event.currentTarget.value !== catalog?.catalogName) {
                        handleUpdateName(event.currentTarget.value);
                      }
                    }
                  }}
                />
                <If condition={errorUpdateName ? true : false}>
                  <p className="text-[#C31D1D] text-sm">{errorUpdateName}</p>
                </If>
              </Stack>
            )}
          </Grid>
          <Grid
            xs={12}
            lg={6}
            sx={{
              display: "flex",
              justifyContent: { xs: "left", lg: "right" },
              alignItems: "center",
            }}
          >
            <Typography
              fontSize={14}
              sx={{
                color: theme.colorSchemes.dark.palette.text.secondary,
              }}
            >
              <span className="text-xs lg:text-sm">
                {t("commons.lastUpdated")}:{" "}
                <span className="ml-2">
                  {formatDate(new Date(catalog?.updatedAt ?? ""))}
                </span>
              </span>
            </Typography>
          </Grid>
        </Grid>

        {/* toolbar */}
        <div className="flex items-center my-6">
          <CatalogTypeButton />
          {/* // TODO: fix ui */}
          <Select
            indicator={<KeyboardArrowDown />}
            placeholder={t("catalog.inventory.editForm.currency")}
            slotProps={{
              button: { sx: { whiteSpace: "nowrap" } },
            }}
            onChange={(_event, newValue: string | null) => {
              // TODO put currency change logic here
              if (!newValue || !catalogId) {
                return;
              }

              setCurrency(newValue);
              setIsLoading(true);
              ItemService.patchCatalogCurrency({
                catalogId,
                updateCatalogCurrencyDto: { newCurrency: newValue },
              })
                .then((res) => {
                  console.log("BANANA RES", res);
                  if (res.length > 0) {
                    window.location.reload();
                  }
                })
                .catch((error) => console.log(error));
            }}
            value={currency}
            className="mt-auto ml-3"
          >
            {currencyList.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
          <span className="flex ml-auto">
            <div className="lg:hidden">
              <Stack width={40}>
                <Dropdown
                  open={mobileMenuOpen}
                  onOpenChange={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  <MenuButton
                    fullWidth
                    slots={{ root: Button }}
                    slotProps={{
                      root: {
                        variant: "plain",
                        color: "neutral",
                        sx: {
                          "&:hover": {
                            bgcolor: "transparent",
                          },
                          "&:focus": {
                            bgcolor: "transparent",
                          },
                          padding: 0,
                          minWidth: 0,
                        },
                      },
                    }}
                  >
                    <Button
                      variant="outlined"
                      className="min-w-[40px] text-nowrap p-0"
                    >
                      <EllipsisVerticalIcon width={18} height={18} />
                    </Button>
                  </MenuButton>
                  <Menu>
                    <Link
                      to={`/client/catalogues/inventory/${catalogId}/manage`}
                    >
                      <MenuItem
                        sx={{
                          gap: "1rem",
                        }}
                      >
                        <PlusIcon
                          style={{
                            width: 18,
                            height: 18,
                          }}
                        />
                        {t("commons.addItemBtn")}
                      </MenuItem>
                    </Link>
                    <MenuItem
                      sx={{
                        gap: "1rem",
                      }}
                      onClick={() => {
                        setShowShareModal(true);
                      }}
                    >
                      <SendOutlinedIcon
                        style={{
                          width: 18,
                          height: 18,
                        }}
                      />
                      {t("catalog.sendBtn")}
                    </MenuItem>
                    <CopyLinkButton
                      url={t("catalog.copyLinkPrompt", {
                        catalogueLink: `${
                          window.location.href.split("/client")[0]
                        }/catalog/${catalogId}/items`,
                      })}
                      variant={"dropdown"}
                    />
                    <MenuItem
                      sx={{
                        gap: "1rem",
                      }}
                      onClick={() => {
                        setConfirmDuplicate(true);
                      }}
                    >
                      <ContentCopyOutlinedIcon
                        style={{
                          width: 18,
                          height: 18,
                        }}
                      />
                      {t("catalog.inventory.items.duplicateBtn")}
                    </MenuItem>
                    <MenuItem
                      sx={{
                        gap: "1rem",
                      }}
                      onClick={() => {
                        setConfirmDelete(true);
                      }}
                    >
                      <TrashIcon width={18} height={18} />
                      {t("catalog.inventory.removeCatalogBtn")}
                    </MenuItem>
                  </Menu>
                </Dropdown>
              </Stack>
            </div>

            <div className="hidden gap-3 lg:flex">
              <Link to={`/client/catalogues/inventory/${catalogId}/manage`}>
                <Button
                  startDecorator={
                    <PlusIcon
                      style={{
                        width: 18,
                        height: 18,
                      }}
                    />
                  }
                  className="text-nowrap"
                >
                  {t("commons.addItemBtn")}
                </Button>
              </Link>

              <Button
                variant="outlined"
                startDecorator={
                  <SendOutlinedIcon
                    style={{
                      width: 18,
                      height: 18,
                    }}
                  />
                }
                className="w-full mt-4 text-sm lg:mt-0 text-nowrap"
                onClick={() => {
                  setShowShareModal(true);
                }}
              >
                {t("catalog.sendBtn")}
              </Button>
              <CopyLinkButton
                url={t("catalog.copyLinkPrompt", {
                  catalogueLink: `${
                    window.location.href.split("/client")[0]
                  }/catalog/${catalogId}/items`,
                })}
                variant={"outlined"}
              />

              <Stack width={40}>
                <Dropdown
                  open={menuOpen}
                  onOpenChange={() => setMenuOpen(!menuOpen)}
                >
                  <MenuButton
                    fullWidth
                    slots={{ root: Button }}
                    slotProps={{
                      root: {
                        variant: "plain",
                        color: "neutral",
                        sx: {
                          "&:hover": {
                            bgcolor: "transparent",
                          },
                          "&:focus": {
                            bgcolor: "transparent",
                          },
                          padding: 0,
                          minWidth: 0,
                        },
                      },
                    }}
                  >
                    <Button
                      variant="outlined"
                      className="min-w-[40px] text-nowrap p-0"
                    >
                      <EllipsisVerticalIcon width={18} height={18} />
                    </Button>
                  </MenuButton>
                  <Menu>
                    <MenuItem
                      sx={{
                        gap: "1rem",
                      }}
                      onClick={() => {
                        setConfirmDuplicate(true);
                      }}
                    >
                      <ContentCopyOutlinedIcon
                        style={{
                          width: 18,
                          height: 18,
                        }}
                      />
                      {t("catalog.inventory.items.duplicateBtn")}
                    </MenuItem>
                    <MenuItem
                      sx={{
                        gap: "1rem",
                      }}
                      onClick={() => {
                        setConfirmDelete(true);
                      }}
                    >
                      <TrashIcon width={18} height={18} />
                      {t("catalog.inventory.removeCatalogBtn")}
                    </MenuItem>
                  </Menu>
                </Dropdown>
              </Stack>
            </div>
          </span>
        </div>

        <div className="flex flex-col gap-2">
          <Input
            defaultValue={query ?? ""}
            variant="linkz-input-search"
            size="sm"
            name="searchFormData.search"
            placeholder={t("catalog.searchItemPlaceholder")}
            startDecorator={<SearchOutlined />}
            sx={{ flexGrow: 1, width: { xs: "100%", lg: "41%" } }}
            onChange={handleChange}
          />

          <div
            className={`flex gap-2 w-[90vw] overflow-auto lg:w-full items-center hide-scrollbar`}
          >
            {/* category filter */}
            <Select
              defaultValue="all"
              variant="plain"
              size="sm"
              indicator={<KeyboardArrowDown />}
              className="w-[50%] lg:min-w-[10%] lg:max-w-[13%] uppercase"
              onChange={(
                _event: React.SyntheticEvent | null,
                value: string | null
              ) => {
                if (!value) {
                  return;
                }

                if (value.length > 1) {
                  searchParams.set("inventoryType", value);
                } else {
                  searchParams.delete("inventoryType");
                }
                setSearchParams(searchParams);
              }}
            >
              <Option
                // key={cat}
                color="primary"
                value="all"
                label={t("catalog.supplier.items.allCategoryLabel")}
                sx={{
                  color: theme.colorSchemes.dark.palette.common.black,
                }}
              >
                {t("catalog.supplier.items.allCategoryLabel")}
              </Option>
              {categories.map((cat) => {
                return (
                  <Option
                    color="primary"
                    value={cat}
                    label={cat.toUpperCase()}
                    sx={{
                      color: theme.colorSchemes.dark.palette.common.black,
                    }}
                  >
                    {cat}
                  </Option>
                );
              })}
            </Select>

            {/* sort by */}
            <Select
              defaultValue={
                sortBy && sortDirection ? sortBy + "_" + sortDirection : ""
              }
              variant="plain"
              size="sm"
              indicator={<KeyboardArrowDown />}
              className="w-[50%] lg:min-w-[10%] lg:max-w-[13%] uppercase"
              onChange={(
                _event: React.SyntheticEvent | null,
                value: string | null
              ) => {
                if (!value) {
                  return;
                }

                if (value.length > 1) {
                  const [field, order] = value.split("_");
                  searchParams.set("sortBy", field);
                  searchParams.set("sortDirection", order);
                } else {
                  searchParams.delete("sortBy");
                  searchParams.delete("sortDirection");
                }
                setSearchParams(searchParams);
              }}
            >
              {sortOptions.map((opt) => {
                if (catalog?.hidePrice && opt.value.includes("price")) {
                  return;
                }

                return (
                  <Option
                    key={opt.value}
                    value={opt.value}
                    color="primary"
                    sx={{
                      color: theme.colorSchemes.dark.palette.common.black,
                    }}
                  >
                    {t(`catalog.sortOptions.${opt.label}`)}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>

        {/* items table */}

        <InfiniteScroll
          pageStart={0}
          // initialLoad
          loadMore={(_page: number) => {
            fetchAllItems({
              catalogId: catalogId ?? "",
              paging: true,
              limit: 9,
              searchTerm: query,
              offset: pagination.limit * pagination.current,
              isReset: false,
            });
          }}
          hasMore={hasMore}
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
        >
          <div className="w-[90vw] lg:w-[78vw]">
            {items.length > 0 ? (
              <>
                <Grid
                  container
                  mt={4}
                  mb={2}
                  spacing={{ xs: 1, lg: 0 }}
                  alignItems={"stretch"}
                  className={"grid grid-cols-2 xl:grid-cols-3 gap-2 md:gap-6"}
                >
                  {items.map((i, index) => {
                    return (
                      <div key={index}>
                        <ProductCard
                          onClick={() => {
                            // setOpenProductDetailsModal(true);
                            setSelectedItem(i);
                            // showEditModal;
                            setShowEditModal(true);
                          }}
                          key={i.id}
                          handleRemoveItem={handleRemoveItem}
                          item={i}
                        />
                      </div>
                    );
                  })}

                  {!isMobile &&
                    Array.from({ length: fillerItems }, () => (
                      <Grid
                        key={Math.random() * 6502}
                        xs={12}
                        lg={4}
                        flexGrow={1}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "stretch",
                          justifyContent: "space-between",
                        }}
                      >
                        <hr
                          className={`hidden lg:block mt-auto w-full border-b border-dashed border-[${theme.colorSchemes.light.palette.others.disabled}]`}
                        />
                      </Grid>
                    ))}
                </Grid>

                <div ref={loaderRef} className="flex justify-center w-full">
                  {isLoading && "Loading..."}
                </div>
              </>
            ) : (
              <div className="flex w-full mt-10">
                <p className="mx-auto my-auto text-base">
                  {t("catalog.noItemInCatalog")}
                </p>
              </div>
            )}
          </div>
        </InfiniteScroll>

        {/* modal */}
        <SuccessModal
          title={t("commons.successModalTitle")}
          content={alertMessage}
          open={showAlert}
        />
        {/* <LoadingModal isLoading={isLoading} /> */}
        <Snackbar
          variant="solid"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showError}
          key={"topcenter" + Math.random() * 1000}
          sx={{
            width: "40%",
            maxWidth: 800,
          }}
        >
          <Box width={"100%"}>
            <Typography
              textAlign={"center"}
              textColor={"common.white"}
              fontSize={14}
            >
              {alertMessage}
            </Typography>
          </Box>
        </Snackbar>
        <Snackbar
          variant="solid"
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showToast}
          key={"topcenter" + Math.random() * 1000}
          sx={{
            width: "40%",
            maxWidth: 800,
            backgroundColor: theme.colorSchemes.light.palette.others.success,
          }}
        >
          <Box width={"100%"}>
            <Typography
              textAlign={"center"}
              textColor={"common.black"}
              fontSize={14}
            >
              {alertMessage}
            </Typography>
          </Box>
        </Snackbar>
        <ShareModal
          show={showShareModal}
          onClose={() => {
            setShowShareModal(false);
          }}
          catalogId={catalogId ?? ""}
          businessId={getActiveBusiness()?.id ?? ""}
          catalogType={catalog?.catalogType.toLowerCase() ?? "private"}
        />
        <ConfirmDeleteModal
          show={confirmDelete}
          onClick={handleRemove}
          onClose={() => {
            setConfirmDelete(false);
          }}
        />
        <ConfirmDuplicateModal
          show={confirmDuplicate}
          catalogType={
            t(`catalog.catalogType.${catalog?.catalogType.toLowerCase()}`) ??
            "private"
          }
          onClick={handleDuplicate}
          onClose={() => {
            setConfirmDuplicate(false);
          }}
        />

        {selectedItem && (
          <EditCatalogueItemModal
            catalogId={catalogId ?? ""}
            showModal={showEditModal}
            catalogInventoryItem={selectedItem}
            onClose={() => {
              setShowEditModal(false);
              setSelectedItem(undefined);
            }}
            onUpdate={(item: CatalogInventoryItemType) => {
              const updatedItems = items.map((i) => {
                if (i.id === item.id) {
                  return { ...item };
                } else {
                  return i;
                }
              });

              setItems(updatedItems);
            }}
          />
        )}
      </div>

      <LoadingModal isLoading={isLoading || isPending} />
      {/* {selectedItem && (
        <Modal
          onClose={() => {
            setOpenProductDetailsModal(false);
            setSelectedItem(undefined);
          }}
          open={openProductDetailsModal}
          style={{
            backgroundColor: "rgba(50, 56, 62,.6)",
            backdropFilter: "blur(0px)",
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="flex items-center w-full h-full">
            <ProductModal
              onUpdate={(item: CatalogInventoryItemType) => {
                const updatedItems = items.map((i) => {
                  if (i.id === item.id) {
                    return { ...item };
                  } else {
                    return i;
                  }
                });
                setItems(updatedItems);
              }}
              catalogId={catalogId ?? ""}
              catalogInventoryItem={selectedItem}
              onClose={() => {
                setOpenProductDetailsModal(false);
                setSelectedItem(undefined);
              }}
            />
          </div>
        </Modal>
      )} */}
    </Sheet>
  );
};

export default ListCatalogueItems;
