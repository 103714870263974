import { privateAxios } from "../lib/api";
import { ICatalogInviteCreatePayload } from "../types/catalog";
import { ApiConstants } from "../utils/constants";

interface RemoveCatalogProps {
  catalogId: string;
  businessId: string;
}
export class CatalogService {
  static async validateCatalogName(catalogName: string) {
    const response = await privateAxios.get(
      `${ApiConstants.catalogURL}/validate/${catalogName}`
    );

    return response.data as Boolean;
  }

  static removeCatalogAccess(payload: RemoveCatalogProps[]) {
    return privateAxios.post(
      `${ApiConstants.catalogURL}/remove-catalog/`,
      payload
    );
  }

  static sendEmailInvitation({
    catalogId,
    accountIds,
  }: {
    catalogId: string;
    accountIds: string[];
  }) {
    return privateAxios.patch(
      `${ApiConstants.catalogURL}/${catalogId}/send-email`,
      { accountIds }
    );
  }

  static sendEmailInvitationToUnregisterUser({
    payload,
  }: {
    catalogId: string;
    payload: ICatalogInviteCreatePayload[];
  }) {
    return privateAxios.patch(
      `${ApiConstants.catalogURL}/invite/send-email/new-account`,
      payload
    );
  }
}
