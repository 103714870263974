import { Typography } from "@mui/joy";
import { ToastOptions } from "react-hot-toast";
import i18next from "../i18n/config";

export const ErrorToastConfig: ToastOptions = {
  style: {
    padding: 0,
    margin: 0,
  },
  position: "bottom-center",
  duration: 4000,
};

export const SuccessToastConfig: ToastOptions = {
  style: {
    padding: "5px 10px",
    margin: 0,
    backgroundColor: "#DCF8E4",
    color: "#344054",
  },
  position: "bottom-center",
  duration: 4000,
};

export default function showErrorToast(error: any) {
  const errorMessage = i18next.t(
    `commons.error.${error.response.data.message || "Something went wrong."}`
  );
  return (t: any) => {
    return (
      <div
        style={{
          width: "100%",
          opacity: t.visible ? 1 : 0,
          transition: "opacity 100ms ease-in-out",
        }}
      >
        <Typography
          textColor={"danger.softColor"}
          fontSize={"14px"}
          fontWeight={500}
          textAlign={"center"}
          py={"12px"}
        >
          {errorMessage}
        </Typography>
      </div>
    );
  };
}
export function showSuccessToast(message: string) {
  return (t: any) => {
    return (
      <div
        style={{
          width: "100%",
          opacity: t.visible ? 1 : 0,
          transition: "opacity 100ms ease-in-out",
        }}
      >
        <Typography
          textColor={"common.white"}
          fontSize={"14px"}
          fontWeight={500}
          textAlign={"center"}
          py={"12px"}
        >
          {message}
        </Typography>
      </div>
    );
  };
}
