function Coin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="12" fill="#499873"></circle>
      <circle
        cx="12"
        cy="12"
        r="12"
        fill="url(#paint0_linear_2266_70296)"
        fillOpacity="0.3"
      ></circle>
      <path
        fill="#DDEEE3"
        d="M8.003 11.75c0-1.392-.009-2.785.003-4.18.009-.973.632-1.58 1.578-1.57.87.01 1.46.637 1.465 1.595.009 1.962 0 3.923.005 5.885.004 1.143-.12.98 1.013.989 1.128.007 2.256-.007 3.386.005.843.008 1.425.448 1.62 1.189.233.888-.42 1.829-1.343 1.839-2.1.026-4.2.022-6.298 0-.79-.008-1.417-.707-1.422-1.53-.01-1.406-.004-2.814-.002-4.222h-.005z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_2266_70296"
          x1="19"
          x2="7.5"
          y1="2"
          y2="23"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C8F60"></stop>
          <stop offset="0.475" stopColor="#fff"></stop>
          <stop offset="1" stopColor="#2C8F60"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Coin;
