import { Add, Search } from "@mui/icons-material";
import { Button, Input, Stack } from "@mui/joy";
import { debounce } from "@mui/material";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { If } from "../../../components/Condition";
import { BusinessSessionHelper } from "../../../helpers/business-session.helper";
import { BusinessContactService } from "../../../services/business-contact.service";
import { ContactList } from "./components/ContactList";

export default function BusinessContact() {
  const [contacts, setContacts] = useState<any[]>([]);
  const [paginate, setPaginate] = useState<any>({
    total: 0,
    limit: 100,
    offset: 0,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const query = searchParams.get("q");

  const getContact = (params: any) => {
    BusinessContactService.getContacts(params).then((data) => {
      setContacts(data.contacts);
      setPaginate(data.paginate);
    });
  };

  useEffect(() => {
    if (query) getContact({ ...paginate, search: query });
    else getContact({ ...paginate });
  }, []);

  const handleSearch = useCallback(
    debounce((value) => {
      if (value.length > 1) {
        searchParams.set("q", value);
        getContact({ ...paginate, search: value });
      } else {
        searchParams.delete("q");
        getContact({ ...paginate });
      }
      setSearchParams(searchParams);
    }, 300), // debounce delay in milliseconds
    [paginate]
  );

  return (
    <Stack gap={1}>
      <Stack
        justifyContent={"space-between"}
        direction={{
          md: "row",
          xs: "column",
        }}
        gap={1}
      >
        <Input
          sx={{
            minWidth: "326px",
          }}
          defaultValue={query ?? ""}
          startDecorator={<Search />}
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
        />
        <If condition={BusinessSessionHelper.IsOwner()}>
          <Button
            onClick={() => navigate("/client/business-contact/add")}
            sx={{ borderRadius: "10px" }}
            size="sm"
            startDecorator={<Add />}
            color="primary"
          >
            {t("manage.businessContact.addNewContact")}
          </Button>
        </If>
      </Stack>
      <ContactList contacts={contacts} />
    </Stack>
  );
}
