import { AccountService } from "../services/account.service";
import {
  getActiveBusiness,
  setBusinessSession,
  useAuthStore,
} from "../store/session";
import { IBusinessResponse } from "../types/business";
import { KycPaymentGatewayStatus } from "../types/kyc";
import { RoleType } from "../types/role-type";

export class BusinessSessionHelper {
  static async SetActiveBusiness(business: IBusinessResponse) {
    try {
      const response = await AccountService.switchBusinessProfile(business.id);
      console.log(response.data);
      setBusinessSession(response.data);
    } catch (error) {
      throw new Error("error");
    }
  }

  static IsOwner() {
    const business = getActiveBusiness();
    return !!business?.isOwner;
  }

  static IsBusinessAdmin() {
    const business = getActiveBusiness();
    return business?.role === RoleType.BusinessAdmin;
  }

  static GetBusinessName() {
    const business = getActiveBusiness();
    return business?.companyName;
  }

  static GetRoleName() {
    const business = getActiveBusiness();
    return business?.role;
  }

  static GetBusinessId() {
    const business = getActiveBusiness();
    return business?.id;
  }

  static Business() {
    return getActiveBusiness();
  }

  static Account() {
    return useAuthStore.getState().session?.account;
  }

  static RolePermissions() {
    return useAuthStore.getState().session?.rolePermissions;
  }

  static RoleResourcePermissions(
    roleCode: string,
    permission: "CREATE" | "READ" | "UPDATE" | "DELETE"
  ): boolean {
    const rolePermissions = useAuthStore.getState().session?.rolePermissions;
    return (
      rolePermissions
        ?.find((role) => role.resource.code === roleCode)
        ?.permission.includes(permission) ?? false
    );
  }

  static IsValidatedKYCPaymentGateway() {
    return getActiveBusiness()?.kycPaymentGatewayStatus === KycPaymentGatewayStatus.VALIDATED
  }
}
