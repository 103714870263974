export class ValdiationUtils {
  static containsUppercase(str: string) {
    return /[A-Z]/.test(str);
  }

  static containsLowercase(str: string) {
    return /[a-z]/.test(str);
  }

  static containsNumber(str: string) {
    return /\d/.test(str);
  }

  static containsSpecialChar(str: string) {
    const regex = /[!@#$%^&*()]/;
    return regex.test(str);
  }
}
