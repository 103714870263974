import { Chip, Stack } from "@mui/joy";
import { TagsType } from "../../../types/catalog";

interface Props {
  tags: TagsType | undefined;
}

const TagsCollection = ({ tags }: Props) => {
  if (tags?.tags) {
    return (
      <Stack direction={"row"} gap={"0.5rem"}>
        {tags.tags.map((tag) => (
          <Chip
            key={tag}
            sx={{
              borderRadius: "4px",
              paddingX: "12px",
              // paddingY: "10px",
              color: "#475467",
              backgroundColor: "#F2F4F7",
            }}
          >
            <span className="capitalize">{tag}</span>
          </Chip>
        ))}
      </Stack>
    );
  }
};

export default TagsCollection;
