import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { KycPaymentGatewayService } from "../../../services/kyc-payment-gateway.service";
import toast from "react-hot-toast";
import showErrorToast, { ErrorToastConfig } from "../../../components/Error";
import { UploadService } from "../../../services/upload.service";
import { BusinessSessionHelper } from "../../../helpers/business-session.helper";
import { getCountryInfo } from "../../../utils/country";

export default function useContainer() {
  const [companyProfileFile, setCompanyProfileFile] = useState<File | null>(null);
  const [personalIDFile, setPersonalIDFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const kycSchema = z.object({
    estimatedAnualIncomeCurrency: z.string().default("IDR"),
    estimatedAnualIncome: z.string().min(1),
    averageValuePerTransactionCurrency: z.string().default("IDR"),
    averageValuePerTransaction: z.string().min(1),
    bankName: z.string().min(2),
    accountNumber: z.string().min(5),
    companyProfileFileUrl: z.string().optional(),
    personalIdFileUrl: z.string().optional(),
  })
  const submitMutation = useMutation({
    mutationFn: KycPaymentGatewayService.submit,
    onSuccess: () => {
      setIsLoading(false);
      setSuccessModalOpen(true);
    },
    onError: (err) => {
      setIsLoading(false);
      toast(showErrorToast(err), ErrorToastConfig)
    },
  })
  const {
    register,
    formState: { errors },
    setError,
    handleSubmit,
    setValue,
    watch
  } = useForm<z.infer<typeof kycSchema>>({
    resolver: zodResolver(kycSchema),
  });

  const uploadFile = async (file: File, name: string) => {
    try {
      await UploadService.upload(file, name, "kyc-payment-gateway", true, false);
      return `kyc-payment-gateway/${name}`;
    } catch (error) {
      throw new Error("Upload failed")
    }
  }

  const onSubmit = async (data: z.infer<typeof kycSchema>) => {
    if (!companyProfileFile) {
      setError("companyProfileFileUrl", {
        type: "custom",
        message: "Company profile file is required",
      })
      return;
    }

    if (!personalIDFile) {
      setError("personalIdFileUrl", {
        type: "custom",
        message: "Personal ID file is required",
      })
      return;
    }
    setIsLoading(true);
    try {
      const countryInfo = getCountryInfo(BusinessSessionHelper.Business()?.companyCountry??'', 'LOCALE')
      data.companyProfileFileUrl = await uploadFile(companyProfileFile!, `company-profile-${BusinessSessionHelper.Business()?.id}`);
      data.personalIdFileUrl = await uploadFile(personalIDFile!, `personal-id-${BusinessSessionHelper.Business()?.id}`);
      data.estimatedAnualIncomeCurrency = countryInfo?.currency ?? 'IDR'
      data.averageValuePerTransactionCurrency = countryInfo?.currency ?? 'IDR'
      submitMutation.mutate(data);
    } catch (error) {
      toast("Upload Error", ErrorToastConfig);
      setIsLoading(false);
    }
  }

  return {
    companyProfileFile,
    personalIDFile,
    isLoading,
    successModalOpen,
    setSuccessModalOpen,
    setCompanyProfileFile,
    setPersonalIDFile,
    register,
    errors,
    setError,
    onSubmit,
    handleSubmit,
    watch,
    setValue
  };
}