import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PhoneVerifiedIllustration from "../../components/Icons/PhoneVerifiedIllustration";
import useContainer from "./useContainer";

const PhoneVerified = () => {
  const { t } = useTranslation();
  const { callbackUrl } = useContainer();
  return (
    <div className="flex items-center justify-center min-w-full min-h-screen">
      <div className="w-[361px] lg:w-[546px] h-[527.6px] flex flex-col items-center gap-[30px]">
        <PhoneVerifiedIllustration />
        <div className="flex flex-col items-center gap-1">
          <h2 className="text-[17px] lg:text-3xl font-bold text-center">
            {t("landing.verify.phoneVerifySuccessTitle")}
          </h2>
          <p className="text-[10px] lg:text-lg font-semibold text-center">
            {t("landing.verify.phoneVerifySuccessPrompt")}
          </p>
        </div>
        <Link
          to={
            callbackUrl
              ? `/signin?callbackURL=${encodeURIComponent(callbackUrl)}`
              : "/signin"
          }
          className="w-full text-white bg-[#499873] px-1 lg:py-4 font-semibold text-sm rounded-full py-3 text-center"
        >
          {t("landing.verify.backToLoginBtn")}
        </Link>
      </div>
    </div>
  );
};

export default PhoneVerified;
