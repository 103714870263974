import LoadingModal from "../../components/LoadingModal/LoadingModal";
import PaymentInfoModal from "../../components/payments/PaymentInfoModal";
import PaymentModal from "../../components/payments/PaymentModal";
import { SuccessModal } from "../../components/SuccessModal";
import { ApplicableAdminFeeTypes } from "../../types/applicable-admin-fee.enum";
import { useContainer } from "./useContainer";

export default function PaymentLink() {
    const {
        showPaymentInfoModal,
        showPaymentModal,
        paymentInfo,
        paymentData,
        paymentMethod,
        loading,
        success,
        setShowPaymentModal,
        setShowPaymentInfoModal,
        control,
        setValue,
        requestPayment,
        sellerData,
        setAdminFee
    } = useContainer();
    return <>
        <LoadingModal isLoading={loading} />\
        {paymentMethod && <PaymentModal
            setOpenPaymentModal={setShowPaymentModal}
            handleContinue={() => requestPayment()}
            handleSkip={() => {
                setShowPaymentModal(false)
                window.close();
            }}
            open={showPaymentModal}
            control={control}
            setValue={setValue}
            paymentAmount={paymentData?.amount}
            paymentCurrency={paymentData?.currency}
            paymentMethods={paymentMethod}
            sellerId={paymentData?.sellerId}
            handleChangePaymentFee={setAdminFee}
            applicableAdminFee={(sellerData as any)?.applicableAdminFee ?? ApplicableAdminFeeTypes.CHARGE_TO_SELLER}
            sellerCurrency={paymentData?.currency}
        />}
        {paymentInfo && <PaymentInfoModal
            open={showPaymentInfoModal}
            setOpenModal={setShowPaymentInfoModal}
            paymentInfo={paymentInfo}
            currency={paymentData?.currency}
            onFinish={() => window.close()}
        />}
        <SuccessModal
            open={success}
            title="Payment Successful"
            content="Thank you for your payment!"
        />
    </>;
}