import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Card,
    Chip,
    Divider,
    Dropdown,
    Grid,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    Stack,
    Typography,
} from "@mui/joy";
import { Add, MoreVert, PersonOutline } from "@mui/icons-material";
import { EmployeeService } from "../../services/employee.service";
import {
    EmployeeStatusTranslate,
    EmployeeStatusType,
    IEmployee,
} from "../../types/employee";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import toast from "react-hot-toast";
import showErrorToast from "../../components/Error";
import { SuccessModal } from "../../components/SuccessModal";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { useNavigate } from "react-router-dom";
import { BusinessSessionHelper } from "../../helpers/business-session.helper";
import { useTranslation } from "react-i18next";
import { RoleTypeTranslate } from "../../types/role-type";
import { If } from "../../components/Condition";
import { theme } from "../../components/Theme";

export default function Employee() {
    const [employees, setEmployees] = useState<IEmployee[] | null>(null);
    const navigate = useNavigate();

    const [selectedEmployee, setSelectedEmployee] = useState<IEmployee | null>(
        null
    );
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const { t } = useTranslation();

    const [isMobile, setIsMobile] = useState(
        window.innerWidth < theme.breakpoints.values.sm
    );
    useEffect(() => {
        if (window.innerWidth < theme.breakpoints.values.sm) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    const getEmloyees = () => {
        EmployeeService.getAll({}).then((data) => {
            setEmployees(data)
        })
    }

    const handleDelete = (e: IEmployee) => {
        setShowDeleteDialog(!showDeleteDialog);
        if (!showDeleteDialog === true) {
            setSelectedEmployee(e);
        } else {
            setSelectedEmployee(null);
        }
    };

    const onRemoveEmployee = () => {
        setLoading(true);
        const employeeId = selectedEmployee?.id;
        setSelectedEmployee(null);
        EmployeeService.remove({
            employeeId: employeeId ?? "",
            businessId: BusinessSessionHelper.GetBusinessId() ?? "",
        })
            .then(() => {
                setLoading(false);
                setSuccessMessage(t("manage.employee.removeSuccessDesc"));
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                    setSuccessMessage("");
                    getEmloyees();
                }, 2000);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                toast(showErrorToast(err), { duration: 4000 });
            });
    };

    const onCancelInvitation = (e: IEmployee) => {
        setLoading(true);
        EmployeeService.cancelInvitation({
            employeeId: e.id as string,
            businessId: BusinessSessionHelper.GetBusinessId() ?? "",
        })
            .then(() => {
                setSuccessMessage(t("manage.employee.invitationCanceledDesc"));
                setLoading(false);
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                    getEmloyees();
                    setSuccessMessage("");
                }, 2000);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                toast(showErrorToast(err), { duration: 4000 });
            });
    };

    const onResendInvitation = (e: IEmployee) => {
        setLoading(true);
        EmployeeService.resendInvitation({ email: e.email })
            .then(() => {
                setLoading(false);
                setSuccessMessage(t("manage.employee.invitationResentDesc"));
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                    setSuccessMessage("");
                    getEmloyees();
                }, 2000);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                toast(showErrorToast(err), { duration: 4000 });
            });
    };

    useEffect(() => {
        if (!employees) getEmloyees();
    }, [employees]);

    const employeeStatusColor = (status: EmployeeStatusType) => {
        switch (status) {
            case EmployeeStatusType.Active:
                return "success";
            case EmployeeStatusType.Invited:
                return "warning";
            case EmployeeStatusType.InvitationExpired:
                return "danger";
            default:
                return "neutral";
        }
    };

    const EmptyState = () => (
        <Stack
            sx={{ width: { xs: "90vw", lg: "100%" } }}
            gap={2}
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Typography level="title-lg">
                {t("manage.employee.emptyStateTitle")}
            </Typography>
            <Typography level="body-sm">
                {t("manage.employee.emptyStateDescription")}
            </Typography>
        </Stack>
    );

    return (
        <React.Fragment>
            <Divider />
            <Stack
                marginTop={5}
                justifyContent={{ xs: "center", lg: "start" }}
                alignItems={"start"}
                direction={"column"}
                gap={5}
            >
                <If
                    condition={BusinessSessionHelper.RoleResourcePermissions(
                        "employee",
                        "CREATE"
                    )}
                >
                    <Button
                        sx={{ maxWidth: 300, width: "100%", mx: { xs: "auto", lg: 0 } }}
                        component="a"
                        href="employees/add"
                        startDecorator={<Add />}
                    >
                        {t("manage.employee.addUserBtn")}
                    </Button>
                </If>
                <Grid
                    container
                    // spacing={2}
                    gap={3}
                    sx={{
                        flexGrow: 1,
                        marginLeft: "0.1rem",
                        width: { xs: "90vw", lg: "100%" },
                    }}
                >
                    {(employees?.length ?? 0) > 0 ? (
                        employees?.map((e, index) => (
                            <Card
                                key={index}
                                sx={{
                                    flex: 1,
                                    maxWidth: 400,
                                    width: { xs: "90vw", lg: "100%" },
                                    borderRadius: 16,
                                    backgroundColor: "white",
                                }}
                            >
                                <Stack
                                    gap={3}
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                >
                                    <Avatar color="neutral" size={isMobile ? "md" : "lg"}>
                                        <PersonOutline />
                                    </Avatar>
                                    <Stack
                                        sx={{ width: "100%" }}
                                        flex={1}
                                        direction={"column"}
                                        justifyContent={"streatch"}
                                        alignItems={"start"}
                                    >
                                        <Typography level="title-md">{e?.fullName}</Typography>
                                        <Typography level="body-sm">{e?.email}</Typography>
                                        <Typography level="body-sm">
                                            +({e?.phoneNumberCountryCode}) {e?.phoneNumber}
                                        </Typography>
                                        <Box sx={{ marginTop: "0.2rem" }} display={"flex"} gap={1}>
                                            <Chip size="sm" variant="outlined" color="primary">
                                                {t(RoleTypeTranslate[e.role])}
                                            </Chip>
                                            {e?.isOwner && (
                                                <Chip size="sm" variant="soft" color="danger">
                                                    {t("manage.employee.owner")}
                                                </Chip>
                                            )}
                                        </Box>
                                        <Chip
                                            sx={{ marginTop: "0.2rem" }}
                                            size="sm"
                                            variant="soft"
                                            color={employeeStatusColor(e?.status)}
                                        >
                                            {t(EmployeeStatusTranslate[e.status!])}
                                        </Chip>
                                    </Stack>
                                    {BusinessSessionHelper.Account()?.id !== e?.id && (
                                        <If
                                            condition={BusinessSessionHelper.RoleResourcePermissions(
                                                "employee",
                                                "UPDATE"
                                            )}
                                        >
                                            <Dropdown>
                                                <Avatar>
                                                    <MenuButton
                                                        variant="plain"
                                                        slots={{ root: IconButton }}
                                                        slotProps={{
                                                            root: { variant: "plain", color: "neutral" },
                                                        }}
                                                    >
                                                        <MoreVert sx={{ rotate: "90deg" }} />
                                                    </MenuButton>
                                                </Avatar>
                                                <Menu size="sm">
                                                    <MenuItem
                                                        onClick={() =>
                                                            navigate(`/client/employees/update/${e?.id}`, {
                                                                state: { employee: e },
                                                            })
                                                        }
                                                    >
                                                        {t("commons.editBtn")}
                                                    </MenuItem>
                                                    {e?.status === EmployeeStatusType.Active && (
                                                        <>
                                                            <MenuItem onClick={() => handleDelete(e)}>
                                                                {t("commons.removeBtn")}
                                                            </MenuItem>
                                                        </>
                                                    )}
                                                    {[
                                                        EmployeeStatusType.Invited,
                                                        EmployeeStatusType.InvitationExpired,
                                                    ].includes(e?.status) && (
                                                            <>
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        onResendInvitation(e);
                                                                    }}
                                                                >
                                                                    {t("manage.employee.resendInvitationBtn")}
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        onCancelInvitation(e);
                                                                    }}
                                                                >
                                                                    {t("manage.employee.cancelInvitationBtn")}
                                                                </MenuItem>
                                                            </>
                                                        )}
                                                </Menu>
                                            </Dropdown>
                                        </If>
                                    )}
                                </Stack>
                            </Card>
                        ))
                    ) : (
                        <EmptyState />
                    )}
                </Grid>
            </Stack>
            <ConfirmationModal
                title={`${t("manage.employee.removeTitle")} ${selectedEmployee?.fullName
                    }`}
                content={`${t("manage.employee.removeDesc")} ${selectedEmployee?.fullName
                    }?`}
                open={selectedEmployee !== null}
                onConfirm={onRemoveEmployee}
                onCancel={() => {
                    setSelectedEmployee(null);
                }}
            />
            <SuccessModal
                open={success}
                title={t("commons.successModalTitle")}
                content={successMessage}
            />
            <LoadingModal isLoading={loading} />
        </React.Fragment>
    );
}
