import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
import Tabs from "@mui/joy/Tabs";
import { Helmet } from "react-helmet";
import Shared from "../layouts/Shared";

export default function Notis() {
  return (
    <Shared title="Notifications">
      <Helmet
        link={[
          { href: "https://linkzasia.com/notifications", rel: "canonical" },
        ]}
        meta={[{ content: "Notifications", name: "description" }]}
        title="Notifications - Linkz"
      />
      <Tabs
        aria-label="transactions"
        defaultValue={0}
        sx={{
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
        }}
      >
        <TabList
          sx={{
            [`&& .${tabClasses.root}`]: {
              "&:hover": {
                bgcolor: "transparent",
              },
              [`&.${tabClasses.selected}`]: {
                "&::after": {
                  bgcolor: "primary.500",
                  borderTopLeftRadius: 3,
                  borderTopRightRadius: 3,
                  height: 2,
                },
                color: "primary.plainColor",
              },
              bgcolor: "transparent",
              flex: "initial",
              paddingY: 2,
            },
            justifyContent: "center",
            paddingTop: 1,
          }}
        >
          <Tab>All</Tab>
          <Tab>Unread</Tab>
          <Tab>Read</Tab>
        </TabList>
        <TabPanel value={0}>
          <div>One</div>
        </TabPanel>
        <TabPanel value={1}>
          <div>Two</div>
        </TabPanel>
        <TabPanel value={2}>
          <div>Three</div>
        </TabPanel>
      </Tabs>
    </Shared>
  );
}
