import axios from "axios";
import { ApiConstants } from "../utils/constants";
import { PaymentType } from "./payment.service";
import { privateAxios } from "../lib/api";

export class PaymentLinkService {
  static axiosService = () =>
    axios.create({
      baseURL: import.meta.env.VITE_API_URL,
      // withCredentials: true,
    });

  static createPaymentRequestPayload({
    method,
    option,
    redirectUrl,
    adminFee
  }: {
    method: string;
    option: string;
    redirectUrl?: string;
    adminFee?: number;
  }) {
    var methodPayload = {};
    switch (method) {
      case "va":
        methodPayload = {
          va: {
            bankCode: option,
          },
        };
        break;
      case "e-wallet":
        methodPayload = {
          ewallet: {
            channel: option,
            phoneNumber: "",
          },
        };
        break;
      case "retail":
        methodPayload = {
          retail: {
            channel: option,
            phoneNumber: "",
          },
        };
    }
    return {
      redirectUrl: redirectUrl,
      paymentMethod: method,
      adminFee: adminFee,
      ...methodPayload,
    };
  }
  static async getMethodList(link: string) {
    const response = await PaymentLinkService.axiosService().get(
      ApiConstants.paymentUrl + "/payment-link/method-list",
      {
        headers: {
          Authorization: `${link}`,
        },
      }
    );
    return response.data;
  }

  static async verifyLink(Link: string) {
    const response = await PaymentLinkService.axiosService().get(
      ApiConstants.paymentUrl + "/payment-link/verify",
      {
        headers: {
          Authorization: `${Link}`,
        },
      }
    );
    return response.data;
  }

  static async getPaymentInfo(link: string) {
    const response = await PaymentLinkService.axiosService().get(
      ApiConstants.paymentUrl + "/payment-link/request-payment/info",
      {
        headers: {
          Authorization: `${link}`,
        },
      }
    );
    return response.data;
  }

  static async requestPayment(link: string, payload: any) {
    const payloadData = this.createPaymentRequestPayload(payload);
    const response = await PaymentLinkService.axiosService().post(
      ApiConstants.paymentUrl + "/payment-link/request-payment",
      payloadData,
      {
        headers: {
          Authorization: `${link}`,
        },
      }
    );
    return response.data;
  }

  static async getPaymentLinkByTransactionID(
    transactionId: string,
    type: PaymentType
  ) {
    const response = await privateAxios.get(
      ApiConstants.paymentUrl + `/payment-link/transaction/${transactionId}`,
      {
        params: {
          paymentType: type,
        },
      }
    );
    return response.data;
  }
}
