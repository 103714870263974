export function matchesFormat(
  number: string,
  format: string,
  countryCode: string
) {
  // Remove '+', '-', '(', and ')' from the format
  let cleanedFormat = format.replace(/[+\-()]/g, "");

  // Count the number of dots in the cleaned format
  let dotCount = (cleanedFormat.match(/\./g) || []).length;

  // Convert the number to a string and count its length
  let numberLength = number.toString().length;
  if (countryCode === "95") {
    return numberLength >= 12 && numberLength <= 16;
  } else if (countryCode === "62") {
    return numberLength >= 10 && numberLength <= 15;
  } else if (countryCode === "60") {
    return numberLength >= 10 && numberLength <= 13;
  }
  return numberLength === dotCount;
}

export function formatPercentageInput(val: string) {
  /** Replace non-digits (alphabets) first, and then match with regex for decimal digit format */
  const validInput = val
    .replace(/[^0-9.]/g, "")
    .match(/^(100(\.0{0,2})?|(\d{0,2})(\.\d{0,2})?)$/);

  /** If the input matches, keep it; otherwise, remove the last input character */
  if (validInput) {
    return validInput[0];
  } else {
    return val.slice(0, -1);
  }
}

export function setDefaultCurrency(country: any) {
  const countryToCurrencyList: { [key: string]: string } = {
    ID: "IDR",
    MY: "MYR",
    PH: "PHP",
    TH: "THB",
    VN: "VND",
    US: "USD",
    SG: "SGD",
    HK: "HKD",
    JP: "JPY",
  };

  // if (business.bankInfo?.[0]?.currency !== "") {
  //   console.log("BANKINFO", business.bankInfo?.[0]?.currency);
  //   return business.bankInfo?.[0]?.currency;
  // }

  return countryToCurrencyList[country];
}

export const getInitials = (name: string) => {
  const nameParts = (name || "").trim().split(" ");
  if (nameParts.length === 1) {
    return (nameParts[0][0] ?? "").toUpperCase();
  }
  return (
    nameParts[0][0] + (nameParts[nameParts.length - 1][0] || "")
  ).toUpperCase();
};

/** For Smart Search Mechanism with Regex */
export const normalizeSearchString = (
  searchString: string | string[]
): string | string[] => {
  if (Array.isArray(searchString)) {
    return searchString.map((str) => {
      return str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
    });
  }
  return searchString.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
};
