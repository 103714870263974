import {
  Box,
  Button,
  Input,
  Modal,
  Snackbar,
  Stack,
  Table,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateItemType, ItemType } from "../../../types/item";
import { InfoOutlined } from "@mui/icons-material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { validateDuplicateSku } from "../../../utils/excel";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  width: "70%",
  // maxWidth: 900,
  px: 4,
  py: 4,
  borderRadius: "32px",
  borderColor: "transparent",
  outline: "none",
};

interface Props {
  show?: boolean;
  useDatabaseSku: boolean;
  items: {
    processedData: CreateItemType[];
    duplicates: string[];
  };
  databaseSku: string[];
  onClose: () => void;
  onClick: (data: ItemType[]) => void;
}

type FormValues = {
  items: { sku: string }[];
};

const DuplicateSKUModal = ({
  show = false,
  useDatabaseSku = true,
  items,
  databaseSku,
  onClose,
  onClick,
}: Props) => {
  const { processedData, duplicates } = items;
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const methods = useForm<FormValues>({
    defaultValues: {
      items: duplicates.map((sku) => ({ sku })),
    },
  });
  const {
    control,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = methods;
  const { fields } = useFieldArray({
    control,
    name: "items",
  });
  const onSubmit = (data: FormValues) => {
    const assignedNewSku = data.items.map((item, index) => {
      return {
        ...processedData[index],
        sku: item.sku,
      };
    });

    const validateDuplicate = validateDuplicateSku(
      assignedNewSku,
      useDatabaseSku ? databaseSku : undefined
    );
    
    if (validateDuplicate.length === 0) {
      // console.log("VALIDATED DATA", assignedNewSku);
      setShowModal(false);
      reset({ items: [] });
      onClick(assignedNewSku as ItemType[]);
    } else {
      setError(
        `items`,
        {
          type: "manual",
          message: t("catalog.inventory.excelUploadedDuplicatePrompt"),
        },
        { shouldFocus: true }
      );

      setTimeout(() => {
        clearErrors();
      }, 2000);
    }
  };

  useEffect(() => {
    if (duplicates) {
      reset({ items: duplicates.map((sku) => ({ sku })) });
    }
  }, [duplicates, reset]);

  const handleClose = () => {
    reset({ items: [] });
    setShowModal(false);
    onClose();
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return (
    <Modal
      open={showModal}
      style={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} justifyContent={"center"}>
        <Typography
          mt={4}
          textAlign={"left"}
          fontWeight={600}
          fontSize={24}
          id="modal-modal-title"
          sx={{
            color: "#265641",
          }}
        >
          {t("catalog.inventory.excelUploadedDuplicate")}
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              if (!(event.target instanceof HTMLTextAreaElement)) {
                event.preventDefault();
              }
            }
          }}
        >
          <div className="w-full flex flex-col">
            {/* copy link  */}
            <Stack
              direction={"column"}
              justifyContent={"left"}
              gap={4}
              mt={4}
              height={"36vh"}
              overflow={"auto"}
            >
              <div
                className={`w-full px-4 py-2 bg-[#CFE5DA] rounded-md flex items-center`}
              >
                <InfoOutlined
                  style={{
                    marginRight: "0.5rem",
                    width: 24,
                    height: 24,
                  }}
                />
                <p>{t("catalog.inventory.excelUploadedDuplicatePrompt")}</p>
              </div>
              <Table
                borderAxis="both"
                sx={{
                  "& th": { textAlign: "center" },
                  minWidth: "100%",
                }}
              >
                <thead>
                  <tr key={"header"}>
                    <th key={"productName"} className="w-48">
                      {t("catalog.items.productName")}
                    </th>
                    <th key={"sku"} className="w-24 lg:w-36">
                      {t("catalog.items.sku")}
                    </th>
                    <th key={"duplicate_sku"} className="w-24 lg:w-36">
                      {t("catalog.items.duplicateSku")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((field, index) => {
                    if (duplicates[index] !== processedData[index].sku) {
                      return (
                        <tr key={field.id}>
                          <td>{processedData[index].productName}</td>
                          <td>{processedData[index].sku}</td>
                          <td>
                            <Controller
                              name={`items.${index}.sku`}
                              control={control}
                              render={({ field }: { field: any }) => (
                                <Input
                                  {...field}
                                  defaultValue={field.sku}
                                  size="sm"
                                />
                              )}
                            />
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
            </Stack>

            {/* buttons */}
            <Stack direction={"row"} justifyContent={"left"} mt={4}>
              <Button
                type="button"
                variant="plain-gray"
                className="ml-auto"
                onClick={handleClose}
              >
                {t("commons.cancelBtn")}
              </Button>
              <Button
                type="submit"
                sx={{
                  marginLeft: "2rem",
                  padding: 1.5,
                }}
              >
                {t("commons.confirmBtn")}
              </Button>
            </Stack>
          </div>
        </form>

        <Snackbar
          variant="solid"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={errors.items !== undefined}
          key={"asjfkangdlknakjrsh"}
          sx={{
            width: "40%",
            maxWidth: 800,
          }}
        >
          <Box width={"100%"}>
            <Typography
              textAlign={"center"}
              textColor={"common.white"}
              fontSize={14}
            >
              {errors.items?.message}
            </Typography>
          </Box>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default DuplicateSKUModal;
