import { PlusIcon } from "@heroicons/react/24/solid";
import { Button, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";

type Props = {
  onClick: () => void;
};

const CreateInventoryDropzone = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="border-[1px] hover:border-[#6BAB8D] ease-in-out duration-300 lg:w-[29.93rem] min-h-[20rem] lg:h-[34.37rem] rounded-lg flex flex-col justify-between items-center cursor-pointer py-[4.5rem] px-20">
      <Typography fontSize={18} fontWeight={700} sx={{ color: "#101828" }}>
        {t("catalog.inventory.empty.option")} 2
      </Typography>
      <img
        src="/single.png"
        width={100}
        height={100}
        className="object-contain"
      />
      <div className="flex flex-col  gap-[4px] text-center w-full">
        <Typography fontSize={18} fontWeight={600} sx={{ color: "#101828" }}>
          {t("catalog.inventory.empty.option2")}
        </Typography>
        <p className="text-sm">{t("catalog.inventory.empty.option2Prompt")}</p>
      </div>

      {/* <Link to={`/client/${BrowserRouterConstants.InventoryCreate}`}> */}
      <Button
        onClick={onClick}
        startDecorator={<PlusIcon width={18} height={18} />}
        className="whitespace-nowrap"
      >
        {t("catalog.inventory.empty.startNowBtn")}
      </Button>
      {/* </Link> */}
    </div>
  );
};

export default CreateInventoryDropzone;
