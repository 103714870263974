import { TempAccountType } from "../components/Inventory/ShareModal";
import { privateAxios, publicAxios } from "../lib/api";
import { ApiConstants } from "../utils/constants";

export enum InvitationType {
  USER_INVITATION = "USER_INVITATION",
  BUSINESS_CONNECTION_INVITATION = "BUSINESS_CONNECTION_INVITATION",
}

export class AccountService {
  static async getAccountInfo(accountId: string | null) {
    return await privateAxios.get(
      `${ApiConstants.accountURL}/${accountId}/info`
    );
  }

  static async getAccountList() {
    return await privateAxios.get(`${ApiConstants.accountURL}`);
  }

  static async getAccountListByCurrentBusinessInternal(
    businessId: string | null
  ) {
    const response = await privateAxios.get(
      `${ApiConstants.accountURL}/${businessId}`
    );
    return response.data;
  }

  static async getAccountListByCurrentBusinessExternal(
    businessId: string | null
  ) {
    return await privateAxios.get(
      `${ApiConstants.accountURL}/${businessId}/external`
    );
  }

  // TODO: use actual AccountType
  static async getAccountByEmail(email: string) {
    const response = await publicAxios.get(
      `${ApiConstants.accountURL}/search`,
      {
        params: {
          email: email,
        },
      }
    );

    return response.data as TempAccountType[];
  }

  static async switchBusinessProfile(businessId: string) {
    return privateAxios.post(
      `${ApiConstants.switchBusinessProfileURL}/${businessId}`
    );
  }

  static async inviteUserToConnect(data: {
    email: string;
    type: InvitationType;
    metadata: any;
  }) {
    return privateAxios.post(`${ApiConstants.accountURL}/invite`, data);
  }

  static async getBusinessByAccountEmail(email: string) {
    const response = await publicAxios.get(
      `${ApiConstants.accountURL}/busineses-by-email`,
      {
        params: {
          email: email,
        },
      }
    );

    return response.data;
  }
}
